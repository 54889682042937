import React from 'react';
import { Modal, Button /*, Alert*/ } from 'react-bootstrap';
import PointDetails from '../../shared/PointDetails';

import './Modal.css';

function MapModal(props) {
  //console.log("THIS IS DATA IN MODAAAAAAAL");
  //console.log(props);
  // let buttonWithAlert;
  // switch (props.alert) {
  //   case 'success':
  //     buttonWithAlert = (
  //       <div>
  //         <Button className="float-right" style={{ margin: '5px' }} disabled>
  //           Save
  //         </Button>{' '}
  //         <Alert sm={2} className="float-right" variant="success">
  //           Data successfully saved!
  //         </Alert>
  //       </div>
  //     );
  //     break;
  //   case 'saved':
  //     buttonWithAlert = (
  //       <div>
  //         <Button className="float-right" style={{ margin: '5px' }} disabled>
  //           Save
  //         </Button>{' '}
  //         <Alert sm={2} className="float-right" variant="warning">
  //           Oy chico, Property already saved.
  //         </Alert>
  //       </div>
  //     );
  //     break;
  //   default:
  //     buttonWithAlert = (
  //       <Button
  //         onClick={props.onButtonSave}
  //         className="float-right"
  //         style={{ margin: '5px' }}
  //       >
  //         Save
  //       </Button>
  //     );
  // }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Property Details:
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <PointDetails
          data={props.freehold}
          userNotesOnChange={props.userNotesOnChange}
          userNotes={props.userNotes}
          json={props.json}
          notesFromDB={props.notesFromDB}
        />
        {/* <Tabs defaultActiveKey="freehold" transition={false}>
          <Tab eventKey="freehold" title="Freehold">
            {freeholdData}
          </Tab>
          <Tab eventKey="leasehold" title="Leaseholds">
          {props.freehold.leaseholds ? props.freehold.leaseholds.map(lease => 
          <Collapsible trigger={"Address: " + lease.address+"    click for leasehold details"}>
            <p>Proprietor: {lease.proprietor}</p>
            <p>Company Number: {lease.companyNo}</p>
          </Collapsible>) : null}
          </Tab>
        </Tabs> */}

        <Button
          onClick={props.onHide}
          className="float-right"
          style={{ margin: '5px' }}
        >
          Close
        </Button>
        {/* {props.alert==="success" ? <div> 
          <Button onClick={props.onButtonSave} className="float-right" style={{margin: "5px"}} disabled>Save</Button> <Alert sm={2} className="float-right" style={{margin: "5px"}} variant="success">
          Data successfully saved!
          </Alert></div> 
        : <Button onClick={props.onButtonSave} className="float-right" style={{margin: "5px"}}>Save</Button>} */}
        {/* {buttonWithAlert} */}
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onButtonSave}>Save</Button>{' '}
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default MapModal;
