import React from 'react';
import { Button, Container } from 'react-bootstrap';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const needsEmailVerification = (authUser) =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map((provider) => provider.providerId)
    .includes('password');

const withEmailVerification = (Component) => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }));
    };

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) =>
            needsEmailVerification(authUser) ? (
              <Container>
                {this.state.isSent ? (
                  <p>
                    Email confirmation sent: Check your email (including Spam
                    folder) for a confirmation email. Refresh page once you
                    confirmed your email.
                  </p>
                ) : (
                  <p>
                    Verify your Email: Check email (Spam folder included) for
                    confirmation email or send a new one.
                  </p>
                )}

                <Button
                  type="button"
                  onClick={this.onSendEmailVerification}
                  disabled={this.state.isSent}
                >
                  Send confirmation E-Mail
                </Button>
              </Container>
            ) : (
              <Component {...this.props} />
            )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
