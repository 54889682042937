import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Button } from 'react-bootstrap';

import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { AuthUserContext } from '../Session';
//import Logo from '../Logo/LogoNav';

const Navigation = ({ authUser }) => (
  <div>
    <AuthUserContext.Consumer>
      {(authUser) =>
        authUser ? (
          <NavigationAuth authUser={authUser} />
        ) : (
          <NavigationNonAuth />
        )
      }
    </AuthUserContext.Consumer>
  </div>
);

const NavigationAuth = ({ authUser }) => (
  <Navbar bg="dark" variant="dark">
    <Navbar.Brand href="#landing" as={Link} to={ROUTES.LANDING}>
      NOCO
    </Navbar.Brand>
    <Nav variant="pills" className="mr-auto">
      <Nav.Link href="#map" as={Link} to={ROUTES.HOME}>
        Map
      </Nav.Link>
      {/* <Nav.Link href="#savedPoints" as={Link} to={ROUTES.SAVED}>
        Saved Properties
      </Nav.Link> 
      <Nav.Link href="#portofolio" as={Link} to={ROUTES.PORTOFOLIO}>
        Portofolio
      </Nav.Link>*/}
      <Nav.Link href="#account" as={Link} to={ROUTES.ACCOUNT}>
        Account
      </Nav.Link>
      {!!authUser.roles[ROLES.ADMIN] && (
        <Nav.Link href="#admin" as={Link} to={ROUTES.ADMIN}>
          Admin
        </Nav.Link>
      )}
    </Nav>
    <Nav>
      <SignOutButton />
    </Nav>
  </Navbar>
  // <ul>
  //   <li>
  //     <Link to={ROUTES.LANDING}>Landing</Link>
  //   </li>
  //   <li>
  //     <Link to={ROUTES.HOME}>Map</Link>
  //   </li>
  //   <li>
  //     <Link to={ROUTES.ACCOUNT}>Account</Link>
  //   </li>
  //   {!!authUser.roles[ROLES.ADMIN] && (
  //     <li>
  //     <Link to={ROUTES.ADMIN}>Admin</Link>
  //     </li>
  //   )}
  //   <li>
  //     <SignOutButton />
  //   </li>
  // </ul>
);

const NavigationNonAuth = () => (
  <Navbar bg="dark" variant="dark">
    <Navbar.Brand as={Link} to={ROUTES.LANDING}>
      NOCO
    </Navbar.Brand>
    <Nav className="mr-auto"></Nav>
    <Nav>
      <Button variant="outline-primary" as={Link} to={ROUTES.SIGN_IN}>
        Sign in
      </Button>
    </Nav>
  </Navbar>
);

export default Navigation;
