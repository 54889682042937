import React from 'react';
import {
  InputGroup,
  FormControl,
  Tab,
  Row,
  Col,
  ListGroup,
} from 'react-bootstrap';
//import Accordion from '../components/Accordion';

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const dataTabs = (props) => {
  // console.log("THIS IS DATA IN TABS");
  // console.log(props);
  //console.log(props.data.leasehold[0] ? true : false);
  let mapLink = `https://www.google.com/maps/@${props.json.long},${props.json.lat},400m/data=!3m1!1e3`;

  //https://www.google.com/maps/@38.9517374,-9.3982374,400m/data=!3m1!1e3
  if (props.json.County === 'LINCOLNSHIRE') {
    mapLink = `https://www.google.com/maps/@${props.json.lat},${props.json.long},400m/data=!3m1!1e3`;
  }

  return (
    <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link0">
      <Row>
        <Col sm={3}>
          <ListGroup>
            <ListGroup.Item action href="#link0">
              Company Information
            </ListGroup.Item>
            <ListGroup.Item action href="#link1">
              Financial Information
            </ListGroup.Item>
            <ListGroup.Item action href="#link2">
              Freeholder Details
            </ListGroup.Item>
            {props.data.leaseholds ? (
              <ListGroup.Item action href="#link3">
                Leaseholder Details
              </ListGroup.Item>
            ) : null}
            <ListGroup.Item action href={mapLink} target="_blank">
              View Property
            </ListGroup.Item>
            <ListGroup.Item action href="#link4">
              Notes
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane
              eventKey="#link0"
              style={{ maxHeight: '400px', overflowY: 'auto' }}
            >
              <ListGroup>
                {/* {Object.entries(props.json).map(([key, value]) => {
                  if (key === 'SIC 2007 Description') {
                    return (
                      <ListGroup.Item className="bg-dark">
                        <strong>Business description:</strong> {value}
                      </ListGroup.Item>
                    );
                  } else if (key === 'Industry Keywords') {
                    return (
                      <ListGroup.Item className="bg-dark">
                        <strong>Industry keywords: </strong> {value}
                      </ListGroup.Item>
                    );
                  } else if (key === 'Capacity (kWp)') {
                    return (
                      <ListGroup.Item className="bg-dark">
                        <strong>Estimated Capacity (kWp): </strong>{' '}
                        {parseInt(value, 10)}
                      </ListGroup.Item>
                    );
                  } else if (key === 'Shareholders Names') {
                    return (
                      <ListGroup.Item className="bg-dark">
                        <strong>Shareholders: </strong> {value}
                      </ListGroup.Item>
                    );
                  } else if (key === 'Credit Risk') {
                    return (
                      <ListGroup.Item className="bg-dark">
                        <strong>Credit Risk: </strong> {value}
                      </ListGroup.Item>
                    );
                  } else if (key === 'Charges') {
                    return (
                      <ListGroup.Item className="bg-dark">
                        <strong>Charges: </strong> {value}
                      </ListGroup.Item>
                    );
                  } else if (key === 'Dormant Company') {
                    return (
                      <ListGroup.Item className="bg-dark">
                        <strong>Dormant Company: </strong> {value}
                      </ListGroup.Item>
                    );
                  } else if (key === 'Web Address 1' && value !== '0') {
                    return (
                      <ListGroup.Item className="bg-dark">
                        <strong>Website: </strong>{' '}
                        <a
                          href={value}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {value}
                        </a>
                      </ListGroup.Item>
                    );
                  } else return null;
                })} */}
                <ListGroup>
                  {props.data.proprietorName ? (
                    <ListGroup.Item className="bg-dark">
                      <strong>Company name:</strong> {props.data.proprietorName}
                    </ListGroup.Item>
                  ) : null}
                  <ListGroup.Item className="bg-dark">
                    <strong>Business description: </strong>{' '}
                    {props.json['SIC 2007 Description']}
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark">
                    <strong>Industry keywords: </strong>{' '}
                    {props.json['Industry Keywords']}
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark">
                    <strong>Estimated Capacity (kWp): </strong>{' '}
                    {props.json.County === 'LINCOLNSHIRE'
                      ? numberWithCommas(
                          parseInt(
                            (parseInt(props.json['Roof.Size'], 10) * 140) /
                              1000,
                            10
                          )
                        )
                      : numberWithCommas(
                          parseInt(
                            (parseInt(props.json['Roof Size'], 10) * 140) /
                              1000,
                            10
                          )
                        )}
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark">
                    <strong>Shareholders: </strong>{' '}
                    {props.json['Shareholders Names']}
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark">
                    <strong>Credit Risk: </strong> {props.json['Credit Risk']}
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark">
                    <strong>Charges: </strong> {props.json['Charges']}
                  </ListGroup.Item>
                  {props.json.County !== 'STAFFORDSHIRE' &&
                  props.json.County !== 'WEST MIDLANDS' ? (
                    <ListGroup.Item className="bg-dark">
                      <strong>Dormant Company: </strong>{' '}
                      {props.json['Dormant Company']}
                    </ListGroup.Item>
                  ) : null}
                  {/* <ListGroup.Item className="bg-dark">
                    <strong>Dormant Company: </strong>{' '}
                    {props.json['Dormant Company']}
                  </ListGroup.Item> */}
                  {props.json['Web Address 1'] !== '0' &&
                  props.json['Web Address 1'] !== '#N/A' ? (
                    <ListGroup.Item className="bg-dark">
                      <strong>Website: </strong>{' '}
                      <a
                        href={props.json['Web Address 1']}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {props.json['Web Address 1']}
                      </a>
                    </ListGroup.Item>
                  ) : null}
                </ListGroup>
                {/* <ListGroup.Item className="bg-dark">
                  <strong>Business Type:</strong> {props.json['Business Type']}
                </ListGroup.Item>
                <ListGroup.Item className="bg-dark">
                  <strong>MWh per annum:</strong> {props.json['MWh per annum']}
                </ListGroup.Item>
                <ListGroup.Item className="bg-dark">
                  <strong>Average Load (kWh):</strong>{' '}
                  {props.json['Average Load (kWh)']}{' '}
                </ListGroup.Item>
                <ListGroup.Item className="bg-dark">
                  <strong>Roof Size (Sq. M):</strong>{' '}
                  {props.json['Roof Size (Sq. M)']}
                </ListGroup.Item>
                <ListGroup.Item className="bg-dark">
                  <strong>Directors:</strong> {props.json['Directors']}
                </ListGroup.Item> */}
                {/* <ListGroup.Item className="bg-dark"><strong>Freeholder:</strong> {props.json['Freeholder']}</ListGroup.Item> 
                <ListGroup.Item className="bg-dark"><strong>Leaseholder:</strong> {props.json['Leaseholder']}</ListGroup.Item>   */}
              </ListGroup>
            </Tab.Pane>
            <Tab.Pane
              eventKey="#link1"
              style={{ maxHeight: '400px', overflowY: 'auto' }}
            >
              <ListGroup>
                <ListGroup.Item className="bg-dark">
                  <strong>EBITDA: </strong> £{' '}
                  {numberWithCommas(props.json['EBITDA'])}
                </ListGroup.Item>
                <ListGroup.Item className="bg-dark">
                  <strong>Cost of Sales: </strong> £{' '}
                  {numberWithCommas(props.json['Cost of Sales'])}
                </ListGroup.Item>
                <ListGroup.Item className="bg-dark">
                  <strong>Capital Employed: </strong> £{' '}
                  {numberWithCommas(props.json['Capital Employed'])}{' '}
                </ListGroup.Item>
                <ListGroup.Item className="bg-dark">
                  <strong>Cash: </strong> £{' '}
                  {numberWithCommas(props.json['Cash'])}
                </ListGroup.Item>
                <ListGroup.Item className="bg-dark">
                  <strong>Current Assets: </strong> £{' '}
                  {numberWithCommas(props.json['Cash'])}
                </ListGroup.Item>
                <ListGroup.Item className="bg-dark">
                  <strong>Current Liabilities: </strong> £{' '}
                  {numberWithCommas(props.json['Current Liabilities'])}
                </ListGroup.Item>
                <ListGroup.Item className="bg-dark">
                  <strong>Net Assets:</strong> £{' '}
                  {numberWithCommas(props.json['Net Assets'])}
                </ListGroup.Item>
              </ListGroup>
            </Tab.Pane>
            <Tab.Pane
              eventKey="#link2"
              style={{ maxHeight: '400px', overflowY: 'auto' }}
            >
              <ListGroup>
                {props.data.proprietorName ? (
                  <ListGroup.Item className="bg-dark">
                    <strong>Proprietor name:</strong>{' '}
                    {props.data.proprietorName}
                  </ListGroup.Item>
                ) : null}
                {props.data.proprietorshipCategory ? (
                  <ListGroup.Item className="bg-dark">
                    <strong>Proprietorship category:</strong>{' '}
                    {props.data.proprietorshipCategory}
                  </ListGroup.Item>
                ) : null}
                {props.data.companyRegistrationNumber ? (
                  <ListGroup.Item className="bg-dark">
                    <strong>Company registration number:</strong>{' '}
                    {props.data.companyRegistrationNumber}
                  </ListGroup.Item>
                ) : null}
                {props.data.roofSize ? (
                  <ListGroup.Item className="bg-dark">
                    <strong>Roof size:</strong> {props.data.roofSize}
                  </ListGroup.Item>
                ) : null}
                {props.data.region ? (
                  <ListGroup.Item className="bg-dark">
                    <strong>Region:</strong> {props.data.region}
                  </ListGroup.Item>
                ) : null}
                {props.data.county ? (
                  <ListGroup.Item className="bg-dark">
                    <strong>County:</strong> {props.data.county}
                  </ListGroup.Item>
                ) : null}
                {props.data.district ? (
                  <ListGroup.Item className="bg-dark">
                    <strong>District:</strong> {props.data.district}
                  </ListGroup.Item>
                ) : null}
                {props.data.dateProprietorAdded ? (
                  <ListGroup.Item className="bg-dark">
                    <strong>Date proprietor added:</strong>{' '}
                    {props.data.dateProprietorAdded}
                  </ListGroup.Item>
                ) : null}
                {props.data.multipleAddressIndicator ? (
                  <ListGroup.Item className="bg-dark">
                    <strong>Multiple address Indicator:</strong>{' '}
                    {props.data.multipleAddressIndicator}
                  </ListGroup.Item>
                ) : null}
                {props.data.postcode ? (
                  <ListGroup.Item className="bg-dark">
                    <strong>Postcode:</strong> {props.data.postcode}
                  </ListGroup.Item>
                ) : null}
                {props.data.pricePaid ? (
                  <ListGroup.Item className="bg-dark">
                    <strong>Price paid:</strong> {props.data.pricePaid}
                  </ListGroup.Item>
                ) : null}
              </ListGroup>
            </Tab.Pane>
            <Tab.Pane
              eventKey="#link3"
              style={{ maxHeight: '400px', overflowY: 'auto' }}
            >
              {props.data.leaseholds ? (
                <ListGroup.Item className="bg-dark">
                  <strong>Address:</strong> {props.data.leaseholds[0].address}
                </ListGroup.Item>
              ) : null}
              {props.data.leaseholds ? (
                <ListGroup.Item className="bg-dark">
                  <strong>Proprietor:</strong>{' '}
                  {props.data.leaseholds[0].proprietor}
                </ListGroup.Item>
              ) : null}
              {props.data.leaseholds ? (
                <ListGroup.Item className="bg-dark">
                  <strong>Company registration number:</strong>{' '}
                  {props.data.leaseholds[0].companyNo}
                </ListGroup.Item>
              ) : null}
              {props.data.leaseholds ? (
                <ListGroup.Item className="bg-dark">
                  <strong>Date proprietor added:</strong>{' '}
                  {props.data.leaseholds[0].datePropr}
                </ListGroup.Item>
              ) : null}
              {props.data.leaseholds ? (
                <ListGroup.Item className="bg-dark">
                  <strong>Region:</strong> {props.data.leaseholds[0].region}
                </ListGroup.Item>
              ) : null}
              {props.data.leaseholds ? (
                <ListGroup.Item className="bg-dark">
                  <strong>County:</strong> {props.data.leaseholds[0].county}
                </ListGroup.Item>
              ) : null}
              {props.data.leaseholds ? (
                <ListGroup.Item className="bg-dark">
                  <strong>District:</strong> {props.data.leaseholds[0].district}
                </ListGroup.Item>
              ) : null}
              {/* <Accordion data={props.data.leaseholds}/>  */}
            </Tab.Pane>
            <Tab.Pane
              eventKey="#link4"
              style={{ maxHeight: '400px', overflowY: 'auto' }}
            >
              <InputGroup>
                <FormControl
                  as="textarea"
                  style={{ minHeight: '300px', maxHeight: '400px' }}
                  value={props.userNotes}
                  onChange={props.userNotesOnChange}
                />
              </InputGroup>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default dataTabs;
