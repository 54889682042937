import React from 'react';
import { compose } from 'recompose';
import { Container, Row, Col } from 'react-bootstrap';

import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../Session';

const AccountPage = () => (
  <AuthUserContext.Consumer>
    {(authUser) => (
      <Container>
        <Row className="justify-content-md-center">
          <Col md={6}>
            <h1>My Account</h1>
            <PasswordForgetForm />
          </Col>
        </Row>
        <Row
          className="justify-content-md-center"
          style={{ marginTop: '50px' }}
        >
          <Col md={6}>
            <PasswordChangeForm />
          </Col>
        </Row>
      </Container>
    )}
  </AuthUserContext.Consumer>
);

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(AccountPage);
