import React from 'react';
import { Card, Button } from 'react-bootstrap';
import DataTabs from './Tabs';

const PointDetails = (props) => {
  // console.log("POINT DETAILS DATA IS:     ");
  // console.log(props);
  return (
    <Card style={{ margin: '5px' }} border="primary" className="bg-dark">
      <Card.Body>
        <Card.Title>Address: {props.data.propertyAddress}</Card.Title>
        <Card.Text>
          <DataTabs
            data={props.data}
            fromSavedPoints={props.fromSavedPoints}
            userNotesOnChange={props.userNotesOnChange}
            userNotes={props.userNotes}
            json={props.json}
            notesFromDB={props.notesFromDB}
          />
        </Card.Text>
      </Card.Body>
      {props.fromSavedPoints ? (
        <Card.Footer>
          <Button
            className="float-right"
            variant="primary"
            style={{ margin: '5px' }}
          >
            Add to portofolio
          </Button>{' '}
          <Button
            className="float-right"
            variant="danger"
            style={{ margin: '5px' }}
            onClick={() => props.onDelete(props.data.id)}
          >
            Delete
          </Button>
        </Card.Footer>
      ) : null}
    </Card>
  );
};

export default PointDetails;
