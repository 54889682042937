import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
 
import { withFirebase } from '../Firebase';
 
const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
};
 
class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);
 
    this.state = { ...INITIAL_STATE };
  }
 
  onSubmit = event => {
    const { passwordOne } = this.state;
 
    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
 
    event.preventDefault();
  };
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
 
  render() {
    const { passwordOne, passwordTwo, error } = this.state;
 
    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';
 
    return (
      // <form onSubmit={this.onSubmit}>
      //   <input
      //     name="passwordOne"
      //     value={passwordOne}
      //     onChange={this.onChange}
      //     type="password"
      //     placeholder="New Password"
      //   />
      //   <input
      //     name="passwordTwo"
      //     value={passwordTwo}
      //     onChange={this.onChange}
      //     type="password"
      //     placeholder="Confirm New Password"
      //   />
      //   <button disabled={isInvalid} type="submit">
      //     Reset My Password
      //   </button>
 
      //   {error && <p>{error.message}</p>}
      // </form>
      <Form onSubmit={this.onSubmit} classname="my-auto" >
      <Form.Group controlId="formBasicEmail1">
        <Form.Label>New Password</Form.Label>
        <Form.Control 
          name="passwordOne"
          value={passwordOne}
          onChange={this.onChange}
          type="password"
          placeholder="New Password"
        />
      </Form.Group>
    
      <Form.Group controlId="formBasicPassword">
        <Form.Label>Confirm Password</Form.Label>
        <Form.Control 
          name="passwordTwo"
          value={passwordTwo}
          onChange={this.onChange}
          type="password"
          placeholder="Confirm New Password"
        />
      </Form.Group>
      <Button disabled={isInvalid} variant="primary" type="submit">
        Change Password
      </Button>
      {error && <p>{error.message}</p>}
    </Form>
    );
  }
}
 
export default withFirebase(PasswordChangeForm);