import React, { Component } from 'react';
import MapGL, {
  NavigationControl,
  Source,
  Layer,
  //LinearInterpolator,
  FlyToInterpolator,
} from 'react-map-gl';
import { compose } from 'recompose';
import { Container, Row } from 'react-bootstrap';
//import Geocoder from 'react-map-gl-geocoder';
//import axios from 'axios';

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../../components/Session';
import { withFirebase } from '../../components/Firebase';
import MapModal from '../../components/Map/Modal/Modal';
import helpers from '../../components/Map/helpers';
import Drawer from '../../components/Map/Drawer/Drawer';

import LINCOLNSHIRE from './Jsons/Lincolnshire';

import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './Map.css';

const TOKEN =
  'pk.eyJ1IjoiY2Jhc25vIiwiYSI6ImNrN3ozcTFnZzAxd3UzbW85N2FvamlsdXgifQ.GnMuG6cQ3VvJr_4pfe-bOw';
const navStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  padding: '10px',
};

class Map extends Component {
  state = {
    viewport: {
      zoom: 14,
      // width: "100%",
      // height: "100%",
      latitude: 53.371012425929834,
      longitude: -0.5389984868795762,
    },
    // popUp : {
    //   popupInfo: null,
    //   popUpCoords: null
    // },
    modalShow: false,
    buttonAlert: '',
    freehold: {
      leaseholds: null,
    },
    id: null,
    fetchedPoints: [{ key: '' }, { key: '' }],
    userNotes: '',
    notesFromDB: '',
    user: null,
    projectName: 'Leicester',
  };
  mapRef = React.createRef();

  componentDidMount() {
    window.addEventListener('resize', this._resize);
    this.setState({
      mapContainerHeight: this.divRef.clientHeight,
    });
    this._resize();
    this.listener = this.props.firebase.onAuthUserListener(
      (authUser) => {
        this.loadData(authUser.uid);
      },
      () => null
    );
  }

  loadData(user) {
    this.setState({ user: user });
    this.props.firebase.userProperties(user).on('value', (snapshot) => {
      const savedProperties = [];
      //console.log('SNAPSHOT DATA');
      snapshot.forEach((childSnapshot) => {
        const datax = {
          id: childSnapshot.val().id,
          key: childSnapshot.key,
          notesFromDB: childSnapshot.val().userNotes,
        };
        savedProperties.push(datax);
      });
      //console.log(savedProperties);
      this.setState({ fetchedPoints: savedProperties });
    });
    // axios.get(`https://noco-mvp.firebaseio.com/points/${USER}.json`)
    //   .then(response => {
    //     const savedPoints = [];
    //     for (let key in response.data) {
    //         savedPoints.push(response.data[key].fid);
    //     }
    //     this.setState({fetchedPoints: savedPoints});
    //   })
    //   .catch(error => {
    //     this.setState({ error: true });
    //   });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resize);

    this.props.firebase.userProperties().off();
  }

  _onViewportChange(viewport) {
    this.setState({
      viewport: { ...this.state.viewport, ...viewport },
    });
  }

  _resize = () => {
    this._onViewportChange({
      width: '100%',
      height: '91.5vh', //TODO Look for workaround
    });
  };

  _handleGeocoderViewportChange = (viewport) => {
    const geocoderDefaultOverrides = { transitionDUration: 1000 };

    return this._onViewportChange({
      ...viewport,
      ...geocoderDefaultOverrides,
    });
  };

  // _flyToHandler = (lat, long) => {
  //   // console.log("FLY TO FUNCTION");
  //   // console.log("THIS IS LONG: " + long + "THIS IS LAT: " + lat);
  //   const viewport = {
  //     ...this.state.viewport,
  //     longitude: long,
  //     latitude: lat,
  //     zoom: 16,
  //     transitionDuration: 1000,
  //     transitionInterpolator: new FlyToInterpolator(),
  //   };
  //   this.setState({ viewport });
  // };

  _flyToHandler = (lat, long, projectName) => {
    let duration = 1700;
    if (projectName === this.state.projectName) {
      duration = 1700;
    } else {
      duration = 8000;
      this.setState({ projectName: projectName });
    }
    const viewport = {
      ...this.state.viewport,
      longitude: long,
      latitude: lat,
      zoom: 16,
      transitionDuration: duration,
      transitionInterpolator: new FlyToInterpolator(),
    };
    this.setState({ viewport });
  };

  _onClickHandler = (map) => {
    this.setState({
      freehold: {
        leaseholds: null,
        userNotes: '',
        buttonAlert: '',
      },
    });

    this.loadData(this.state.user);

    let FID;
    let property;
    let leases;
    let jsonData;
    const features = map.features;

    //console.log('MUIEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE');
    //console.log(features);

    if (features.filter((x) => x.source === 'lincolnshire')[0]) {
      FID = parseInt(
        features.filter((x) => x.source === 'lincolnshire')[0].properties.fid,
        10
      );
      //console.log('FIIIIIIIIIIIIIIID', FID);
      this.setState({ id: FID });
      property = helpers.propertyData(
        features
          .filter((x) => x.properties.fid === FID)
          .filter((x) => x.source === 'freeholds_source_CCOD')
      );
      leases = helpers.leasesData(
        features
          .filter((x) => x.properties.fid === FID)
          .filter((x) => x.source === 'leaseholds_source_CCOD')
      );
      jsonData = features.filter((x) => x.source === 'lincolnshire')[0]
        .properties;
      //console.log('JSON DATAAAAAAA');
      //console.log(jsonData);
    }

    let dataz = this.state.fetchedPoints.find(
      (o) => o.id === this.state.id.toString()
    )
      ? this.state.fetchedPoints.find((o) => o.id === this.state.id.toString())
          .notesFromDB
      : null;
    this.setState({ userNotes: dataz });

    this.setState({
      jsonData: jsonData,
      freehold: {
        ...property,
        leaseholds: leases,
      },
    });

    if (!FID) {
      this.hideDetails();
    } else {
      this.setModalShow(true);
    }
  };

  setModalShow(e, user) {
    if (!e) this.hideDetails();
    this.setState({ modalShow: e });
  }

  hideDetails = () => {
    this.setState({
      freehold: {
        leaseholds: null,
      },
      userNotes: '',
      buttonAlert: '',
    });
  };

  newOrOld(user) {
    let data = this.state.fetchedPoints.find(
      (o) => o.id === this.state.id.toString()
    )
      ? this.state.fetchedPoints.find((o) => o.id === this.state.id.toString())
      : null;

    // console.log('MUIEEEEE DATAAAAAAAAAAAAAAAAA');
    // console.log(
    //   this.state.fetchedPoints.find((o) => o.id === this.state.id.toString())
    // );
    // console.log('MUIEEEEE DATAAAAAAAAAAAAAAAAA');
    // console.log(this.state.id);

    if (data) {
      this.updateExisting(user, data);
    } else {
      this.savePoint(user);
    }
  }

  updateExisting(user, data) {
    // console.log("MERGEEEEEEEE BAAAAAAAAAAAAAAA");
    // console.log(data);
    // console.log(user);

    if (this.state.userNotes !== this.state.fetchedPoints.notesFromDB) {
      this.props.firebase.userProperties(user.uid + '/' + data.key).update({
        createdAt: this.props.firebase.serverValue.TIMESTAMP,
        userNotes: this.state.userNotes,
        id: this.state.jsonData.fid,
      });
    }
  }

  savePoint = (user) => {
    //console.log('THIS IS THE USER NOTES');
    //console.log(this.state.userNotes);

    this.props.firebase.userProperties(user.uid).push({
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
      userNotes: this.state.userNotes,
      id: this.state.jsonData.fid,
    });
  };

  onChangeUserNotes = (event) => {
    const text = event.target.value;
    this.setState({
      userNotes: text,
    });
  };

  render() {
    return (
      <Container fluid>
        <Row>
          <div
            className="mapContainer col-10"
            ref={(element) => (this.divRef = element)}
            style={{ padding: '0px' }}
          >
            <MapGL
              ref={this.mapRef}
              {...this.state.viewport}
              mapStyle="mapbox://styles/cbasno/ck87j7fur13sd1iqghnp9y2ec"
              mapboxApiAccessToken={TOKEN}
              onClick={this._onClickHandler}
              onViewportChange={(viewport) => this._onViewportChange(viewport)}
            >
              {/* {this.renderPopup()} */}

              {/* <Geocoder
              mapRef={this.mapRef}
              onViewportChange={this._handleGeocoderViewportChange}
              mapboxApiAccessToken={TOKEN}
              position="top-left"
            />
            {/* !! search not working */}
              <div className="nav" style={navStyle}>
                <NavigationControl
                  onViewportChange={(viewport) =>
                    this._onViewportChange(viewport)
                  }
                />
              </div>
              <Source
                id="buildings_source"
                type="vector"
                url="mapbox://cbasno.bcn0qnas"
              />
              <Source
                id="freeholds_source_CCOD"
                type="vector"
                url="mapbox://cbasno.9zf8hw41"
              />
              <Source
                id="leaseholds_source_CCOD"
                type="vector"
                url="mapbox://cbasno.dnvnjtzq"
              />
              {/* <Source
              id="freeholds_source_OCOD"
              type="vector"
              url="mapbox://cbasno.4z9k3wag"
            />
            <Source
              id="leaseholds_source_OCOD"
              type="vector"
              url="mapbox://cbasno.980dq0dj"
            /> */}

              <Source id="lincolnshire" type="geojson" data={LINCOLNSHIRE} />

              <Layer
                id="freeholds_layer_CCOD"
                type="fill"
                source="freeholds_source_CCOD"
                source-layer="CCOD_FREEHOLDS_FID-0povdm"
                paint={{
                  'fill-color': 'rgba(253, 38, 238, 0)',
                  'fill-outline-color': 'rgba(253, 38, 238, 1)',
                }}
              />

              {/* <Layer
              id="freeholds_layer_OCOD"
              type="fill"
              source="freeholds_source_OCOD"
              source-layer="OCOD_FREEHOLDS_FID_0620-3u2aoq"
              paint={{'fill-color': 'rgba(253, 38, 238, 0)', 'fill-outline-color': 'rgba(253, 38, 238, 1)'}}
            /> */}
              <Layer
                id="leaseholds_layer_CCOD"
                type="fill"
                source="leaseholds_source_CCOD"
                source-layer="CCOD_LEASEHOLDS_FID-9yvfns"
                paint={{
                  'fill-color': 'rgba(52, 230, 254, 0.1)',
                  'fill-outline-color': 'rgba(52, 230, 254, 1)',
                }}
              />
              {/* <Layer
              id="leaseholds_layer_OCOD"
              type="fill"
              source="leaseholds_source_OCOD"
              source-layer="OCOD_LEASEHOLDS_FID_0620-0gjmn2"
              paint={{'fill-color': 'rgba(52, 230, 254, 0.1)', 'fill-outline-color': 'rgba(52, 230, 254, 1)'}}
            /> */}
              <Layer
                id="buildings_layer"
                type="fill"
                source="buildings_source"
                source-layer="Buildings"
                paint={{
                  'fill-color': 'rgba(282, 283, 200, 0.4)',
                  'fill-outline-color': 'rgba(282, 283, 200, 1)',
                }}
              />

              <Layer
                id="lincolnshire"
                type="fill"
                source="lincolnshire"
                paint={{
                  'fill-color': 'rgba(18, 243, 81, 0.5)',
                  'fill-outline-color': 'rgba(18, 243, 81, 1)',
                }}
              />
            </MapGL>

            <AuthUserContext.Consumer>
              {(authUser) => (
                <MapModal
                  freehold={this.state.freehold}
                  show={this.state.modalShow}
                  onHide={() => {
                    this.newOrOld(authUser);
                    this.setModalShow(false);
                  }}
                  onButtonSave={() => this.newOrOld(authUser)}
                  userNotesOnChange={this.onChangeUserNotes}
                  userNotes={this.state.userNotes}
                  notesFromDB={this.state.notesFromDB}
                  json={this.state.jsonData}
                  alert={this.state.buttonAlert}
                  //user={authUser}
                />
              )}
            </AuthUserContext.Consumer>
          </div>
          <div
            className="col-2"
            style={{ padding: '0px', maxHeight: '91.5vh', overflowY: 'scroll' }}
          >
            <Drawer lincolnshire={LINCOLNSHIRE} click={this._flyToHandler} />
          </div>
        </Row>
      </Container>
    );
  }
}

const condition = (authUser) => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition)
)(Map);
