const lincolnshire = {
  type: 'FeatureCollection',
  name: 'Lincolnshire',
  crs: {
    type: 'name',
    properties: {
      name: 'urn:ogc:def:crs:OGC:1.3:CRS84'
    }
  },
  features: [
    {
      type: 'Feature',
      properties: {
        fid: '5003254',
        'Roof.Size': '9292.166163',
        'Estimated capacity': '1300.903263',
        'Title.Number': 'LL221235',
        Tenure: 'Freehold',
        'Property Address': 'land at Cliff Farm, Owmby By Spital (LN8 2AD)',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'ARDEN FARMS OWMBY LIMITED',
        'Company Registration No. (1)': '00435439',
        'Date Proprietor Added': '',
        POSTCODE_2: 'LN8 2AD',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '73',
        'Capital Employed': '9875278',
        Cash: '8044',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '949868',
        'Current Liabilities': '320537',
        'Debt to Capital': '20.13192668',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': 'farmers, agriculture, environment, cereals, leguminous crops, oil seeds, oil, fresh food, seeds, food, crop farming, wheat, potatoes, peas, barley, crops, farming',
        'Net Assets': '8143201',
        'SIC 2007 Description': 'Growing of cereals (except rice), leguminous crops and oil seeds',
        'Shareholder Funds': '8143201',
        'Shareholders Names': 'Paul Frank Arden, Sarah Hi Arden',
        Status: 'Active',
        Telephone: '01673 878686',
        'Trading Address': 'Owmby Cliff Offices, Owmby-By-Spital, Market Rasen, Lincolnshire, LN8 2AD',
        'Trading Address 1': 'Owmby Cliff Offices',
        'Trading Address 2': 'Owmby-By-Spital',
        'Trading Address 3': 'Market Rasen',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.ardenfarms.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '629331',
        lat: 53.371012425929834,
        'long': -0.5389984868795762
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.538947599192765,
              53.37031342586642
            ],
            [
              -0.538172092631386,
              53.370486776848075
            ],
            [
              -0.53895431980289,
              53.37174228973698
            ],
            [
              -0.539825506969429,
              53.37154951817789
            ],
            [
              -0.538992697256033,
              53.37021221735771
            ],
            [
              -0.53889742220325,
              53.37023361474135
            ],
            [
              -0.538947599192765,
              53.37031342586642
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5009593',
        'Roof.Size': '7824.92977',
        'Estimated capacity': '1095.490168',
        'Title.Number': 'LL200782',
        Tenure: 'Freehold',
        'Property Address': 'Lodge Farm, Ferry Lane, Kettlethorpe, Lincoln',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'AMBER REAL ESTATE INVESTMENTS (AGRICULTURE) LIMITED',
        'Company Registration No. (1)': '09885883',
        'Date Proprietor Added': '29/01/2016',
        POSTCODE_2: 'LN1 2LF',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '4',
        'Capital Employed': '175423000',
        Cash: '8959000',
        Charges: 'Outstanding',
        'Cost of Sales': '7301000',
        'Cost of Sales Delta': '340000',
        'Cost of Sales Growth': '4.884355696',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '14297000',
        'Current Liabilities': '68601000',
        'Debt to Capital': '70.72910861',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '52376000',
        'Financial Year End': 'December',
        'Industry Keywords': 'commercial property experts, property information, commercial property letting, property landlords, property finding, property advice, property repossession, commercial property sales, residential property services, residential property investment, estate investment, investment management, leadership team, investment, homes for sale, property market, real estate markets, real estate finance, luxury homes, market research, human resources, market information, corporate social responsibility, senior management team, commercial real estate, real estate investment management, real estate investment, estate investment management, renting, leased real estate, leasing, agricultural property, property assets, real estate, property',
        'Net Assets': '71428000',
        'SIC 2007 Description': 'Letting and operating of own or leased real estate (other than Housing Association real estate and conference and exhibition services) n.e.c.',
        'Shareholder Funds': '71428000',
        'Shareholders Names': 'Amber Rei Holdings Limited',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': '2nd Floor Colmore Court, 9 Colmore Row, Birmingham, West Midlands, B3 2BJ',
        'Trading Address 1': '2nd Floor Colmore Court',
        'Trading Address 2': '9 Colmore Row',
        'Trading Address 3': 'Birmingham',
        'Trading Address 4': 'West Midlands',
        Turnover: '38421000',
        'Ultimate Domestic Parent Name': 'Amber Rei Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/07191982',
        'Ultimate Parent Name': 'Amber Rei Holdings Limited',
        'Ultimate Parent Id': 'company/gb/07191982',
        'Web Address 1': 'http://www.atisreal.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-54304000',
        lat: 53.27272359465242,
        'long': -0.7150134788770193
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.714952825955362,
              53.27317497777337
            ],
            [
              -0.714586810109847,
              53.27227362230345
            ],
            [
              -0.714241440861301,
              53.27232401541858
            ],
            [
              -0.714632727183005,
              53.27328758124499
            ],
            [
              -0.715784682586468,
              53.27311948439202
            ],
            [
              -0.715393371766745,
              53.27215592217291
            ],
            [
              -0.71504830420065,
              53.27220632086716
            ],
            [
              -0.715401281823315,
              53.27304766510434
            ],
            [
              -0.71543451468976,
              53.27310465622799
            ],
            [
              -0.71535679265724,
              53.273116044614376
            ],
            [
              -0.714990618864351,
              53.27221468871245
            ],
            [
              -0.71464479811879,
              53.27226516802114
            ],
            [
              -0.714983765814635,
              53.273073371459525
            ],
            [
              -0.714998436274019,
              53.273099239191005
            ],
            [
              -0.715024334607668,
              53.2731507577676
            ],
            [
              -0.715024162151679,
              53.27315650911964
            ],
            [
              -0.71502272939014,
              53.27315928039334
            ],
            [
              -0.715021950178015,
              53.2731602608306
            ],
            [
              -0.715020405226827,
              53.273161772380725
            ],
            [
              -0.715017189661629,
              53.27316398508175
            ],
            [
              -0.715013537826555,
              53.273165743612495
            ],
            [
              -0.715010815117979,
              53.273166523321194
            ],
            [
              -0.714952825955362,
              53.27317497777337
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5011605',
        'Roof.Size': '7025.876391',
        'Estimated capacity': '983.6226947',
        'Title.Number': 'LL264302',
        Tenure: 'Freehold',
        'Property Address': 'Bishop Grosseteste College, Newport, Lincoln (LN1 3DY)',
        District: 'LINCOLN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'THE LINCOLN DIOCESAN TRUST AND BOARD OF FINANCE LIMITED',
        'Company Registration No. (1)': '00097256',
        'Date Proprietor Added': '23/01/2006',
        POSTCODE_2: 'LN1 3DS',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '112',
        'Capital Employed': '167751000',
        Cash: '5635000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '7376000',
        'Current Liabilities': '1226000',
        'Debt to Capital': '1.439249129',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': 'charitable organization, church, real estate purchasing, real estate, real estate sales, charity, registered charity, charities',
        'Net Assets': '166545000',
        'SIC 2007 Description': 'Buying and selling of own real estate',
        'Shareholder Funds': '166545000',
        'Shareholders Names': '0',
        Status: 'Active',
        Telephone: '01522 504050',
        'Trading Address': 'Edward King House Minster Yard, The Old Palace, Lincoln, Lincolnshire, LN2 1PU',
        'Trading Address 1': 'Edward King House Minster Yard',
        'Trading Address 2': 'The Old Palace',
        'Trading Address 3': 'Lincoln',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '7679000',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.anglican.org',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '6150000',
        lat: 53.24342418247854,
        'long': -0.5363731081428944
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.536519750449651,
              53.24329357220648
            ],
            [
              -0.536239323458213,
              53.24329902903623
            ],
            [
              -0.536227680489034,
              53.243223013646045
            ],
            [
              -0.535970591341511,
              53.24323406027421
            ],
            [
              -0.535998919581206,
              53.243453485317495
            ],
            [
              -0.535293930011627,
              53.24352923817599
            ],
            [
              -0.535211878310842,
              53.24324622547304
            ],
            [
              -0.534971539948127,
              53.24327140968636
            ],
            [
              -0.534553848057235,
              53.243627568946174
            ],
            [
              -0.534475336422683,
              53.24363586312106
            ],
            [
              -0.534499625865689,
              53.24371886627401
            ],
            [
              -0.535190837738511,
              53.24364735371935
            ],
            [
              -0.535186742347907,
              53.24361368219984
            ],
            [
              -0.535188739947301,
              53.24360345857217
            ],
            [
              -0.535194170662815,
              53.2435936367131
            ],
            [
              -0.53520257281988,
              53.243584570534495
            ],
            [
              -0.535213631476034,
              53.243576705647264
            ],
            [
              -0.535227184557797,
              53.243570399644305
            ],
            [
              -0.535242629799213,
              53.24356573502106
            ],
            [
              -0.536006213516352,
              53.24348566785005
            ],
            [
              -0.536046226422616,
              53.24385014910276
            ],
            [
              -0.536216547497893,
              53.24384352060064
            ],
            [
              -0.536190246851996,
              53.243604163561166
            ],
            [
              -0.536686727687348,
              53.243591380551756
            ],
            [
              -0.536693984312388,
              53.243703750021744
            ],
            [
              -0.536477406280763,
              53.243708912377585
            ],
            [
              -0.536482617168683,
              53.2437889839985
            ],
            [
              -0.537319933718529,
              53.24376608859345
            ],
            [
              -0.5372998779931,
              53.24349723259084
            ],
            [
              -0.537594311060023,
              53.24348978756476
            ],
            [
              -0.537588719489499,
              53.2434032388014
            ],
            [
              -0.537149996425682,
              53.24341430710932
            ],
            [
              -0.53714218269849,
              53.24329626736921
            ],
            [
              -0.537476372330668,
              53.24328751256089
            ],
            [
              -0.537449439561957,
              53.24290386460131
            ],
            [
              -0.536833412861412,
              53.24292003794675
            ],
            [
              -0.536845074841355,
              53.243140516768946
            ],
            [
              -0.537131260490234,
              53.24313315146729
            ],
            [
              -0.537141193388992,
              53.243281332454494
            ],
            [
              -0.536760141298318,
              53.24328879196541
            ],
            [
              -0.53675255453049,
              53.243071869111574
            ],
            [
              -0.536629019920844,
              53.24307331933003
            ],
            [
              -0.536631199505162,
              53.24314121774853
            ],
            [
              -0.536515013998613,
              53.24314248837298
            ],
            [
              -0.536519750449651,
              53.24329357220648
            ]
          ],
          [
            [
              -0.536505902085492,
              53.243321719516985
            ],
            [
              -0.537102460458249,
              53.24330971384973
            ],
            [
              -0.53710821166833,
              53.24341352467835
            ],
            [
              -0.536511798585569,
              53.24342562206187
            ],
            [
              -0.536505902085492,
              53.243321719516985
            ]
          ],
          [
            [
              -0.536173760613845,
              53.2433275301795
            ],
            [
              -0.536460198141218,
              53.24332160791503
            ],
            [
              -0.536473264605794,
              53.24350965146952
            ],
            [
              -0.536179402861031,
              53.243517909808865
            ],
            [
              -0.536178969898564,
              53.243447156149365
            ],
            [
              -0.536173760613845,
              53.2433275301795
            ]
          ],
          [
            [
              -0.536687994858222,
              53.24354105421381
            ],
            [
              -0.536860488057517,
              53.24353660896997
            ],
            [
              -0.536862445417605,
              53.24356270290108
            ],
            [
              -0.53668980231385,
              53.24356714630827
            ],
            [
              -0.536687994858222,
              53.24354105421381
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5012192',
        'Roof.Size': '11271.016',
        'Estimated capacity': '1577.94224',
        'Title.Number': 'LL244130',
        Tenure: 'Freehold',
        'Property Address': 'land at Deacon Road, Deacon Road Industrial Estate, Lincoln',
        District: 'LINCOLN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'TESCO STORES LIMITED',
        'Company Registration No. (1)': '00519500',
        'Date Proprietor Added': '',
        POSTCODE_2: 'LN2 4JB',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '67',
        'Capital Employed': '10128000000',
        Cash: '1481000000',
        Charges: 'Outstanding, Partially Satisfied, Satisfied',
        'Cost of Sales': '40273000000',
        'Cost of Sales Delta': '566000000',
        'Cost of Sales Growth': '1.425441358',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '8184000000',
        'Current Liabilities': '9318000000',
        'Debt to Capital': '71.08402756',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '1446000000',
        'Financial Year End': 'February',
        'Industry Keywords': 'governance, waste, retail units, retail merchandising, b2c, retail display, retail trade, retail business, retail market, retail shop, retail outlets, health products, packaging products, sustainable development, cash flow, community grants, corporate responsibility, software, books, market leader, oak, internet services, petrol, carbon footprint, electronics, carbon reduction, garden, clothing, market share, retailing, financial services, internet, corporate governance, general merchandise, tea, retailer, stalls, food waste, grocer, toys, furniture, shop, stock exchange, supermarket, grocery, tobacco, retail stores, retail sales, stores, beverages, food, retail',
        'Net Assets': '5623000000',
        'SIC 2007 Description': 'Retail sale in non-specialised stores with food, beverages or tobacco predominating',
        'Shareholder Funds': '5623000000',
        'Shareholders Names': 'Tesco Holdings Ltd',
        Status: 'Active',
        Telephone: '0345 610 6775',
        'Trading Address': 'Tesco House Shire Park, Cirrus Building, 1a Shires Park, Kestrel Way, Welwyn Garden City, Hertfordshire, AL7 1GA',
        'Trading Address 1': 'Tesco House Shire Park',
        'Trading Address 2': 'Cirrus Building',
        'Trading Address 3': '1a Shires Park, Kestrel Way',
        'Trading Address 4': 'Welwyn Garden City, Hertfordshire',
        Turnover: '42219000000',
        'Ultimate Domestic Parent Name': 'Tesco Stores Limited',
        'Ultimate Domestic Parent Id': 'company/gb/00519500',
        'Ultimate Parent Name': 'Tesco Plc',
        'Ultimate Parent Id': 'company/gb/00445790',
        'Web Address 1': 'http://www.tescoplc.com',
        'Web Address 2': 'http://www.tescobank.co.uk',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-1134000000',
        lat: 53.2388744506728,
        'long': -0.5154025565392442
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.516488713878008,
              53.23905367995303
            ],
            [
              -0.51574012565178,
              53.2381383841442
            ],
            [
              -0.515678503347156,
              53.23815676481918
            ],
            [
              -0.515752256456193,
              53.23824686053164
            ],
            [
              -0.515412850044279,
              53.23834673426198
            ],
            [
              -0.515461442268035,
              53.23840613176333
            ],
            [
              -0.514740081448565,
              53.23861847139835
            ],
            [
              -0.514622919149514,
              53.23847515477661
            ],
            [
              -0.514449126315293,
              53.23852629775051
            ],
            [
              -0.514566291277852,
              53.23866952468103
            ],
            [
              -0.514336331343867,
              53.238737227719916
            ],
            [
              -0.514967189795859,
              53.239503275890186
            ],
            [
              -0.516488713878008,
              53.23905367995303
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5013153',
        'Roof.Size': '3559.54147',
        'Estimated capacity': '498.3358058',
        'Title.Number': 'LL416',
        Tenure: 'Freehold',
        'Property Address': 'A W Curtis & Sons Ltd, Long Leys Road, Lincoln (LN1 1DX)',
        District: 'LINCOLN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'A.W. CURTIS & SONS LIMITED',
        'Company Registration No. (1)': '00250817',
        'Date Proprietor Added': '24/09/1963',
        POSTCODE_2: 'LN1 1DX',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '90',
        'Capital Employed': '2083140',
        Cash: '0',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '1495303',
        'Current Liabilities': '486240',
        'Debt to Capital': '30.34848874',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'September',
        'Industry Keywords': 'real estate purchasing, real estate, real estate sales',
        'Net Assets': '1789612',
        'SIC 2007 Description': 'Buying and selling of own real estate',
        'Shareholder Funds': '1789612',
        'Shareholders Names': 'N.A. Curtis, A.C. Curtis, S.E. Waite, C.L. Hart, R.F. Curtis, A.R. Houlton, Shelia Mary Curtis Miss, M.A. Curtis',
        Status: 'Active',
        Telephone: '01522 569380',
        'Trading Address': '164 High Street, Lincoln, Lincolnshire, LN5 7AG',
        'Trading Address 1': '164 High Street',
        'Trading Address 2': 'Lincoln',
        'Trading Address 3': 'Lincolnshire',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'A.W. Curtis and Sons Limited',
        'Ultimate Domestic Parent Id': 'company/gb/00250817',
        'Ultimate Parent Name': 'A.W. Curtis and Sons Limited',
        'Ultimate Parent Id': 'company/gb/00250817',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '1009063',
        lat: 53.241227099994475,
        'long': -0.5564447468220338
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.556171954149515,
              53.240804410636265
            ],
            [
              -0.555852263109006,
              53.24089510891269
            ],
            [
              -0.556446674705327,
              53.24164934439333
            ],
            [
              -0.557020568932788,
              53.24148602927371
            ],
            [
              -0.556560081155645,
              53.24089765842625
            ],
            [
              -0.556303188884729,
              53.24097024010632
            ],
            [
              -0.556171954149515,
              53.240804410636265
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5020447',
        'Roof.Size': '3552.576085',
        'Estimated capacity': '497.3606519',
        'Title.Number': 'LL356453',
        Tenure: 'Freehold',
        'Property Address': 'land on the north eastern side of Beevor Street, Lincoln (LN6 7AF)',
        District: 'LINCOLN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'JAMES DAWSON & SON LIMITED',
        'Company Registration No. (1)': '00047152',
        'Date Proprietor Added': '17/03/2015',
        POSTCODE_2: 'LN6 7AF',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '124',
        'Capital Employed': '7700000',
        Cash: '4000',
        Charges: '0',
        'Cost of Sales': '28428000',
        'Cost of Sales Delta': '704000',
        'Cost of Sales Growth': '2.539316116',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '9347000',
        'Current Liabilities': '5486000',
        'Debt to Capital': '41.6805703',
        'Dividends Payable': '1638000',
        'Dormant Company': 'No',
        EBITDA: '3837000',
        'Financial Year End': 'December',
        'Industry Keywords': 'manufacturing industry, commercial manufacturing, manufacturing software, pipe manufacturing, manufacturing support, can manufacturing, manufacturing services, manufacturing design, industrial manufacturing, manufacturing company, leadership team, business development manager, new business development, networking, investment, underwriting, delivery, ducting, research, warehousing, rubber, markets, bespoke engineering solutions, engineering solutions, silicones, bespoke engineering, quality control, development manager, business development, green technology, development engineers, technical support, technology, solutions provider, future technology, rubber products, automotive, general mechanical engineering, mechanical engineering, polymer, engineering, manufacturing',
        'Net Assets': '7690000',
        'SIC 2007 Description': 'Machining',
        'Shareholder Funds': '7690000',
        'Shareholders Names': 'Fenner Group Holdings Limited',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Campbell Road, Stoke-On-Trent, ST4 4EY',
        'Trading Address 1': 'Campbell Road',
        'Trading Address 2': 'Stoke-On-Trent',
        'Trading Address 3': '0',
        'Trading Address 4': '0',
        Turnover: '41070000',
        'Ultimate Domestic Parent Name': 'Fenner Group Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/00329377',
        'Ultimate Parent Name': 'Compagnie Generale Des Etabl. Mic. Sca',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.james-dawson.com',
        'Web Address 2': 'http://www.james-dawson.co.uk',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '3861000',
        lat: 53.22568892586917,
        'long': -0.5524614407441731
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.552281317028858,
              53.225834005850885
            ],
            [
              -0.552370628001543,
              53.22583311287713
            ],
            [
              -0.552877130608305,
              53.226027775993096
            ],
            [
              -0.553194503106364,
              53.225731644851045
            ],
            [
              -0.552196133484722,
              53.22534834013169
            ],
            [
              -0.55192603498879,
              53.22560180286995
            ],
            [
              -0.551730968257163,
              53.22552715618279
            ],
            [
              -0.551627253066747,
              53.2256219950904
            ],
            [
              -0.552247585939042,
              53.22586586892154
            ],
            [
              -0.552281317028858,
              53.225834005850885
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5020449',
        'Roof.Size': '9264.031391',
        'Estimated capacity': '1296.964395',
        'Title.Number': 'LL249093',
        Tenure: 'Freehold',
        'Property Address': 'Land and buildings on the south west side of Beevor Street, Lincoln',
        District: 'LINCOLN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'LINDUM GROUP LIMITED',
        'Company Registration No. (1)': '01236338',
        'Date Proprietor Added': '22/11/2004',
        POSTCODE_2: 'LN6 7DJ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '44',
        'Capital Employed': '48643365',
        Cash: '30001169',
        Charges: 'Satisfied',
        'Cost of Sales': '144614697',
        'Cost of Sales Delta': '9444272',
        'Cost of Sales Growth': '6.986936676',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '80659991',
        'Current Liabilities': '51367125',
        'Debt to Capital': '52.22648444',
        'Dividends Payable': '1160090',
        'Dormant Company': 'No',
        EBITDA: '6800086',
        'Financial Year End': 'November',
        'Industry Keywords': 'commercial construction, construction services, general construction, construction engineering, building projects, building construction, construction software, construction work, construction projects, construction industry, civil construction, building site, building industry, construction site, waste recycling, retail, education, design agency, joinery, client relationships, supermarket, maintenance jobs, trusts, savings, business space, primary services, construction company, craftsmen, building, design, skilled craftsmen, commercial development, maintenance, customer support services, food, refurbishment, support services, commercial buildings, civil engineering works, building construction contractors, construction contractors, construction',
        'Net Assets': '47778527',
        'SIC 2007 Description': 'Construction of commercial buildings',
        'Shareholder Funds': '47778527',
        'Shareholders Names': 'Tracey Papworth, Dean Taylor, Robert Wright, John Hayhurst, Peter Sharp-Grose, Alexander Block, Jay Mallia, Jennifer Murray, Charlie O\'kelly, Malcolm Coxell, Daniel Peel, Chris Brown, Simon Cartner, Rosemary Sharp, Paul Fisher, Iain Mcdermid, Lorraine Woolley, David Hollis, Peter Storr, Thomas Jackson, Andrew Foster, Jason Aisthorpe, Alistair Carradice, Lester Fisher, Conrad Cousins, Danielle Weatherley, Andrew Hudson, Jake Lee, Nicola Ward, Katie O\'kelly, Aaron Horrocks, Jason Roskell, Luke Scotney, Chris Hammett, George Wellock, Robert Jays, Leanne Goodale, David Asquith, Kim Baker, John David Pearson, William Lazenby, Alexander Hackett, Ashley Bliss, Andy Barradell, Stephen Roberts, Ian Ross, Sara Hobson, Carol Buckley, Roy Brown, Lynda Stacey, Martin Brennan, John Howard, David Jolliffe, Jamie Boulton, Sandra Kettleborough, Robert Mcauley, Sally Choularton, Colin Knight, Steve Timmins, Stuart Weir, Dean Bailey, Anthony Gamwell, Mark Holland, Richard Neale, Richard Cooke, Philip Dudding, Neil White, Paul Brown, James Branscombe, Vincent Bell, Nigel Carter, Dean Hanson, Dylan Billanie, Paul Bowler, Christopher Elsom, Frank Williams, Gary Burton, Matthew Harrison, Neil Potts, Darren Staples, Ashley Jarvis, Herman Kok, David Horton, Dominic Kidd, Matthew Carter, Nicole Watkins, Lynn Ritson, Jonathan Cocking, Joseph Brennan, Ryan Calvert, Stephen Deighton, Alex Stanton, George Kusi-Manu, William Gargan, Daniel Clark, Daryl Waters, Adam Davenport, Matthew Marshallsay, Carl Pacey, Becky Mcconnell',
        Status: 'Active',
        Telephone: '01522 500300',
        'Trading Address': 'Lindum Business Park, Station Road, North Hykeham, Lincoln, Lincolnshire, Ln6 3QX',
        'Trading Address 1': 'Lindum Business Park',
        'Trading Address 2': 'Station Road, North Hykeham',
        'Trading Address 3': 'Lincoln',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '170570982',
        'Ultimate Domestic Parent Name': 'Lindum Group Limited',
        'Ultimate Domestic Parent Id': 'company/gb/01236338',
        'Ultimate Parent Name': 'Lindum Group Limited',
        'Ultimate Parent Id': 'company/gb/01236338',
        'Web Address 1': 'http://www.lindumgroup.com',
        'Web Address 2': 'http://www.lindumplanthire.co.uk',
        'Web Address 3': 'http://www.lindumgroup.co.uk',
        'Web Address 4': 'http://www.lindumhomes.co.uk',
        'Working Capital': '29292866',
        lat: 53.226267795267916,
        'long': -0.5573283710565508
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.557647804266224,
              53.22551152400011
            ],
            [
              -0.556280013093401,
              53.22678911100587
            ],
            [
              -0.556957156499181,
              53.22704659319469
            ],
            [
              -0.558088598234527,
              53.22597721595269
            ],
            [
              -0.558223060583224,
              53.22602801575516
            ],
            [
              -0.558446901390441,
              53.22581721958889
            ],
            [
              -0.557647804266224,
              53.22551152400011
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5020652',
        'Roof.Size': '6168.7393',
        'Estimated capacity': '863.623502',
        'Title.Number': 'LL30133',
        Tenure: 'Freehold',
        'Property Address': 'land and buildings on the south side of Beevor Street, Lincoln',
        District: 'LINCOLN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'FAIRMOUNT TRUSTEE SERVICES LIMITED',
        'Company Registration No. (1)': '01909678',
        'Date Proprietor Added': '27/03/1998',
        POSTCODE_2: 'LN6 7DJ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '35',
        'Capital Employed': '1000',
        Cash: '0',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '1000',
        'Current Liabilities': '0',
        'Debt to Capital': '0',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': 'management solutions, banking, wealth management solutions, private banking, financial advice, building, investment management, lending, wealth planning, management firm, financial planners, investment, wealth management, financial planning, financial, architecture, engineering, technical consulting, consulting',
        'Net Assets': '1000',
        'SIC 2007 Description': 'Other professional, scientific and technical activities (not including environmental consultancy or quantity surveying) n.e.c.',
        'Shareholder Funds': '1000',
        'Shareholders Names': 'Brown Shipley & Co Ltd',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': '2 Moorgate, London, Ec2r 6ag, EC2R 6AG',
        'Trading Address 1': '2 Moorgate',
        'Trading Address 2': 'London',
        'Trading Address 3': 'Ec2r 6ag',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'Brown Shipley & Co. Limited',
        'Ultimate Domestic Parent Id': 'company/gb/00398426',
        'Ultimate Parent Name': 'Precision Capital Sa',
        'Ultimate Parent Id': 'company/lu/B121640',
        'Web Address 1': 'http://www.brownshipley.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '1000',
        lat: 53.22647246927701,
        'long': -0.5600664941931369
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.558905148744833,
              53.22622297894037
            ],
            [
              -0.561157716185237,
              53.22708532785269
            ],
            [
              -0.561210492691244,
              53.22703481399295
            ],
            [
              -0.561115801515048,
              53.22699951038592
            ],
            [
              -0.561254294726975,
              53.22686768631264
            ],
            [
              -0.560801745724371,
              53.22669321804011
            ],
            [
              -0.560924048136442,
              53.22657962757016
            ],
            [
              -0.55921927053201,
              53.22592877038717
            ],
            [
              -0.558905148744833,
              53.22622297894037
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5020658',
        'Roof.Size': '3617.292559',
        'Estimated capacity': '506.4209583',
        'Title.Number': 'LL236378',
        Tenure: 'Freehold',
        'Property Address': 'Bay 22, Beevor Street, Lincoln',
        District: 'LINCOLN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'FAIRMOUNT TRUSTEE SERVICES LIMITED',
        'Company Registration No. (1)': '01909678',
        'Date Proprietor Added': '27/05/2005',
        POSTCODE_2: 'LN6 7DJ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '35',
        'Capital Employed': '1000',
        Cash: '0',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '1000',
        'Current Liabilities': '0',
        'Debt to Capital': '0',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': 'management solutions, banking, wealth management solutions, private banking, financial advice, building, investment management, lending, wealth planning, management firm, financial planners, investment, wealth management, financial planning, financial, architecture, engineering, technical consulting, consulting',
        'Net Assets': '1000',
        'SIC 2007 Description': 'Other professional, scientific and technical activities (not including environmental consultancy or quantity surveying) n.e.c.',
        'Shareholder Funds': '1000',
        'Shareholders Names': 'Brown Shipley & Co Ltd',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': '2 Moorgate, London, Ec2r 6ag, EC2R 6AG',
        'Trading Address 1': '2 Moorgate',
        'Trading Address 2': 'London',
        'Trading Address 3': 'Ec2r 6ag',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'Brown Shipley & Co. Limited',
        'Ultimate Domestic Parent Id': 'company/gb/00398426',
        'Ultimate Parent Name': 'Precision Capital Sa',
        'Ultimate Parent Id': 'company/lu/B121640',
        'Web Address 1': 'http://www.brownshipley.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '1000',
        lat: 53.227510349461284,
        'long': -0.5602731272939945
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.560909325385994,
              53.227197486194925
            ],
            [
              -0.560469845362017,
              53.227030815646835
            ],
            [
              -0.55963729791638,
              53.227823531032925
            ],
            [
              -0.560075285241771,
              53.22799018657272
            ],
            [
              -0.560909325385994,
              53.227197486194925
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5025319',
        'Roof.Size': '6695.261872',
        'Estimated capacity': '937.3366621',
        'Title.Number': 'LL16166',
        Tenure: 'Freehold',
        'Property Address': 'land and buildings on the south east side of Moorland Way, Lincoln',
        District: 'LINCOLN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'LCS PROPERTY LIMITED',
        'Company Registration No. (1)': '25275R',
        'Date Proprietor Added': '08/02/1996',
        POSTCODE_2: 'VLN00334',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '#N/A',
        'Capital Employed': '#N/A',
        Cash: '#N/A',
        Charges: '#N/A',
        'Cost of Sales': '#N/A',
        'Cost of Sales Delta': '#N/A',
        'Cost of Sales Growth': '#N/A',
        Country: '#N/A',
        'Credit Risk': '#N/A',
        'Current Assets': '#N/A',
        'Current Liabilities': '#N/A',
        'Debt to Capital': '#N/A',
        'Dividends Payable': '#N/A',
        'Dormant Company': '#N/A',
        EBITDA: '#N/A',
        'Financial Year End': '#N/A',
        'Industry Keywords': '#N/A',
        'Net Assets': '#N/A',
        'SIC 2007 Description': '#N/A',
        'Shareholder Funds': '#N/A',
        'Shareholders Names': '#N/A',
        Status: '#N/A',
        Telephone: '#N/A',
        'Trading Address': '#N/A',
        'Trading Address 1': '#N/A',
        'Trading Address 2': '#N/A',
        'Trading Address 3': '#N/A',
        'Trading Address 4': '#N/A',
        Turnover: '#N/A',
        'Ultimate Domestic Parent Name': '#N/A',
        'Ultimate Domestic Parent Id': '#N/A',
        'Ultimate Parent Name': '#N/A',
        'Ultimate Parent Id': '#N/A',
        'Web Address 1': '#N/A',
        'Web Address 2': '#N/A',
        'Web Address 3': '#N/A',
        'Web Address 4': '#N/A',
        'Working Capital': '#N/A',
        lat: 53.20305020896203,
        'long': -0.5820027544005416
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.581943356944343,
              53.20336137489132
            ],
            [
              -0.581812587181076,
              53.20346760434681
            ],
            [
              -0.5821361159706,
              53.203617895276224
            ],
            [
              -0.582876584317017,
              53.20303410685082
            ],
            [
              -0.582128929287344,
              53.20268792239185
            ],
            [
              -0.581633056206757,
              53.20261549799638
            ],
            [
              -0.581588425630688,
              53.20259779657326
            ],
            [
              -0.581107919858979,
              53.20297584357041
            ],
            [
              -0.581943356944343,
              53.20336137489132
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5025556',
        'Roof.Size': '5094.15996',
        'Estimated capacity': '713.1823944',
        'Title.Number': 'LL6935',
        Tenure: 'Freehold',
        'Property Address': 'land on the south west side of Pioneer Way, Lincoln',
        District: 'LINCOLN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'BOULDER HOLDINGS LIMITED',
        'Company Registration No. (1)': '11633774',
        'Date Proprietor Added': '02/05/2019',
        POSTCODE_2: 'LN6 3DH',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '2',
        'Capital Employed': '1625157',
        Cash: '423',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '7423',
        'Current Liabilities': '453386',
        'Debt to Capital': '75.93713481',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'October',
        'Industry Keywords': 'real estate purchasing, real estate, real estate sales',
        'Net Assets': '500157',
        'SIC 2007 Description': 'Buying and selling of own real estate',
        'Shareholder Funds': '500157',
        'Shareholders Names': 'Alison Rachel Bown, Craig William Bown, William Bown',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Doddington Road, Lincoln, LN6 0QR',
        'Trading Address 1': 'Doddington Road',
        'Trading Address 2': 'Lincoln',
        'Trading Address 3': '0',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'Boulder Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/11633774',
        'Ultimate Parent Name': 'Boulder Holdings Limited',
        'Ultimate Parent Id': 'company/gb/11633774',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-445963',
        lat: 53.20049557747698,
        'long': -0.588962780760798
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.589723681477101,
              53.20027605317228
            ],
            [
              -0.589598475006788,
              53.200209126818514
            ],
            [
              -0.589796000176725,
              53.20007509192401
            ],
            [
              -0.589525402024409,
              53.19993138140123
            ],
            [
              -0.588981304678994,
              53.20029773973175
            ],
            [
              -0.588885786824721,
              53.20024312037125
            ],
            [
              -0.588103064155772,
              53.20075452807524
            ],
            [
              -0.588590946040619,
              53.20101974834892
            ],
            [
              -0.589723681477101,
              53.20027605317228
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5028072',
        'Roof.Size': '11459.79739',
        'Estimated capacity': '1604.371635',
        'Title.Number': 'LL327742',
        Tenure: 'Freehold',
        'Property Address': 'Land on the south west side of Doddington Road, Lincoln',
        District: 'LINCOLN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'DYNEX SEMICONDUCTOR LIMITED',
        'Company Registration No. (1)': '03824626',
        'Date Proprietor Added': '26/08/2011',
        POSTCODE_2: 'LN6 3LH',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '21',
        'Capital Employed': '11721000',
        Cash: '856000',
        Charges: 'Satisfied',
        'Cost of Sales': '23859000',
        'Cost of Sales Delta': '488000',
        'Cost of Sales Growth': '2.088057849',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '18043000',
        'Current Liabilities': '21095000',
        'Debt to Capital': '67.5798391',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '6664000',
        'Financial Year End': 'December',
        'Industry Keywords': 'compound semiconductor, commercial manufacturing, can manufacturing, manufacturing services, semiconductor wafers, component assembly, pipe manufacturing, manufacturing industry, manufacturing software, manufacturing company, industrial manufacturing, semiconductor manufacturing, telephone, electronic manufacturing, design development, stock exchange, housing, train, railways, rail systems, integrated circuits, industrial automation, radiation, energy, power converters, fabrication, aerospace, electric power, electric vehicles, aerospace industry, electrical systems, control systems, supply equipment, electrical components, power supplies, silicon, power products, electronic tubes, electronic valves, gate, design, electronic components, semiconductor devices, motor, inverters, power semiconductor, semiconductor industry, diodes, electronic assemblies, electronic assembly, semiconductors, semiconductor, circuit, manufacturing',
        'Net Assets': '10639000',
        'SIC 2007 Description': 'Manufacture of electronic components',
        'Shareholder Funds': '10639000',
        'Shareholders Names': 'Dynex Power Inc.',
        Status: 'Active',
        Telephone: '01522 500500',
        'Trading Address': 'Doddington Road, Lincoln, Lincolnshire, LN6 3LF',
        'Trading Address 1': 'Doddington Road',
        'Trading Address 2': 'Lincoln',
        'Trading Address 3': 'Lincolnshire',
        'Trading Address 4': '0',
        Turnover: '28612000',
        'Ultimate Domestic Parent Name': 'Dynex Semiconductor Limited',
        'Ultimate Domestic Parent Id': 'company/gb/03824626',
        'Ultimate Parent Name': 'Csr Group',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.dynexsemi.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-3052000',
        lat: 53.20583876691183,
        'long': -0.6113963532128998
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.612162401584799,
              53.20535477744905
            ],
            [
              -0.612042519865061,
              53.20532542362007
            ],
            [
              -0.611994418010655,
              53.20539615086144
            ],
            [
              -0.611249408561004,
              53.205214421471645
            ],
            [
              -0.61102937192946,
              53.205536651160514
            ],
            [
              -0.610735362723709,
              53.205465442455825
            ],
            [
              -0.610307538918876,
              53.20610572739545
            ],
            [
              -0.611777227849637,
              53.206459786883116
            ],
            [
              -0.612049742849982,
              53.20605186697665
            ],
            [
              -0.61216429930595,
              53.206079270953154
            ],
            [
              -0.612322117702819,
              53.205842794635856
            ],
            [
              -0.612168617484692,
              53.20580657696095
            ],
            [
              -0.612398595925051,
              53.205468369605946
            ],
            [
              -0.612202043509775,
              53.2054207732531
            ],
            [
              -0.612011795030309,
              53.20570172978691
            ],
            [
              -0.611938707891499,
              53.20568379828129
            ],
            [
              -0.612162401584799,
              53.20535477744905
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5028103',
        'Roof.Size': '3964.51122',
        'Estimated capacity': '555.0315708',
        'Title.Number': 'LL21439',
        Tenure: 'Freehold',
        'Property Address': '25-35 Sadler Road, Lincoln (LN6 3XJ)',
        District: 'LINCOLN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'SPALDINGS HOLDINGS LIMITED',
        'Company Registration No. (1)': '04897291',
        'Date Proprietor Added': '20/12/2005',
        POSTCODE_2: 'LN6 3RY',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '17',
        'Capital Employed': '3385000',
        Cash: '11000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '14000',
        'Current Liabilities': '7044000',
        'Debt to Capital': '67.54242976',
        'Dividends Payable': '1059000',
        'Dormant Company': 'No',
        EBITDA: '-300000',
        'Financial Year End': 'March',
        'Industry Keywords': 'agricultural products, leading specialist, tilling, agricultural industry, replacement parts, cleaning supplies, protective workwear, power tools, hand tools, electric fencing, agricultural equipment, distribute, markets, landscape gardening, safety, agricultural services, agricultural machinery, crops, farming, crop farming, tools, machinery, ground care, agriculture, agricultural, groundcare',
        'Net Assets': '3385000',
        'SIC 2007 Description': 'Support activities for crop production',
        'Shareholder Funds': '3385000',
        'Shareholders Names': 'David Andrew Fox, John Harold Sorby, Marubeni Agro Machinery Holding Ltd, Stephen Charles Constable',
        Status: 'Active',
        Telephone: '01522 500600',
        'Trading Address': '25-35 Sadler Road, Lincoln, Lincolnshire, LN6 3XJ',
        'Trading Address 1': '25-35 Sadler Road',
        'Trading Address 2': 'Lincoln',
        'Trading Address 3': 'Lincolnshire',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'Marubeni Agro Machinery Holding Limited',
        'Ultimate Domestic Parent Id': 'company/gb/08022039',
        'Ultimate Parent Name': 'Marubeni Agro Machinery Holding Limited',
        'Ultimate Parent Id': 'company/gb/08022039',
        'Web Address 1': 'http://www.spaldings.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-7030000',
        lat: 53.20346987176023,
        'long': -0.6031355796076162
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.602628191178597,
              53.20320991245428
            ],
            [
              -0.602705923084892,
              53.20377528139154
            ],
            [
              -0.603638798175183,
              53.20373057047689
            ],
            [
              -0.603570027990729,
              53.20316548701577
            ],
            [
              -0.602628191178597,
              53.20320991245428
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5028112',
        'Roof.Size': '10831.86862',
        'Estimated capacity': '1516.461607',
        'Title.Number': 'LL168867',
        Tenure: 'Freehold',
        'Property Address': '2 Sadler Road, Lincoln (LN6 3RA)',
        District: 'LINCOLN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'NMB-MINEBEA UK LTD',
        'Company Registration No. (1)': '02194706',
        'Date Proprietor Added': '',
        POSTCODE_2: 'LN6 3RA',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '32',
        'Capital Employed': '35767000',
        Cash: '4216000',
        Charges: '0',
        'Cost of Sales': '36942000',
        'Cost of Sales Delta': '3580000',
        'Cost of Sales Growth': '10.73077154',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '35675000',
        'Current Liabilities': '12237000',
        'Debt to Capital': '59.97833514',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '5608000',
        'Financial Year End': 'March',
        'Industry Keywords': 'engineering products, automotive engineering, electronic engineering, process engineering, engineering consulting, engineering & technical services, pipe manufacturing, cad engineering, manufacturing industry, manufacturing software, industrial manufacturing, manufacturing company, data protection, apprenticeships, conflict minerals, environment, precision products, global capabilities, minerals, metal products, roses, fabricated metal products, precision engineering, machinery, fasteners, cnc engineering, aircraft, design, landing gear, cnc, gears, gear, engineering, manufacturing',
        'Net Assets': '19212000',
        'SIC 2007 Description': 'Manufacture of other fabricated metal products n.e.c.',
        'Shareholder Funds': '19212000',
        'Shareholders Names': 'Minebea Mitsumi Inc',
        Status: 'Active',
        Telephone: '01522 500933',
        'Trading Address': 'Nmb-Minebea UK Ltd, Doddington Road, Lincoln, Lincolnshire, LN6 3RA',
        'Trading Address 1': 'Nmb-Minebea UK Ltd',
        'Trading Address 2': 'Doddington Road',
        'Trading Address 3': 'Lincoln',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '45360000',
        'Ultimate Domestic Parent Name': 'Nmb-Minebea UK Ltd',
        'Ultimate Domestic Parent Id': 'company/gb/02194706',
        'Ultimate Parent Name': 'Minebea Mitsumi Inc',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.nmb-minebea.co.uk',
        'Web Address 2': 'http://www.nmb-minebea-uk.net',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 53.204351212654615,
        'long': -0.6056084184205595
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.604990547023548,
              53.20478002679488
            ],
            [
              -0.605482126919362,
              53.20475602471204
            ],
            [
              -0.605508888127204,
              53.20496480675547
            ],
            [
              -0.606081736500217,
              53.20493725834794
            ],
            [
              -0.606064147820238,
              53.20479178064287
            ],
            [
              -0.605681763039448,
              53.204809781222124
            ],
            [
              -0.605672064541024,
              53.20473954884548
            ],
            [
              -0.606468837318191,
              53.204700055242945
            ],
            [
              -0.606355050605708,
              53.20386916406821
            ],
            [
              -0.604679617512138,
              53.203948713731364
            ],
            [
              -0.60475329817528,
              53.20452904632356
            ],
            [
              -0.604953988631324,
              53.204518001367084
            ],
            [
              -0.604990547023548,
              53.20478002679488
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5028138',
        'Roof.Size': '5397.018353',
        'Estimated capacity': '755.5825694',
        'Title.Number': 'LL160066',
        Tenure: 'Freehold',
        'Property Address': 'land on the north side of Whisby Road, Lincoln',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'PRAXAIR SURFACE TECHNOLOGIES LIMITED',
        'Company Registration No. (1)': '02416734',
        'Date Proprietor Added': '18/01/2013',
        POSTCODE_2: 'LN6 3AH',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '31',
        'Capital Employed': '39227000',
        Cash: '2000',
        Charges: '0',
        'Cost of Sales': '25039000',
        'Cost of Sales Delta': '1304000',
        'Cost of Sales Growth': '5.493996208',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '35581000',
        'Current Liabilities': '6813000',
        'Debt to Capital': '21.92658558',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '11101000',
        'Financial Year End': 'December',
        'Industry Keywords': 'power generation & transmission, chemical products, corrosion protection, nuclear power generation, powder coating, aviation, transportation, textiles, petroleum, paper industry, food industry, electricity generation, industrial coatings, protective coatings, aviation industry, oil gas industry, transportation equipment, ceramic coatings, thermal spray, power generation industry, gas industry, processing equipment, metal coating, carbide, fabric, metal treatment, metals, fabrics, metal, food, coatings, resistant coatings, glass, coating services, ceramics, ceramic, chemicals, corrosion, plastics industry, chemical, power generation, coating',
        'Net Assets': '35945000',
        'SIC 2007 Description': 'Treatment and coating of metals',
        'Shareholder Funds': '35945000',
        'Shareholders Names': 'Praxair Euroholdings Bv',
        Status: 'Active',
        Telephone: '01793 512555',
        'Trading Address': 'Drakes Way, Swindon, Wiltshire, SN3 3HX',
        'Trading Address 1': 'Drakes Way',
        'Trading Address 2': 'Swindon',
        'Trading Address 3': 'Wiltshire',
        'Trading Address 4': '0',
        Turnover: '39807000',
        'Ultimate Domestic Parent Name': 'Praxair Surface Technologies Limited',
        'Ultimate Domestic Parent Id': 'company/gb/02416734',
        'Ultimate Parent Name': 'Praxair Euroholdings Bv',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.praxairsurfacetechnologies.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '28768000',
        lat: 53.20204065163018,
        'long': -0.5993487099441437
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.599273645190168,
              53.20160004376569
            ],
            [
              -0.59926669107279,
              53.201666395270365
            ],
            [
              -0.598923220729661,
              53.20165336780124
            ],
            [
              -0.598845255086429,
              53.20241432087184
            ],
            [
              -0.599770121401845,
              53.20244919417471
            ],
            [
              -0.599849860781486,
              53.20168844122041
            ],
            [
              -0.599546157972069,
              53.20167714134572
            ],
            [
              -0.599552964931858,
              53.201610698201485
            ],
            [
              -0.599273645190168,
              53.20160004376569
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5028239',
        'Roof.Size': '9322.971796',
        'Estimated capacity': '1305.216051',
        'Title.Number': 'LL121526',
        Tenure: 'Freehold',
        'Property Address': 'land on the north side of Whisby Road, Lincoln',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'LINCAT LIMITED',
        'Company Registration No. (1)': '02175448',
        'Date Proprietor Added': '16/08/1995',
        POSTCODE_2: 'LN6 3QZ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '33',
        'Capital Employed': '15477506',
        Cash: '915691',
        Charges: 'Satisfied',
        'Cost of Sales': '22187150',
        'Cost of Sales Delta': '-815108',
        'Cost of Sales Growth': '-3.543599937',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '17340198',
        'Current Liabilities': '6693862',
        'Debt to Capital': '35.60057277',
        'Dividends Payable': '13000000',
        'Dormant Company': 'No',
        EBITDA: '13513221',
        'Financial Year End': 'December',
        'Industry Keywords': 'catering equipment hire, catering equipment suppliers, equipment catering, catering equipment services, kitchen equipment catering, catering equipment supply, commercial kitchen equipment, manufacturer, induction hobs, event catering, food waste, water, trolley, cooking equipment, display cases, kitchen equipment, displays, stainless steel fabrication, stainless steel, food display, counter top, food, steel fabrication, display cabinets, boilers, water boilers, ovens, merchandisers, hospitality, cupboards, manufacturing, steamer, commercial catering equipment, restaurant equipment, commercial catering, catering equipment, hotel equipment, catering',
        'Net Assets': '14278234',
        'SIC 2007 Description': 'Other manufacturing n.e.c.',
        'Shareholder Funds': '14278234',
        'Shareholders Names': 'Lincat Group Ltd',
        Status: 'Active',
        Telephone: '01522 875555',
        'Trading Address': 'Whisby Road, Lincoln, Lincolnshire, LN6 3QZ',
        'Trading Address 1': 'Whisby Road',
        'Trading Address 2': 'Lincoln',
        'Trading Address 3': 'Lincolnshire',
        'Trading Address 4': '0',
        Turnover: '44538620',
        'Ultimate Domestic Parent Name': 'Middleby Holding UK Ltd',
        'Ultimate Domestic Parent Id': 'company/gb/07568995',
        'Ultimate Parent Name': 'The Middleby Corporation ',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.lincat.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '10646336',
        lat: 53.20133586600497,
        'long': -0.609303309496284
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.61034177836508,
              53.20144627812386
            ],
            [
              -0.61027643839686,
              53.20098542826607
            ],
            [
              -0.610076566275051,
              53.200994873520756
            ],
            [
              -0.610066986218814,
              53.20093003663534
            ],
            [
              -0.610063128662047,
              53.20092432819055
            ],
            [
              -0.610057152670368,
              53.200919314204285
            ],
            [
              -0.610050232232381,
              53.20091572753811
            ],
            [
              -0.61004134592246,
              53.2009127472134
            ],
            [
              -0.610031371324259,
              53.200911012738366
            ],
            [
              -0.610021200556406,
              53.200910714310844
            ],
            [
              -0.609913526862872,
              53.20091629041204
            ],
            [
              -0.609904175528106,
              53.200918428726425
            ],
            [
              -0.609862781649686,
              53.200934576610585
            ],
            [
              -0.609804250195547,
              53.20093901785839
            ],
            [
              -0.60977015659209,
              53.200923877160086
            ],
            [
              -0.60964526662009,
              53.20092943195617
            ],
            [
              -0.609642106403372,
              53.20092993446035
            ],
            [
              -0.609634409988833,
              53.20093182237442
            ],
            [
              -0.609628800029066,
              53.200934004320246
            ],
            [
              -0.609623765427364,
              53.200936912147235
            ],
            [
              -0.609618842676373,
              53.20094098992756
            ],
            [
              -0.609616660255742,
              53.20094366134471
            ],
            [
              -0.609615551597139,
              53.2009455362259
            ],
            [
              -0.609614079650246,
              53.20094938458117
            ],
            [
              -0.609613681465595,
              53.200952436400534
            ],
            [
              -0.609622466033132,
              53.20101870238072
            ],
            [
              -0.608305010327821,
              53.20108297127208
            ],
            [
              -0.608389622536393,
              53.20172932311694
            ],
            [
              -0.609986819610944,
              53.20165411302194
            ],
            [
              -0.609962124586331,
              53.20146414434715
            ],
            [
              -0.61034177836508,
              53.20144627812386
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5028291',
        'Roof.Size': '4245.720107',
        'Estimated capacity': '594.400815',
        'Title.Number': 'LL279163',
        Tenure: 'Freehold',
        'Property Address': 'Land and buildings on the north side of Freeman Road, North Hykeham',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'SPECIALIST HEAT EXCHANGERS LIMITED',
        'Company Registration No. (1)': '00936014',
        'Date Proprietor Added': '05/02/2007',
        POSTCODE_2: 'LN6 9AP',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '52',
        'Capital Employed': '6062000',
        Cash: '97000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '6024000',
        'Cost of Sales Delta': '564000',
        'Cost of Sales Growth': '10.32967033',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '6179000',
        'Current Liabilities': '2717000',
        'Debt to Capital': '33.77377833',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '350000',
        'Financial Year End': 'December',
        'Industry Keywords': 'design, cooling equipment, non-domestic cooling equipment, non-domestic ventilation equipment, manufacturing, heat exchangers, ventilation equipment',
        'Net Assets': '5814000',
        'SIC 2007 Description': 'Manufacture of non-domestic cooling and ventilation equipment',
        'Shareholder Funds': '5814000',
        'Shareholders Names': 'Dynamic Technologies UK Ltd',
        Status: 'Active',
        Telephone: '01522 881100',
        'Trading Address': 'Freeman Road, North Hykeham, Lincoln, Lincolnshire, LN6 9AP',
        'Trading Address 1': 'Freeman Road',
        'Trading Address 2': 'North Hykeham',
        'Trading Address 3': 'Lincoln',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '7273000',
        'Ultimate Domestic Parent Name': 'International Auto Oem Supplier Ukco Ltd.',
        'Ultimate Domestic Parent Id': 'company/gb/10824134',
        'Ultimate Parent Name': 'Ardian Holding',
        'Ultimate Parent Id': 'company/fr/752778159',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '3462000',
        lat: 53.19441699567954,
        'long': -0.5975666336917252
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.597797533399619,
              53.19398249832009
            ],
            [
              -0.597484354261493,
              53.19403850357478
            ],
            [
              -0.597526495700595,
              53.194123232395306
            ],
            [
              -0.596991523502228,
              53.19421905665886
            ],
            [
              -0.597283574393229,
              53.19480583194126
            ],
            [
              -0.598131601947263,
              53.19465354925245
            ],
            [
              -0.597797533399619,
              53.19398249832009
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5028309',
        'Roof.Size': '7544.890851',
        'Estimated capacity': '1056.284719',
        'Title.Number': 'LL181757',
        Tenure: 'Freehold',
        'Property Address': 'Lindum House, Lindum Business Park, Station Road, North Hykeham, Lincoln (LN6 3FE)',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'LINDUM GROUP LIMITED',
        'Company Registration No. (1)': '01236338',
        'Date Proprietor Added': '08/03/2000',
        POSTCODE_2: 'VLN00327',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '44',
        'Capital Employed': '48643365',
        Cash: '30001169',
        Charges: 'Satisfied',
        'Cost of Sales': '144614697',
        'Cost of Sales Delta': '9444272',
        'Cost of Sales Growth': '6.986936676',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '80659991',
        'Current Liabilities': '51367125',
        'Debt to Capital': '52.22648444',
        'Dividends Payable': '1160090',
        'Dormant Company': 'No',
        EBITDA: '6800086',
        'Financial Year End': 'November',
        'Industry Keywords': 'commercial construction, construction services, general construction, construction engineering, building projects, building construction, construction software, construction work, construction projects, construction industry, civil construction, building site, building industry, construction site, waste recycling, retail, education, design agency, joinery, client relationships, supermarket, maintenance jobs, trusts, savings, business space, primary services, construction company, craftsmen, building, design, skilled craftsmen, commercial development, maintenance, customer support services, food, refurbishment, support services, commercial buildings, civil engineering works, building construction contractors, construction contractors, construction',
        'Net Assets': '47778527',
        'SIC 2007 Description': 'Construction of commercial buildings',
        'Shareholder Funds': '47778527',
        'Shareholders Names': 'Tracey Papworth, Dean Taylor, Robert Wright, John Hayhurst, Peter Sharp-Grose, Alexander Block, Jay Mallia, Jennifer Murray, Charlie O\'kelly, Malcolm Coxell, Daniel Peel, Chris Brown, Simon Cartner, Rosemary Sharp, Paul Fisher, Iain Mcdermid, Lorraine Woolley, David Hollis, Peter Storr, Thomas Jackson, Andrew Foster, Jason Aisthorpe, Alistair Carradice, Lester Fisher, Conrad Cousins, Danielle Weatherley, Andrew Hudson, Jake Lee, Nicola Ward, Katie O\'kelly, Aaron Horrocks, Jason Roskell, Luke Scotney, Chris Hammett, George Wellock, Robert Jays, Leanne Goodale, David Asquith, Kim Baker, John David Pearson, William Lazenby, Alexander Hackett, Ashley Bliss, Andy Barradell, Stephen Roberts, Ian Ross, Sara Hobson, Carol Buckley, Roy Brown, Lynda Stacey, Martin Brennan, John Howard, David Jolliffe, Jamie Boulton, Sandra Kettleborough, Robert Mcauley, Sally Choularton, Colin Knight, Steve Timmins, Stuart Weir, Dean Bailey, Anthony Gamwell, Mark Holland, Richard Neale, Richard Cooke, Philip Dudding, Neil White, Paul Brown, James Branscombe, Vincent Bell, Nigel Carter, Dean Hanson, Dylan Billanie, Paul Bowler, Christopher Elsom, Frank Williams, Gary Burton, Matthew Harrison, Neil Potts, Darren Staples, Ashley Jarvis, Herman Kok, David Horton, Dominic Kidd, Matthew Carter, Nicole Watkins, Lynn Ritson, Jonathan Cocking, Joseph Brennan, Ryan Calvert, Stephen Deighton, Alex Stanton, George Kusi-Manu, William Gargan, Daniel Clark, Daryl Waters, Adam Davenport, Matthew Marshallsay, Carl Pacey, Becky Mcconnell',
        Status: 'Active',
        Telephone: '01522 500300',
        'Trading Address': 'Lindum Business Park, Station Road, North Hykeham, Lincoln, Lincolnshire, Ln6 3QX',
        'Trading Address 1': 'Lindum Business Park',
        'Trading Address 2': 'Station Road, North Hykeham',
        'Trading Address 3': 'Lincoln',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '170570982',
        'Ultimate Domestic Parent Name': 'Lindum Group Limited',
        'Ultimate Domestic Parent Id': 'company/gb/01236338',
        'Ultimate Parent Name': 'Lindum Group Limited',
        'Ultimate Parent Id': 'company/gb/01236338',
        'Web Address 1': 'http://www.lindumgroup.com',
        'Web Address 2': 'http://www.lindumplanthire.co.uk',
        'Web Address 3': 'http://www.lindumgroup.co.uk',
        'Web Address 4': 'http://www.lindumhomes.co.uk',
        'Working Capital': '29292866',
        lat: 53.1974108834202,
        'long': -0.598133087483263
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.599032260010113,
              53.19719364203645
            ],
            [
              -0.597756205933181,
              53.19693267641144
            ],
            [
              -0.597498533203393,
              53.19739260942197
            ],
            [
              -0.597945394413571,
              53.1974841684954
            ],
            [
              -0.597837280103243,
              53.19768237604758
            ],
            [
              -0.597664901878235,
              53.19764789499816
            ],
            [
              -0.597714781440497,
              53.197559844330456
            ],
            [
              -0.597200031931852,
              53.1974567875501
            ],
            [
              -0.597066697989222,
              53.19769371248117
            ],
            [
              -0.598150070877131,
              53.19790980793519
            ],
            [
              -0.598343271446676,
              53.197576767828906
            ],
            [
              -0.598755597666637,
              53.19765784923131
            ],
            [
              -0.598902691230378,
              53.197398070817634
            ],
            [
              -0.59907106135384,
              53.19743151409666
            ],
            [
              -0.599147430701905,
              53.1972930728153
            ],
            [
              -0.599035866947863,
              53.197271264868846
            ],
            [
              -0.599075254589079,
              53.19720187859491
            ],
            [
              -0.599207759531147,
              53.19722878707956
            ],
            [
              -0.599252891337608,
              53.197148500937416
            ],
            [
              -0.599077706036915,
              53.19711291017204
            ],
            [
              -0.599032260010113,
              53.19719364203645
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5028447',
        'Roof.Size': '4479.667194',
        'Estimated capacity': '627.1534072',
        'Title.Number': 'LL183250',
        Tenure: 'Freehold',
        'Property Address': 'Land on the east side of Wigsley Road, North Scarle, Lincoln',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'D & L J WILSON FARMING LIMITED',
        'Company Registration No. (1)': '11942483',
        'Date Proprietor Added': '10/06/2019',
        POSTCODE_2: 'LN6 9HD',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '1',
        'Capital Employed': '5145508',
        Cash: '111920',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '229615',
        'Current Liabilities': '232989',
        'Debt to Capital': '57.36059721',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'April',
        'Industry Keywords': 'poultry farming, farming, poultry',
        'Net Assets': '2293359',
        'SIC 2007 Description': 'Raising of poultry',
        'Shareholder Funds': '2293359',
        'Shareholders Names': 'Linda Jane Wilson, David Wilson',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Orchard Farm, Wigsley Road, North Scarle, Lincoln, LN6 9HD',
        'Trading Address 1': 'Orchard Farm',
        'Trading Address 2': 'Wigsley Road',
        'Trading Address 3': 'North Scarle',
        'Trading Address 4': 'Lincoln',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-3374',
        lat: 53.20094865745251,
        'long': -0.7266124761277046
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.726153491817706,
              53.200716984069246
            ],
            [
              -0.726057681849271,
              53.20066130381909
            ],
            [
              -0.72582265319383,
              53.20080720748794
            ],
            [
              -0.726882769381659,
              53.20142290059825
            ],
            [
              -0.727117797874514,
              53.20127699485158
            ],
            [
              -0.726247391058885,
              53.20077147518486
            ],
            [
              -0.726308396106598,
              53.200733652232095
            ],
            [
              -0.727179393557582,
              53.20123944744923
            ],
            [
              -0.727411675525946,
              53.201095219794794
            ],
            [
              -0.726350969034529,
              53.20047925536308
            ],
            [
              -0.726118686904333,
              53.20062348096283
            ],
            [
              -0.726214499533245,
              53.200679071297884
            ],
            [
              -0.726153491817706,
              53.200716984069246
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5028816',
        'Roof.Size': '5994.309573',
        'Estimated capacity': '839.2033402',
        'Title.Number': 'LL183540',
        Tenure: 'Freehold',
        'Property Address': 'North Scarle Egg Packing Station, Hives Lane, North Scarle, (LN6 9HA)',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'NOBLE FOODS LIMITED',
        'Company Registration No. (1)': '03636168',
        'Date Proprietor Added': '20/04/2000',
        POSTCODE_2: 'LN6 9HA',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '22',
        'Capital Employed': '76251000',
        Cash: '7853000',
        Charges: 'Outstanding, Partially Satisfied, Satisfied',
        'Cost of Sales': '277935000',
        'Cost of Sales Delta': '672000',
        'Cost of Sales Growth': '0.242369159',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '173806000',
        'Current Liabilities': '177448000',
        'Debt to Capital': '71.87493841',
        'Dividends Payable': '5000000',
        'Dormant Company': 'No',
        EBITDA: '14751000',
        'Financial Year End': 'September',
        'Industry Keywords': 'compound feed, energy, green energy, quality assurance, supply chain, milling, food waste, nationwide service, food, fresh food, manufacturing, food products, merchants, poultry, animals, poultry meat, egg merchants, egg, animal feeds',
        'Net Assets': '71353000',
        'SIC 2007 Description': 'Manufacture of other food products n.e.c.',
        'Shareholder Funds': '71353000',
        'Shareholders Names': 'Noble Foods Holdings Ltd',
        Status: 'Active',
        Telephone: '01865 301200',
        'Trading Address': 'Cotswold Farm, Standlake, Witney, Oxfordshire, OX29 7RB',
        'Trading Address 1': 'Cotswold Farm',
        'Trading Address 2': 'Standlake',
        'Trading Address 3': 'Witney',
        'Trading Address 4': 'Oxfordshire',
        Turnover: '314224000',
        'Ultimate Domestic Parent Name': 'Noble Foods Group Limited',
        'Ultimate Domestic Parent Id': 'company/gb/05826545',
        'Ultimate Parent Name': 'Noble Foods Group Guernsey Ltd',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.noblefoods.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-3642000',
        lat: 53.19333241464919,
        'long': -0.7398806095996486
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.738935027011775,
              53.19307702644829
            ],
            [
              -0.738783197757442,
              53.193241994682566
            ],
            [
              -0.740591594889867,
              53.19384220128505
            ],
            [
              -0.740976112160212,
              53.1934240286949
            ],
            [
              -0.739174578650518,
              53.192824979194455
            ],
            [
              -0.739016715036817,
              53.19299671588283
            ],
            [
              -0.738935027011775,
              53.19307702644829
            ]
          ],
          [
            [
              -0.739000399570589,
              53.19309875364955
            ],
            [
              -0.739081782971315,
              53.19301861960615
            ],
            [
              -0.740764482524185,
              53.193576501454096
            ],
            [
              -0.74068101413297,
              53.193656344911474
            ],
            [
              -0.739000399570589,
              53.19309875364955
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5029018',
        'Roof.Size': '13299.38223',
        'Estimated capacity': '1861.913512',
        'Title.Number': 'LL183540',
        Tenure: 'Freehold',
        'Property Address': 'North Scarle Egg Packing Station, Hives Lane, North Scarle, (LN6 9HA)',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'NOBLE FOODS LIMITED',
        'Company Registration No. (1)': '03636168',
        'Date Proprietor Added': '20/04/2000',
        POSTCODE_2: 'LN6 9HA',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '22',
        'Capital Employed': '76251000',
        Cash: '7853000',
        Charges: 'Outstanding, Partially Satisfied, Satisfied',
        'Cost of Sales': '277935000',
        'Cost of Sales Delta': '672000',
        'Cost of Sales Growth': '0.242369159',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '173806000',
        'Current Liabilities': '177448000',
        'Debt to Capital': '71.87493841',
        'Dividends Payable': '5000000',
        'Dormant Company': 'No',
        EBITDA: '14751000',
        'Financial Year End': 'September',
        'Industry Keywords': 'compound feed, energy, green energy, quality assurance, supply chain, milling, food waste, nationwide service, food, fresh food, manufacturing, food products, merchants, poultry, animals, poultry meat, egg merchants, egg, animal feeds',
        'Net Assets': '71353000',
        'SIC 2007 Description': 'Manufacture of other food products n.e.c.',
        'Shareholder Funds': '71353000',
        'Shareholders Names': 'Noble Foods Holdings Ltd',
        Status: 'Active',
        Telephone: '01865 301200',
        'Trading Address': 'Cotswold Farm, Standlake, Witney, Oxfordshire, OX29 7RB',
        'Trading Address 1': 'Cotswold Farm',
        'Trading Address 2': 'Standlake',
        'Trading Address 3': 'Witney',
        'Trading Address 4': 'Oxfordshire',
        Turnover: '314224000',
        'Ultimate Domestic Parent Name': 'Noble Foods Group Limited',
        'Ultimate Domestic Parent Id': 'company/gb/05826545',
        'Ultimate Parent Name': 'Noble Foods Group Guernsey Ltd',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.noblefoods.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-3642000',
        lat: 53.19417762098515,
        'long': -0.7418961197386931
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.742265276345168,
              53.194597465131224
            ],
            [
              -0.742207182903072,
              53.194664452827105
            ],
            [
              -0.743033002660366,
              53.194941142967785
            ],
            [
              -0.743447364408657,
              53.1944949604924
            ],
            [
              -0.742264336520728,
              53.19409251623714
            ],
            [
              -0.742439250935189,
              53.19390558325279
            ],
            [
              -0.741062987125826,
              53.1934424760442
            ],
            [
              -0.740439591458132,
              53.194110279089244
            ],
            [
              -0.741133419574433,
              53.19434423568621
            ],
            [
              -0.741224600283166,
              53.194246404534894
            ],
            [
              -0.742265276345168,
              53.194597465131224
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5030823',
        'Roof.Size': '7532.008329',
        'Estimated capacity': '1054.481166',
        'Title.Number': 'LL335915',
        Tenure: 'Freehold',
        'Property Address': 'Fielden House, Teal Park Road, Lincoln (LN6 3AD)',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'SIEMENS PLC',
        'Company Registration No. (1)': '00727817',
        'Date Proprietor Added': '21/07/2017',
        POSTCODE_2: 'LN6 3AD',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '58',
        'Capital Employed': '1887966000',
        Cash: '0',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '682453000',
        'Cost of Sales Delta': '-30386000',
        'Cost of Sales Growth': '-4.262673619',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '902898000',
        'Current Liabilities': '299386000',
        'Debt to Capital': '32.83033549',
        'Dividends Payable': '875000000',
        'Dormant Company': 'No',
        EBITDA: '34474000',
        'Financial Year End': 'September',
        'Industry Keywords': 'healthcare staff, automation equipment, automation software, healthcare information, manufacturing industry, healthcare support, electricity connections, commercial manufacturing, pipe manufacturing, manufacturing software, plants, plant, electric drive, digitization, gas, value chain, gas industry, electronic manufacturing, factory automation, electrical systems, electrical supplies, industrial manufacturing, manufacturing company, health care, automated systems, automated control systems, industrial automation, air handling units, renewable energy services, traffic management system, trusts, offshore wind farm, supply chain finance, variable speed drives, internet of things, green technology, medical technology, family business, supply chain management, fire protection systems, building management systems, engineering, environmental, medical systems, technology company, medical solutions, medical, infrastructure, ultrasound, diagnostics, software, tomography, imaging software, molecular, molecular diagnostics, technology, magnetic resonance, laboratory, radiation, installation, electronic components, energy industry, electronic valves, electronic tubes, transportation, smart energy, metering, renewable electricity, transportation systems, electricity generation, electricity, healthcare equipment, manufacturing, energy, automation, healthcare',
        'Net Assets': '1469237000',
        'SIC 2007 Description': 'Manufacture of electronic components',
        'Shareholder Funds': '1469237000',
        'Shareholders Names': 'Siemens Holdings Plc',
        Status: 'Active',
        Telephone: '01276 696000',
        'Trading Address': 'Faraday House, Sir William Siemens Square, Frimley, Camberley, Surrey, GU16 8QD',
        'Trading Address 1': 'Faraday House',
        'Trading Address 2': 'Sir William Siemens Square',
        'Trading Address 3': 'Frimley',
        'Trading Address 4': 'Camberley, Surrey',
        Turnover: '756014000',
        'Ultimate Domestic Parent Name': 'Siemens Holdings Plc',
        'Ultimate Domestic Parent Id': 'company/gb/02465263',
        'Ultimate Parent Name': 'Cinetik',
        'Ultimate Parent Id': 'company/fr/420109217',
        'Web Address 1': 'http://www.siemens.com',
        'Web Address 2': 'http://www.siemens.co.uk',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '603512000',
        lat: 53.195819384574094,
        'long': -0.6172839393954487
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.617057927754495,
              53.19638146061317
            ],
            [
              -0.618041137392487,
              53.19617648783809
            ],
            [
              -0.61750994841575,
              53.1952573420522
            ],
            [
              -0.616526760328906,
              53.19546222073238
            ],
            [
              -0.617057927754495,
              53.19638146061317
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5030825',
        'Roof.Size': '10287.41157',
        'Estimated capacity': '1440.23762',
        'Title.Number': 'LL335915',
        Tenure: 'Freehold',
        'Property Address': 'Fielden House, Teal Park Road, Lincoln (LN6 3AD)',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'SIEMENS PLC',
        'Company Registration No. (1)': '00727817',
        'Date Proprietor Added': '21/07/2017',
        POSTCODE_2: 'LN6 3AD',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '58',
        'Capital Employed': '1887966000',
        Cash: '0',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '682453000',
        'Cost of Sales Delta': '-30386000',
        'Cost of Sales Growth': '-4.262673619',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '902898000',
        'Current Liabilities': '299386000',
        'Debt to Capital': '32.83033549',
        'Dividends Payable': '875000000',
        'Dormant Company': 'No',
        EBITDA: '34474000',
        'Financial Year End': 'September',
        'Industry Keywords': 'healthcare staff, automation equipment, automation software, healthcare information, manufacturing industry, healthcare support, electricity connections, commercial manufacturing, pipe manufacturing, manufacturing software, plants, plant, electric drive, digitization, gas, value chain, gas industry, electronic manufacturing, factory automation, electrical systems, electrical supplies, industrial manufacturing, manufacturing company, health care, automated systems, automated control systems, industrial automation, air handling units, renewable energy services, traffic management system, trusts, offshore wind farm, supply chain finance, variable speed drives, internet of things, green technology, medical technology, family business, supply chain management, fire protection systems, building management systems, engineering, environmental, medical systems, technology company, medical solutions, medical, infrastructure, ultrasound, diagnostics, software, tomography, imaging software, molecular, molecular diagnostics, technology, magnetic resonance, laboratory, radiation, installation, electronic components, energy industry, electronic valves, electronic tubes, transportation, smart energy, metering, renewable electricity, transportation systems, electricity generation, electricity, healthcare equipment, manufacturing, energy, automation, healthcare',
        'Net Assets': '1469237000',
        'SIC 2007 Description': 'Manufacture of electronic components',
        'Shareholder Funds': '1469237000',
        'Shareholders Names': 'Siemens Holdings Plc',
        Status: 'Active',
        Telephone: '01276 696000',
        'Trading Address': 'Faraday House, Sir William Siemens Square, Frimley, Camberley, Surrey, GU16 8QD',
        'Trading Address 1': 'Faraday House',
        'Trading Address 2': 'Sir William Siemens Square',
        'Trading Address 3': 'Frimley',
        'Trading Address 4': 'Camberley, Surrey',
        Turnover: '756014000',
        'Ultimate Domestic Parent Name': 'Siemens Holdings Plc',
        'Ultimate Domestic Parent Id': 'company/gb/02465263',
        'Ultimate Parent Name': 'Cinetik',
        'Ultimate Parent Id': 'company/fr/420109217',
        'Web Address 1': 'http://www.siemens.com',
        'Web Address 2': 'http://www.siemens.co.uk',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '603512000',
        lat: 53.196251179227644,
        'long': -0.6155641586469734
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.615497877548462,
              53.19578414445153
            ],
            [
              -0.615458150327566,
              53.19571626081543
            ],
            [
              -0.61534797457721,
              53.19573952221347
            ],
            [
              -0.615387698748791,
              53.19580749574747
            ],
            [
              -0.614873892128342,
              53.19591605180393
            ],
            [
              -0.615207713242891,
              53.19648735560251
            ],
            [
              -0.614930085530838,
              53.19654588744386
            ],
            [
              -0.614581349427161,
              53.195949777925264
            ],
            [
              -0.614359789883457,
              53.1959965546475
            ],
            [
              -0.614526074989777,
              53.19628058200737
            ],
            [
              -0.614509009077345,
              53.19629917185353
            ],
            [
              -0.614507586988702,
              53.19630149261587
            ],
            [
              -0.614506760562027,
              53.19630391020019
            ],
            [
              -0.614506679437284,
              53.1963064263464
            ],
            [
              -0.614507352306537,
              53.19630877146741
            ],
            [
              -0.614508626632707,
              53.19631103368565
            ],
            [
              -0.614510652055777,
              53.19631321474104
            ],
            [
              -0.61451313798795,
              53.19631504156664
            ],
            [
              -0.614517114524802,
              53.196317065515856
            ],
            [
              -0.614520668217851,
              53.19631827548399
            ],
            [
              -0.614550836520016,
              53.19632509876793
            ],
            [
              -0.614875174953368,
              53.19687939275736
            ],
            [
              -0.615094830830656,
              53.19683142428921
            ],
            [
              -0.614943840845185,
              53.196574274688636
            ],
            [
              -0.615224029989142,
              53.19651523320545
            ],
            [
              -0.615371583524176,
              53.1967675780244
            ],
            [
              -0.61672699522461,
              53.19648234826839
            ],
            [
              -0.616838255407364,
              53.19634636856255
            ],
            [
              -0.616748178186976,
              53.196187016031224
            ],
            [
              -0.616697065633811,
              53.196198378853104
            ],
            [
              -0.61677207416772,
              53.196328250398295
            ],
            [
              -0.616657379983642,
              53.19646859508285
            ],
            [
              -0.616529265499905,
              53.196495694642756
            ],
            [
              -0.616045592653631,
              53.19566842828241
            ],
            [
              -0.615497877548462,
              53.19578414445153
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5040677',
        'Roof.Size': '6275.841511',
        'Estimated capacity': '878.6178115',
        'Title.Number': 'LL119930',
        Tenure: 'Freehold',
        'Property Address': 'John Coupland Hospital, Ropery Road, Gainsborough',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'NHS PROPERTY SERVICES LIMITED',
        'Company Registration No. (1)': '07888110',
        'Date Proprietor Added': '23/05/2013',
        POSTCODE_2: 'DN21 2TH',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '8',
        'Capital Employed': '4328769000',
        Cash: '112849000',
        Charges: '0',
        'Cost of Sales': '661172000',
        'Cost of Sales Delta': '42077000',
        'Cost of Sales Growth': '6.79653365',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '703795000',
        'Current Liabilities': '293620000',
        'Debt to Capital': '20.94462409',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '103898000',
        'Financial Year End': 'March',
        'Industry Keywords': 'residential property services, property landlords, property information, commercial property experts, property advice, property finding, property repossession, commercial property sales, commercial property letting, residential property investment, health care, delivery, pct, social, clinical services, primary care, national health service, gp practices, national health, strategic health authorities, health services, pcts, social care, gps, health authorities, primary care trusts, health, hospital & health care, property services, commercial real estate, facilities management, real estate management, property management, real estate, facilities management services, property',
        'Net Assets': '3654247000',
        'SIC 2007 Description': 'Management of real estate on a fee or contract basis',
        'Shareholder Funds': '3654247000',
        'Shareholders Names': 'Secretary of State For Health & Social Care',
        Status: 'Active',
        Telephone: '020 3049 4300',
        'Trading Address': '85 Gresham Street, London, EC2V 7NQ',
        'Trading Address 1': '85 Gresham Street',
        'Trading Address 2': 'London',
        'Trading Address 3': '0',
        'Trading Address 4': '0',
        Turnover: '797743000',
        'Ultimate Domestic Parent Name': 'Nhs Property Services Limited',
        'Ultimate Domestic Parent Id': 'company/gb/07888110',
        'Ultimate Parent Name': 'Secretary of State For Health',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://property.nhs.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 53.41159699304559,
        'long': -0.7851116400838668
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.785639611223791,
              53.411907868029985
            ],
            [
              -0.785657913257681,
              53.41205763308605
            ],
            [
              -0.785744529927049,
              53.41205383935387
            ],
            [
              -0.785725007609158,
              53.41189408398175
            ],
            [
              -0.785618657406191,
              53.41189875575454
            ],
            [
              -0.785591603902049,
              53.41154916342223
            ],
            [
              -0.785441708307091,
              53.41155617889586
            ],
            [
              -0.785432651211798,
              53.41148857848783
            ],
            [
              -0.785611926578945,
              53.41148006392663
            ],
            [
              -0.785580079103963,
              53.41114561444025
            ],
            [
              -0.785919642229142,
              53.411134863778564
            ],
            [
              -0.785881293710185,
              53.41073805366
            ],
            [
              -0.785724671325777,
              53.410743652727554
            ],
            [
              -0.785739851407421,
              53.41090794843873
            ],
            [
              -0.785366431637621,
              53.41091898351339
            ],
            [
              -0.785373199205632,
              53.41098772923606
            ],
            [
              -0.785518690186192,
              53.410982107328664
            ],
            [
              -0.785532269363305,
              53.41111807104876
            ],
            [
              -0.78509703399706,
              53.41113369040644
            ],
            [
              -0.78512334998821,
              53.41143509369615
            ],
            [
              -0.785357810651461,
              53.411427590287005
            ],
            [
              -0.785343045351861,
              53.4112804679773
            ],
            [
              -0.785547098036686,
              53.411273553864284
            ],
            [
              -0.785564565098572,
              53.41145261500028
            ],
            [
              -0.785383033776804,
              53.41146110654802
            ],
            [
              -0.785423409013517,
              53.41186584783541
            ],
            [
              -0.785282325408797,
              53.41186980653093
            ],
            [
              -0.785245750015561,
              53.41146375546855
            ],
            [
              -0.785067591496301,
              53.411470033560036
            ],
            [
              -0.785070951357866,
              53.411510518787715
            ],
            [
              -0.784984328058661,
              53.41151458163304
            ],
            [
              -0.785019817725783,
              53.41187945156073
            ],
            [
              -0.784906727411232,
              53.41188306533226
            ],
            [
              -0.784892258049425,
              53.41172560849141
            ],
            [
              -0.784904762971775,
              53.4116405188313
            ],
            [
              -0.784947593197317,
              53.41155771528283
            ],
            [
              -0.785004579694263,
              53.41149024734336
            ],
            [
              -0.784992582643693,
              53.4113938517985
            ],
            [
              -0.784577431813002,
              53.411412010760564
            ],
            [
              -0.784586612694831,
              53.411496332268754
            ],
            [
              -0.784625053968065,
              53.41150418441887
            ],
            [
              -0.784573277945073,
              53.411605234630905
            ],
            [
              -0.784687929474051,
              53.411625997586306
            ],
            [
              -0.784613202986591,
              53.411761602187944
            ],
            [
              -0.784602738666943,
              53.41175924842295
            ],
            [
              -0.784590492171693,
              53.41174024664489
            ],
            [
              -0.784501568468498,
              53.41169304773411
            ],
            [
              -0.784470898261227,
              53.4117134105863
            ],
            [
              -0.78432862329583,
              53.41163780229132
            ],
            [
              -0.784343167954126,
              53.41162896120747
            ],
            [
              -0.784478052840393,
              53.41159437752283
            ],
            [
              -0.784443474218897,
              53.411546023693134
            ],
            [
              -0.784228099462811,
              53.41160172150737
            ],
            [
              -0.784157659513792,
              53.411650264862395
            ],
            [
              -0.784537523862187,
              53.41185144241473
            ],
            [
              -0.784554407929997,
              53.41205081318317
            ],
            [
              -0.784499507122836,
              53.412050344371096
            ],
            [
              -0.784266363936347,
              53.41221202296685
            ],
            [
              -0.784356792518701,
              53.412259237361795
            ],
            [
              -0.784379489268857,
              53.41234441556891
            ],
            [
              -0.784469854736896,
              53.41233580678719
            ],
            [
              -0.784446540810974,
              53.41225116166411
            ],
            [
              -0.78441334882158,
              53.41225441950518
            ],
            [
              -0.784388862199146,
              53.41224257432215
            ],
            [
              -0.784593894130843,
              53.41210119452099
            ],
            [
              -0.784797311353409,
              53.41220645944424
            ],
            [
              -0.784913209166414,
              53.41212547799755
            ],
            [
              -0.784747077086701,
              53.41204225642674
            ],
            [
              -0.784713199623021,
              53.41196927962145
            ],
            [
              -0.784902314292031,
              53.411964012424214
            ],
            [
              -0.784913049817568,
              53.4120571590517
            ],
            [
              -0.785397944069692,
              53.41204150598296
            ],
            [
              -0.785384177563977,
              53.41191740600255
            ],
            [
              -0.785639611223791,
              53.411907868029985
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5040884',
        'Roof.Size': '5216.183566',
        'Estimated capacity': '730.2656992',
        'Title.Number': 'LL308583',
        Tenure: 'Freehold',
        'Property Address': 'Land on the north side of North Warren Road, Gainsborough',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'HEXADEX LIMITED',
        'Company Registration No. (1)': '02674947',
        'Date Proprietor Added': '16/03/2020',
        POSTCODE_2: 'DN21 2TT',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '28',
        'Capital Employed': '62379000',
        Cash: '8685000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '52531000',
        'Current Liabilities': '21993000',
        'Debt to Capital': '29.48727066',
        'Dividends Payable': '784000',
        'Dormant Company': 'No',
        EBITDA: '9474000',
        'Financial Year End': 'December',
        'Industry Keywords': 'control systems, emission control, exhaust pipes, stainless steel products, steel products, stainless steel, head offices, holding companies, technology, manufacturing, emissions, exhaust systems, clamps, holding companies management',
        'Net Assets': '59493000',
        'SIC 2007 Description': 'Activities of head offices',
        'Shareholder Funds': '59493000',
        'Shareholders Names': 'D.H. Milles Settlement 1988 Trustees of, Hexadex Ltd 2008 Employee Benefit Trust Trustees of, David Herbert Milles, D.H. Milles Settlement 1998 Trustees of, 2008 Sip Trustees of',
        Status: 'Active',
        Telephone: '01427 676700',
        'Trading Address': 'The Old Court House, 24 Market Street, Gainsborough, Lincolnshire, DN21 2BE',
        'Trading Address 1': 'The Old Court House',
        'Trading Address 2': '24 Market Street',
        'Trading Address 3': 'Gainsborough',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '128426000',
        'Ultimate Domestic Parent Name': 'Hexadex Limited',
        'Ultimate Domestic Parent Id': 'company/gb/02674947',
        'Ultimate Parent Name': 'Hexadex Limited',
        'Ultimate Parent Id': 'company/gb/02674947',
        'Web Address 1': 'http://www.hexadex.com',
        'Web Address 2': 'http://www.hexadex.co.uk',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '30538000',
        lat: 53.40879120308101,
        'long': -0.786564419520334
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.787256880122128,
              53.40907474872684
            ],
            [
              -0.787206949602731,
              53.40882560254898
            ],
            [
              -0.78703556970871,
              53.40883725594507
            ],
            [
              -0.786946669236272,
              53.40841916776598
            ],
            [
              -0.785891260000768,
              53.40849644714848
            ],
            [
              -0.785933736278472,
              53.40870066241648
            ],
            [
              -0.786049214695633,
              53.408692127963285
            ],
            [
              -0.786148693762828,
              53.40915580931523
            ],
            [
              -0.787256880122128,
              53.40907474872684
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5041747',
        'Roof.Size': '5509.490256',
        'Estimated capacity': '771.3286358',
        'Title.Number': 'LL175735',
        Tenure: 'Freehold',
        'Property Address': 'Dean Foods, Corringham Road, Gainsborough (DN21 1QH)',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'NOBLE FOODS LIMITED',
        'Company Registration No. (1)': '03636168',
        'Date Proprietor Added': '27/03/2000',
        POSTCODE_2: 'DN21 1QH',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '22',
        'Capital Employed': '76251000',
        Cash: '7853000',
        Charges: 'Outstanding, Partially Satisfied, Satisfied',
        'Cost of Sales': '277935000',
        'Cost of Sales Delta': '672000',
        'Cost of Sales Growth': '0.242369159',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '173806000',
        'Current Liabilities': '177448000',
        'Debt to Capital': '71.87493841',
        'Dividends Payable': '5000000',
        'Dormant Company': 'No',
        EBITDA: '14751000',
        'Financial Year End': 'September',
        'Industry Keywords': 'compound feed, energy, green energy, quality assurance, supply chain, milling, food waste, nationwide service, food, fresh food, manufacturing, food products, merchants, poultry, animals, poultry meat, egg merchants, egg, animal feeds',
        'Net Assets': '71353000',
        'SIC 2007 Description': 'Manufacture of other food products n.e.c.',
        'Shareholder Funds': '71353000',
        'Shareholders Names': 'Noble Foods Holdings Ltd',
        Status: 'Active',
        Telephone: '01865 301200',
        'Trading Address': 'Cotswold Farm, Standlake, Witney, Oxfordshire, OX29 7RB',
        'Trading Address 1': 'Cotswold Farm',
        'Trading Address 2': 'Standlake',
        'Trading Address 3': 'Witney',
        'Trading Address 4': 'Oxfordshire',
        Turnover: '314224000',
        'Ultimate Domestic Parent Name': 'Noble Foods Group Limited',
        'Ultimate Domestic Parent Id': 'company/gb/05826545',
        'Ultimate Parent Name': 'Noble Foods Group Guernsey Ltd',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.noblefoods.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-3642000',
        lat: 53.405373525495165,
        'long': -0.7552295614772384
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.755639938199748,
              53.40486710389509
            ],
            [
              -0.755425735313827,
              53.40486702924053
            ],
            [
              -0.755425558279274,
              53.40495557062865
            ],
            [
              -0.754797529887911,
              53.404955676168356
            ],
            [
              -0.754800296770341,
              53.405788281077804
            ],
            [
              -0.754917069368445,
              53.405817814381784
            ],
            [
              -0.75564487177662,
              53.40581677014926
            ],
            [
              -0.755639938199748,
              53.40486710389509
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5042766',
        'Roof.Size': '6143.779423',
        'Estimated capacity': '860.1291192',
        'Title.Number': 'LL120074',
        Tenure: 'Freehold',
        'Property Address': 'Tesco Store, Sandsfield Road, Gainsborough',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'TESCO STORES LIMITED',
        'Company Registration No. (1)': '00519500',
        'Date Proprietor Added': '25/10/2013',
        POSTCODE_2: 'DN21 2HA',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '67',
        'Capital Employed': '10128000000',
        Cash: '1481000000',
        Charges: 'Outstanding, Partially Satisfied, Satisfied',
        'Cost of Sales': '40273000000',
        'Cost of Sales Delta': '566000000',
        'Cost of Sales Growth': '1.425441358',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '8184000000',
        'Current Liabilities': '9318000000',
        'Debt to Capital': '71.08402756',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '1446000000',
        'Financial Year End': 'February',
        'Industry Keywords': 'governance, waste, retail units, retail merchandising, b2c, retail display, retail trade, retail business, retail market, retail shop, retail outlets, health products, packaging products, sustainable development, cash flow, community grants, corporate responsibility, software, books, market leader, oak, internet services, petrol, carbon footprint, electronics, carbon reduction, garden, clothing, market share, retailing, financial services, internet, corporate governance, general merchandise, tea, retailer, stalls, food waste, grocer, toys, furniture, shop, stock exchange, supermarket, grocery, tobacco, retail stores, retail sales, stores, beverages, food, retail',
        'Net Assets': '5623000000',
        'SIC 2007 Description': 'Retail sale in non-specialised stores with food, beverages or tobacco predominating',
        'Shareholder Funds': '5623000000',
        'Shareholders Names': 'Tesco Holdings Ltd',
        Status: 'Active',
        Telephone: '0345 610 6775',
        'Trading Address': 'Tesco House Shire Park, Cirrus Building, 1a Shires Park, Kestrel Way, Welwyn Garden City, Hertfordshire, AL7 1GA',
        'Trading Address 1': 'Tesco House Shire Park',
        'Trading Address 2': 'Cirrus Building',
        'Trading Address 3': '1a Shires Park, Kestrel Way',
        'Trading Address 4': 'Welwyn Garden City, Hertfordshire',
        Turnover: '42219000000',
        'Ultimate Domestic Parent Name': 'Tesco Stores Limited',
        'Ultimate Domestic Parent Id': 'company/gb/00519500',
        'Ultimate Parent Name': 'Tesco Plc',
        'Ultimate Parent Id': 'company/gb/00445790',
        'Web Address 1': 'http://www.tescoplc.com',
        'Web Address 2': 'http://www.tescobank.co.uk',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-1134000000',
        lat: 53.39775789175392,
        'long': -0.7714877047211339
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.772347314759896,
              53.39806290713955
            ],
            [
              -0.772433903614377,
              53.397944151613906
            ],
            [
              -0.772288814919303,
              53.39789546703439
            ],
            [
              -0.772252490803582,
              53.39792952205424
            ],
            [
              -0.771993887784606,
              53.39794223454012
            ],
            [
              -0.771915260914663,
              53.397350748600964
            ],
            [
              -0.770860258020052,
              53.397401560057126
            ],
            [
              -0.770957895384498,
              53.39813140572621
            ],
            [
              -0.772347314759896,
              53.39806290713955
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5043386',
        'Roof.Size': '8671.242246',
        'Estimated capacity': '1213.973914',
        'Title.Number': 'LL149404',
        Tenure: 'Freehold',
        'Property Address': 'Land and buildings on the North West side of Carr Lane, Gainsborough',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'KERRY INGREDIENTS (UK) LIMITED',
        'Company Registration No. (1)': '00329695',
        'Date Proprietor Added': '28/04/1998',
        POSTCODE_2: 'DN21 1LQ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '83',
        'Capital Employed': '190100099',
        Cash: '1014139',
        Charges: '0',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '141202966',
        'Current Liabilities': '96818917',
        'Debt to Capital': '73.75616261',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '33129346',
        'Financial Year End': 'December',
        'Industry Keywords': 'food industry, chilled food, dairy farming, dairy products, drinks, milk production, events, environment, graduate programme, investors, innovation centre, association, capital markets, food company, stock exchange, markets, water, food production, cooked meats, meat, meals, cheese, sausage, sausages, ready meals, corporate governance, food products, technology, governance, beverage, manufacturing, nutrition, ingredient, dairy, food, beverages, food ingredients',
        'Net Assets': '75298560',
        'SIC 2007 Description': 'Manufacture of other food products n.e.c.',
        'Shareholder Funds': '75298560',
        'Shareholders Names': 'Kerry Ingredients Holdings (UK) Ltd',
        Status: 'Active',
        Telephone: '01275 378500',
        'Trading Address': 'Kerry, Bradley Road, Portbury, Bristol, Avon, BS20 7NZ',
        'Trading Address 1': 'Kerry',
        'Trading Address 2': 'Bradley Road',
        'Trading Address 3': 'Portbury',
        'Trading Address 4': 'Bristol, Avon',
        Turnover: '596941067',
        'Ultimate Domestic Parent Name': 'Kerry Holdings (U.K.) Limited',
        'Ultimate Domestic Parent Id': 'company/gb/01663249',
        'Ultimate Parent Name': 'Kerry Group Public Limited Company',
        'Ultimate Parent Id': 'company/ie/111471',
        'Web Address 1': 'http://www.kerrygroup.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '44384049',
        lat: 53.38694272948729,
        'long': -0.7791879814394368
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.779708525483092,
              53.386910180767444
            ],
            [
              -0.779051156173294,
              53.38633696371673
            ],
            [
              -0.778363207094458,
              53.38661731087922
            ],
            [
              -0.778452102021759,
              53.38669498779657
            ],
            [
              -0.778245979254338,
              53.38677827599761
            ],
            [
              -0.778945355712948,
              53.38739669306735
            ],
            [
              -0.779151535486039,
              53.3873062129118
            ],
            [
              -0.779386709172449,
              53.38751626682933
            ],
            [
              -0.779844622728648,
              53.38733019686874
            ],
            [
              -0.779773050406814,
              53.387267080697136
            ],
            [
              -0.780178235546012,
              53.38710546049022
            ],
            [
              -0.78004418067214,
              53.38699262532924
            ],
            [
              -0.780198251597762,
              53.38692597160873
            ],
            [
              -0.780029522606356,
              53.38677952230067
            ],
            [
              -0.779708525483092,
              53.386910180767444
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5044649',
        'Roof.Size': '3501.356391',
        'Estimated capacity': '490.1898947',
        'Title.Number': 'LL222256',
        Tenure: 'Freehold',
        'Property Address': 'Albion Works, Ropery Road, Gainsborough (DN21 2QD)',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'SINCLAIR ANIMAL AND HOUSEHOLD CARE LIMITED',
        'Company Registration No. (1)': '04436927',
        'Date Proprietor Added': '17/12/2002',
        POSTCODE_2: 'DN21 2QB',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '18',
        'Capital Employed': '3614687',
        Cash: '124814',
        Charges: 'Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '2327047',
        'Current Liabilities': '574222',
        'Debt to Capital': '13.92868644',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': 'marketing, pharmaceutical products, basic pharmaceutical products, pet care, household products, manufacturing',
        'Net Assets': '3605449',
        'SIC 2007 Description': 'Manufacture of basic pharmaceutical products',
        'Shareholder Funds': '3605449',
        'Shareholders Names': 'Verenigde Bedrijven Beaphar Bv',
        Status: 'Active',
        Telephone: '01427 810231',
        'Trading Address': 'Ropery Road, Gainsborough, Lincolnshire, DN21 2QB',
        'Trading Address 1': 'Ropery Road',
        'Trading Address 2': 'Gainsborough',
        'Trading Address 3': 'Lincolnshire',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'Sinclair Animal and Household Care Limited',
        'Ultimate Domestic Parent Id': 'company/gb/04436927',
        'Ultimate Parent Name': 'Stichting Administratiekantoor Beaphar Beheer',
        'Ultimate Parent Id': 'company/nl/05087095',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '1752825',
        lat: 53.4004760608388,
        'long': -0.7806263438561507
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.780192797194602,
              53.40081946023516
            ],
            [
              -0.780923686744147,
              53.40059680135915
            ],
            [
              -0.781429751314508,
              53.40044106056842
            ],
            [
              -0.780832462876426,
              53.40018371792338
            ],
            [
              -0.78076122071778,
              53.40016087731113
            ],
            [
              -0.78021280964167,
              53.40033002603117
            ],
            [
              -0.780304989144354,
              53.400435960708826
            ],
            [
              -0.779954079789186,
              53.40054132469242
            ],
            [
              -0.780192797194602,
              53.40081946023516
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5045405',
        'Roof.Size': '5967.076383',
        'Estimated capacity': '835.3906936',
        'Title.Number': 'LL136080',
        Tenure: 'Freehold',
        'Property Address': 'land and buildings on the South West side of Heapham Road, Gainsborough',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'WM MORRISON SUPERMARKETS PLC',
        'Company Registration No. (1)': '00358949',
        'Date Proprietor Added': '12/02/1992',
        POSTCODE_2: 'DN21 1XY',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '80',
        'Capital Employed': '7524000000',
        Cash: '305000000',
        Charges: 'Satisfied',
        'Cost of Sales': '16907000000',
        'Cost of Sales Delta': '-176000000',
        'Cost of Sales Growth': '-1.030264005',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '1322000000',
        'Current Liabilities': '3396000000',
        'Debt to Capital': '58.41575092',
        'Dividends Payable': '302000000',
        'Dormant Company': 'No',
        EBITDA: '1031000000',
        'Financial Year End': 'February',
        'Industry Keywords': 'food products, retail market, retail shop, retail outlets, retail business, b2c, retail units, retail merchandising, retail display, retail trade, retail stores, doors, manufacturing, charity, retailing, charities, accessibility, corporate responsibility, store finder, financial, butter, egg, information privacy, financial statements, grocery, corporate governance, corporate news, retailer, shop, fresh food, market share, supermarkets, stalls, cd, dvd, dvds, clothing, drink, drinks, books, magazine, retail sales, beverages, tobacco, stores, food, supermarket, retail',
        'Net Assets': '4541000000',
        'SIC 2007 Description': 'Retail sale in non-specialised stores with food, beverages or tobacco predominating',
        'Shareholder Funds': '4541000000',
        'Shareholders Names': 'Undisclosed',
        Status: 'Active',
        Telephone: '0845 611 5000',
        'Trading Address': 'Hilmore House, 71 Gain Lane, Bradford, West Yorkshire, BD3 7DL',
        'Trading Address 1': 'Hilmore House',
        'Trading Address 2': '71 Gain Lane',
        'Trading Address 3': 'Bradford',
        'Trading Address 4': 'West Yorkshire',
        Turnover: '17536000000',
        'Ultimate Domestic Parent Name': 'Wm Morrison Supermarkets P L C',
        'Ultimate Domestic Parent Id': 'company/gb/00358949',
        'Ultimate Parent Name': 'Wm Morrison Supermarkets P L C',
        'Ultimate Parent Id': 'company/gb/00358949',
        'Web Address 1': 'http://www.morrisons-corporate.com',
        'Web Address 2': 'http://www.morrisons.net',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 53.39039633091525,
        'long': -0.7467852772399655
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.746409091955727,
              53.39087491325195
            ],
            [
              -0.747666566183318,
              53.390346059840425
            ],
            [
              -0.747162752709935,
              53.38991792337553
            ],
            [
              -0.745903649910934,
              53.39044612513831
            ],
            [
              -0.746409091955727,
              53.39087491325195
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5045408',
        'Roof.Size': '7954.625768',
        'Estimated capacity': '1113.647608',
        'Title.Number': 'LL48912',
        Tenure: 'Freehold',
        'Property Address': 'Wedco Technology, Sandars Road, Heapham Road Industrial Estate, Gainsborough (DN21 1RZ)',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'WEDCO TECHNOLOGY U.K. LIMITED',
        'Company Registration No. (1)': 'SC509439',
        'Date Proprietor Added': '14/09/1989',
        POSTCODE_2: 'DN21 1RZ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '5',
        'Capital Employed': '33812',
        Cash: '0',
        Charges: '0',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '50912',
        'Current Liabilities': '17100',
        'Debt to Capital': '33.58736644',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'June',
        'Industry Keywords': 'business support',
        'Net Assets': '33812',
        'SIC 2007 Description': 'Other business support service activities n.e.c.',
        'Shareholder Funds': '33812',
        'Shareholders Names': 'Darren Weddell',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': '6 Wellsgreen Court, Uddingston, Glasgow, G71 7uz, G71 7UZ',
        'Trading Address 1': '6 Wellsgreen Court',
        'Trading Address 2': 'Uddingston',
        'Trading Address 3': 'Glasgow',
        'Trading Address 4': 'G71 7uz',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 53.39271150375464,
        'long': -0.7456036304769444
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.746357182410338,
              53.393291740765754
            ],
            [
              -0.746748839636981,
              53.39292630646394
            ],
            [
              -0.745878460569804,
              53.39259247902836
            ],
            [
              -0.74597147157686,
              53.39250805855805
            ],
            [
              -0.745080325047355,
              53.39215989408686
            ],
            [
              -0.744988765849008,
              53.39225116091462
            ],
            [
              -0.744876078813085,
              53.392206918300715
            ],
            [
              -0.744489376834069,
              53.39256206107689
            ],
            [
              -0.744972718313111,
              53.392760948942204
            ],
            [
              -0.746357182410338,
              53.393291740765754
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5045734',
        'Roof.Size': '4365.685294',
        'Estimated capacity': '611.1959412',
        'Title.Number': 'LL301811',
        Tenure: 'Freehold',
        'Property Address': 'Land on the north east side of Heapham Road, Gainsborough',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'REGAL MANUFACTURING LIMITED',
        'Company Registration No. (1)': '00516559',
        'Date Proprietor Added': '04/11/2008',
        POSTCODE_2: 'DN21 1PT',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '67',
        'Capital Employed': '17278149',
        Cash: '9155',
        Charges: 'Satisfied',
        'Cost of Sales': '18174598',
        'Cost of Sales Delta': '668308',
        'Cost of Sales Growth': '3.817530728',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '18654890',
        'Current Liabilities': '2897133',
        'Debt to Capital': '31.49776543',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '2457661',
        'Financial Year End': 'December',
        'Industry Keywords': 'electric motor repairs, can manufacturing, manufacturing services, commercial manufacturing, pipe manufacturing, manufacturing industry, manufacturing software, industrial manufacturing, design, factory, garage door openers, fans, fan, motor, motors, brush, brushes, musical instruments, electric motor, manufacturing company, manufacturer, electronic wires, electric wires, insulated wires, insulated cables, electronic cables, cables, cable, electric cables, wires, electric motors, manufacturing',
        'Net Assets': '13820519',
        'SIC 2007 Description': 'Manufacture of other electronic and electric wires and cables',
        'Shareholder Funds': '13820519',
        'Shareholders Names': 'Rotor Beheer Bv',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'A.O. Smith Electrical Products L, One New Change, London, EC4M 9AF',
        'Trading Address 1': 'A.O. Smith Electrical Products L',
        'Trading Address 2': 'One New Change',
        'Trading Address 3': 'London',
        'Trading Address 4': '0',
        Turnover: '23034845',
        'Ultimate Domestic Parent Name': 'Regal Manufacturing Limited',
        'Ultimate Domestic Parent Id': 'company/gb/00516559',
        'Ultimate Parent Name': 'Rotor Beheer Bv',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.regalmanufacturing.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '15757757',
        lat: 53.39435357507079,
        'long': -0.7518022061939127
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.750945922715599,
              53.39468667612605
            ],
            [
              -0.751013843275392,
              53.39471938802481
            ],
            [
              -0.751157998028762,
              53.39461383456009
            ],
            [
              -0.751580252569853,
              53.39482068609602
            ],
            [
              -0.752545204002148,
              53.39411424285845
            ],
            [
              -0.752054287710871,
              53.393874406006375
            ],
            [
              -0.750945922715599,
              53.39468667612605
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5045774',
        'Roof.Size': '8410.050208',
        'Estimated capacity': '1177.407029',
        'Title.Number': 'LL324840',
        Tenure: 'Freehold',
        'Property Address': 'Unit 2, Corringham Road Industrial Estate, Gainsborough (DN21 1QB)',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'HEXADEX LIMITED',
        'Company Registration No. (1)': '02674947',
        'Date Proprietor Added': '08/04/2011',
        POSTCODE_2: 'DN21 1QB',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '28',
        'Capital Employed': '62379000',
        Cash: '8685000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '52531000',
        'Current Liabilities': '21993000',
        'Debt to Capital': '29.48727066',
        'Dividends Payable': '784000',
        'Dormant Company': 'No',
        EBITDA: '9474000',
        'Financial Year End': 'December',
        'Industry Keywords': 'control systems, emission control, exhaust pipes, stainless steel products, steel products, stainless steel, head offices, holding companies, technology, manufacturing, emissions, exhaust systems, clamps, holding companies management',
        'Net Assets': '59493000',
        'SIC 2007 Description': 'Activities of head offices',
        'Shareholder Funds': '59493000',
        'Shareholders Names': 'D.H. Milles Settlement 1988 Trustees of, Hexadex Ltd 2008 Employee Benefit Trust Trustees of, David Herbert Milles, D.H. Milles Settlement 1998 Trustees of, 2008 Sip Trustees of',
        Status: 'Active',
        Telephone: '01427 676700',
        'Trading Address': 'The Old Court House, 24 Market Street, Gainsborough, Lincolnshire, DN21 2BE',
        'Trading Address 1': 'The Old Court House',
        'Trading Address 2': '24 Market Street',
        'Trading Address 3': 'Gainsborough',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '128426000',
        'Ultimate Domestic Parent Name': 'Hexadex Limited',
        'Ultimate Domestic Parent Id': 'company/gb/02674947',
        'Ultimate Parent Name': 'Hexadex Limited',
        'Ultimate Parent Id': 'company/gb/02674947',
        'Web Address 1': 'http://www.hexadex.com',
        'Web Address 2': 'http://www.hexadex.co.uk',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '30538000',
        lat: 53.40255432633493,
        'long': -0.7508469620346998
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.751044131186424,
              53.401857828571686
            ],
            [
              -0.750250209305232,
              53.4019719554218
            ],
            [
              -0.75050189241108,
              53.40258162759098
            ],
            [
              -0.750227723880272,
              53.40262127750913
            ],
            [
              -0.750392248105218,
              53.40302796118622
            ],
            [
              -0.750662340632531,
              53.40298889748285
            ],
            [
              -0.750747306738739,
              53.40319500901188
            ],
            [
              -0.751530067350806,
              53.40308264982352
            ],
            [
              -0.751044131186424,
              53.401857828571686
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5046285',
        'Roof.Size': '5143.279254',
        'Estimated capacity': '720.0590956',
        'Title.Number': 'LL257817',
        Tenure: 'Freehold',
        'Property Address': 'Land and buildings at Caenby Corner Estate, Hemswell Cliff, Gainsborough',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'HEMSWELL ESTATES LIMITED',
        'Company Registration No. (1)': '04951482',
        'Date Proprietor Added': '27/07/2005',
        POSTCODE_2: 'DN21 5TJ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '16',
        'Capital Employed': '4550090',
        Cash: '612640',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '4317237',
        'Current Liabilities': '8225564',
        'Debt to Capital': '76.75679852',
        'Dividends Payable': '44484',
        'Dormant Company': 'No',
        EBITDA: '460262',
        'Financial Year End': 'December',
        'Industry Keywords': 'underwriters, real estate, renting, leased real estate, insurance underwriting, insurance, property rental, property, underwriting',
        'Net Assets': '2969471',
        'SIC 2007 Description': 'Letting and operating of own or leased real estate (other than Housing Association real estate and conference and exhibition services) n.e.c.',
        'Shareholder Funds': '2969471',
        'Shareholders Names': 'Ronald Matthew Duguid, Sarah Anne Christina Duguid, Adam Matthew Duguid, Hannah Elizabeth Duguid',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Norton Place, Bishop Norton, Market Rasen, Lincolnshire, LN8 2AX',
        'Trading Address 1': 'Norton Place',
        'Trading Address 2': 'Bishop Norton',
        'Trading Address 3': 'Market Rasen',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '3142043',
        'Ultimate Domestic Parent Name': 'Hemswell Estates Limited',
        'Ultimate Domestic Parent Id': 'company/gb/04951482',
        'Ultimate Parent Name': 'Hemswell Estates Limited',
        'Ultimate Parent Id': 'company/gb/04951482',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-3908327',
        lat: 53.39820719936876,
        'long': -0.5791678539708365
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.57881796491709,
              53.39864887132425
            ],
            [
              -0.579977964024931,
              53.39817697673658
            ],
            [
              -0.57953011468152,
              53.39776883705286
            ],
            [
              -0.57835618950288,
              53.39823004394179
            ],
            [
              -0.57881796491709,
              53.39864887132425
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5046290',
        'Roof.Size': '5424.975494',
        'Estimated capacity': '759.4965692',
        'Title.Number': 'LL315929',
        Tenure: 'Freehold',
        'Property Address': 'Hangar 2, Caenby Corner Estate, Hemswell Cliff, Gainsborough (DN21 5TH)',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'PARKACRE HOLDINGS LIMITED',
        'Company Registration No. (1)': '08314488',
        'Date Proprietor Added': '19/10/2015',
        POSTCODE_2: 'DN21 5TJ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '7',
        'Capital Employed': '5162869',
        Cash: '716117',
        Charges: 'Outstanding',
        'Cost of Sales': '10339807',
        'Cost of Sales Delta': '669463',
        'Cost of Sales Growth': '6.922845764',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '6863204',
        'Current Liabilities': '6140592',
        'Debt to Capital': '69.1188743',
        'Dividends Payable': '132508',
        'Dormant Company': 'No',
        EBITDA: '1331783',
        'Financial Year End': 'September',
        'Industry Keywords': 'packaging company, packaging manufacturers, packaging machinery, transit packaging, packaging supplies, consumer packaging, media packaging, polypropylene packaging, protein powders, product packaging, manufacturer, bottles, vitamins, supplements, food, packaging & labeling, home services, quality assurance, home solutions, label solutions, nutritional supplements, sports nutrition products, nutrition products, sports supplements, sports nutrition, private label, glass, clean room, contract manufacturing, manufacturing, labelling, packing supplier, food supplements, nutrition, health, packaging, health food, protein, food products, capsules, minerals',
        'Net Assets': '3490636',
        'SIC 2007 Description': 'Packaging activities',
        'Shareholder Funds': '3490636',
        'Shareholders Names': 'John Stern, Charles Stern, James Caseley',
        Status: 'Active',
        Telephone: '01427 666000',
        'Trading Address': 'Hangar 2, Learoyd Road, Caenby Corner Estate, Hemswell Cliff, Gainsborough, Lincolnshire, DN21 5TJ',
        'Trading Address 1': 'Hangar 2',
        'Trading Address 2': 'Learoyd Road, Caenby Corner Estate',
        'Trading Address 3': 'Hemswell Cliff',
        'Trading Address 4': 'Gainsborough, Lincolnshire',
        Turnover: '17909218',
        'Ultimate Domestic Parent Name': 'Parkacre Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/08314488',
        'Ultimate Parent Name': 'Parkacre Holdings Limited',
        'Ultimate Parent Id': 'company/gb/08314488',
        'Web Address 1': 'http://www.park-acre.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 53.39913442320551,
        'long': -0.577456435264744
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.577321286241066,
              53.399631564408935
            ],
            [
              -0.578241334855299,
              53.398959421254126
            ],
            [
              -0.577583979163632,
              53.398636965200744
            ],
            [
              -0.57667448214735,
              53.399312824854185
            ],
            [
              -0.577321286241066,
              53.399631564408935
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5048827',
        'Roof.Size': '3534.84817',
        'Estimated capacity': '494.8787438',
        'Title.Number': 'LL177025',
        Tenure: 'Freehold',
        'Property Address': 'Welton Cliff Farm, Welton Cliff',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'R H WARD (WELTON) LIMITED',
        'Company Registration No. (1)': '00630302',
        'Date Proprietor Added': '26/11/1999',
        POSTCODE_2: 'LN2 3PU',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '61',
        'Capital Employed': '10557380',
        Cash: '1629375',
        Charges: 'Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '3287559',
        'Current Liabilities': '748869',
        'Debt to Capital': '8.433451271',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': 'cattle farming, agriculture, data protection, internet security, seeds, business information, engineers, crop farming, leguminous crops, local information, places of interest, aviation engineering, cereals, engineering, crops, oil seeds, agricultural products, aviation, arable farming, farming',
        'Net Assets': '10352742',
        'SIC 2007 Description': 'Growing of cereals (except rice), leguminous crops and oil seeds',
        'Shareholder Funds': '10352742',
        'Shareholders Names': 'John Robert Ward, J R Ward Disc Trust, Lucy Jane Haxton, Kate Anne Ward, Patricia Margaret Judge',
        Status: 'Active',
        Telephone: '01522 730210',
        'Trading Address': 'Holme Farm, Cliff Farm, Welton Cliff, Lincoln, Lincolnshire, LN2 3PU',
        'Trading Address 1': 'Holme Farm',
        'Trading Address 2': 'Cliff Farm',
        'Trading Address 3': 'Welton Cliff',
        'Trading Address 4': 'Lincoln, Lincolnshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.weltoncliff.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '2538690',
        lat: 53.30677046961511,
        'long': -0.525449524231202
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.525601759360451,
              53.30659778116628
            ],
            [
              -0.525630016656053,
              53.30667499103667
            ],
            [
              -0.524964978758989,
              53.30676259831727
            ],
            [
              -0.525025320302202,
              53.306927853133544
            ],
            [
              -0.525687494795252,
              53.30684065959424
            ],
            [
              -0.525703553512939,
              53.306888592551616
            ],
            [
              -0.525038990715472,
              53.30696667721125
            ],
            [
              -0.525087516925256,
              53.30712639217122
            ],
            [
              -0.525982728383237,
              53.30701879506332
            ],
            [
              -0.525755114616379,
              53.30640694059406
            ],
            [
              -0.52488176693606,
              53.30652064950438
            ],
            [
              -0.524940700547593,
              53.306683190099186
            ],
            [
              -0.525601759360451,
              53.30659778116628
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5559723',
        'Roof.Size': '3665.33127',
        'Estimated capacity': '513.1463778',
        'Title.Number': 'LL116916',
        Tenure: 'Freehold',
        'Property Address': 'land at South Hykeham',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'H & S ROE & SONS FARMS LIMITED',
        'Company Registration No. (1)': '01918366',
        'Date Proprietor Added': '13/03/1995',
        POSTCODE_2: 'LN6 9NQ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '35',
        'Capital Employed': '8586968',
        Cash: '172154',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '2327424',
        'Current Liabilities': '1211889',
        'Debt to Capital': '40.12951715',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'February',
        'Industry Keywords': 'self drive, self drive hire, mot testing, agricultural machinery, plants, agricultural machinery sales, machinery, plant, trailers, agricultural equipment, agricultural, seeds, leguminous crops, cereals, crop farming, farming, oil seeds, crops',
        'Net Assets': '5866623',
        'SIC 2007 Description': 'Growing of cereals (except rice), leguminous crops and oil seeds',
        'Shareholder Funds': '5866623',
        'Shareholders Names': 'Trustees of S J Roe\'s Discretionary Settlement, Simon Roe, Trustees of H S Roe\'s Discretionary Settlement, Howard J. Roe',
        Status: 'Active',
        Telephone: '01522 681542',
        'Trading Address': 'Roe House, Boundary Lane, South Hykeham, Lincoln, Lincolnshire, LN6 9NQ',
        'Trading Address 1': 'Roe House',
        'Trading Address 2': 'Boundary Lane',
        'Trading Address 3': 'South Hykeham',
        'Trading Address 4': 'Lincoln, Lincolnshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.roehire.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '1115535',
        lat: 53.17234012673555,
        'long': -0.6115660666260974
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.611975864319976,
              53.17266401515212
            ],
            [
              -0.612230593120293,
              53.17226721345594
            ],
            [
              -0.611160959598065,
              53.17201409540935
            ],
            [
              -0.6109018607892,
              53.17241623770593
            ],
            [
              -0.611975864319976,
              53.17266401515212
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5564575',
        'Roof.Size': '3556.972212',
        'Estimated capacity': '497.9761097',
        'Title.Number': 'LL375901',
        Tenure: 'Freehold',
        'Property Address': 'Land lying to the east of Camp Road, Witham St Hughs, Lincoln',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'CARGILL PLC',
        'Company Registration No. (1)': '01387437',
        'Date Proprietor Added': '15/08/2017',
        POSTCODE_2: 'LN6 9TN',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '42',
        'Capital Employed': '603653000',
        Cash: '2790000',
        Charges: 'Satisfied',
        'Cost of Sales': '1050825000',
        'Cost of Sales Delta': '13251000',
        'Cost of Sales Growth': '1.277113729',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '312165000',
        'Current Liabilities': '258115000',
        'Debt to Capital': '39.77392987',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '34523000',
        'Financial Year End': 'May',
        'Industry Keywords': 'management solutions, community engagement, animal nutrition, food ingredients, agriculture, risk management, farmers, risk management solutions, markets, food, egg, agribusiness, feeds, grain, meat, livestock, manufacturing, oils, oil, commodities, fats, commodity trading',
        'Net Assets': '519009000',
        'SIC 2007 Description': 'Manufacture of oils and fats',
        'Shareholder Funds': '519009000',
        'Shareholders Names': 'Cargill UK Holdings Limited',
        Status: 'Active',
        Telephone: '01932 861000',
        'Trading Address': 'Velocity V1, Brooklands Drive, Weybridge, Surrey, KT13 0SL',
        'Trading Address 1': 'Velocity V1',
        'Trading Address 2': 'Brooklands Drive',
        'Trading Address 3': 'Weybridge',
        'Trading Address 4': 'Surrey',
        Turnover: '1120439000',
        'Ultimate Domestic Parent Name': 'Cargill UK Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/07203317',
        'Ultimate Parent Name': 'Cargill Inc',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.cargill.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '54050000',
        lat: 53.15284081868495,
        'long': -0.6650122926986639
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.664639830621564,
              53.153160823761986
            ],
            [
              -0.665389279823986,
              53.15315825639968
            ],
            [
              -0.665385089608018,
              53.152521744750494
            ],
            [
              -0.664635710040859,
              53.1525224249938
            ],
            [
              -0.664639830621564,
              53.153160823761986
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5565145',
        'Roof.Size': '5759.168125',
        'Estimated capacity': '806.2835375',
        'Title.Number': 'LL376980',
        Tenure: 'Freehold',
        'Property Address': 'Network 46, Camp Road, Swinderby, Lincoln (LN6 9BJ)',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'ST MODWEN DEVELOPMENTS LIMITED',
        'Company Registration No. (1)': '00892832',
        'Date Proprietor Added': '05/10/2017',
        POSTCODE_2: 'LN6 9BJ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '53',
        'Capital Employed': '852819145',
        Cash: '289120',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '67627418',
        'Cost of Sales Delta': '-38514503',
        'Cost of Sales Growth': '-36.28585448',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '392490076',
        'Current Liabilities': '347340789',
        'Debt to Capital': '70.95226893',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '69865018',
        'Financial Year End': 'November',
        'Industry Keywords': 'property landlords, commercial property letting, commercial property experts, residential property services, property finding, property information, property advice, property repossession, commercial property sales, residential property investment, brownfield, company registration, investor relations, commercial residential, asset management, active asset management, senior management, senior management team, development projects, governance, corporate governance, real estate development, building projects development, real estate, property development, project development, property investment, real estate sales, property',
        'Net Assets': '348619230',
        'SIC 2007 Description': 'Development of building projects',
        'Shareholder Funds': '348619230',
        'Shareholders Names': 'St. Modwen Properties Plc',
        Status: 'Active',
        Telephone: '0121 222 9400',
        'Trading Address': 'Park Point 17 High Street, Longbridge, Birmingham, B31 2uq, B31 2UQ',
        'Trading Address 1': 'Park Point 17 High Street',
        'Trading Address 2': 'Longbridge',
        'Trading Address 3': 'Birmingham',
        'Trading Address 4': 'B31 2uq',
        Turnover: '95834928',
        'Ultimate Domestic Parent Name': 'St. Modwen Properties Plc',
        'Ultimate Domestic Parent Id': 'company/gb/00349201',
        'Ultimate Parent Name': 'St. Modwen Properties Plc',
        'Ultimate Parent Id': 'company/gb/00349201',
        'Web Address 1': 'http://www.stmodwen.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '45149287',
        lat: 53.14821360152134,
        'long': -0.6736777803799243
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.674194763657793,
              53.147789116453275
            ],
            [
              -0.672804141450567,
              53.14818292547438
            ],
            [
              -0.673160791117613,
              53.14863808786955
            ],
            [
              -0.674551424912378,
              53.148244274784496
            ],
            [
              -0.674194763657793,
              53.147789116453275
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5565888',
        'Roof.Size': '5024.619087',
        'Estimated capacity': '703.4466722',
        'Title.Number': 'LL163700',
        Tenure: 'Freehold',
        'Property Address': 'Land on the south of the Great North Road, Great Gonerby',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'TEGOMETALL LIMITED',
        'Company Registration No. (1)': '02752472',
        'Date Proprietor Added': '',
        POSTCODE_2: 'NG32 2AB',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '28',
        'Capital Employed': '1237751',
        Cash: '10844',
        Charges: '0',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '113497',
        'Current Liabilities': '2458092',
        'Debt to Capital': '66.5096434',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': 'storage systems, shopfittings, environmental, plants, metal, steel, environment, design, logistics, storage, shelving, shelving systems, shopfitting',
        'Net Assets': '1237751',
        'SIC 2007 Description': 'Agents specialised in the sale of other particular products',
        'Shareholder Funds': '1237751',
        'Shareholders Names': 'Tegometall (International) Ag',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Suite A, 7th Floor,, City Gate East, Nottingham, England and Wales Ng1 5fs, NG1 5FS',
        'Trading Address 1': 'Suite A, 7th Floor,',
        'Trading Address 2': 'City Gate East',
        'Trading Address 3': 'Nottingham',
        'Trading Address 4': 'England and Wales Ng1 5fs',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'Tegometall Property Services Limited',
        'Ultimate Domestic Parent Id': 'company/gb/02752472',
        'Ultimate Parent Name': 'Tegometall (International) Ag',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.tegometall.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-2344595',
        lat: 52.943959102608865,
        'long': -0.6747201311364643
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.675479691338924,
              52.94397548138845
            ],
            [
              -0.674952726295042,
              52.9435220003159
            ],
            [
              -0.673960623382971,
              52.94394272095994
            ],
            [
              -0.674487434237561,
              52.94439620470792
            ],
            [
              -0.675479691338924,
              52.94397548138845
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5565911',
        'Roof.Size': '3905.53173',
        'Estimated capacity': '546.7744422',
        'Title.Number': 'LL297141',
        Tenure: 'Freehold',
        'Property Address': 'The Olde Barn Hotel Ltd, Toll Bar Road, Marston, Grantham (NG32 2HT)',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'NEW BARN HOTEL LIMITED',
        'Company Registration No. (1)': '08653835',
        'Date Proprietor Added': '24/10/2013',
        POSTCODE_2: 'NG32 2HT',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '7',
        'Capital Employed': '4318107',
        Cash: '1200',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '1619936',
        'Cost of Sales Delta': '133031',
        'Cost of Sales Growth': '8.94683924',
        Country: 'GB',
        'Credit Risk': 'Not Rated',
        'Current Assets': '196754',
        'Current Liabilities': '884003',
        'Debt to Capital': '78.64105142',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '232830',
        'Financial Year End': 'October',
        'Industry Keywords': 'lodging',
        'Net Assets': '1111116',
        'SIC 2007 Description': 'Hotels and similar accommodation',
        'Shareholder Funds': '1111116',
        'Shareholders Names': 'Mohamed Dossa, Ferial Dossa',
        Status: 'Administration',
        Telephone: '0',
        'Trading Address': 'St Philips Point Temple Row, Birmingham, B2 5AF',
        'Trading Address 1': 'St Philips Point Temple Row',
        'Trading Address 2': 'Birmingham',
        'Trading Address 3': '0',
        'Trading Address 4': '0',
        Turnover: '2512125',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-687249',
        lat: 52.976655387152114,
        'long': -0.6809380407934521
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.681652436902757,
              52.977204611815154
            ],
            [
              -0.681788921170091,
              52.97709501056086
            ],
            [
              -0.681070361585374,
              52.976770329106195
            ],
            [
              -0.6813203951647,
              52.97657065106409
            ],
            [
              -0.681508130303422,
              52.97665615894606
            ],
            [
              -0.681564300923508,
              52.97661138309032
            ],
            [
              -0.680567167253421,
              52.97616215612253
            ],
            [
              -0.680311453557139,
              52.97637714220031
            ],
            [
              -0.680560206012919,
              52.976488500441675
            ],
            [
              -0.680341661935518,
              52.97666641138213
            ],
            [
              -0.680891347030884,
              52.97691200095384
            ],
            [
              -0.680792538919666,
              52.97699235272138
            ],
            [
              -0.680869379636255,
              52.97702691723351
            ],
            [
              -0.68101629595116,
              52.976907363964855
            ],
            [
              -0.681652436902757,
              52.977204611815154
            ]
          ],
          [
            [
              -0.681022788153418,
              52.9766892522761
            ],
            [
              -0.680916565156691,
              52.97664141649135
            ],
            [
              -0.68107921998326,
              52.97650873277393
            ],
            [
              -0.681185748814652,
              52.97655630211187
            ],
            [
              -0.681022788153418,
              52.9766892522761
            ]
          ],
          [
            [
              -0.680754805994899,
              52.9765686018964
            ],
            [
              -0.680664005615406,
              52.976527859196416
            ],
            [
              -0.680827741273028,
              52.976393929233375
            ],
            [
              -0.680918844769738,
              52.976434495375635
            ],
            [
              -0.680754805994899,
              52.9765686018964
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5565972',
        'Roof.Size': '16622.0643',
        'Estimated capacity': '2327.089002',
        'Title.Number': 'LL174901',
        Tenure: 'Freehold',
        'Property Address': 'Q K Cold Stores (Marston) Ltd, Toll Bar Road, Marston (NG32 2HT)',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'Q.K. COLD STORES (MARSTON) LIMITED',
        'Company Registration No. (1)': '01860326',
        'Date Proprietor Added': '04/12/1989',
        POSTCODE_2: 'NG32 2HT',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '35',
        'Capital Employed': '4837235',
        Cash: '799989',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '4403518',
        'Cost of Sales Delta': '198562',
        'Cost of Sales Growth': '4.722094595',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '3678024',
        'Current Liabilities': '3564982',
        'Debt to Capital': '47.82785305',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '1172283',
        'Financial Year End': 'December',
        'Industry Keywords': 'booking system, food industry, stores, warehousing, cold store, cold storage, storage',
        'Net Assets': '4383617',
        'SIC 2007 Description': 'Operation of warehousing and storage facilities for land transport activities of division 49',
        'Shareholder Funds': '4383617',
        'Shareholders Names': 'John Queally Mr, Q.K. (Holdings) Ltd, Peter Queally Mr',
        Status: 'Active',
        Telephone: '01400 259300',
        'Trading Address': 'Toll Bar Road, Marston, Grantham, Lincolnshire, NG32 2HT',
        'Trading Address 1': 'Toll Bar Road',
        'Trading Address 2': 'Marston',
        'Trading Address 3': 'Grantham',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '7435312',
        'Ultimate Domestic Parent Name': 'Q.K. (Holdings) Limited',
        'Ultimate Domestic Parent Id': 'company/gb/01860327',
        'Ultimate Parent Name': 'Q.K. (Holdings) Limited',
        'Ultimate Parent Id': 'company/gb/01860327',
        'Web Address 1': 'http://www.qkcoldstores.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '113042',
        lat: 52.9663152834749,
        'long': -0.6937000109566236
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.692734305165803,
              52.96607564017361
            ],
            [
              -0.692852299606587,
              52.966112897040034
            ],
            [
              -0.692769770384411,
              52.96620800123934
            ],
            [
              -0.692563131074404,
              52.96614279986549
            ],
            [
              -0.692317061283468,
              52.96642704961175
            ],
            [
              -0.694557884022803,
              52.96713247858753
            ],
            [
              -0.694884049796678,
              52.966754350564045
            ],
            [
              -0.694804385438308,
              52.966601187859474
            ],
            [
              -0.694724293276203,
              52.96657594601307
            ],
            [
              -0.694808226263346,
              52.96647860842724
            ],
            [
              -0.694258656293674,
              52.966305092559054
            ],
            [
              -0.694470799199722,
              52.96605921255326
            ],
            [
              -0.694341036416425,
              52.96601715332661
            ],
            [
              -0.694127815838633,
              52.96626418994827
            ],
            [
              -0.694103182809108,
              52.96625645778394
            ],
            [
              -0.694473770707238,
              52.96582712714084
            ],
            [
              -0.693392318142122,
              52.96548684661201
            ],
            [
              -0.693265616926464,
              52.96563468560868
            ],
            [
              -0.693149084785707,
              52.96559834420582
            ],
            [
              -0.692734305165803,
              52.96607564017361
            ]
          ],
          [
            [
              -0.694062793030982,
              52.96613914599579
            ],
            [
              -0.694111849289584,
              52.96608250939811
            ],
            [
              -0.69415462269542,
              52.966096014532575
            ],
            [
              -0.694105566476833,
              52.96615265114803
            ],
            [
              -0.694062793030982,
              52.96613914599579
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5565973',
        'Roof.Size': '4467.578824',
        'Estimated capacity': '625.4610354',
        'Title.Number': 'LL174901',
        Tenure: 'Freehold',
        'Property Address': 'Q K Cold Stores (Marston) Ltd, Toll Bar Road, Marston (NG32 2HT)',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'Q.K. COLD STORES (MARSTON) LIMITED',
        'Company Registration No. (1)': '01860326',
        'Date Proprietor Added': '04/12/1989',
        POSTCODE_2: 'NG32 2HT',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '35',
        'Capital Employed': '4837235',
        Cash: '799989',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '4403518',
        'Cost of Sales Delta': '198562',
        'Cost of Sales Growth': '4.722094595',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '3678024',
        'Current Liabilities': '3564982',
        'Debt to Capital': '47.82785305',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '1172283',
        'Financial Year End': 'December',
        'Industry Keywords': 'booking system, food industry, stores, warehousing, cold store, cold storage, storage',
        'Net Assets': '4383617',
        'SIC 2007 Description': 'Operation of warehousing and storage facilities for land transport activities of division 49',
        'Shareholder Funds': '4383617',
        'Shareholders Names': 'John Queally Mr, Q.K. (Holdings) Ltd, Peter Queally Mr',
        Status: 'Active',
        Telephone: '01400 259300',
        'Trading Address': 'Toll Bar Road, Marston, Grantham, Lincolnshire, NG32 2HT',
        'Trading Address 1': 'Toll Bar Road',
        'Trading Address 2': 'Marston',
        'Trading Address 3': 'Grantham',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '7435312',
        'Ultimate Domestic Parent Name': 'Q.K. (Holdings) Limited',
        'Ultimate Domestic Parent Id': 'company/gb/01860327',
        'Ultimate Parent Name': 'Q.K. (Holdings) Limited',
        'Ultimate Parent Id': 'company/gb/01860327',
        'Web Address 1': 'http://www.qkcoldstores.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '113042',
        lat: 52.96537564008396,
        'long': -0.6940850770759808
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.693567358612935,
              52.96499001308506
            ],
            [
              -0.693309886245718,
              52.96529238897908
            ],
            [
              -0.693479376532909,
              52.96534513440389
            ],
            [
              -0.69342149467826,
              52.96541300096803
            ],
            [
              -0.694416330367608,
              52.965722214019266
            ],
            [
              -0.694473905277582,
              52.96565461330419
            ],
            [
              -0.694647974787191,
              52.965708665912985
            ],
            [
              -0.694906190033542,
              52.96540611553069
            ],
            [
              -0.693567358612935,
              52.96499001308506
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5565985',
        'Roof.Size': '3754.947729',
        'Estimated capacity': '525.6926821',
        'Title.Number': 'LL339743',
        Tenure: 'Freehold',
        'Property Address': 'land lying to the east of Toll Bar Road, Marston, Grantham',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'HARLAXTON HOLDINGS LIMITED',
        'Company Registration No. (1)': '09438175',
        'Date Proprietor Added': '20/06/2017',
        POSTCODE_2: 'NG32 2HT',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '5',
        'Capital Employed': '2837106',
        Cash: '8007996',
        Charges: '0',
        'Cost of Sales': '9081706',
        'Cost of Sales Delta': '476086',
        'Cost of Sales Growth': '5.532268448',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '13854949',
        'Current Liabilities': '13900213',
        'Debt to Capital': '83.05617525',
        'Dividends Payable': '176052',
        'Dormant Company': 'No',
        EBITDA: '875120',
        'Financial Year End': 'May',
        'Industry Keywords': 'engineering, recycled products, delivery, business awards, engineering services, design, carbon emissions, maintenance, utility infrastructure provider, commercial industrial, electrical installation, electrical fittings, building installation, electrical wiring',
        'Net Assets': '2835942',
        'SIC 2007 Description': 'Electrical installation',
        'Shareholder Funds': '2835942',
        'Shareholders Names': 'Joanne Elizabeth Hibbert, Richard Charles Hibbert',
        Status: 'Active',
        Telephone: '0844 335 8897',
        'Trading Address': 'Toll Bar Road, Marston, Grantham, Lincolnshire, NG32 2HT',
        'Trading Address 1': 'Toll Bar Road',
        'Trading Address 2': 'Marston',
        'Trading Address 3': 'Grantham',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '12665787',
        'Ultimate Domestic Parent Name': 'Harlaxton Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/09438175',
        'Ultimate Parent Name': 'Harlaxton Holdings Limited',
        'Ultimate Parent Id': 'company/gb/09438175',
        'Web Address 1': 'http://www.harlaxton.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-45264',
        lat: 52.96699978682653,
        'long': -0.6900838407987843
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.68999477935645,
              52.967458240110496
            ],
            [
              -0.690686814720783,
              52.96671619962523
            ],
            [
              -0.690170933970317,
              52.96654186714459
            ],
            [
              -0.689481726288929,
              52.967283756048964
            ],
            [
              -0.68999477935645,
              52.967458240110496
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5565998',
        'Roof.Size': '5313.87633',
        'Estimated capacity': '743.9426862',
        'Title.Number': 'LL103660',
        Tenure: 'Freehold',
        'Property Address': 'land on the north side of Great North Road, Grantham',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'SHARMANS AGRICULTURAL LIMITED',
        'Company Registration No. (1)': '00199317',
        'Date Proprietor Added': '02/05/2008',
        POSTCODE_2: 'NG32 2AB',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '96',
        'Capital Employed': '24696918',
        Cash: '6504609',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '32905802',
        'Cost of Sales Delta': '3488884',
        'Cost of Sales Growth': '11.86012756',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '16819781',
        'Current Liabilities': '2568602',
        'Debt to Capital': '11.21724801',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '3379626',
        'Financial Year End': 'December',
        'Industry Keywords': 'agricultural tractors, agricultural machine, machinery sales, agricultural industry, farm machinery, farm equipment, keyboard, online shop, car dealership, safety, family run, government, manure, agricultural, wholesale, agricultural equipment, construction, golf equipment, engines, agricultural supplies, forestry equipment, dealer, agricultural accessories, agricultural implements, agriculture, mowers, agricultural machinery sales, agricultural machinery services, agricultural machinery repair, lawn tractors, forestry, machinery, construction equipment, agricultural machinery',
        'Net Assets': '24207079',
        'SIC 2007 Description': 'Wholesale of agricultural machinery, equipment and supplies',
        'Shareholder Funds': '24207079',
        'Shareholders Names': 'Scott James Barclay',
        Status: 'Active',
        Telephone: '01476 562561',
        'Trading Address': 'College Farm, Gonerby Moor, Grantham, Lincolnshire, NG32 2AB',
        'Trading Address 1': 'College Farm',
        'Trading Address 2': 'Gonerby Moor',
        'Trading Address 3': 'Grantham',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '38699969',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.sharmans-agri.co.uk',
        'Web Address 2': 'http://www.sharmansshop.co.uk',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '14251179',
        lat: 52.95217997878587,
        'long': -0.6793176395646026
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.678532133261954,
              52.952387412180514
            ],
            [
              -0.679109416408894,
              52.95268905659387
            ],
            [
              -0.680051576343475,
              52.95203139407225
            ],
            [
              -0.679766010306294,
              52.95188222683057
            ],
            [
              -0.679896798385169,
              52.95179099366684
            ],
            [
              -0.67960493466308,
              52.951638519719914
            ],
            [
              -0.678532133261954,
              52.952387412180514
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5566003',
        'Roof.Size': '4531.709946',
        'Estimated capacity': '634.4393924',
        'Title.Number': 'LL114786',
        Tenure: 'Freehold',
        'Property Address': 'land on north side of Gonerby Lane, Allington, Grantham',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'G W PADLEY HOLDINGS LIMITED',
        'Company Registration No. (1)': '00633999',
        'Date Proprietor Added': '20/09/2010',
        POSTCODE_2: 'NG32 2DU',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '61',
        'Capital Employed': '38089000',
        Cash: '15613000',
        Charges: 'Satisfied',
        'Cost of Sales': '3157000',
        'Cost of Sales Delta': '-5522000',
        'Cost of Sales Growth': '-63.62484157',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '18219000',
        'Current Liabilities': '1705000',
        'Debt to Capital': '5.292255114',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '1831000',
        'Financial Year End': 'July',
        'Industry Keywords': 'poultry products, poultry meat, head offices, holding companies management, vegetables, arable farming, poultry, vegetable processing',
        'Net Assets': '37688000',
        'SIC 2007 Description': 'Activities of head offices',
        'Shareholder Funds': '37688000',
        'Shareholders Names': 'Zedra Holdings (Cayman) Limited, Zedra Trust Company (Suisse) Sa',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Cumberland Court, 80 Mount Street, Nottingham, Nottinghamshire, NG1 6HH',
        'Trading Address 1': 'Cumberland Court',
        'Trading Address 2': '80 Mount Street',
        'Trading Address 3': 'Nottingham',
        'Trading Address 4': 'Nottinghamshire',
        Turnover: '5269000',
        'Ultimate Domestic Parent Name': 'G W Padley Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/00633999',
        'Ultimate Parent Name': 'Zedra Holdings (Cayman) Ltd',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.gwpadley.co.uk',
        'Web Address 2': 'http://www.chickenology.co.uk',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '16514000',
        lat: 52.952579766794834,
        'long': -0.7087192804070562
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.709072849819864,
              52.95229348243232
            ],
            [
              -0.708807252448648,
              52.95295989273735
            ],
            [
              -0.709076253970646,
              52.95299904358104
            ],
            [
              -0.709351181567959,
              52.95230909067328
            ],
            [
              -0.708361002548536,
              52.95216510549917
            ],
            [
              -0.708086060181954,
              52.95285505604044
            ],
            [
              -0.708359803365162,
              52.95289488933787
            ],
            [
              -0.708625407182924,
              52.952228480065926
            ],
            [
              -0.70870899684023,
              52.95224062548546
            ],
            [
              -0.708443394226772,
              52.95290703495041
            ],
            [
              -0.708724995264488,
              52.952947941667006
            ],
            [
              -0.708990593820809,
              52.95228153155189
            ],
            [
              -0.709072849819864,
              52.95229348243232
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5566024',
        'Roof.Size': '8561.490153',
        'Estimated capacity': '1198.608621',
        'Title.Number': 'LL259633',
        Tenure: 'Freehold',
        'Property Address': 'Arena UK, Allington Lane, Allington, Grantham (NG32 2EF)',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'ARENA UK LIMITED',
        'Company Registration No. (1)': '05366084',
        'Date Proprietor Added': '21/09/2005',
        POSTCODE_2: 'NG32 2EF',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '15',
        'Capital Employed': '5558176',
        Cash: '0',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '85127',
        'Current Liabilities': '129400',
        'Debt to Capital': '60.2879856',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'February',
        'Industry Keywords': 'credit card, card, accommodation, corporate events, events, sports, conference facilities, conference, hospitality, meeting rooms',
        'Net Assets': '2258651',
        'SIC 2007 Description': 'Other sports activities (not including activities of racehorse owners) n.e.c.',
        'Shareholder Funds': '2258651',
        'Shareholders Names': 'Norman Oley',
        Status: 'Active',
        Telephone: '01476 591569',
        'Trading Address': 'Arena UK Limited, Willow Top Farm, Allington, Notts, NG32 2EF',
        'Trading Address 1': 'Arena UK Limited',
        'Trading Address 2': 'Willow Top Farm',
        'Trading Address 3': 'Allington',
        'Trading Address 4': 'Notts',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.arenauk.co.uk',
        'Web Address 2': 'http://www.arenauk.com',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-44273',
        lat: 52.95171384007217,
        'long': -0.6973617245733151
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.696660337557392,
              52.952572014036306
            ],
            [
              -0.697073532862304,
              52.95264684468995
            ],
            [
              -0.697323341996752,
              52.95214030643729
            ],
            [
              -0.69756599557378,
              52.952183959293876
            ],
            [
              -0.6980268910833,
              52.95125514064106
            ],
            [
              -0.697765163369752,
              52.95120741408487
            ],
            [
              -0.697814459608619,
              52.95110735746914
            ],
            [
              -0.698159872299878,
              52.95117874486724
            ],
            [
              -0.698225228403751,
              52.9510643003829
            ],
            [
              -0.698055110070727,
              52.95102917464193
            ],
            [
              -0.698011640359636,
              52.95110870836884
            ],
            [
              -0.697792661865371,
              52.95106495616551
            ],
            [
              -0.697841406939153,
              52.95097828840976
            ],
            [
              -0.697713001623053,
              52.95095188981649
            ],
            [
              -0.697638658131261,
              52.951083046617555
            ],
            [
              -0.697400458334881,
              52.95103998207308
            ],
            [
              -0.697516126115267,
              52.95080661389037
            ],
            [
              -0.697782609920394,
              52.95085448301498
            ],
            [
              -0.697812770515414,
              52.950791884323095
            ],
            [
              -0.697466217640195,
              52.950728843982475
            ],
            [
              -0.696785305633074,
              52.95208397558369
            ],
            [
              -0.696892470073502,
              52.95210402919019
            ],
            [
              -0.696660337557392,
              52.952572014036306
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5567016',
        'Roof.Size': '6328.405073',
        'Estimated capacity': '885.9767102',
        'Title.Number': 'LL389333',
        Tenure: 'Freehold',
        'Property Address': 'Land adjoining 70 High Street, Swinderby, Lincoln (LN6 9LU)',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'I.P.M PERSONAL PENSION TRUSTEES LIMITED',
        'Company Registration No. (1)': '03029085',
        'Date Proprietor Added': '08/03/2019',
        POSTCODE_2: 'LN6 9LX',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '25',
        'Capital Employed': '100',
        Cash: '0',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Not Rated',
        'Current Assets': '100',
        'Current Liabilities': '0',
        'Debt to Capital': '0',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'March',
        'Industry Keywords': 'suit, suits, data protection, due diligence, online access, personal pensions, advisers, administration services, investment, administration, business support, commercial property, property',
        'Net Assets': '100',
        'SIC 2007 Description': 'Other business support service activities n.e.c.',
        'Shareholder Funds': '100',
        'Shareholders Names': 'I.P.M. Sipp Administration Ltd',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Unit B, Campus 6, Caxton Way, Stevenage, Hertfordshire, SG1 2XD',
        'Trading Address 1': 'Unit B',
        'Trading Address 2': 'Campus 6',
        'Trading Address 3': 'Caxton Way',
        'Trading Address 4': 'Stevenage, Hertfordshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'I.P.M. Sipp Administration Limited',
        'Ultimate Domestic Parent Id': 'company/gb/03002939',
        'Ultimate Parent Name': 'I.P.M. Sipp Administration Limited',
        'Ultimate Parent Id': 'company/gb/03002939',
        'Web Address 1': 'http://www.ipm-pensions.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '100',
        lat: 53.15820111653424,
        'long': -0.6982548367428144
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.697237233311574,
              53.15835470728254
            ],
            [
              -0.698945316321158,
              53.15838173649626
            ],
            [
              -0.698937878474027,
              53.158538703084744
            ],
            [
              -0.69946244327091,
              53.15854703852339
            ],
            [
              -0.699479529064227,
              53.15817964147103
            ],
            [
              -0.698400337654554,
              53.158163269171844
            ],
            [
              -0.698412621181167,
              53.157905492531775
            ],
            [
              -0.697261497904302,
              53.15788418864207
            ],
            [
              -0.697237233311574,
              53.15835470728254
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5570626',
        'Roof.Size': '5147.154075',
        'Estimated capacity': '720.6015705',
        'Title.Number': 'LL212126',
        Tenure: 'Freehold',
        'Property Address': 'Land on the west side of Great North Road, Long Bennington',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'TYREMART AGRICULTURAL LIMITED',
        'Company Registration No. (1)': '01405709',
        'Date Proprietor Added': '19/04/2002',
        POSTCODE_2: 'NG23 5DJ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '41',
        'Capital Employed': '4699172',
        Cash: '7769',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Moderate',
        'Current Assets': '4849078',
        'Current Liabilities': '1794117',
        'Debt to Capital': '29.43143298',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': 'wholesale, factoring, farming, tyre, trailers, trailer, wheels',
        'Net Assets': '4582221',
        'SIC 2007 Description': 'Non-specialised wholesale trade',
        'Shareholder Funds': '4582221',
        'Shareholders Names': 'R P F Spa',
        Status: 'Active',
        Telephone: '01400 283820',
        'Trading Address': 'Main Road, Long Bennington, Newark, Notts, NG23 5DJ',
        'Trading Address 1': 'Main Road',
        'Trading Address 2': 'Long Bennington',
        'Trading Address 3': 'Newark',
        'Trading Address 4': 'Notts',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '3054961',
        lat: 53.00170350713499,
        'long': -0.7615137681323961
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.761056278282729,
              53.00204994556568
            ],
            [
              -0.761110175364098,
              53.00208287008537
            ],
            [
              -0.761962630264906,
              53.002086083556534
            ],
            [
              -0.761970385180425,
              53.00132527283549
            ],
            [
              -0.761063857573652,
              53.001321855650836
            ],
            [
              -0.761056278282729,
              53.00204994556568
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5585264',
        'Roof.Size': '5016.330198',
        'Estimated capacity': '702.2862277',
        'Title.Number': 'LL391892',
        Tenure: 'Freehold',
        'Property Address': 'Land at Hurlingham Business Park, Fulbeck Heath, Grantham (NG32 3HL)',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'MELBOURNE HOLDINGS LIMITED',
        'Company Registration No. (1)': '01437478',
        'Date Proprietor Added': '24/06/2019',
        POSTCODE_2: 'NG32 3HL',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '41',
        'Capital Employed': '66537129',
        Cash: '7103119',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '38962618',
        'Cost of Sales Delta': '-2331012',
        'Cost of Sales Growth': '-5.644967517',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '40536744',
        'Current Liabilities': '7957919',
        'Debt to Capital': '19.4131226',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '6624103',
        'Financial Year End': 'December',
        'Industry Keywords': 'property development company, commercial property development, property finance, residential property investment, commercial property finance, commercial development, residential property development, property development consultancy, property development finance, property development loans, project development, real estate development, haulage, real estate, building projects development, real estate sales, property, property development',
        'Net Assets': '60033233',
        'SIC 2007 Description': 'Development of building projects',
        'Shareholder Funds': '60033233',
        'Shareholders Names': 'Lynne Patricia Priestley, John Langstaff Priestley Mr',
        Status: 'Active',
        Telephone: '01529 461666',
        'Trading Address': 'Station Road, Heckington, Sleaford, Lincolnshire, NG34 9NF',
        'Trading Address 1': 'Station Road',
        'Trading Address 2': 'Heckington',
        'Trading Address 3': 'Sleaford',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '47635314',
        'Ultimate Domestic Parent Name': 'Melbourne Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/01437478',
        'Ultimate Parent Name': 'Melbourne Holdings Limited',
        'Ultimate Parent Id': 'company/gb/01437478',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '32578825',
        lat: 53.03920584575315,
        'long': -0.5339506265505175
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.534581237809166,
              53.03895931141454
            ],
            [
              -0.533471910361869,
              53.03886077632706
            ],
            [
              -0.533317946769486,
              53.03945086430306
            ],
            [
              -0.534430343696193,
              53.0395516860324
            ],
            [
              -0.534581237809166,
              53.03895931141454
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5585758',
        'Roof.Size': '6892.904322',
        'Estimated capacity': '965.0066051',
        'Title.Number': 'LL154741',
        Tenure: 'Freehold',
        'Property Address': 'Dalgety Agriculture Ltd, Sleaford Road, Bracebridge Heath (LN4 2NW)',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'MASSTOCK ARABLE (UK) LIMITED',
        'Company Registration No. (1)': '02387531',
        'Date Proprietor Added': '23/02/2004',
        POSTCODE_2: 'LN4 2NW',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '31',
        'Capital Employed': '34544000',
        Cash: '7029000',
        Charges: 'Satisfied',
        'Cost of Sales': '269142000',
        'Cost of Sales Delta': '34343000',
        'Cost of Sales Growth': '14.62655292',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '232357000',
        'Current Liabilities': '246042000',
        'Debt to Capital': '89.6163743',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '13681000',
        'Financial Year End': 'July',
        'Industry Keywords': 'crop production, pipes, agricultural industry, plant protection, advice services, drainage, events careers, networking, intelligence, seed processing, research & development, horticulture, services technology, ancillary products, strategic advice, technology, precision farming, research, agronomy services, crop farming, protection products, landscape gardening, agricultural services, agriculture, crop protection, agronomy, crops, farming',
        'Net Assets': '29135000',
        'SIC 2007 Description': 'Support activities for crop production',
        'Shareholder Funds': '29135000',
        'Shareholders Names': 'Oval (1173) Ltd',
        Status: 'Active',
        Telephone: '0845 607 3322',
        'Trading Address': 'Station Road, Andoversford, Cheltenham, Gloucestershire, GL54 4LZ',
        'Trading Address 1': 'Station Road',
        'Trading Address 2': 'Andoversford',
        'Trading Address 3': 'Cheltenham',
        'Trading Address 4': 'Gloucestershire',
        Turnover: '342829000',
        'Ultimate Domestic Parent Name': 'Agrii Holdings (UK) Limited',
        'Ultimate Domestic Parent Id': 'company/gb/NI604285',
        'Ultimate Parent Name': 'Origin Enterprises Public Limited Compan',
        'Ultimate Parent Id': 'company/ie/426261',
        'Web Address 1': 'http://www.agrii.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-13685000',
        lat: 53.191140384285305,
        'long': -0.5260575896768221
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.526470413470418,
              53.19174579910985
            ],
            [
              -0.526769815699198,
              53.19158310198043
            ],
            [
              -0.52684167633872,
              53.19163073697509
            ],
            [
              -0.527081741942473,
              53.19150038754083
            ],
            [
              -0.525562948370075,
              53.190509455125756
            ],
            [
              -0.525473424547383,
              53.19054484547039
            ],
            [
              -0.525352983370395,
              53.190604484907986
            ],
            [
              -0.525113548542712,
              53.1907382547759
            ],
            [
              -0.525327697572094,
              53.190883392389395
            ],
            [
              -0.525242217227011,
              53.19093177779316
            ],
            [
              -0.526470413470418,
              53.19174579910985
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5588596',
        'Roof.Size': '4986.243404',
        'Estimated capacity': '698.0740766',
        'Title.Number': 'LL196658',
        Tenure: 'Freehold',
        'Property Address': 'Land lying to the south of Willoughby Road, Ancaster, Grantham',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'SAM TRUSTEES LIMITED',
        'Company Registration No. (1)': '03305996',
        'Date Proprietor Added': '19/03/2001',
        POSTCODE_2: 'NG32 3PL',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '23',
        'Capital Employed': '0',
        Cash: '0',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Not Rated',
        'Current Assets': '0',
        'Current Liabilities': '0',
        'Debt to Capital': '0',
        'Dividends Payable': '0',
        'Dormant Company': 'Yes',
        EBITDA: '0',
        'Financial Year End': 'March',
        'Industry Keywords': 'due diligence, environmental issues, administration, commercial premises, financial, residential property, property, self invested, advisers, pension scheme, life insurance, insurance, financial services',
        'Net Assets': '0',
        'SIC 2007 Description': 'Life insurance',
        'Shareholder Funds': '0',
        'Shareholders Names': 'Talbot & Muir Ltd',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': '55 Maid Marian Way, Nottingham, NG1 6GE',
        'Trading Address 1': '55 Maid Marian Way',
        'Trading Address 2': 'Nottingham',
        'Trading Address 3': '0',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'Talbot and Muir Limited',
        'Ultimate Domestic Parent Id': 'company/gb/02869547',
        'Ultimate Parent Name': 'Talbot and Muir Limited',
        'Ultimate Parent Id': 'company/gb/02869547',
        'Web Address 1': 'http://www.talbotmuir.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 52.97891326483978,
        'long': -0.5402410023294846
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.539623984640767,
              52.97911065299328
            ],
            [
              -0.539604872756327,
              52.97917388760739
            ],
            [
              -0.539716910402137,
              52.97918623156813
            ],
            [
              -0.539638366200823,
              52.979373247634754
            ],
            [
              -0.53973928087567,
              52.97938860144331
            ],
            [
              -0.53979931894246,
              52.9792468474035
            ],
            [
              -0.539985742701284,
              52.979275028080856
            ],
            [
              -0.539927327786682,
              52.97941725164458
            ],
            [
              -0.540025107004487,
              52.979432836403156
            ],
            [
              -0.540107501533611,
              52.979237596574315
            ],
            [
              -0.540442902481013,
              52.97928252916681
            ],
            [
              -0.540549506180092,
              52.97899202258783
            ],
            [
              -0.540755706088082,
              52.97901675892613
            ],
            [
              -0.540900514236514,
              52.97862225719735
            ],
            [
              -0.539966900611976,
              52.978499589310395
            ],
            [
              -0.53973795155698,
              52.97912320043301
            ],
            [
              -0.539623984640767,
              52.97911065299328
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5777151',
        'Roof.Size': '7418.451443',
        'Estimated capacity': '1038.583202',
        'Title.Number': 'LL106978',
        Tenure: 'Freehold',
        'Property Address': 'land and buildings on the South side of Green Lane, Belton',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'AUTOCRAFT DRIVETRAIN SOLUTIONS LIMITED',
        'Company Registration No. (1)': '02847178',
        'Date Proprietor Added': '01/06/2010',
        POSTCODE_2: 'NG32 2BZ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '27',
        'Capital Employed': '9432000',
        Cash: '528000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '19336000',
        'Cost of Sales Delta': '-1559000',
        'Cost of Sales Growth': '-7.461115099',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '11624000',
        'Current Liabilities': '6570000',
        'Debt to Capital': '44.43819523',
        'Dividends Payable': '200000',
        'Dormant Company': 'No',
        EBITDA: '2560000',
        'Financial Year End': 'December',
        'Industry Keywords': 'land, rover, motor, pension scheme, engineering services, machined components, company pension, global market, solutions limited, aston martin, automotive, motor vehicle engine parts, motor vehicle engine accessories, motor vehicle accessories, remanufacture, manufacturing, motor vehicle parts, engine distribution, vehicle engineers, engines',
        'Net Assets': '8891000',
        'SIC 2007 Description': 'Manufacture of other parts and accessories for motor vehicles',
        'Shareholder Funds': '8891000',
        'Shareholders Names': 'Harmor Ltd',
        Status: 'Active',
        Telephone: '01476 581300',
        'Trading Address': 'Autocraft Drivetrain Solutions L, Main Road, Belton, Grantham, Lincolnshire, NG32 2LY',
        'Trading Address 1': 'Autocraft Drivetrain Solutions L',
        'Trading Address 2': 'Main Road',
        'Trading Address 3': 'Belton',
        'Trading Address 4': 'Grantham, Lincolnshire',
        Turnover: '23192000',
        'Ultimate Domestic Parent Name': 'Harmor Limited',
        'Ultimate Domestic Parent Id': 'company/gb/07220280',
        'Ultimate Parent Name': 'Harmor Limited',
        'Ultimate Parent Id': 'company/gb/07220280',
        'Web Address 1': 'http://www.autocraftds.com',
        'Web Address 2': 'http://www.autocraftdrivetrainsolutions.net',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '5054000',
        lat: 52.952554797516036,
        'long': -0.6097281632144272
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.609669482303781,
              52.95250033655903
            ],
            [
              -0.60841092141491,
              52.95257029391965
            ],
            [
              -0.60846182210521,
              52.95287196360973
            ],
            [
              -0.61067608244323,
              52.95275861116394
            ],
            [
              -0.610584499765046,
              52.95213273853711
            ],
            [
              -0.609621607445196,
              52.95218297037297
            ],
            [
              -0.609669482303781,
              52.95250033655903
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5777154',
        'Roof.Size': '3758.489544',
        'Estimated capacity': '526.1885362',
        'Title.Number': 'LL106978',
        Tenure: 'Freehold',
        'Property Address': 'land and buildings on the South side of Green Lane, Belton',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'AUTOCRAFT DRIVETRAIN SOLUTIONS LIMITED',
        'Company Registration No. (1)': '02847178',
        'Date Proprietor Added': '01/06/2010',
        POSTCODE_2: 'NG32 2LY',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '27',
        'Capital Employed': '9432000',
        Cash: '528000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '19336000',
        'Cost of Sales Delta': '-1559000',
        'Cost of Sales Growth': '-7.461115099',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '11624000',
        'Current Liabilities': '6570000',
        'Debt to Capital': '44.43819523',
        'Dividends Payable': '200000',
        'Dormant Company': 'No',
        EBITDA: '2560000',
        'Financial Year End': 'December',
        'Industry Keywords': 'land, rover, motor, pension scheme, engineering services, machined components, company pension, global market, solutions limited, aston martin, automotive, motor vehicle engine parts, motor vehicle engine accessories, motor vehicle accessories, remanufacture, manufacturing, motor vehicle parts, engine distribution, vehicle engineers, engines',
        'Net Assets': '8891000',
        'SIC 2007 Description': 'Manufacture of other parts and accessories for motor vehicles',
        'Shareholder Funds': '8891000',
        'Shareholders Names': 'Harmor Ltd',
        Status: 'Active',
        Telephone: '01476 581300',
        'Trading Address': 'Autocraft Drivetrain Solutions L, Main Road, Belton, Grantham, Lincolnshire, NG32 2LY',
        'Trading Address 1': 'Autocraft Drivetrain Solutions L',
        'Trading Address 2': 'Main Road',
        'Trading Address 3': 'Belton',
        'Trading Address 4': 'Grantham, Lincolnshire',
        Turnover: '23192000',
        'Ultimate Domestic Parent Name': 'Harmor Limited',
        'Ultimate Domestic Parent Id': 'company/gb/07220280',
        'Ultimate Parent Name': 'Harmor Limited',
        'Ultimate Parent Id': 'company/gb/07220280',
        'Web Address 1': 'http://www.autocraftds.com',
        'Web Address 2': 'http://www.autocraftdrivetrainsolutions.net',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '5054000',
        lat: 52.95255274054187,
        'long': -0.6118584740646407
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.612659081248864,
              52.95267569142625
            ],
            [
              -0.612616192365055,
              52.952355328176665
            ],
            [
              -0.611060099655747,
              52.95242948179215
            ],
            [
              -0.611098650444099,
              52.95275015456487
            ],
            [
              -0.612659081248864,
              52.95267569142625
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5778947',
        'Roof.Size': '18264.28631',
        'Estimated capacity': '2557.000083',
        'Title.Number': 'LL374538',
        Tenure: 'Freehold',
        'Property Address': 'Land on the south side of Gonerby Road, Gonerby Hill Foot, Grantham',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'MOY PARK LIMITED',
        'Company Registration No. (1)': '00004842',
        'Date Proprietor Added': '13/06/2017',
        POSTCODE_2: 'NG31 8HY',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '#N/A',
        'Capital Employed': '#N/A',
        Cash: '#N/A',
        Charges: '#N/A',
        'Cost of Sales': '#N/A',
        'Cost of Sales Delta': '#N/A',
        'Cost of Sales Growth': '#N/A',
        Country: '#N/A',
        'Credit Risk': '#N/A',
        'Current Assets': '#N/A',
        'Current Liabilities': '#N/A',
        'Debt to Capital': '#N/A',
        'Dividends Payable': '#N/A',
        'Dormant Company': '#N/A',
        EBITDA: '#N/A',
        'Financial Year End': '#N/A',
        'Industry Keywords': '#N/A',
        'Net Assets': '#N/A',
        'SIC 2007 Description': '#N/A',
        'Shareholder Funds': '#N/A',
        'Shareholders Names': '#N/A',
        Status: '#N/A',
        Telephone: '#N/A',
        'Trading Address': '#N/A',
        'Trading Address 1': '#N/A',
        'Trading Address 2': '#N/A',
        'Trading Address 3': '#N/A',
        'Trading Address 4': '#N/A',
        Turnover: '#N/A',
        'Ultimate Domestic Parent Name': '#N/A',
        'Ultimate Domestic Parent Id': '#N/A',
        'Ultimate Parent Name': '#N/A',
        'Ultimate Parent Id': '#N/A',
        'Web Address 1': '#N/A',
        'Web Address 2': '#N/A',
        'Web Address 3': '#N/A',
        'Web Address 4': '#N/A',
        'Working Capital': '#N/A',
        lat: 52.92217017671614,
        'long': -0.6516439092339765
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.650618101103638,
              52.92138789963868
            ],
            [
              -0.650114876942729,
              52.921823770989214
            ],
            [
              -0.650346845901508,
              52.92192187933566
            ],
            [
              -0.65022433162978,
              52.9220254006005
            ],
            [
              -0.65063305662987,
              52.92219869431846
            ],
            [
              -0.650562734500606,
              52.922259746677696
            ],
            [
              -0.65222865780133,
              52.92296090157252
            ],
            [
              -0.65270271166931,
              52.92254923323761
            ],
            [
              -0.653075814582384,
              52.92270611244428
            ],
            [
              -0.653005492240258,
              52.922767256129255
            ],
            [
              -0.653235733082701,
              52.922863900780406
            ],
            [
              -0.653502798686605,
              52.922631839920236
            ],
            [
              -0.652927070883961,
              52.922389597998894
            ],
            [
              -0.652976996115597,
              52.922343416171486
            ],
            [
              -0.652943363092794,
              52.92232954996871
            ],
            [
              -0.653001344512125,
              52.92221549536951
            ],
            [
              -0.652815927144313,
              52.92218444572215
            ],
            [
              -0.652705101494265,
              52.92213680102221
            ],
            [
              -0.652609690730326,
              52.9221100978138
            ],
            [
              -0.652280275360807,
              52.92195964597173
            ],
            [
              -0.652219847123852,
              52.92201352964786
            ],
            [
              -0.65198032066251,
              52.92191435035351
            ],
            [
              -0.652063037031164,
              52.92181334253787
            ],
            [
              -0.651028244244226,
              52.92138140890451
            ],
            [
              -0.650882617849902,
              52.92150120999175
            ],
            [
              -0.650618101103638,
              52.92138789963868
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5780923',
        'Roof.Size': '5191.156525',
        'Estimated capacity': '726.7619135',
        'Title.Number': 'LL172146',
        Tenure: 'Freehold',
        'Property Address': 'land and buildings on the north and south sides of Isaac Newton Way, Grantham',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'THE RANDOM HOUSE GROUP LIMITED',
        'Company Registration No. (1)': '00954009',
        'Date Proprietor Added': '16/10/2001',
        POSTCODE_2: 'NG31 9RT',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '51',
        'Capital Employed': '204942033',
        Cash: '2460026',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '35781239',
        'Cost of Sales Delta': '-2870326',
        'Cost of Sales Growth': '-7.426157259',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '173216942',
        'Current Liabilities': '275475306',
        'Debt to Capital': '64.51870194',
        'Dividends Payable': '54000000',
        'Dormant Company': 'No',
        EBITDA: '38168069',
        'Financial Year End': 'December',
        'Industry Keywords': 'journal publishing, publishing solutions, academic publishing, publishing platform, publishing systems, self publishing, online publishing, customer publishing, newspaper publishing, published media, publishing industry, publishing house, managing director, personal data, leadership team, mentoring programme, vintage, tv production, media company, media conglomerate, global education, children, creative, houses, publishers, book publishing, publishing, books',
        'Net Assets': '170458308',
        'SIC 2007 Description': 'Book publishing',
        'Shareholder Funds': '170458308',
        'Shareholders Names': 'Penguin Random House Limited',
        Status: 'Active',
        Telephone: '020 7840 8400',
        'Trading Address': '20 Vauxhall Bridge Road, London, SW1V 2SA',
        'Trading Address 1': '20 Vauxhall Bridge Road',
        'Trading Address 2': 'London',
        'Trading Address 3': '0',
        'Trading Address 4': '0',
        Turnover: '198819193',
        'Ultimate Domestic Parent Name': 'Bertelsmann UK Limited',
        'Ultimate Domestic Parent Id': 'company/gb/03185285',
        'Ultimate Parent Name': 'Stiftungen (Bertelsmann-,Reinhard Mohn-,Bvg-)',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.penguinrandomhouse.co.uk',
        'Web Address 2': 'http://www.thewritersacademy.co.uk',
        'Web Address 3': 'http://www.gbsdistribution.co.uk',
        'Web Address 4': 'http://www.thebookservice.co.uk',
        'Working Capital': '-102258364',
        lat: 52.92123155563281,
        'long': -0.6045034751047422
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.605047473571684,
              52.92082561145634
            ],
            [
              -0.604511816277233,
              52.92076178575165
            ],
            [
              -0.604476323551822,
              52.920871047040094
            ],
            [
              -0.604213012505715,
              52.92083972566994
            ],
            [
              -0.603956554080809,
              52.921626665063975
            ],
            [
              -0.604531637110453,
              52.921695181536954
            ],
            [
              -0.604559745939836,
              52.9216074095518
            ],
            [
              -0.60478424651024,
              52.92163386932302
            ],
            [
              -0.605047473571684,
              52.92082561145634
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5781473',
        'Roof.Size': '6251.629111',
        'Estimated capacity': '875.2280755',
        'Title.Number': 'LL228973',
        Tenure: 'Freehold',
        'Property Address': 'land and buildings on the north side of Londonthorpe Road, Grantham',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'VALE GARDEN HOUSES LIMITED',
        'Company Registration No. (1)': '02896406',
        'Date Proprietor Added': '16/06/2003',
        POSTCODE_2: 'NG31 9SJ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '26',
        'Capital Employed': '12948001',
        Cash: '3251345',
        Charges: '0',
        'Cost of Sales': '4790279',
        'Cost of Sales Delta': '155697',
        'Cost of Sales Growth': '3.359461544',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '8758280',
        'Current Liabilities': '4065517',
        'Debt to Capital': '24.42975051',
        'Dividends Payable': '450000',
        'Dormant Company': 'No',
        EBITDA: '1955667',
        'Financial Year End': 'March',
        'Industry Keywords': 'aluminium conservatories, timber conservatories, hardwood conservatories, pvcu conservatories, new conservatory, orangeries, timber orangeries, steel windows, edwardian conservatories, victorian conservatories, long term investment, investment, screens, glass, building, glazing, secondary glazing, building regulations, doors, timber, residential property, design, installation, joinery, joinery installation, metal, manufacturing, construction installation, conservatories, metal windows, windows',
        'Net Assets': '12857158',
        'SIC 2007 Description': 'Joinery installation',
        'Shareholder Funds': '12857158',
        'Shareholders Names': 'Lisa Catherine Moth, Simon Adrian Charles Morton, Dennis Herbert Morton Executors of, Denise Morton',
        Status: 'Active',
        Telephone: '01476 564433',
        'Trading Address': 'Belton Park, Londonthorpe Road, Grantham, Lincolnshire, NG31 9SJ',
        'Trading Address 1': 'Belton Park',
        'Trading Address 2': 'Londonthorpe Road',
        'Trading Address 3': 'Grantham',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '10295949',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.bronzecasements.co.uk',
        'Web Address 2': 'http://www.valeorangeries.com',
        'Web Address 3': 'http://www.valegardenhouses.com',
        'Web Address 4': 'http://www.valegardenhouses.net',
        'Working Capital': '4692763',
        lat: 52.92766491918696,
        'long': -0.6147631612681875
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.614884776350298,
              52.92813933842726
            ],
            [
              -0.614903089918989,
              52.928180815937644
            ],
            [
              -0.615135776081907,
              52.928143162803856
            ],
            [
              -0.615111486358116,
              52.928088490300055
            ],
            [
              -0.614853232778522,
              52.92813016056312
            ],
            [
              -0.614565740244374,
              52.927801551311596
            ],
            [
              -0.614617742686944,
              52.9277849866998
            ],
            [
              -0.614811575697439,
              52.92801370413032
            ],
            [
              -0.615192924085983,
              52.92789543539054
            ],
            [
              -0.615109682114036,
              52.92780007042272
            ],
            [
              -0.615115716309114,
              52.927769484882134
            ],
            [
              -0.615251529931224,
              52.92777915857474
            ],
            [
              -0.615284380339492,
              52.92758886370017
            ],
            [
              -0.61549815850117,
              52.92759818698382
            ],
            [
              -0.615479231212156,
              52.92774845888091
            ],
            [
              -0.615637597879463,
              52.927755338299235
            ],
            [
              -0.615658370456173,
              52.92758917559592
            ],
            [
              -0.615836466501566,
              52.9275978128861
            ],
            [
              -0.61585060771288,
              52.92749027753503
            ],
            [
              -0.615342554111511,
              52.927467457508214
            ],
            [
              -0.615353800196991,
              52.92738542004423
            ],
            [
              -0.614429491313985,
              52.9273395853442
            ],
            [
              -0.61440914912221,
              52.92731507517986
            ],
            [
              -0.614225468066512,
              52.92736957024791
            ],
            [
              -0.61415328675253,
              52.927286649858566
            ],
            [
              -0.613880592806327,
              52.92737067224406
            ],
            [
              -0.614604682401169,
              52.928226692424914
            ],
            [
              -0.614884776350298,
              52.92813933842726
            ]
          ],
          [
            [
              -0.614806952562667,
              52.92779501369617
            ],
            [
              -0.614674874684179,
              52.9276356997138
            ],
            [
              -0.614699816160636,
              52.927627989382515
            ],
            [
              -0.614796430559666,
              52.927742479404486
            ],
            [
              -0.614925339713491,
              52.9277587255793
            ],
            [
              -0.614833844579316,
              52.927786786673984
            ],
            [
              -0.614806952562667,
              52.92779501369617
            ]
          ],
          [
            [
              -0.614581118886071,
              52.9273711100764
            ],
            [
              -0.614572815438576,
              52.92746810516594
            ],
            [
              -0.614443668638368,
              52.927463992319076
            ],
            [
              -0.614452121084292,
              52.927366998974335
            ],
            [
              -0.614581118886071,
              52.9273711100764
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5781474',
        'Roof.Size': '6637.475458',
        'Estimated capacity': '929.2465641',
        'Title.Number': 'LL195939',
        Tenure: 'Freehold',
        'Property Address': 'land and buildings on the side of Londonthorpe Road, Grantham',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'PARKER HANNIFIN LIMITED',
        'Company Registration No. (1)': '04806503',
        'Date Proprietor Added': '04/04/2011',
        POSTCODE_2: 'NG31 9SJ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '17',
        'Capital Employed': '278597000',
        Cash: '149821000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '108967000',
        'Cost of Sales Delta': '-11684000',
        'Cost of Sales Growth': '-9.68413026',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '294326000',
        'Current Liabilities': '192616000',
        'Debt to Capital': '49.63402962',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '19402000',
        'Financial Year End': 'June',
        'Industry Keywords': 'compressors, pumps, fluid power equipment, motion control systems, manufacturing, control systems, motion control',
        'Net Assets': '237331000',
        'SIC 2007 Description': 'Manufacture of fluid power equipment',
        'Shareholder Funds': '237331000',
        'Shareholders Names': 'Alenco Holdings Ltd, Parker Hannifin International Corp, Parker Hannifin GB Ltd',
        Status: 'Active',
        Telephone: '01442 458000',
        'Trading Address': 'Parker House, 55 Maylands Avenue, Hemel Hempstead Industrial Estate, Hemel Hempstead, Hertfordshire, HP2 4SJ',
        'Trading Address 1': 'Parker House',
        'Trading Address 2': '55 Maylands Avenue',
        'Trading Address 3': 'Hemel Hempstead Industrial Estate',
        'Trading Address 4': 'Hemel Hempstead, Hertfordshire',
        Turnover: '117522000',
        'Ultimate Domestic Parent Name': 'Parker Hannifin (Holdings) Limited',
        'Ultimate Domestic Parent Id': 'company/gb/03922924',
        'Ultimate Parent Name': 'Parker Hannifin Corporation',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.parker.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '101710000',
        lat: 52.92786436630221,
        'long': -0.6131719517844565
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.613465522166364,
              52.92809276060631
            ],
            [
              -0.613611820616126,
              52.928263209632526
            ],
            [
              -0.613989756871044,
              52.9281448150247
            ],
            [
              -0.613521029293611,
              52.92759868904716
            ],
            [
              -0.613762434572007,
              52.92752553997513
            ],
            [
              -0.613606919646315,
              52.92734095919157
            ],
            [
              -0.612384376335697,
              52.92771263009273
            ],
            [
              -0.612584378521615,
              52.927952750647805
            ],
            [
              -0.612458709906313,
              52.92799335651766
            ],
            [
              -0.612746891659491,
              52.92831856278148
            ],
            [
              -0.613465522166364,
              52.92809276060631
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5788196',
        'Roof.Size': '28545.78378',
        'Estimated capacity': '3996.409729',
        'Title.Number': 'LL147240',
        Tenure: 'Freehold',
        'Property Address': 'Hangars, 1-8 Wilsford Heath, Ancaster, Grantham (NG32 3QD)',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'NEW EARTH SOLUTIONS (WEST) LIMITED',
        'Company Registration No. (1)': '06968057',
        'Date Proprietor Added': '05/07/2019',
        POSTCODE_2: 'NG32 3FH',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '11',
        'Capital Employed': '3453910',
        Cash: '378699',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '15047189',
        'Cost of Sales Delta': '1887379.118',
        'Cost of Sales Growth': '14.3419938',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '3347184',
        'Current Liabilities': '5470238',
        'Debt to Capital': '61.29703362',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '-84227',
        'Financial Year End': 'December',
        'Industry Keywords': 'vessels, compost, waste, recycling',
        'Net Assets': '3453910',
        'SIC 2007 Description': 'Other personal service activities n.e.c.',
        'Shareholder Funds': '3453910',
        'Shareholders Names': 'Dm Holdco Ltd',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'The Mrf Station Road, Caythorpe, Grantham, Lincolnshire Ng32 3ew, NG32 3EW',
        'Trading Address 1': 'The Mrf Station Road',
        'Trading Address 2': 'Caythorpe',
        'Trading Address 3': 'Grantham',
        'Trading Address 4': 'Lincolnshire Ng32 3ew',
        Turnover: '14127684',
        'Ultimate Domestic Parent Name': 'Dm Topco Limited',
        'Ultimate Domestic Parent Id': 'company/gb/10125468',
        'Ultimate Parent Name': 'Sretaw 2 Limited',
        'Ultimate Parent Id': 'company/ie/611261',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-2123054',
        lat: 52.96435568531566,
        'long': -0.5442413168443276
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.54502419411207,
              52.96465324723282
            ],
            [
              -0.544946857101788,
              52.96477033946988
            ],
            [
              -0.545457922105321,
              52.9648929247197
            ],
            [
              -0.545862304854008,
              52.9642811573706
            ],
            [
              -0.543881965718094,
              52.96380840419607
            ],
            [
              -0.543828780407781,
              52.963680813701316
            ],
            [
              -0.545233504395316,
              52.963474521413275
            ],
            [
              -0.545092954482466,
              52.9631284837718
            ],
            [
              -0.542441860356278,
              52.9635211444307
            ],
            [
              -0.542582095609021,
              52.96386709153327
            ],
            [
              -0.543388194032535,
              52.963747695460114
            ],
            [
              -0.543416302366656,
              52.9638151053899
            ],
            [
              -0.543494870684198,
              52.963803212369335
            ],
            [
              -0.543637045557144,
              52.96378158027352
            ],
            [
              -0.543635943340402,
              52.963783364772475
            ],
            [
              -0.543261493006258,
              52.964367622505414
            ],
            [
              -0.543414852199559,
              52.96440312440954
            ],
            [
              -0.543212792729238,
              52.964722311014945
            ],
            [
              -0.543603885295369,
              52.96481259821105
            ],
            [
              -0.543529274548366,
              52.96492819464325
            ],
            [
              -0.543411537977967,
              52.96490041130874
            ],
            [
              -0.543222750875386,
              52.96519234100944
            ],
            [
              -0.545287846369452,
              52.96567908761088
            ],
            [
              -0.545505051281987,
              52.96534309177836
            ],
            [
              -0.544373451050922,
              52.96507644149057
            ],
            [
              -0.544695260115612,
              52.96457415412294
            ],
            [
              -0.54502419411207,
              52.96465324723282
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5788777',
        'Roof.Size': '3799.263332',
        'Estimated capacity': '531.8968665',
        'Title.Number': 'LL79605',
        Tenure: 'Freehold',
        'Property Address': 'Swallow Hotel, Swingbridge Road, Grantham (NG31 7XT)',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'SUNNYBANK LIMITED',
        'Company Registration No. (1)': '04855270',
        'Date Proprietor Added': '03/07/2018',
        POSTCODE_2: 'NG31 7XF',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '17',
        'Capital Employed': '7318567',
        Cash: '145879',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '871710',
        'Current Liabilities': '433413',
        'Debt to Capital': '61.21269663',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'March',
        'Industry Keywords': 'lodging, hotel',
        'Net Assets': '3006784',
        'SIC 2007 Description': 'Hotels and similar accommodation',
        'Shareholder Funds': '3006784',
        'Shareholders Names': 'Gurnam Singh, Gurmit Kaur',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': '119 Manthorpe Road, Grantham, Lincolnshire, NG31 8DQ',
        'Trading Address 1': '119 Manthorpe Road',
        'Trading Address 2': 'Grantham',
        'Trading Address 3': 'Lincolnshire',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '438297',
        lat: 52.89999344667461,
        'long': -0.6608979698678684
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.661584563537301,
              52.8999058903184
            ],
            [
              -0.661423039760608,
              52.89988393293123
            ],
            [
              -0.661457765853959,
              52.89978947988003
            ],
            [
              -0.660509360503801,
              52.899660392307986
            ],
            [
              -0.66037554240218,
              52.90002243836664
            ],
            [
              -0.66019358411983,
              52.899997821587746
            ],
            [
              -0.660141654411799,
              52.900139007971276
            ],
            [
              -0.661454162687872,
              52.900316521939146
            ],
            [
              -0.661509276764938,
              52.90016835869612
            ],
            [
              -0.661326422706882,
              52.90014382346713
            ],
            [
              -0.661401512071354,
              52.89994086665875
            ],
            [
              -0.661606833716835,
              52.89996961048514
            ],
            [
              -0.66161354099568,
              52.89996905674865
            ],
            [
              -0.661584563537301,
              52.8999058903184
            ]
          ],
          [
            [
              -0.660698672397089,
              52.9000540404219
            ],
            [
              -0.660762617640618,
              52.899879906114265
            ],
            [
              -0.661091609421153,
              52.89992388946008
            ],
            [
              -0.661027673736024,
              52.90009775434588
            ],
            [
              -0.660698672397089,
              52.9000540404219
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5788827',
        'Roof.Size': '8024.441116',
        'Estimated capacity': '1123.421756',
        'Title.Number': 'LL48297',
        Tenure: 'Freehold',
        'Property Address': 'land on the South West side of Hornsby Road, Grantham (NG31 7XT)',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'CORPACQ PROPERTIES LIMITED',
        'Company Registration No. (1)': '08224887',
        'Date Proprietor Added': '13/11/2019',
        POSTCODE_2: 'NG31 7XD',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '8',
        'Capital Employed': '7101726',
        Cash: '25254',
        Charges: 'Outstanding',
        'Cost of Sales': '142500',
        'Cost of Sales Delta': '35422',
        'Cost of Sales Growth': '33.0805581',
        Country: 'GB',
        'Credit Risk': 'Not Rated',
        'Current Assets': '121233',
        'Current Liabilities': '37012',
        'Debt to Capital': '99.58534688',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '606979',
        'Financial Year End': 'December',
        'Industry Keywords': 'operational management, material handling, forklift services, fork trucks, orange, plant, corporate acquisitions, plant hire, plants, acquisition, private equity firm, property, land',
        'Net Assets': '29601',
        'SIC 2007 Description': 'Letting and operating of own or leased real estate (other than Housing Association real estate and conference and exhibition services) n.e.c.',
        'Shareholder Funds': '29601',
        'Shareholders Names': 'Corpacq Finance Limited',
        Status: 'Active',
        Telephone: '0161 804 4570',
        'Trading Address': 'Corpacq House 1 Goose Green, Altrincham, Cheshire, WA14 1DW',
        'Trading Address 1': 'Corpacq House 1 Goose Green',
        'Trading Address 2': 'Altrincham',
        'Trading Address 3': 'Cheshire',
        'Trading Address 4': '0',
        Turnover: '638355',
        'Ultimate Domestic Parent Name': 'Orange UK Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/11058668',
        'Ultimate Parent Name': 'Orange UK Holdings Limited',
        'Ultimate Parent Id': 'company/gb/11058668',
        'Web Address 1': 'http://www.corpacqplc.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '84221',
        lat: 52.90220721952295,
        'long': -0.6639879598967627
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.662829202943715,
              52.9018679445051
            ],
            [
              -0.662709008029934,
              52.902294964075324
            ],
            [
              -0.665146305945618,
              52.90254667364211
            ],
            [
              -0.665266491484644,
              52.902119202129896
            ],
            [
              -0.662829202943715,
              52.9018679445051
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5788926',
        'Roof.Size': '10536.94488',
        'Estimated capacity': '1475.172283',
        'Title.Number': 'LL280697',
        Tenure: 'Freehold',
        'Property Address': 'Fenland Foods, Turnpike Close, Grantham (NG31 7XU)',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'OSCAR MAYER LIMITED',
        'Company Registration No. (1)': '01239606',
        'Date Proprietor Added': '27/06/2014',
        POSTCODE_2: 'NG31 7XN',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '44',
        'Capital Employed': '42006044',
        Cash: '1218968',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '275162314',
        'Cost of Sales Delta': '20786734',
        'Cost of Sales Growth': '8.17167041',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '75247082',
        'Current Liabilities': '70793858',
        'Debt to Capital': '62.76056694',
        'Dividends Payable': '24733766',
        'Dormant Company': 'No',
        EBITDA: '12700471',
        'Financial Year End': 'March',
        'Industry Keywords': 'food products, factory, acquisition, corporate social responsibility, cooked meats, grocery, grocer, sustainability, ready meals, prepared foods, manufacturing, meat, poultry meat, meat products, prepared dishes, prepared meals, food manufacturing, food',
        'Net Assets': '42006044',
        'SIC 2007 Description': 'Manufacture of prepared meals and dishes',
        'Shareholder Funds': '42006044',
        'Shareholders Names': 'Ontario Management Limited',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Forum 4, Parkway, Whiteley, Fareham, PO15 7AD',
        'Trading Address 1': 'Forum 4',
        'Trading Address 2': 'Parkway',
        'Trading Address 3': 'Whiteley',
        'Trading Address 4': 'Fareham',
        Turnover: '319993488',
        'Ultimate Domestic Parent Name': 'Ontario Holding Limited',
        'Ultimate Domestic Parent Id': 'company/gb/10525811',
        'Ultimate Parent Name': 'Dbag Kanada Holding Sarl',
        'Ultimate Parent Id': 'company/lu/B208088',
        'Web Address 1': 'http://www.oscarmayer.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '4453224',
        lat: 52.9010051072376,
        'long': -0.6672717546786586
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.667531397156056,
              52.90148647004371
            ],
            [
              -0.667631626550004,
              52.90152939752059
            ],
            [
              -0.667747355343619,
              52.90143054703332
            ],
            [
              -0.667647271928592,
              52.901387711189756
            ],
            [
              -0.667762237885547,
              52.901289481430894
            ],
            [
              -0.66815010704925,
              52.90146005487866
            ],
            [
              -0.668462401259454,
              52.90120059871005
            ],
            [
              -0.668224908169983,
              52.9010958105784
            ],
            [
              -0.668317514492525,
              52.9010188156741
            ],
            [
              -0.668074154959086,
              52.90091144428082
            ],
            [
              -0.667981840176718,
              52.90098862206589
            ],
            [
              -0.66766704199806,
              52.90084979303082
            ],
            [
              -0.667804061171781,
              52.90073535889227
            ],
            [
              -0.667489213256243,
              52.90059338231956
            ],
            [
              -0.667558138299431,
              52.90053715892778
            ],
            [
              -0.667359707470518,
              52.900448359783994
            ],
            [
              -0.666844082898288,
              52.900487974161344
            ],
            [
              -0.666688314183455,
              52.90059320680672
            ],
            [
              -0.666584909226984,
              52.900547276178536
            ],
            [
              -0.666404324697939,
              52.90069565151154
            ],
            [
              -0.666543073416001,
              52.90075744176057
            ],
            [
              -0.66646804177288,
              52.90081881021069
            ],
            [
              -0.666347683209064,
              52.90076333950318
            ],
            [
              -0.666140169706926,
              52.90093163958432
            ],
            [
              -0.666619405152302,
              52.901147744239715
            ],
            [
              -0.666486045114703,
              52.901259071605665
            ],
            [
              -0.666777189594897,
              52.901388648350796
            ],
            [
              -0.666868885283711,
              52.90131236354331
            ],
            [
              -0.667360970774374,
              52.90153148614003
            ],
            [
              -0.667495519053284,
              52.90151708305184
            ],
            [
              -0.667531397156056,
              52.90148647004371
            ]
          ],
          [
            [
              -0.666713045085811,
              52.900809513480105
            ],
            [
              -0.666616718050773,
              52.9008865552879
            ],
            [
              -0.666531240358634,
              52.90084747840478
            ],
            [
              -0.666627567456195,
              52.90077043666624
            ],
            [
              -0.666713045085811,
              52.900809513480105
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '5792828',
        'Roof.Size': '3852.402592',
        'Estimated capacity': '539.3363629',
        'Title.Number': 'LL92742',
        Tenure: 'Freehold',
        'Property Address': 'land and buildings on the North side of Honeypot Lane, Colsterworth, Grantham',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'WITHAM (SPECIALIST VEHICLES) LIMITED',
        'Company Registration No. (1)': '16555991',
        'Date Proprietor Added': '13/04/1993',
        POSTCODE_2: 'NG33 5LY',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '#N/A',
        'Capital Employed': '#N/A',
        Cash: '#N/A',
        Charges: '#N/A',
        'Cost of Sales': '#N/A',
        'Cost of Sales Delta': '#N/A',
        'Cost of Sales Growth': '#N/A',
        Country: '#N/A',
        'Credit Risk': '#N/A',
        'Current Assets': '#N/A',
        'Current Liabilities': '#N/A',
        'Debt to Capital': '#N/A',
        'Dividends Payable': '#N/A',
        'Dormant Company': '#N/A',
        EBITDA: '#N/A',
        'Financial Year End': '#N/A',
        'Industry Keywords': '#N/A',
        'Net Assets': '#N/A',
        'SIC 2007 Description': '#N/A',
        'Shareholder Funds': '#N/A',
        'Shareholders Names': '#N/A',
        Status: '#N/A',
        Telephone: '#N/A',
        'Trading Address': '#N/A',
        'Trading Address 1': '#N/A',
        'Trading Address 2': '#N/A',
        'Trading Address 3': '#N/A',
        'Trading Address 4': '#N/A',
        Turnover: '#N/A',
        'Ultimate Domestic Parent Name': '#N/A',
        'Ultimate Domestic Parent Id': '#N/A',
        'Ultimate Parent Name': '#N/A',
        'Ultimate Parent Id': '#N/A',
        'Web Address 1': '#N/A',
        'Web Address 2': '#N/A',
        'Web Address 3': '#N/A',
        'Web Address 4': '#N/A',
        'Working Capital': '#N/A',
        lat: 52.78966215551289,
        'long': -0.594841889299721
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.594960476496642,
              52.78916790203246
            ],
            [
              -0.594241003193458,
              52.79000365178208
            ],
            [
              -0.594722483937852,
              52.79015679384892
            ],
            [
              -0.595443151027203,
              52.78932060585204
            ],
            [
              -0.594960476496642,
              52.78916790203246
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10875055',
        'Roof.Size': '4739.252128',
        'Estimated capacity': '663.4952979',
        'Title.Number': 'LL314802',
        Tenure: 'Freehold',
        'Property Address': 'Land at Nocton Heath, Lincoln',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'BEESWAX DYSON FARMING LIMITED',
        'Company Registration No. (1)': '06970038',
        'Date Proprietor Added': '29/01/2010',
        POSTCODE_2: 'LN4 2GR',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '11',
        'Capital Employed': '529144000',
        Cash: '4000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '6414000',
        'Cost of Sales Delta': '-1515000',
        'Cost of Sales Growth': '-19.10707529',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '29393000',
        'Current Liabilities': '15532000',
        'Debt to Capital': '3.206860592',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '6809000',
        'Financial Year End': 'December',
        'Industry Keywords': 'agriculture, holding companies, energy, farming, agricultural holding companies, holding companies management, investment, investment property',
        'Net Assets': '527209000',
        'SIC 2007 Description': 'Activities of agricultural holding companies',
        'Shareholder Funds': '527209000',
        'Shareholders Names': 'New Beeswax Dyson Farming Limited',
        Status: 'Active',
        Telephone: '01526 322058',
        'Trading Address': 'The Estate Office Cyclone Way, Nocton, Lincoln Ln4 2gr, LN4 2GR',
        'Trading Address 1': 'The Estate Office Cyclone Way',
        'Trading Address 2': 'Nocton',
        'Trading Address 3': 'Lincoln Ln4 2gr',
        'Trading Address 4': '0',
        Turnover: '21923000',
        'Ultimate Domestic Parent Name': 'Weybourne Group Limited',
        'Ultimate Domestic Parent Id': 'company/gb/08445070',
        'Ultimate Parent Name': 'Weybourne Group Limited',
        'Ultimate Parent Id': 'company/gb/08445070',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '13861000',
        lat: 53.164689264726626,
        'long': -0.389727948605384
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.389783547126598,
              53.16416566303356
            ],
            [
              -0.389162456095065,
              53.164289506473125
            ],
            [
              -0.389672246200991,
              53.16521283121677
            ],
            [
              -0.390293498726016,
              53.16508898724774
            ],
            [
              -0.389783547126598,
              53.16416566303356
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10880146',
        'Roof.Size': '10445.23528',
        'Estimated capacity': '1462.332939',
        'Title.Number': 'LL330403',
        Tenure: 'Freehold',
        'Property Address': 'Nocton Nurseries, Nocton (LN4 2AB)',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'MUNKS AGRICULTURAL CONTRACTORS LIMITED',
        'Company Registration No. (1)': '06525585',
        'Date Proprietor Added': '21/12/2011',
        POSTCODE_2: 'LN4 2HA',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '12',
        'Capital Employed': '10346261',
        Cash: '540',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '2778312',
        'Current Liabilities': '2939671',
        'Debt to Capital': '41.85555819',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'September',
        'Industry Keywords': 'landscape gardening, crop farming, farming, crops, agricultural services',
        'Net Assets': '7725031',
        'SIC 2007 Description': 'Support activities for crop production',
        'Shareholder Funds': '7725031',
        'Shareholders Names': 'Damian Wesley Munks Mr, Stefan Munks Mr',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Wood Lane, South Kyme, Lincoln, Lincolnshire, LN4 4AB',
        'Trading Address 1': 'Wood Lane',
        'Trading Address 2': 'South Kyme',
        'Trading Address 3': 'Lincoln',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-161359',
        lat: 53.155281569100275,
        'long': -0.41974245835916363
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.419866477800179,
              53.15590140958905
            ],
            [
              -0.420848131348865,
              53.15523443470009
            ],
            [
              -0.419730463588731,
              53.154640661377975
            ],
            [
              -0.418504885790936,
              53.15547429720049
            ],
            [
              -0.41879929851097,
              53.15563076467892
            ],
            [
              -0.418925234803634,
              53.15554505489783
            ],
            [
              -0.418840154650758,
              53.1554997850576
            ],
            [
              -0.418958727047043,
              53.15541910171336
            ],
            [
              -0.419866477800179,
              53.15590140958905
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '6074200',
        'Roof.Size': '3579.785983',
        'Estimated capacity': '501.1700376',
        'Title.Number': 'LL285623',
        Tenure: 'Freehold',
        'Property Address': 'land lying to the north of Morkery Lane, Castle Bytham, Grantham',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'ANDIGESTION LTD',
        'Company Registration No. (1)': '01847506',
        'Date Proprietor Added': '15/07/2013',
        POSTCODE_2: 'NG33 4SW',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '36',
        'Capital Employed': '26601900',
        Cash: '1426136',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '7284214',
        'Cost of Sales Delta': '1406418',
        'Cost of Sales Growth': '23.92764227',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '7924336',
        'Current Liabilities': '1554490',
        'Debt to Capital': '85.80819487',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '2720050',
        'Financial Year End': 'March',
        'Industry Keywords': 'commercial waste collection, environmental waste management, commercial waste, waste disposal, energy, energy sector, renewable, renewable energy sector, farmers, food, wood chip, land, local authorities, plant, food waste, plants, organic waste, nitrogen compounds, manufacturing, fertilisers, waste management, waste management services, renewable energy, industrial waste, anaerobic digestion, waste',
        'Net Assets': '3995900',
        'SIC 2007 Description': 'Manufacture of fertilisers and nitrogen compounds',
        'Shareholder Funds': '3995900',
        'Shareholders Names': 'Peter Herbert Prior, Summerleaze Ltd',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': '7 Summerleaze Road, Maidenhead, Berkshire, SL6 8SP',
        'Trading Address 1': '7 Summerleaze Road',
        'Trading Address 2': 'Maidenhead',
        'Trading Address 3': 'Berkshire',
        'Trading Address 4': '0',
        Turnover: '11550950',
        'Ultimate Domestic Parent Name': 'Summerleaze Limited',
        'Ultimate Domestic Parent Id': 'company/gb/01738920',
        'Ultimate Parent Name': 'Summerleaze Limited',
        'Ultimate Parent Id': 'company/gb/01738920',
        'Web Address 1': 'http://www.andigestion.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '6369846',
        lat: 52.764214819308236,
        'long': -0.5642011457744727
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.563357334213743,
              52.7641975502028
            ],
            [
              -0.563358985084734,
              52.764156394722356
            ],
            [
              -0.565156549736907,
              52.76423982198887
            ],
            [
              -0.565172232063725,
              52.76411441750272
            ],
            [
              -0.563281737517153,
              52.764026718489006
            ],
            [
              -0.563245525616627,
              52.76431639621558
            ],
            [
              -0.565134408852907,
              52.76440389644548
            ],
            [
              -0.565149872266197,
              52.76428064698344
            ],
            [
              -0.563357334213743,
              52.7641975502028
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10880155',
        'Roof.Size': '5543.232335',
        'Estimated capacity': '776.0525269',
        'Title.Number': 'LL330403',
        Tenure: 'Freehold',
        'Property Address': 'Nocton Nurseries, Nocton (LN4 2AB)',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'MUNKS AGRICULTURAL CONTRACTORS LIMITED',
        'Company Registration No. (1)': '06525585',
        'Date Proprietor Added': '21/12/2011',
        POSTCODE_2: 'LN4 2HA',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '12',
        'Capital Employed': '10346261',
        Cash: '540',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '2778312',
        'Current Liabilities': '2939671',
        'Debt to Capital': '41.85555819',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'September',
        'Industry Keywords': 'landscape gardening, crop farming, farming, crops, agricultural services',
        'Net Assets': '7725031',
        'SIC 2007 Description': 'Support activities for crop production',
        'Shareholder Funds': '7725031',
        'Shareholders Names': 'Damian Wesley Munks Mr, Stefan Munks Mr',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Wood Lane, South Kyme, Lincoln, Lincolnshire, LN4 4AB',
        'Trading Address 1': 'Wood Lane',
        'Trading Address 2': 'South Kyme',
        'Trading Address 3': 'Lincoln',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-161359',
        lat: 53.15629438918207,
        'long': -0.4187986957902605
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.418005267820153,
              53.1561725588183
            ],
            [
              -0.41891584600651,
              53.15678157888585
            ],
            [
              -0.419592123992551,
              53.15641621595687
            ],
            [
              -0.418681543752138,
              53.15580720101086
            ],
            [
              -0.418005267820153,
              53.1561725588183
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11030378',
        'Roof.Size': '10113.72785',
        'Estimated capacity': '1415.921899',
        'Title.Number': 'LL224166',
        Tenure: 'Freehold',
        'Property Address': 'Havenside, Fishtoft Road, Boston (PE21 0AH)',
        District: 'BOSTON',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'CITICLIENT (CPF) NOMINEES LIMITED',
        'Company Registration No. (1)': '03999261',
        'Date Proprietor Added': '',
        POSTCODE_2: 'PE21 0QR',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '20',
        'Capital Employed': '2',
        Cash: '0',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Not Rated',
        'Current Assets': '2',
        'Current Liabilities': '0',
        'Debt to Capital': '0',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': '0',
        'Net Assets': '2',
        'SIC 2007 Description': 'Non-trading company',
        'Shareholder Funds': '2',
        'Shareholders Names': 'Citibank Europe Plc',
        Status: 'Active',
        Telephone: '020 7986 6000',
        'Trading Address': '33 Canada Square, Canary Wharf, London, E14 5LB',
        'Trading Address 1': '33 Canada Square',
        'Trading Address 2': 'Canary Wharf',
        'Trading Address 3': 'London',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'Citibank UK Limited',
        'Ultimate Domestic Parent Id': 'company/gb/11283101',
        'Ultimate Parent Name': 'Citibank Overseas Investments Corp',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '2',
        lat: 52.95956000494233,
        'long': 0.006478694515640301
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.005492444029202,
              52.959699799881484
            ],
            [
              0.005969707722509,
              52.95898408408011
            ],
            [
              0.00749341815472,
              52.95936959044125
            ],
            [
              0.006931770533037,
              52.96017889259254
            ],
            [
              0.006540467540975,
              52.96008210677266
            ],
            [
              0.006604024102829,
              52.95998842907545
            ],
            [
              0.00588196390489,
              52.95980965577773
            ],
            [
              0.005819269562402,
              52.95990268917733
            ],
            [
              0.00554879370537,
              52.959836407128094
            ],
            [
              0.005620114694556,
              52.95973351829237
            ],
            [
              0.005492444029202,
              52.959699799881484
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10651827',
        'Roof.Size': '8256.224164',
        'Estimated capacity': '1155.871383',
        'Title.Number': 'LL378611',
        Tenure: 'Freehold',
        'Property Address': 'land at Peacefields Industrial Estate, Louth Road, Holton Le Clay, Grimsby (DN36 5HS)',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'LIMAGRAIN UK LIMITED',
        'Company Registration No. (1)': '01305690',
        'Date Proprietor Added': '11/12/2017',
        POSTCODE_2: 'DN36 5EE',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '43',
        'Capital Employed': '30349303',
        Cash: '14566045',
        Charges: '0',
        'Cost of Sales': '39080948',
        'Cost of Sales Delta': '881432',
        'Cost of Sales Growth': '2.307442848',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '28754077',
        'Current Liabilities': '5615841',
        'Debt to Capital': '50.3608188',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '1659622',
        'Financial Year End': 'June',
        'Industry Keywords': 'mustard, oilseeds, cereals, barley, agricultural, food, maize, farmers, crops, live animals, agricultural raw materials, semi-finished goods, textile raw materials, merchants, agricultural merchants, plants, plant, seeds',
        'Net Assets': '17852803',
        'SIC 2007 Description': 'Agents involved in the sale of agricultural raw materials, live animals, textile raw materials and semi-finished goods',
        'Shareholder Funds': '17852803',
        'Shareholders Names': 'Limagrain Verneuil Holding Sa',
        Status: 'Active',
        Telephone: '01472 371471',
        'Trading Address': 'Joseph Nickerson Research Centre, Rothwell, Market Rasen, Lincolnshire, LN7 6DT',
        'Trading Address 1': 'Joseph Nickerson Research Centre',
        'Trading Address 2': 'Rothwell',
        'Trading Address 3': 'Market Rasen',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '50391689',
        'Ultimate Domestic Parent Name': 'Limagrain UK Limited',
        'Ultimate Domestic Parent Id': 'company/gb/01305690',
        'Ultimate Parent Name': 'Groupe Limagrain Holding',
        'Ultimate Parent Id': 'company/fr/322791039',
        'Web Address 1': 'http://www.lgseeds.co.uk',
        'Web Address 2': 'http://www.nickerson.co.uk',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '23138236',
        lat: 53.50244418253124,
        'long': -0.06562716125581997
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.066347987304683,
              53.50244577020906
            ],
            [
              -0.066309504533808,
              53.50253261927477
            ],
            [
              -0.066093264676139,
              53.5024964871654
            ],
            [
              -0.066395444580292,
              53.501870195798595
            ],
            [
              -0.066276026164197,
              53.50185028392165
            ],
            [
              -0.066152487913624,
              53.502106384976756
            ],
            [
              -0.065952868686274,
              53.50207285889805
            ],
            [
              -0.066057624375286,
              53.50185178440589
            ],
            [
              -0.065802642339924,
              53.50180891169873
            ],
            [
              -0.065589964702552,
              53.50226422406671
            ],
            [
              -0.064872613126586,
              53.5021432942066
            ],
            [
              -0.064622055400606,
              53.502671888541165
            ],
            [
              -0.065867796786161,
              53.502881651638084
            ],
            [
              -0.065905807671317,
              53.50279857083008
            ],
            [
              -0.066303375902868,
              53.50286271896291
            ],
            [
              -0.066436640636591,
              53.502581783220066
            ],
            [
              -0.066591668244436,
              53.502607844703135
            ],
            [
              -0.066644864048521,
              53.50249588205299
            ],
            [
              -0.066347987304683,
              53.50244577020906
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10741318',
        'Roof.Size': '12752.24044',
        'Estimated capacity': '1785.313662',
        'Title.Number': 'LL297065',
        Tenure: 'Freehold',
        'Property Address': 'Cherry Valley Farm, Caistor',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'FACCENDA FOODS (LINCS) LIMITED',
        'Company Registration No. (1)': '08583135',
        'Date Proprietor Added': '16/07/2014',
        POSTCODE_2: 'LN7 6QB',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '7',
        'Capital Employed': '3495718',
        Cash: '258418',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '35076513',
        'Cost of Sales Delta': '3329166.231',
        'Cost of Sales Growth': '10.48643924',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '14168944',
        'Current Liabilities': '14953686',
        'Debt to Capital': '81.0524069',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '189619',
        'Financial Year End': 'June',
        'Industry Keywords': 'manufacturing, meat products, meat preservation, foodservice, poultry meat',
        'Net Assets': '3495718',
        'SIC 2007 Description': 'Processing and preserving of poultry meat',
        'Shareholder Funds': '3495718',
        'Shareholders Names': 'Faccenda Foods Limited',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': '1 Willow Road, Brackley, Northamptonshire, NN13 7EX',
        'Trading Address 1': '1 Willow Road',
        'Trading Address 2': 'Brackley',
        'Trading Address 3': 'Northamptonshire',
        'Trading Address 4': '0',
        Turnover: '37710496',
        'Ultimate Domestic Parent Name': 'Cargill UK Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/07203317',
        'Ultimate Parent Name': 'Cargill Inc',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-784742',
        lat: 53.49857616020464,
        'long': -0.3304904047244002
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.330210880009542,
              53.498206060293896
            ],
            [
              -0.330217539992018,
              53.49868115960464
            ],
            [
              -0.328954244625624,
              53.498682023069655
            ],
            [
              -0.328951362071247,
              53.49901603339824
            ],
            [
              -0.328832226681921,
              53.499016794928345
            ],
            [
              -0.3288323837471,
              53.49923515260236
            ],
            [
              -0.328995870399896,
              53.49923375258171
            ],
            [
              -0.328991834644631,
              53.49902199293412
            ],
            [
              -0.329271140838974,
              53.49901978449955
            ],
            [
              -0.329275631164082,
              53.49910497802891
            ],
            [
              -0.330168105978397,
              53.4990989311595
            ],
            [
              -0.330165884569546,
              53.499001993101515
            ],
            [
              -0.330049307305231,
              53.49900288147817
            ],
            [
              -0.330044900343539,
              53.4988081068988
            ],
            [
              -0.330196194156304,
              53.49880617531302
            ],
            [
              -0.330199969109612,
              53.498959499366464
            ],
            [
              -0.331535238225695,
              53.49895351450439
            ],
            [
              -0.331532622936122,
              53.49856701860017
            ],
            [
              -0.331201470957859,
              53.49856886803383
            ],
            [
              -0.331199834960416,
              53.49844928458662
            ],
            [
              -0.331543205336067,
              53.49844724613934
            ],
            [
              -0.331540312477003,
              53.49827901185798
            ],
            [
              -0.331161505175567,
              53.498281274678455
            ],
            [
              -0.331160650295186,
              53.4981110911343
            ],
            [
              -0.331433765540318,
              53.49810897103278
            ],
            [
              -0.331427807897495,
              53.49772314757209
            ],
            [
              -0.330845167414157,
              53.49772562010294
            ],
            [
              -0.330855734396467,
              53.498112946317534
            ],
            [
              -0.330376007982052,
              53.498112898331634
            ],
            [
              -0.330375087860769,
              53.4982053876478
            ],
            [
              -0.330210880009542,
              53.498206060293896
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10803813',
        'Roof.Size': '4713.149326',
        'Estimated capacity': '659.8409056',
        'Title.Number': 'LL197375',
        Tenure: 'Freehold',
        'Property Address': 'Greenways Caravan Site, South Road, Chapel St Leonards, Skegness',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'BLUE ANCHOR LEISURE LIMITED',
        'Company Registration No. (1)': '01091655',
        'Date Proprietor Added': '09/04/2001',
        POSTCODE_2: 'PE24 5TR',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '47',
        'Capital Employed': '68837000',
        Cash: '15000',
        Charges: 'Outstanding, Partially Satisfied, Satisfied',
        'Cost of Sales': '17354000',
        'Cost of Sales Delta': '1023000',
        'Cost of Sales Growth': '6.264160186',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '9159000',
        'Current Liabilities': '17620000',
        'Debt to Capital': '29.75814567',
        'Dividends Payable': '1000000',
        'Dormant Company': 'No',
        EBITDA: '3345000',
        'Financial Year End': 'December',
        'Industry Keywords': 'resort, resorts, catering, special events, caravan hire, events, family entertainment, accommodation booking, used caravans, entertainment, leisure, land, land development, recreational vehicle parks, trailer parks, chapel, camping sites, camping, family holidays, caravans, parks, holiday',
        'Net Assets': '60729000',
        'SIC 2007 Description': 'Camping grounds, recreational vehicle parks and trailer parks',
        'Shareholder Funds': '60729000',
        'Shareholders Names': 'Blue Anchor Leisure Holdings Ltd',
        Status: 'Active',
        Telephone: '01754 897897',
        'Trading Address': 'Golden Anchor Supermarket, Trunch Lane, Chapel St. Leonards, Skegness, Lincolnshire, PE24 5TR',
        'Trading Address 1': 'Golden Anchor Supermarket',
        'Trading Address 2': 'Trunch Lane',
        'Trading Address 3': 'Chapel St. Leonards',
        'Trading Address 4': 'Skegness, Lincolnshire',
        Turnover: '22239000',
        'Ultimate Domestic Parent Name': 'Blue Anchor Leisure Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/08321590',
        'Ultimate Parent Name': 'Sw Trust',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.goldenpalmresort.co.uk',
        'Web Address 2': 'http://www.blueanchorleisure.co.uk',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-8461000',
        lat: 53.21603171918365,
        'long': 0.3344454748894308
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.334236245699537,
              53.21634064778468
            ],
            [
              0.334230394253364,
              53.21623305095156
            ],
            [
              0.334035876845556,
              53.21623686211771
            ],
            [
              0.333955666971018,
              53.21622449761218
            ],
            [
              0.333951100672529,
              53.21614052176845
            ],
            [
              0.333783816031677,
              53.21614379899452
            ],
            [
              0.33376246789763,
              53.21575392042968
            ],
            [
              0.334499226726061,
              53.21573939519622
            ],
            [
              0.334507535816708,
              53.21589216831183
            ],
            [
              0.334361648951889,
              53.21589502704287
            ],
            [
              0.334322833050782,
              53.21594146161951
            ],
            [
              0.334326227764888,
              53.21600388215218
            ],
            [
              0.334524784030825,
              53.215999991355645
            ],
            [
              0.334520995156612,
              53.2159330830787
            ],
            [
              0.33469261320546,
              53.21592962997488
            ],
            [
              0.334682346296416,
              53.21574362874507
            ],
            [
              0.33503216927599,
              53.215736682613894
            ],
            [
              0.335064447433491,
              53.21632450721114
            ],
            [
              0.334236245699537,
              53.21634064778468
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10810453',
        'Roof.Size': '7076.137575',
        'Estimated capacity': '990.6592605',
        'Title.Number': 'LL125763',
        Tenure: 'Freehold',
        'Property Address': 'Ivy House Farm, Bilsby (LN13 9PL)',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'L J FAIRBURN & SON LIMITED',
        'Company Registration No. (1)': '01182857',
        'Date Proprietor Added': '05/01/1996',
        POSTCODE_2: 'LN13 9PL',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '46',
        'Capital Employed': '31951256',
        Cash: '10208',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '86666012',
        'Cost of Sales Delta': '7685602',
        'Cost of Sales Growth': '9.731023174',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '16141825',
        'Current Liabilities': '13836463',
        'Debt to Capital': '67.19940996',
        'Dividends Payable': '15000',
        'Dormant Company': 'No',
        EBITDA: '3545039',
        'Financial Year End': 'September',
        'Industry Keywords': 'poultry meat, poultry products, dinner, managing director, family business, chicken, charity, charities, free range eggs, free range, charity challenge, recipes, farming, poultry farming, poultry, poultry farmers, egg',
        'Net Assets': '15018642',
        'SIC 2007 Description': 'Raising of poultry',
        'Shareholder Funds': '15018642',
        'Shareholders Names': 'Caroline Mary Fairburn-Wright, Sarah Hall, Daniel Fairburn, S.H. Fairburn',
        Status: 'Active',
        Telephone: '01507 462264',
        'Trading Address': 'Ivy House Farm Office, Farlesthorpe Road Bilsby, Alford, Lincolnshire, LN13 9PL',
        'Trading Address 1': 'Ivy House Farm Office',
        'Trading Address 2': 'Farlesthorpe Road Bilsby',
        'Trading Address 3': 'Alford',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '92743650',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.ljfairburnpoultry.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '2305362',
        lat: 53.24858589112197,
        'long': 0.1956346425112179
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.195540470738758,
              53.24918099086351
            ],
            [
              0.195648972159001,
              53.24922439482202
            ],
            [
              0.195870819429642,
              53.2490253002166
            ],
            [
              0.196001977174267,
              53.24907790641816
            ],
            [
              0.195751040368428,
              53.249303160579316
            ],
            [
              0.194670228128892,
              53.248872007062864
            ],
            [
              0.195747992018106,
              53.24789725777877
            ],
            [
              0.195967103868496,
              53.247984385953224
            ],
            [
              0.194920433109459,
              53.248931053194624
            ],
            [
              0.19502157477602,
              53.24897126671613
            ],
            [
              0.196076612050303,
              53.24802408478822
            ],
            [
              0.196285198918788,
              53.24810763024954
            ],
            [
              0.195229873985649,
              53.24905499914619
            ],
            [
              0.195332410043412,
              53.24909608578954
            ],
            [
              0.196389597652203,
              53.24814706333343
            ],
            [
              0.196598948298639,
              53.24823086389753
            ],
            [
              0.195540470738758,
              53.24918099086351
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10811945',
        'Roof.Size': '4602.778272',
        'Estimated capacity': '644.3889581',
        'Title.Number': 'LL162126',
        Tenure: 'Freehold',
        'Property Address': 'Golden Sands Caravan Park, Quebec Road, Mablethorpe',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'HAVEN LEISURE LIMITED',
        'Company Registration No. (1)': '01968698',
        'Date Proprietor Added': '04/12/1998',
        POSTCODE_2: 'LN12 1QJ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '34',
        'Capital Employed': '402871000',
        Cash: '102036000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '204181000',
        'Cost of Sales Delta': '12765000',
        'Cost of Sales Growth': '6.668721528',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '184464000',
        'Current Liabilities': '513702000',
        'Debt to Capital': '74.80135243',
        'Dividends Payable': '85000000',
        'Dormant Company': 'No',
        EBITDA: '147024000',
        'Financial Year End': 'December',
        'Industry Keywords': 'new caravans, caravan holiday homes, used caravans, motorhomes, camping equipment, touring caravan, steps, leisure activities, luxury lodges, home owners, landscape, investment, flume, courses, trailer, trailers, golf, pool, static caravans, holiday homes, tent, family holidays, trailer tents, resorts, golf course, homes for sale, accommodation, caravan sales, recreational vehicle parks, trailer parks, camping sites, holiday park, caravan park, caravan holiday, caravans, holiday, camping, parks, leisure',
        'Net Assets': '230964000',
        'SIC 2007 Description': 'Camping grounds, recreational vehicle parks and trailer parks',
        'Shareholder Funds': '230964000',
        'Shareholders Names': 'Durlacher Nominees Ltd',
        Status: 'Active',
        Telephone: '01442 230300',
        'Trading Address': 'No1 Park Lane, Hemel Hempstead, Herts, HP2 4YL',
        'Trading Address 1': 'No1 Park Lane',
        'Trading Address 2': 'Hemel Hempstead',
        'Trading Address 3': 'Herts',
        'Trading Address 4': '0',
        Turnover: '623426000',
        'Ultimate Domestic Parent Name': 'Bourne Leisure Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/04011667',
        'Ultimate Parent Name': 'Bourne Leisure Holdings Limited',
        'Ultimate Parent Id': 'company/gb/04011667',
        'Web Address 1': 'http://www.haven.com',
        'Web Address 2': 'http://www.haven.fr',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-329238000',
        lat: 53.356002421518134,
        'long': 0.24751061324979737
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.247987667470144,
              53.35577798373066
            ],
            [
              0.247694495992363,
              53.35606949838679
            ],
            [
              0.247787171363573,
              53.35610290573503
            ],
            [
              0.247658768366629,
              53.35623011437422
            ],
            [
              0.247504674356464,
              53.356251807546556
            ],
            [
              0.247078424396763,
              53.35667610125216
            ],
            [
              0.246695788755802,
              53.35653846835766
            ],
            [
              0.247210010787992,
              53.356026748732454
            ],
            [
              0.247522030722683,
              53.35613900809075
            ],
            [
              0.247619735436602,
              53.356042406536105
            ],
            [
              0.247298941327407,
              53.35592617702266
            ],
            [
              0.247900052894162,
              53.35533253145422
            ],
            [
              0.248283502935801,
              53.355471493694544
            ],
            [
              0.248089941530665,
              53.355662595208436
            ],
            [
              0.248133503888311,
              53.35570223213306
            ],
            [
              0.248132480148818,
              53.35572275001364
            ],
            [
              0.248125048274212,
              53.35574428770066
            ],
            [
              0.248034596640266,
              53.35577880772805
            ],
            [
              0.247987667470144,
              53.35577798373066
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10818447',
        'Roof.Size': '10275.41181',
        'Estimated capacity': '1438.557653',
        'Title.Number': 'LL167497',
        Tenure: 'Freehold',
        'Property Address': 'Barfen Farm, Gayton Le Marsh',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'L J FAIRBURN & SON LIMITED',
        'Company Registration No. (1)': '01182857',
        'Date Proprietor Added': '06/11/2007',
        POSTCODE_2: 'LN13 0PF',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '46',
        'Capital Employed': '31951256',
        Cash: '10208',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '86666012',
        'Cost of Sales Delta': '7685602',
        'Cost of Sales Growth': '9.731023174',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '16141825',
        'Current Liabilities': '13836463',
        'Debt to Capital': '67.19940996',
        'Dividends Payable': '15000',
        'Dormant Company': 'No',
        EBITDA: '3545039',
        'Financial Year End': 'September',
        'Industry Keywords': 'poultry meat, poultry products, dinner, managing director, family business, chicken, charity, charities, free range eggs, free range, charity challenge, recipes, farming, poultry farming, poultry, poultry farmers, egg',
        'Net Assets': '15018642',
        'SIC 2007 Description': 'Raising of poultry',
        'Shareholder Funds': '15018642',
        'Shareholders Names': 'Caroline Mary Fairburn-Wright, Sarah Hall, Daniel Fairburn, S.H. Fairburn',
        Status: 'Active',
        Telephone: '01507 462264',
        'Trading Address': 'Ivy House Farm Office, Farlesthorpe Road Bilsby, Alford, Lincolnshire, LN13 9PL',
        'Trading Address 1': 'Ivy House Farm Office',
        'Trading Address 2': 'Farlesthorpe Road Bilsby',
        'Trading Address 3': 'Alford',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '92743650',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.ljfairburnpoultry.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '2305362',
        lat: 53.3347029404982,
        'long': 0.17339114513493425
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.174494835113579,
              53.33450393094418
            ],
            [
              0.173652706875005,
              53.33547029481619
            ],
            [
              0.1730680401336,
              53.33528846018957
            ],
            [
              0.173199706657725,
              53.33513663937677
            ],
            [
              0.173725311185255,
              53.33530013011189
            ],
            [
              0.173754711056325,
              53.33526686893243
            ],
            [
              0.172260538155389,
              53.33479448469749
            ],
            [
              0.172452548698525,
              53.33457692409236
            ],
            [
              0.173930990162873,
              53.33504428818176
            ],
            [
              0.173997830933954,
              53.33496754979306
            ],
            [
              0.17251939265703,
              53.33450612021891
            ],
            [
              0.172700907532837,
              53.33429783074454
            ],
            [
              0.174179035747946,
              53.334759173785024
            ],
            [
              0.1742481532176,
              53.33467996631284
            ],
            [
              0.172779716261309,
              53.334214222081364
            ],
            [
              0.172971711842133,
              53.33399351417412
            ],
            [
              0.174494835113579,
              53.33450393094418
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10819206',
        'Roof.Size': '4215.282422',
        'Estimated capacity': '590.1395391',
        'Title.Number': 'LL131387',
        Tenure: 'Freehold',
        'Property Address': 'Beech Grove Hall, Carlton Road, Manby, Louth (LN11 8UF)',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'ROCK PROPERTIES LIMITED',
        'Company Registration No. (1)': '00089947',
        'Date Proprietor Added': '08/04/2008',
        POSTCODE_2: 'LN11 8UF',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '114',
        'Capital Employed': '1369140',
        Cash: '0',
        Charges: 'Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Not Rated',
        'Current Assets': '4228429',
        'Current Liabilities': '2859289',
        'Debt to Capital': '67.6206',
        'Dividends Payable': '0',
        'Dormant Company': 'Yes',
        EBITDA: '0',
        'Financial Year End': 'March',
        'Industry Keywords': '0',
        'Net Assets': '1369140',
        'SIC 2007 Description': 'Non-trading company',
        'Shareholder Funds': '1369140',
        'Shareholders Names': '0',
        Status: 'Dissolved',
        Telephone: '020 7493 8484',
        'Trading Address': 'New Century Park, Po Box 53, Coventry, Warwickshire, CV3 1HJ',
        'Trading Address 1': 'New Century Park',
        'Trading Address 2': 'Po Box 53',
        'Trading Address 3': 'Coventry',
        'Trading Address 4': 'Warwickshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 53.35944399800886,
        'long': 0.09581258591701
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.095134226595379,
              53.35939185716808
            ],
            [
              0.095196974392373,
              53.359292401051555
            ],
            [
              0.095338292113684,
              53.359324082910994
            ],
            [
              0.095364206452456,
              53.359282541981514
            ],
            [
              0.095174549482962,
              53.35923966187114
            ],
            [
              0.094939277712417,
              53.35960565399208
            ],
            [
              0.094739528843996,
              53.35955926432193
            ],
            [
              0.095112316410357,
              53.35897284233065
            ],
            [
              0.095299106005043,
              53.35901550317173
            ],
            [
              0.095181880180484,
              53.359199166510216
            ],
            [
              0.095390102521465,
              53.35924675518685
            ],
            [
              0.09542970920696,
              53.35918411619923
            ],
            [
              0.096372499410357,
              53.359399777013195
            ],
            [
              0.09633110507729,
              53.35946577415493
            ],
            [
              0.096540831495715,
              53.35951333441663
            ],
            [
              0.096658008161316,
              53.359328771540824
            ],
            [
              0.096844354750759,
              53.359371527671065
            ],
            [
              0.096470364491905,
              53.359957526815094
            ],
            [
              0.096265529126763,
              53.35991167856099
            ],
            [
              0.096499426667958,
              53.359542381480786
            ],
            [
              0.096446988380692,
              53.35953035669352
            ],
            [
              0.096357742246493,
              53.35967163460984
            ],
            [
              0.095134226595379,
              53.35939185716808
            ]
          ],
          [
            [
              0.096002280042153,
              53.35942587962697
            ],
            [
              0.095905733059474,
              53.35940312174516
            ],
            [
              0.095876643641565,
              53.35944732576364
            ],
            [
              0.095973040555793,
              53.35947008630551
            ],
            [
              0.096002280042153,
              53.35942587962697
            ]
          ],
          [
            [
              0.095780887512003,
              53.35937025220845
            ],
            [
              0.095677504500808,
              53.35934599594698
            ],
            [
              0.095645349536665,
              53.359395108533306
            ],
            [
              0.095748732650028,
              53.35941936482148
            ],
            [
              0.095780887512003,
              53.35937025220845
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10819233',
        'Roof.Size': '3507.052122',
        'Estimated capacity': '490.9872971',
        'Title.Number': 'LL361713',
        Tenure: 'Freehold',
        'Property Address': 'Land on the west side of Manby Park, Manby, Louth',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'B & T ASSOCIATES LIMITED',
        'Company Registration No. (1)': '01987654',
        'Date Proprietor Added': '10/11/2015',
        POSTCODE_2: 'LN11 8EB',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '34',
        'Capital Employed': '3216714',
        Cash: '576787',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '4642948',
        'Current Liabilities': '2566827',
        'Debt to Capital': '56.71326615',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'April',
        'Industry Keywords': 'lighting products, outdoor lighting, led lighting products, decorative lighting, lighting fixtures, led lighting, lighting suppliers, lighting accessories, retail lighting, led lighting systems, electric lighting, mailing list, manufacturing, electric lighting equipment, wholesale, retail, lamps, lighting equipment, lighting wholesale, lighting retail, lighting, lighting supply, electric lamps',
        'Net Assets': '2503506',
        'SIC 2007 Description': 'Manufacture of electric lighting equipment',
        'Shareholder Funds': '2503506',
        'Shareholders Names': 'Mark Chapman, Benjamin Chapman, Nathan Chapman, Jacob Chapman, Joshua Chapman',
        Status: 'Active',
        Telephone: '01507 328031',
        'Trading Address': 'Davis House Manby Park, Manby, Louth, Lincolnshire Ln11 8u, T, LN11 8UT',
        'Trading Address 1': 'Davis House Manby Park',
        'Trading Address 2': 'Manby',
        'Trading Address 3': 'Louth',
        'Trading Address 4': 'Lincolnshire Ln11 8u, T',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.bt-associates.co.uk',
        'Web Address 2': 'http://www.bt-lighting.co.uk',
        'Web Address 3': 'http://www.lumineux.co.uk',
        'Web Address 4': '0',
        'Working Capital': '2076121',
        lat: 53.36089406084765,
        'long': 0.09224644855205825
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.091708070795198,
              53.36111310961823
            ],
            [
              0.092098951952008,
              53.36051451319876
            ],
            [
              0.092785072673879,
              53.36067518617691
            ],
            [
              0.09239359034129,
              53.361273615629834
            ],
            [
              0.091708070795198,
              53.36111310961823
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10819235',
        'Roof.Size': '3813.023907',
        'Estimated capacity': '533.823347',
        'Title.Number': 'LL319110',
        Tenure: 'Freehold',
        'Property Address': 'Land on the west side of Manby Park, Manby, Louth',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'HETSE PROPERTY LIMITED',
        'Company Registration No. (1)': '10879186',
        'Date Proprietor Added': '18/12/2018',
        POSTCODE_2: 'LN11 8UT',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '3',
        'Capital Employed': '2654',
        Cash: '7955',
        Charges: '0',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Moderate',
        'Current Assets': '7955',
        'Current Liabilities': '1571081',
        'Debt to Capital': '99.83135661',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': 'leased real estate, renting, real estate',
        'Net Assets': '2654',
        'SIC 2007 Description': 'Letting and operating of own or leased real estate (other than Housing Association real estate and conference and exhibition services) n.e.c.',
        'Shareholder Funds': '2654',
        'Shareholders Names': 'Manby Storage Services Ltd',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Davis House, Manby Park, Manby, Louth, LN11 8UT',
        'Trading Address 1': 'Davis House',
        'Trading Address 2': 'Manby Park',
        'Trading Address 3': 'Manby',
        'Trading Address 4': 'Louth',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'Manby Storage Services Ltd',
        'Ultimate Domestic Parent Id': 'company/gb/11797136',
        'Ultimate Parent Name': 'Manby Storage Services Ltd',
        'Ultimate Parent Id': 'company/gb/11797136',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-1563126',
        lat: 53.360997363026925,
        'long': 0.09028287440605255
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.090495117511782,
              53.36137162039061
            ],
            [
              0.089704394371219,
              53.36118948115045
            ],
            [
              0.09007115736136,
              53.36062260033613
            ],
            [
              0.090861327976979,
              53.360805915514575
            ],
            [
              0.090495117511782,
              53.36137162039061
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10819243',
        'Roof.Size': '3904.517226',
        'Estimated capacity': '546.6324116',
        'Title.Number': 'LL319110',
        Tenure: 'Freehold',
        'Property Address': 'Land on the west side of Manby Park, Manby, Louth',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'HETSE PROPERTY LIMITED',
        'Company Registration No. (1)': '10879186',
        'Date Proprietor Added': '18/12/2018',
        POSTCODE_2: 'LN11 8FA',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '3',
        'Capital Employed': '2654',
        Cash: '7955',
        Charges: '0',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Moderate',
        'Current Assets': '7955',
        'Current Liabilities': '1571081',
        'Debt to Capital': '99.83135661',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': 'leased real estate, renting, real estate',
        'Net Assets': '2654',
        'SIC 2007 Description': 'Letting and operating of own or leased real estate (other than Housing Association real estate and conference and exhibition services) n.e.c.',
        'Shareholder Funds': '2654',
        'Shareholders Names': 'Manby Storage Services Ltd',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Davis House, Manby Park, Manby, Louth, LN11 8UT',
        'Trading Address 1': 'Davis House',
        'Trading Address 2': 'Manby Park',
        'Trading Address 3': 'Manby',
        'Trading Address 4': 'Louth',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'Manby Storage Services Ltd',
        'Ultimate Domestic Parent Id': 'company/gb/11797136',
        'Ultimate Parent Name': 'Manby Storage Services Ltd',
        'Ultimate Parent Id': 'company/gb/11797136',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-1563126',
        lat: 53.362425775590744,
        'long': 0.08985634189335352
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.090282860275314,
              53.36273377789923
            ],
            [
              0.089416803648438,
              53.362726469552726
            ],
            [
              0.089430593575494,
              53.36211758274099
            ],
            [
              0.090295136206688,
              53.362124917412345
            ],
            [
              0.090282860275314,
              53.36273377789923
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10819275',
        'Roof.Size': '3824.693077',
        'Estimated capacity': '535.4570308',
        'Title.Number': 'LL376235',
        Tenure: 'Freehold',
        'Property Address': 'Hanger Number 3, Manby Park, Louth (LN11 8EB)',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'B & T ASSOCIATES LIMITED',
        'Company Registration No. (1)': '01987654',
        'Date Proprietor Added': '01/09/2017',
        POSTCODE_2: 'LN11 8UT',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '34',
        'Capital Employed': '3216714',
        Cash: '576787',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '4642948',
        'Current Liabilities': '2566827',
        'Debt to Capital': '56.71326615',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'April',
        'Industry Keywords': 'lighting products, outdoor lighting, led lighting products, decorative lighting, lighting fixtures, led lighting, lighting suppliers, lighting accessories, retail lighting, led lighting systems, electric lighting, mailing list, manufacturing, electric lighting equipment, wholesale, retail, lamps, lighting equipment, lighting wholesale, lighting retail, lighting, lighting supply, electric lamps',
        'Net Assets': '2503506',
        'SIC 2007 Description': 'Manufacture of electric lighting equipment',
        'Shareholder Funds': '2503506',
        'Shareholders Names': 'Mark Chapman, Benjamin Chapman, Nathan Chapman, Jacob Chapman, Joshua Chapman',
        Status: 'Active',
        Telephone: '01507 328031',
        'Trading Address': 'Davis House Manby Park, Manby, Louth, Lincolnshire Ln11 8u, T, LN11 8UT',
        'Trading Address 1': 'Davis House Manby Park',
        'Trading Address 2': 'Manby',
        'Trading Address 3': 'Louth',
        'Trading Address 4': 'Lincolnshire Ln11 8u, T',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.bt-associates.co.uk',
        'Web Address 2': 'http://www.bt-lighting.co.uk',
        'Web Address 3': 'http://www.lumineux.co.uk',
        'Web Address 4': '0',
        'Working Capital': '2076121',
        lat: 53.35975555055054,
        'long': 0.09148318769275601
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.091501818253798,
              53.35935992322072
            ],
            [
              0.092138986079066,
              53.35969667949795
            ],
            [
              0.091462717763766,
              53.3601510389914
            ],
            [
              0.090828286287537,
              53.35981495039244
            ],
            [
              0.091501818253798,
              53.35935992322072
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10835600',
        'Roof.Size': '4407.351625',
        'Estimated capacity': '617.0292275',
        'Title.Number': 'LL147905',
        Tenure: 'Freehold',
        'Property Address': 'Ludborough House Farm, Ludborough',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'R. CAUDWELL (PRODUCE) LIMITED',
        'Company Registration No. (1)': '00787985',
        'Date Proprietor Added': '09/01/1995',
        POSTCODE_2: 'DN36 5SG',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '56',
        'Capital Employed': '14329126',
        Cash: '2047487',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '5934340',
        'Current Liabilities': '786116',
        'Debt to Capital': '10.3989602',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'September',
        'Industry Keywords': 'agriculture, seeds, oil seeds, leguminous crops, crop farming, crops, cereals, farming',
        'Net Assets': '13543414',
        'SIC 2007 Description': 'Growing of cereals (except rice), leguminous crops and oil seeds',
        'Shareholder Funds': '13543414',
        'Shareholders Names': 'Struan Thomas Caudwell Abbott, Struan Thomas Caudwell Abbott & Julia Clare Vincent & Simon Martin Scriven Williams & Jarred Thomas Wright, Rosemary Anne Abbott & Struan Thomas Caudwell Abbott & Jarred Thomas Wright',
        Status: 'Active',
        Telephone: '01472 840381',
        'Trading Address': 'Estate Office, Livesey Road, Ludborough, Grimsby, South Humberside, DN36 5SG',
        'Trading Address 1': 'Estate Office',
        'Trading Address 2': 'Livesey Road',
        'Trading Address 3': 'Ludborough',
        'Trading Address 4': 'Grimsby, South Humberside',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '5148224',
        lat: 53.43907414239076,
        'long': -0.05387750761576869
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.053649542598571,
              53.43946043795292
            ],
            [
              -0.053892548684837,
              53.4393879828083
            ],
            [
              -0.054019169362692,
              53.439355074834886
            ],
            [
              -0.054634409056977,
              53.43916623871269
            ],
            [
              -0.054354091081419,
              53.438836144677666
            ],
            [
              -0.054236192647663,
              53.43887261129398
            ],
            [
              -0.054129310300977,
              53.43874707768109
            ],
            [
              -0.053409777439738,
              53.43896136122102
            ],
            [
              -0.053168966324933,
              53.43867299251289
            ],
            [
              -0.053024560958233,
              53.43871621786099
            ],
            [
              -0.053649542598571,
              53.43946043795292
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10820103',
        'Roof.Size': '5130.303023',
        'Estimated capacity': '718.2424232',
        'Title.Number': 'LL268612',
        Tenure: 'Freehold',
        'Property Address': 'Land to the rear of 17 and 19 Reynard Street, Spilsby (PE23 5JB)',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'TONG ENGINEERING LIMITED',
        'Company Registration No. (1)': '01957036',
        'Date Proprietor Added': '12/05/2006',
        POSTCODE_2: 'PE23 5DW',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '34',
        'Capital Employed': '5868090',
        Cash: '1418227',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '10739376',
        'Cost of Sales Delta': '331434',
        'Cost of Sales Growth': '3.18443358',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '6635325',
        'Current Liabilities': '3074836',
        'Debt to Capital': '45.23722996',
        'Dividends Payable': '31446',
        'Dormant Company': 'No',
        EBITDA: '1090414',
        'Financial Year End': 'January',
        'Industry Keywords': 'agricultural machine, agricultural tractors, machinery sales, agricultural industry, agricultural equipment, farm machinery, agricultural implements, farm equipment, machinery, recycling, recycling equipment, forestry machinery, manufacturing, agricultural machinery sales, agricultural machinery repair, agricultural machinery services, agricultural machinery',
        'Net Assets': '4897394',
        'SIC 2007 Description': 'Manufacture of agricultural and forestry machinery (other than agricultural tractors)',
        'Shareholder Funds': '4897394',
        'Shareholders Names': 'Ian Wheeler, Alice Victoria Tong, Charles Edmund Tong, Edward Charles Tong',
        Status: 'Active',
        Telephone: '01790 752771',
        'Trading Address': 'Ashby Road, Spilsby, Lincolnshire, PE23 5DW',
        'Trading Address 1': 'Ashby Road',
        'Trading Address 2': 'Spilsby',
        'Trading Address 3': 'Lincolnshire',
        'Trading Address 4': '0',
        Turnover: '14390008',
        'Ultimate Domestic Parent Name': 'Tong Engineering Limited',
        'Ultimate Domestic Parent Id': 'company/gb/01957036',
        'Ultimate Parent Name': 'Tong Engineering Limited',
        'Ultimate Parent Id': 'company/gb/01957036',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '3560489',
        lat: 53.17539965366456,
        'long': 0.09754541131403459
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.096467990004566,
              53.17566307932391
            ],
            [
              0.09638228964936,
              53.17556883814051
            ],
            [
              0.096639160181537,
              53.17548474958443
            ],
            [
              0.096588552421345,
              53.17542890989838
            ],
            [
              0.096785662937035,
              53.175386690305864
            ],
            [
              0.096821033395136,
              53.17544612470054
            ],
            [
              0.097016969573595,
              53.175404374929045
            ],
            [
              0.097122346160623,
              53.17541420718685
            ],
            [
              0.0971988721796,
              53.17516391021502
            ],
            [
              0.097244401571466,
              53.17511563814467
            ],
            [
              0.097079636396625,
              53.175100018910136
            ],
            [
              0.097122428505189,
              53.17493734488135
            ],
            [
              0.097287944996098,
              53.17495304072734
            ],
            [
              0.097245395553849,
              53.17511454177003
            ],
            [
              0.097265178266764,
              53.17509369485287
            ],
            [
              0.09774492942499,
              53.17513073783058
            ],
            [
              0.098101168033119,
              53.175086791907056
            ],
            [
              0.098138631360565,
              53.17539450917258
            ],
            [
              0.098124158720155,
              53.175459766110905
            ],
            [
              0.098551220617048,
              53.17549944231162
            ],
            [
              0.098491067075061,
              53.17573245949465
            ],
            [
              0.098229302963035,
              53.17570704298063
            ],
            [
              0.09825259587382,
              53.1756170863218
            ],
            [
              0.097175391495484,
              53.17551693460181
            ],
            [
              0.09716088877696,
              53.17556933539786
            ],
            [
              0.097121915414232,
              53.17561704245583
            ],
            [
              0.096749240892964,
              53.175695349097204
            ],
            [
              0.096686069556951,
              53.175590552123786
            ],
            [
              0.096467990004566,
              53.17566307932391
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10829259',
        'Roof.Size': '3595.002346',
        'Estimated capacity': '503.3003284',
        'Title.Number': 'LL198865',
        Tenure: 'Freehold',
        'Property Address': 'land on the east side of Grimsby Road, Louth',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'FENLAND LAUNDRIES LIMITED',
        'Company Registration No. (1)': '00176558',
        'Date Proprietor Added': '22/05/2001',
        POSTCODE_2: 'LN11 0LS',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '99',
        'Capital Employed': '15686967',
        Cash: '695749',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '24074618',
        'Cost of Sales Delta': '1164208',
        'Cost of Sales Growth': '5.081567724',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '13431889',
        'Current Liabilities': '4930574',
        'Debt to Capital': '24.90735922',
        'Dividends Payable': '300000',
        'Dormant Company': 'No',
        EBITDA: '2841536',
        'Financial Year End': 'December',
        'Industry Keywords': 'energy, construction, monofilament, cleanrooms, consumables, garments, fabrics, textile fibres, food sector, automotive sector, mop, jackets, coats, trousers, microfibre, coat, mops, cleaning services, polyester, fur products, clothing, workwear, washing, dry cleaning, coveralls, laundry, textile, workwear rental, textiles',
        'Net Assets': '15482256',
        'SIC 2007 Description': 'Washing and (dry-)cleaning of textile and fur products',
        'Shareholder Funds': '15482256',
        'Shareholders Names': 'Trustees of Fenland Laundries Legacy Fund, S.J. Fry, Trustees of D.N. Fry 1994 Settlement',
        Status: 'Active',
        Telephone: '07767 674121',
        'Trading Address': 'Roman Bank, Skegness, Lincolnshire, PE25 1SQ',
        'Trading Address 1': 'Roman Bank',
        'Trading Address 2': 'Skegness',
        'Trading Address 3': 'Lincolnshire',
        'Trading Address 4': '0',
        Turnover: '32146483',
        'Ultimate Domestic Parent Name': 'Micronclean Limited',
        'Ultimate Domestic Parent Id': 'company/gb/00176558',
        'Ultimate Parent Name': 'Micronclean Limited',
        'Ultimate Parent Id': 'company/gb/00176558',
        'Web Address 1': 'http://www.micronclean.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '8501315',
        lat: 53.384900009668016,
        'long': -0.012582335698783422
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.012254256225373,
              53.38477045380028
            ],
            [
              -0.011981676718043,
              53.384853478186784
            ],
            [
              -0.012323579212183,
              53.38525455835312
            ],
            [
              -0.013213712182525,
              53.38498151361005
            ],
            [
              -0.012867696711787,
              53.38457820992
            ],
            [
              -0.01257636649841,
              53.38466676707942
            ],
            [
              -0.012513193929071,
              53.3845928951162
            ],
            [
              -0.012411123917055,
              53.384611603139746
            ],
            [
              -0.012300996717909,
              53.38464510053203
            ],
            [
              -0.012188697177666,
              53.38469294586655
            ],
            [
              -0.012254256225373,
              53.38477045380028
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10829491',
        'Roof.Size': '4667.010402',
        'Estimated capacity': '653.3814563',
        'Title.Number': 'LL232602',
        Tenure: 'Freehold',
        'Property Address': 'land on the East side of Tattershall Way, Louth',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'MONCASTER WIRE PRODUCTS LIMITED',
        'Company Registration No. (1)': '02177443',
        'Date Proprietor Added': '19/09/2003',
        POSTCODE_2: 'LN11 0LQ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '33',
        'Capital Employed': '2995756',
        Cash: '32262',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '4142263',
        'Current Liabilities': '2563601',
        'Debt to Capital': '53.29472455',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': 'wooden fencing, agricultural products, agricultural industry, sales team, stock fencing, import agent, wire products, steel wire, wire mesh, railway sleepers, agricultural merchants, tools, wholesale, garden fencing, agricultural, garden, equestrian fencing',
        'Net Assets': '2596513',
        'SIC 2007 Description': 'Non-specialised wholesale trade',
        'Shareholder Funds': '2596513',
        'Shareholders Names': 'Metallurgica Abbruzzese Spa',
        Status: 'Active',
        Telephone: '01507 600666',
        'Trading Address': 'Cavatorta House, Tattershall Way, Fairfield Industrial Estate, Louth, Lincolnshire, LN11 0YZ',
        'Trading Address 1': 'Cavatorta House',
        'Trading Address 2': 'Tattershall Way',
        'Trading Address 3': 'Fairfield Industrial Estate',
        'Trading Address 4': 'Louth, Lincolnshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.fenceline.co.uk',
        'Web Address 2': 'http://www.fencelinesupplies.co.uk',
        'Web Address 3': 'http://www.thefenceline.co.uk',
        'Web Address 4': '0',
        'Working Capital': '1578662',
        lat: 53.38188354344038,
        'long': -0.008439535080482367
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.009118502576433,
              53.38204928912089
            ],
            [
              -0.008847463315703,
              53.38151632715348
            ],
            [
              -0.00775973838621,
              53.38172311022006
            ],
            [
              -0.00802594283823,
              53.38224664429397
            ],
            [
              -0.009118502576433,
              53.38204928912089
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10829665',
        'Roof.Size': '7085.263957',
        'Estimated capacity': '991.936954',
        'Title.Number': 'LL242920',
        Tenure: 'Freehold',
        'Property Address': 'Land on the west side of Brackenborough Road, Louth',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'COVERIS FLEXIBLES (GAINSBOROUGH) UK LIMITED',
        'Company Registration No. (1)': '04786458',
        'Date Proprietor Added': '01/03/2016',
        POSTCODE_2: 'LN11 0AS',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '17',
        'Capital Employed': '117576000',
        Cash: '19117000',
        Charges: 'Outstanding, Partially Satisfied, Satisfied',
        'Cost of Sales': '173741000',
        'Cost of Sales Delta': '1916000',
        'Cost of Sales Growth': '1.115088026',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '96504000',
        'Current Liabilities': '82007000',
        'Debt to Capital': '93.94236984',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '18907000',
        'Financial Year End': 'December',
        'Industry Keywords': 'powder coating, can manufacturing, manufacturing services, commercial manufacturing, pipe manufacturing, decorative coatings, manufacturing industry, manufacturing software, protective coatings, metal coating, industrial coatings, filmmaking, motion pictures, industrial manufacturing, manufacturing company, capital partners, private investment, investment, medical, coat, packing goods, paper, electronics, technology, design, manufacturer, retailer, plastic packing goods, food manufacturing, food, flexible films, coating, coatings, film, manufacturing',
        'Net Assets': '12090000',
        'SIC 2007 Description': 'Manufacture of plastic packing goods',
        'Shareholder Funds': '12090000',
        'Shareholders Names': 'Coveris Flexibles Holdings UK Limited',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Holland Place Wardentree Park, Pinchbeck, Spalding, Lincolnshire Pe11 3z, PE11 3ZN',
        'Trading Address 1': 'Holland Place Wardentree Park',
        'Trading Address 2': 'Pinchbeck',
        'Trading Address 3': 'Spalding',
        'Trading Address 4': 'Lincolnshire Pe11 3z',
        Turnover: '184181000',
        'Ultimate Domestic Parent Name': 'Coveris Flexibles Holdings UK Limited',
        'Ultimate Domestic Parent Id': 'company/gb/08339980',
        'Ultimate Parent Name': 'Coveris Holdings S.A',
        'Ultimate Parent Id': 'company/lu/B178003',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '14497000',
        lat: 53.37814954414594,
        'long': -0.0011116150464758317
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.000452025477075,
              53.37813781178856
            ],
            [
              -0.000296387937079,
              53.378167299170556
            ],
            [
              -0.000335751648614,
              53.37824167802782
            ],
            [
              -0.000684040623958,
              53.378175681346406
            ],
            [
              -0.00083858826393,
              53.37846793316414
            ],
            [
              -0.001100714674315,
              53.37841829254927
            ],
            [
              -0.001300023897456,
              53.37879499177947
            ],
            [
              -0.001824725625498,
              53.37869580534964
            ],
            [
              -0.001447090093725,
              53.37798222648555
            ],
            [
              -0.001637615232539,
              53.37794612961535
            ],
            [
              -0.001584162957889,
              53.377845084227175
            ],
            [
              -0.001393642436396,
              53.377881091185195
            ],
            [
              -0.001355928469777,
              53.37780997676912
            ],
            [
              -0.00154584358503,
              53.37777404963784
            ],
            [
              -0.001455928858336,
              53.377604068411095
            ],
            [
              -0.000286552299276,
              53.37782532830709
            ],
            [
              -0.000452025477075,
              53.37813781178856
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10829676',
        'Roof.Size': '4352.735695',
        'Estimated capacity': '609.3829973',
        'Title.Number': 'LL242920',
        Tenure: 'Freehold',
        'Property Address': 'Land on the west side of Brackenborough Road, Louth',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'COVERIS FLEXIBLES (GAINSBOROUGH) UK LIMITED',
        'Company Registration No. (1)': '04786458',
        'Date Proprietor Added': '01/03/2016',
        POSTCODE_2: 'LN11 0AD',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '17',
        'Capital Employed': '117576000',
        Cash: '19117000',
        Charges: 'Outstanding, Partially Satisfied, Satisfied',
        'Cost of Sales': '173741000',
        'Cost of Sales Delta': '1916000',
        'Cost of Sales Growth': '1.115088026',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '96504000',
        'Current Liabilities': '82007000',
        'Debt to Capital': '93.94236984',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '18907000',
        'Financial Year End': 'December',
        'Industry Keywords': 'powder coating, can manufacturing, manufacturing services, commercial manufacturing, pipe manufacturing, decorative coatings, manufacturing industry, manufacturing software, protective coatings, metal coating, industrial coatings, filmmaking, motion pictures, industrial manufacturing, manufacturing company, capital partners, private investment, investment, medical, coat, packing goods, paper, electronics, technology, design, manufacturer, retailer, plastic packing goods, food manufacturing, food, flexible films, coating, coatings, film, manufacturing',
        'Net Assets': '12090000',
        'SIC 2007 Description': 'Manufacture of plastic packing goods',
        'Shareholder Funds': '12090000',
        'Shareholders Names': 'Coveris Flexibles Holdings UK Limited',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Holland Place Wardentree Park, Pinchbeck, Spalding, Lincolnshire Pe11 3z, PE11 3ZN',
        'Trading Address 1': 'Holland Place Wardentree Park',
        'Trading Address 2': 'Pinchbeck',
        'Trading Address 3': 'Spalding',
        'Trading Address 4': 'Lincolnshire Pe11 3z',
        Turnover: '184181000',
        'Ultimate Domestic Parent Name': 'Coveris Flexibles Holdings UK Limited',
        'Ultimate Domestic Parent Id': 'company/gb/08339980',
        'Ultimate Parent Name': 'Coveris Holdings S.A',
        'Ultimate Parent Id': 'company/lu/B178003',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '14497000',
        lat: 53.37886099381126,
        'long': 0.00018465190763652836
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.000157732185132,
              53.37883860902997
            ],
            [
              -0.000033338461328,
              53.37886223656813
            ],
            [
              0.000074252599045,
              53.37865887418722
            ],
            [
              0.000202435370391,
              53.3786831571958
            ],
            [
              0.000247873934412,
              53.37859725884489
            ],
            [
              0.000959470234299,
              53.378731870895614
            ],
            [
              0.000721365600332,
              53.37918186453262
            ],
            [
              -0.000688644061723,
              53.37891501961449
            ],
            [
              -0.000513783961048,
              53.37858457875474
            ],
            [
              -0.000067958483213,
              53.37866889882709
            ],
            [
              -0.000157732185132,
              53.37883860902997
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10829842',
        'Roof.Size': '20359.00856',
        'Estimated capacity': '2850.261198',
        'Title.Number': 'LL240137',
        Tenure: 'Freehold',
        'Property Address': 'Land and buildings on the north side of Windsor Road, Fairfield Industrial Estate, Louth',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'DS SMITH PACKAGING LIMITED',
        'Company Registration No. (1)': '00630681',
        'Date Proprietor Added': '07/04/2004',
        POSTCODE_2: 'LN11 0LF',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '61',
        'Capital Employed': '196052000',
        Cash: '43026000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '457600000',
        'Cost of Sales Delta': '55911000',
        'Cost of Sales Growth': '13.91897712',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '151301000',
        'Current Liabilities': '234381000',
        'Debt to Capital': '94.16029905',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '14893000',
        'Financial Year End': 'April',
        'Industry Keywords': 'manufacturing industry, commercial manufacturing, manufacturing software, packaging manufacturers, polypropylene packaging, pipe manufacturing, cardboard recycling, consumer goods, governance, recycling solutions, waste recycling, metal recycling, packaging & labeling, industrial manufacturing, manufacturing company, career opportunities, local service, transit, market share, coffee cups, waste management services, waste management solutions, industrial packaging, waste management, transit packaging, stock exchange, arts, art, financial information, retail packaging, corrugated packaging, plastic packaging, corporate governance, recycled paper, displays, packaging company, protective packaging, consumer packaging, packaging design, packaging & containers, paperboard containers, boxes, corrugated paper, container, paperboard, paper containers, paper, containers, recycling, manufacturing, packaging',
        'Net Assets': '25136000',
        'SIC 2007 Description': 'Manufacture of paper and paperboard containers other than sacks and bags',
        'Shareholder Funds': '25136000',
        'Shareholders Names': 'D S Smith (UK) Ltd',
        Status: 'Active',
        Telephone: '01977 781100',
        'Trading Address': 'Commonside Lane, Featherstone, West Yorkshire, WF7 5DF',
        'Trading Address 1': 'Commonside Lane',
        'Trading Address 2': '0',
        'Trading Address 3': 'Featherstone',
        'Trading Address 4': 'West Yorkshire',
        Turnover: '582289000',
        'Ultimate Domestic Parent Name': 'Ds Smith Plc',
        'Ultimate Domestic Parent Id': 'company/gb/01377658',
        'Ultimate Parent Name': 'Ds Smith Plc',
        'Ultimate Parent Id': 'company/gb/01377658',
        'Web Address 1': 'http://www.dssmith.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-83080000',
        lat: 53.37821853299665,
        'long': -0.008231571394327203
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.007649232737523,
              53.37757509787042
            ],
            [
              -0.007779502936534,
              53.37777002126741
            ],
            [
              -0.007304823668645,
              53.377883554114455
            ],
            [
              -0.007224050476783,
              53.377762007164435
            ],
            [
              -0.007034317176268,
              53.37780702639223
            ],
            [
              -0.007115373584179,
              53.37792893780843
            ],
            [
              -0.006957247829457,
              53.37796675313956
            ],
            [
              -0.007044795348814,
              53.37809776314613
            ],
            [
              -0.006587137670816,
              53.37820726212033
            ],
            [
              -0.007062985697763,
              53.378915541554285
            ],
            [
              -0.007832478370793,
              53.378731862934735
            ],
            [
              -0.007615931272472,
              53.37840756959128
            ],
            [
              -0.006990119429288,
              53.37855705615072
            ],
            [
              -0.006933365580709,
              53.3784716909082
            ],
            [
              -0.007656648178855,
              53.37829865930475
            ],
            [
              -0.007756296392672,
              53.378447491452015
            ],
            [
              -0.00809490206599,
              53.37836647782448
            ],
            [
              -0.008494611226972,
              53.37896389070331
            ],
            [
              -0.009511501160791,
              53.37872049892088
            ],
            [
              -0.009308537294244,
              53.37841738232168
            ],
            [
              -0.009623118587921,
              53.37834207698236
            ],
            [
              -0.009426063042183,
              53.37804768963114
            ],
            [
              -0.009647891376299,
              53.37799466118856
            ],
            [
              -0.009446056260237,
              53.37769318182242
            ],
            [
              -0.00894051272782,
              53.377814207626734
            ],
            [
              -0.008758086181478,
              53.377541549599414
            ],
            [
              -0.008383152066128,
              53.377631218724595
            ],
            [
              -0.008249642282641,
              53.37743156705911
            ],
            [
              -0.007649232737523,
              53.37757509787042
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10833679',
        'Roof.Size': '3741.906658',
        'Estimated capacity': '523.8669321',
        'Title.Number': 'LL240135',
        Tenure: 'Freehold',
        'Property Address': 'land and buildings on the south side of Mareham Road, Horncastle',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'FORUM PACKAGING LIMITED',
        'Company Registration No. (1)': '07536149',
        'Date Proprietor Added': '27/05/2011',
        POSTCODE_2: 'LN9 6NG',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '9',
        'Capital Employed': '2430791',
        Cash: '16130',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '10094333',
        'Cost of Sales Delta': '1901291',
        'Cost of Sales Growth': '23.20616689',
        Country: 'GB',
        'Credit Risk': 'Moderate',
        'Current Assets': '4051924',
        'Current Liabilities': '3695716',
        'Debt to Capital': '78.47866656',
        'Dividends Payable': '115200',
        'Dormant Company': 'No',
        EBITDA: '522737',
        'Financial Year End': 'April',
        'Industry Keywords': 'environment, heritage, home products, bag manufacturers, sacks, printing, packaging, container, paperboard containers, paper containers, corrugated paper, design, manufacturing, paperboard, containers, paper finishers, paper converters, paper sacks, paper',
        'Net Assets': '1318506',
        'SIC 2007 Description': 'Manufacture of paper and paperboard containers other than sacks and bags',
        'Shareholder Funds': '1318506',
        'Shareholders Names': 'Helen Elizabeth Spivey, Mark Ian Spivey, Robert George Massey',
        Status: 'Active',
        Telephone: '01507 523434',
        'Trading Address': 'Forum Packaging Limited, Forum Packaging Mareham Road, Horncastle, Lincolnshire, LN9 6NG',
        'Trading Address 1': 'Forum Packaging Limited',
        'Trading Address 2': 'Forum Packaging Mareham Road',
        'Trading Address 3': 'Horncastle',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '11120609',
        'Ultimate Domestic Parent Name': 'Rm217 Limited',
        'Ultimate Domestic Parent Id': 'company/gb/10715662',
        'Ultimate Parent Name': 'Rm217 Limited',
        'Ultimate Parent Id': 'company/gb/10715662',
        'Web Address 1': 'http://www.forumpackaging.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '356208',
        lat: 53.20124305166647,
        'long': -0.10373388275280886
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.103933398727686,
              53.20168236939859
            ],
            [
              -0.104129221566385,
              53.2008550568238
            ],
            [
              -0.10353341557468,
              53.20080215124037
            ],
            [
              -0.103340206153124,
              53.20163444916029
            ],
            [
              -0.103933398727686,
              53.20168236939859
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10833680',
        'Roof.Size': '4574.327706',
        'Estimated capacity': '640.4058788',
        'Title.Number': 'LL240135',
        Tenure: 'Freehold',
        'Property Address': 'land and buildings on the south side of Mareham Road, Horncastle',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'FORUM PACKAGING LIMITED',
        'Company Registration No. (1)': '07536149',
        'Date Proprietor Added': '27/05/2011',
        POSTCODE_2: 'LN9 6BP',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '9',
        'Capital Employed': '2430791',
        Cash: '16130',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '10094333',
        'Cost of Sales Delta': '1901291',
        'Cost of Sales Growth': '23.20616689',
        Country: 'GB',
        'Credit Risk': 'Moderate',
        'Current Assets': '4051924',
        'Current Liabilities': '3695716',
        'Debt to Capital': '78.47866656',
        'Dividends Payable': '115200',
        'Dormant Company': 'No',
        EBITDA: '522737',
        'Financial Year End': 'April',
        'Industry Keywords': 'environment, heritage, home products, bag manufacturers, sacks, printing, packaging, container, paperboard containers, paper containers, corrugated paper, design, manufacturing, paperboard, containers, paper finishers, paper converters, paper sacks, paper',
        'Net Assets': '1318506',
        'SIC 2007 Description': 'Manufacture of paper and paperboard containers other than sacks and bags',
        'Shareholder Funds': '1318506',
        'Shareholders Names': 'Helen Elizabeth Spivey, Mark Ian Spivey, Robert George Massey',
        Status: 'Active',
        Telephone: '01507 523434',
        'Trading Address': 'Forum Packaging Limited, Forum Packaging Mareham Road, Horncastle, Lincolnshire, LN9 6NG',
        'Trading Address 1': 'Forum Packaging Limited',
        'Trading Address 2': 'Forum Packaging Mareham Road',
        'Trading Address 3': 'Horncastle',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '11120609',
        'Ultimate Domestic Parent Name': 'Rm217 Limited',
        'Ultimate Domestic Parent Id': 'company/gb/10715662',
        'Ultimate Parent Name': 'Rm217 Limited',
        'Ultimate Parent Id': 'company/gb/10715662',
        'Web Address 1': 'http://www.forumpackaging.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '356208',
        lat: 53.20142845203006,
        'long': -0.10454669751123308
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.104707135678175,
              53.20196501731608
            ],
            [
              -0.104968660302604,
              53.200863107809276
            ],
            [
              -0.104469247167821,
              53.200821000073304
            ],
            [
              -0.104259862255983,
              53.20170131960862
            ],
            [
              -0.104019096578755,
              53.20168112605668
            ],
            [
              -0.103968680191715,
              53.201900674599486
            ],
            [
              -0.104707135678175,
              53.20196501731608
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10834564',
        'Roof.Size': '7703.298178',
        'Estimated capacity': '1078.461745',
        'Title.Number': 'LL338819',
        Tenure: 'Freehold',
        'Property Address': 'Louth County Hospital, High Holme Road, Louth (LN11 0EU)',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'NHS PROPERTY SERVICES LIMITED',
        'Company Registration No. (1)': '07888110',
        'Date Proprietor Added': '11/01/2013',
        POSTCODE_2: 'LN11 0DZ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '8',
        'Capital Employed': '4328769000',
        Cash: '112849000',
        Charges: '0',
        'Cost of Sales': '661172000',
        'Cost of Sales Delta': '42077000',
        'Cost of Sales Growth': '6.79653365',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '703795000',
        'Current Liabilities': '293620000',
        'Debt to Capital': '20.94462409',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '103898000',
        'Financial Year End': 'March',
        'Industry Keywords': 'residential property services, property landlords, property information, commercial property experts, property advice, property finding, property repossession, commercial property sales, commercial property letting, residential property investment, health care, delivery, pct, social, clinical services, primary care, national health service, gp practices, national health, strategic health authorities, health services, pcts, social care, gps, health authorities, primary care trusts, health, hospital & health care, property services, commercial real estate, facilities management, real estate management, property management, real estate, facilities management services, property',
        'Net Assets': '3654247000',
        'SIC 2007 Description': 'Management of real estate on a fee or contract basis',
        'Shareholder Funds': '3654247000',
        'Shareholders Names': 'Secretary of State For Health & Social Care',
        Status: 'Active',
        Telephone: '020 3049 4300',
        'Trading Address': '85 Gresham Street, London, EC2V 7NQ',
        'Trading Address 1': '85 Gresham Street',
        'Trading Address 2': 'London',
        'Trading Address 3': '0',
        'Trading Address 4': '0',
        Turnover: '797743000',
        'Ultimate Domestic Parent Name': 'Nhs Property Services Limited',
        'Ultimate Domestic Parent Id': 'company/gb/07888110',
        'Ultimate Parent Name': 'Secretary of State For Health',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://property.nhs.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 53.371666992029,
        'long': -0.009460999977053058
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.00985738695868,
              53.37151598361791
            ],
            [
              -0.009930289239355,
              53.37157734342683
            ],
            [
              -0.010024605300809,
              53.371537291651705
            ],
            [
              -0.00965860974991,
              53.37123334452903
            ],
            [
              -0.009495340143496,
              53.37130146401521
            ],
            [
              -0.009368017976076,
              53.37119829101846
            ],
            [
              -0.009498219544782,
              53.37113977906887
            ],
            [
              -0.009423582589758,
              53.37108018808092
            ],
            [
              -0.009174349535997,
              53.371192992828824
            ],
            [
              -0.00833229575211,
              53.37052176260542
            ],
            [
              -0.008116921756025,
              53.37061445282189
            ],
            [
              -0.008516281583169,
              53.370950427134176
            ],
            [
              -0.008478748102555,
              53.37096553360091
            ],
            [
              -0.008308521901456,
              53.370976537761535
            ],
            [
              -0.008342088898041,
              53.37113343684745
            ],
            [
              -0.008452265926957,
              53.371127184299915
            ],
            [
              -0.008444695877482,
              53.37107356651662
            ],
            [
              -0.008489082390593,
              53.371069452511406
            ],
            [
              -0.008527045997371,
              53.37109346041985
            ],
            [
              -0.008464819925348,
              53.37117063646609
            ],
            [
              -0.008547928983078,
              53.37119359967239
            ],
            [
              -0.008651638785541,
              53.37106497283537
            ],
            [
              -0.008661985494271,
              53.37106874153983
            ],
            [
              -0.009572430196163,
              53.37184683415115
            ],
            [
              -0.009176755621226,
              53.371985875136346
            ],
            [
              -0.009544727217087,
              53.37236033947601
            ],
            [
              -0.009732705771197,
              53.372294340088764
            ],
            [
              -0.009467923483609,
              53.37202480444648
            ],
            [
              -0.009639397558254,
              53.371964553347446
            ],
            [
              -0.010045665617412,
              53.37237858215986
            ],
            [
              -0.009931511648287,
              53.37241848297651
            ],
            [
              -0.009821836351928,
              53.37240101126343
            ],
            [
              -0.009816667545044,
              53.37239580068115
            ],
            [
              -0.009863139582642,
              53.37237922461457
            ],
            [
              -0.009801677093925,
              53.37231751613878
            ],
            [
              -0.009604977425538,
              53.37238678644997
            ],
            [
              -0.009675200403817,
              53.3724572716757
            ],
            [
              -0.009649562071729,
              53.37251707816581
            ],
            [
              -0.00956275994423,
              53.372547455700364
            ],
            [
              -0.009652727775451,
              53.372639397101835
            ],
            [
              -0.009735252935626,
              53.372610476504626
            ],
            [
              -0.00984522915128,
              53.37262795331093
            ],
            [
              -0.009892044969227,
              53.37267494337469
            ],
            [
              -0.010041701797055,
              53.37262160991341
            ],
            [
              -0.009996320963323,
              53.37257608226088
            ],
            [
              -0.01002316164162,
              53.37250982283473
            ],
            [
              -0.010318100076665,
              53.37240667852821
            ],
            [
              -0.010428422499201,
              53.37242317165398
            ],
            [
              -0.010473803639737,
              53.37246869914603
            ],
            [
              -0.010623308819826,
              53.372415362436215
            ],
            [
              -0.010577927573586,
              53.37236983499967
            ],
            [
              -0.010605396846526,
              53.37230619307167
            ],
            [
              -0.010691125359393,
              53.372276246399004
            ],
            [
              -0.010601160203025,
              53.37218421592979
            ],
            [
              -0.010516345629625,
              53.37221390806771
            ],
            [
              -0.01040488065815,
              53.37219613735001
            ],
            [
              -0.010336237994219,
              53.37212720722941
            ],
            [
              -0.010135416866414,
              53.37220108457626
            ],
            [
              -0.010181802861422,
              53.372247617856125
            ],
            [
              -0.010256527410605,
              53.372250571807804
            ],
            [
              -0.010227826670672,
              53.37231482243946
            ],
            [
              -0.010117495278693,
              53.37235334873713
            ],
            [
              -0.009776585037421,
              53.37200450992053
            ],
            [
              -0.010324294422611,
              53.37180938585911
            ],
            [
              -0.01023978292734,
              53.37172616647078
            ],
            [
              -0.009999895368005,
              53.37180937133066
            ],
            [
              -0.009922175651759,
              53.37173525511754
            ],
            [
              -0.010024615265291,
              53.37169830555102
            ],
            [
              -0.009946979462561,
              53.37162239271915
            ],
            [
              -0.009536990630764,
              53.37177189448354
            ],
            [
              -0.009471818581552,
              53.37171884440822
            ],
            [
              -0.009629891238115,
              53.37164937965342
            ],
            [
              -0.009330807238269,
              53.37140633196223
            ],
            [
              -0.009172881032191,
              53.37147588866465
            ],
            [
              -0.008737665710284,
              53.37112205730652
            ],
            [
              -0.008794452551412,
              53.37109702320619
            ],
            [
              -0.009170750483929,
              53.37140231361784
            ],
            [
              -0.009277376574282,
              53.371356174663
            ],
            [
              -0.009150498257296,
              53.3712467157237
            ],
            [
              -0.009233231116262,
              53.3712100674125
            ],
            [
              -0.009691232743556,
              53.37158792104147
            ],
            [
              -0.00985738695868,
              53.37151598361791
            ]
          ],
          [
            [
              -0.00967421509959,
              53.371927105628444
            ],
            [
              -0.0096010857484,
              53.371851267725724
            ],
            [
              -0.009742845460552,
              53.371799600979365
            ],
            [
              -0.009818870809054,
              53.37187458807128
            ],
            [
              -0.00967421509959,
              53.371927105628444
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10835020',
        'Roof.Size': '22475.35077',
        'Estimated capacity': '3146.549108',
        'Title.Number': 'LL118764',
        Tenure: 'Freehold',
        'Property Address': 'Land on the West side of Grimsby Road, Louth',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'DS SMITH PACKAGING LIMITED',
        'Company Registration No. (1)': '00630681',
        'Date Proprietor Added': '15/05/1995',
        POSTCODE_2: 'LN11 0LA',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '61',
        'Capital Employed': '196052000',
        Cash: '43026000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '457600000',
        'Cost of Sales Delta': '55911000',
        'Cost of Sales Growth': '13.91897712',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '151301000',
        'Current Liabilities': '234381000',
        'Debt to Capital': '94.16029905',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '14893000',
        'Financial Year End': 'April',
        'Industry Keywords': 'manufacturing industry, commercial manufacturing, manufacturing software, packaging manufacturers, polypropylene packaging, pipe manufacturing, cardboard recycling, consumer goods, governance, recycling solutions, waste recycling, metal recycling, packaging & labeling, industrial manufacturing, manufacturing company, career opportunities, local service, transit, market share, coffee cups, waste management services, waste management solutions, industrial packaging, waste management, transit packaging, stock exchange, arts, art, financial information, retail packaging, corrugated packaging, plastic packaging, corporate governance, recycled paper, displays, packaging company, protective packaging, consumer packaging, packaging design, packaging & containers, paperboard containers, boxes, corrugated paper, container, paperboard, paper containers, paper, containers, recycling, manufacturing, packaging',
        'Net Assets': '25136000',
        'SIC 2007 Description': 'Manufacture of paper and paperboard containers other than sacks and bags',
        'Shareholder Funds': '25136000',
        'Shareholders Names': 'D S Smith (UK) Ltd',
        Status: 'Active',
        Telephone: '01977 781100',
        'Trading Address': 'Commonside Lane, Featherstone, West Yorkshire, WF7 5DF',
        'Trading Address 1': 'Commonside Lane',
        'Trading Address 2': '0',
        'Trading Address 3': 'Featherstone',
        'Trading Address 4': 'West Yorkshire',
        Turnover: '582289000',
        'Ultimate Domestic Parent Name': 'Ds Smith Plc',
        'Ultimate Domestic Parent Id': 'company/gb/01377658',
        'Ultimate Parent Name': 'Ds Smith Plc',
        'Ultimate Parent Id': 'company/gb/01377658',
        'Web Address 1': 'http://www.dssmith.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-83080000',
        lat: 53.37882741804836,
        'long': -0.014208262505669256
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.013554219208697,
              53.37885302922716
            ],
            [
              -0.013034518033741,
              53.37897131950992
            ],
            [
              -0.013624645180034,
              53.37989822652186
            ],
            [
              -0.014415152927025,
              53.37971827153467
            ],
            [
              -0.01415131732876,
              53.3793040216215
            ],
            [
              -0.01475748475331,
              53.379166037857296
            ],
            [
              -0.014862859370054,
              53.379331321151064
            ],
            [
              -0.0150275854272,
              53.37929378493245
            ],
            [
              -0.015127632696466,
              53.37945079835486
            ],
            [
              -0.015683044457413,
              53.379324372224126
            ],
            [
              -0.015034315957559,
              53.37830543088962
            ],
            [
              -0.014910774324145,
              53.378333583112926
            ],
            [
              -0.01467525745486,
              53.37796349732715
            ],
            [
              -0.014288225645095,
              53.37805163519002
            ],
            [
              -0.014194226490434,
              53.37790371175467
            ],
            [
              -0.013673699986716,
              53.378020642486455
            ],
            [
              -0.013571190771222,
              53.37795546604622
            ],
            [
              -0.013627800870593,
              53.3778794525648
            ],
            [
              -0.013279711826482,
              53.37778663434563
            ],
            [
              -0.013125918700645,
              53.37799309514656
            ],
            [
              -0.013459457986346,
              53.37808198567747
            ],
            [
              -0.013545614606004,
              53.37822069913376
            ],
            [
              -0.013201586978961,
              53.37829894085239
            ],
            [
              -0.013554219208697,
              53.37885302922716
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10835983',
        'Roof.Size': '7434.433567',
        'Estimated capacity': '1040.820699',
        'Title.Number': 'LL17781',
        Tenure: 'Freehold',
        'Property Address': 'two parcels of land fronting Barton Street and The Autby Frozen Food Factory, North Thoresby',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'LUNETTE PROPERTIES LIMITED',
        'Company Registration No. (1)': '02543659',
        'Date Proprietor Added': '24/12/2008',
        POSTCODE_2: 'DN36 5SB',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '30',
        'Capital Employed': '13517000',
        Cash: '4000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '778000',
        'Current Liabilities': '2721000',
        'Debt to Capital': '66.25815987',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'September',
        'Industry Keywords': 'business support, property',
        'Net Assets': '5479000',
        'SIC 2007 Description': 'Other business support service activities n.e.c.',
        'Shareholder Funds': '5479000',
        'Shareholders Names': 'J.R.A. Young 1982 Settlement, J.R.A. Young 1988 Settlement, Mrs A.J. Young 1998 Settlement',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': '15 Saxon Way East, Corby, NN18 9EY',
        'Trading Address 1': '15 Saxon Way East',
        'Trading Address 2': 'Corby',
        'Trading Address 3': '0',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'Lunette Properties Limited',
        'Ultimate Domestic Parent Id': 'company/gb/02543659',
        'Ultimate Parent Name': 'Lunette Properties Limited',
        'Ultimate Parent Id': 'company/gb/02543659',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-1943000',
        lat: 53.45407191146969,
        'long': -0.07115210506888739
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.070325875123369,
              53.4536666475159
            ],
            [
              -0.07075975176704,
              53.4544548825616
            ],
            [
              -0.071031699842043,
              53.45439643452594
            ],
            [
              -0.070991973688594,
              53.454332503752774
            ],
            [
              -0.071115685060298,
              53.45430798106607
            ],
            [
              -0.071313051440033,
              53.45466222547476
            ],
            [
              -0.071868736112569,
              53.454551090312385
            ],
            [
              -0.071628876965515,
              53.45412406147503
            ],
            [
              -0.071826883509763,
              53.454085274670234
            ],
            [
              -0.071650092254785,
              53.453772177622
            ],
            [
              -0.071547188783899,
              53.453792901393385
            ],
            [
              -0.071483920762856,
              53.45367680859287
            ],
            [
              -0.071369802966273,
              53.453699059252976
            ],
            [
              -0.071297408284077,
              53.45357167145194
            ],
            [
              -0.07068875332561,
              53.45370199597414
            ],
            [
              -0.070637018304829,
              53.453607305266914
            ],
            [
              -0.070325875123369,
              53.4536666475159
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10837700',
        'Roof.Size': '5394.296291',
        'Estimated capacity': '755.2014807',
        'Title.Number': 'LL195040',
        Tenure: 'Freehold',
        'Property Address': 'Middle Farm, Cottage Farm, Woodbeck Farm and part of Fir Tree Farm, Edlington (LN9 5RJ)',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'BEESWAX DYSON FARMING LIMITED',
        'Company Registration No. (1)': '06970038',
        'Date Proprietor Added': '23/04/2015',
        POSTCODE_2: 'LN9 5RJ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '11',
        'Capital Employed': '529144000',
        Cash: '4000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '6414000',
        'Cost of Sales Delta': '-1515000',
        'Cost of Sales Growth': '-19.10707529',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '29393000',
        'Current Liabilities': '15532000',
        'Debt to Capital': '3.206860592',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '6809000',
        'Financial Year End': 'December',
        'Industry Keywords': 'agriculture, holding companies, energy, farming, agricultural holding companies, holding companies management, investment, investment property',
        'Net Assets': '527209000',
        'SIC 2007 Description': 'Activities of agricultural holding companies',
        'Shareholder Funds': '527209000',
        'Shareholders Names': 'New Beeswax Dyson Farming Limited',
        Status: 'Active',
        Telephone: '01526 322058',
        'Trading Address': 'The Estate Office Cyclone Way, Nocton, Lincoln Ln4 2gr, LN4 2GR',
        'Trading Address 1': 'The Estate Office Cyclone Way',
        'Trading Address 2': 'Nocton',
        'Trading Address 3': 'Lincoln Ln4 2gr',
        'Trading Address 4': '0',
        Turnover: '21923000',
        'Ultimate Domestic Parent Name': 'Weybourne Group Limited',
        'Ultimate Domestic Parent Id': 'company/gb/08445070',
        'Ultimate Parent Name': 'Weybourne Group Limited',
        'Ultimate Parent Id': 'company/gb/08445070',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '13861000',
        lat: 53.21839163352887,
        'long': -0.1665295334513794
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.166790056084252,
              53.21838762711319
            ],
            [
              -0.167100375977878,
              53.21831814220556
            ],
            [
              -0.166802973197924,
              53.217839607624654
            ],
            [
              -0.165943249404209,
              53.218032072745906
            ],
            [
              -0.166049900130671,
              53.21820371758475
            ],
            [
              -0.16639242465572,
              53.218127178444576
            ],
            [
              -0.16640461437796,
              53.21814687459842
            ],
            [
              -0.166062235561035,
              53.21822350591814
            ],
            [
              -0.166165805259294,
              53.218390158651744
            ],
            [
              -0.166510453512164,
              53.21831302253904
            ],
            [
              -0.166522643339595,
              53.21833271868117
            ],
            [
              -0.166167559296807,
              53.21841221146798
            ],
            [
              -0.166393166382513,
              53.21877259139101
            ],
            [
              -0.166220514211081,
              53.218810030597304
            ],
            [
              -0.166446077619144,
              53.21916097007217
            ],
            [
              -0.166791583631218,
              53.21908141897954
            ],
            [
              -0.166520712361596,
              53.21865849132364
            ],
            [
              -0.166906912834406,
              53.218569407200484
            ],
            [
              -0.166790056084252,
              53.21838762711319
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10837966',
        'Roof.Size': '3906.128566',
        'Estimated capacity': '546.8579992',
        'Title.Number': 'LL136607',
        Tenure: 'Freehold',
        'Property Address': 'land and buildings at Barsey Walk Farm, Edlington',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'JOHN BOOTH & SONS (LINCOLN) LIMITED',
        'Company Registration No. (1)': '01840762',
        'Date Proprietor Added': '02/03/2010',
        POSTCODE_2: 'LN9 5RY',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '36',
        'Capital Employed': '3094816',
        Cash: '10000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '1825887',
        'Current Liabilities': '361640',
        'Debt to Capital': '10.7400181',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'September',
        'Industry Keywords': 'breakfast cereals, agriculture, seeds, crop farming, leguminous crops, oil seeds, arable farming, farming, crops, cereals',
        'Net Assets': '3085232',
        'SIC 2007 Description': 'Growing of cereals (except rice), leguminous crops and oil seeds',
        'Shareholder Funds': '3085232',
        'Shareholders Names': 'Roythornes Trustees Limited & Duncan & Toplis Trustees Limited, Sallie Jeanette Gaunt Mrs, Helen Joanne Strawson Mrs, Valerie Marjorie Booth & Duncan & Toplis Trustees Limited & Roythornes Trustees Limited, Roger Harold Booth & Duncan & Toplis Trustees Limited & Roythornes Trustees Limited, Jonathan Roger Booth',
        Status: 'Active',
        Telephone: '01507 343744',
        'Trading Address': 'Ranby Hall, Ranby, Market Rasen, Lincolnshire, LN8 5LN',
        'Trading Address 1': 'Ranby Hall',
        'Trading Address 2': 'Ranby',
        'Trading Address 3': 'Market Rasen',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '1464247',
        lat: 53.215067041625645,
        'long': -0.1862062591798191
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.186359024809897,
              53.2152192251956
            ],
            [
              -0.18651165407732,
              53.215204736643585
            ],
            [
              -0.186443155551114,
              53.215273367483945
            ],
            [
              -0.18635670238198,
              53.21524229442439
            ],
            [
              -0.186218603632905,
              53.215380797737296
            ],
            [
              -0.186574608762899,
              53.21550848153772
            ],
            [
              -0.186801389573534,
              53.21528088680385
            ],
            [
              -0.186762294912529,
              53.215266806754315
            ],
            [
              -0.18657345868261,
              53.21519884474483
            ],
            [
              -0.186614363659782,
              53.215194882291115
            ],
            [
              -0.186475127506369,
              53.21466890972085
            ],
            [
              -0.185997688959049,
              53.21471441442004
            ],
            [
              -0.186037087509552,
              53.214863081279766
            ],
            [
              -0.185591897690308,
              53.214914469350184
            ],
            [
              -0.185721007231438,
              53.215289974206684
            ],
            [
              -0.186359024809897,
              53.2152192251956
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10837994',
        'Roof.Size': '4078.974047',
        'Estimated capacity': '571.0563666',
        'Title.Number': 'LL125638',
        Tenure: 'Freehold',
        'Property Address': 'Grange Farm, Wispington',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'WISPINGTON FARMS LIMITED',
        'Company Registration No. (1)': '03115612',
        'Date Proprietor Added': '29/12/1995',
        POSTCODE_2: 'LN9 5RN',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '25',
        'Capital Employed': '9682447',
        Cash: '472626',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '1839080',
        'Current Liabilities': '502588',
        'Debt to Capital': '38.55267066',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'June',
        'Industry Keywords': 'animals, crop farming, animal farming, mixed farming, cereals, farming, crops',
        'Net Assets': '6258432',
        'SIC 2007 Description': 'Mixed farming',
        'Shareholder Funds': '6258432',
        'Shareholders Names': 'Mr Richard Frank Craven & John Russell Bowser Ward & Jarred Thomas Wright & Wendy Craven, Richard Frank Craven Mr',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': '18 Northgate, Sleaford, Lincolnshire, NG34 7BJ',
        'Trading Address 1': '18 Northgate',
        'Trading Address 2': 'Sleaford',
        'Trading Address 3': 'Lincolnshire',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '1336492',
        lat: 53.227719075616804,
        'long': -0.19750330666989035
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.196951131707101,
              53.22804214144206
            ],
            [
              -0.198270257780977,
              53.2277673069558
            ],
            [
              -0.198055284097399,
              53.22739600310732
            ],
            [
              -0.196736467611044,
              53.22767083984589
            ],
            [
              -0.196951131707101,
              53.22804214144206
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10838985',
        'Roof.Size': '5187.365877',
        'Estimated capacity': '726.2312228',
        'Title.Number': 'LL168601',
        Tenure: 'Freehold',
        'Property Address': 'Alpine Binbrook Ltd, Brookenby Business Park, Brookenby, Binbrook, Market Rasen (LN8 6HF)',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'HIS STOREHOUSE LIMITED',
        'Company Registration No. (1)': '09514841',
        'Date Proprietor Added': '05/03/2018',
        POSTCODE_2: 'LN8 6HF',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '5',
        'Capital Employed': '473426',
        Cash: '9144',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '9144',
        'Current Liabilities': '25718',
        'Debt to Capital': '59.31935474',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'March',
        'Industry Keywords': 'leased real estate, renting, real estate',
        'Net Assets': '203055',
        'SIC 2007 Description': 'Letting and operating of own or leased real estate (other than Housing Association real estate and conference and exhibition services) n.e.c.',
        'Shareholder Funds': '203055',
        'Shareholders Names': 'Andrea Jane Quentine Smith, Alan James Waller, Janet Ann Russell, Richard Stuart Humphrey',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': '6-8 Castilian Street, Northampton, Northamptonshir, NN1 1JX',
        'Trading Address 1': '6-8 Castilian Street',
        'Trading Address 2': 'Northampton',
        'Trading Address 3': 'Northamptonshir',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-16574',
        lat: 53.44544650268675,
        'long': -0.1957382211761487
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.195276035312981,
              53.445841949450084
            ],
            [
              -0.196140292066804,
              53.445861566456024
            ],
            [
              -0.196199682017619,
              53.44504978727881
            ],
            [
              -0.195336098905905,
              53.44503242821135
            ],
            [
              -0.195276035312981,
              53.445841949450084
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10839150',
        'Roof.Size': '5205.533471',
        'Estimated capacity': '728.7746859',
        'Title.Number': 'LL338110',
        Tenure: 'Freehold',
        'Property Address': 'land at Brookenby Business Park, Brookenby, Binbrook, Market Rasen',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'CHESTNUT INVESTMENTS (UK) LIMITED',
        'Company Registration No. (1)': '05030508',
        'Date Proprietor Added': '10/12/2012',
        POSTCODE_2: 'LN8 6HF',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '16',
        'Capital Employed': '1664115',
        Cash: '6733',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '36703',
        'Current Liabilities': '344363',
        'Debt to Capital': '89.2953769',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'February',
        'Industry Keywords': 'real estate management, real estate',
        'Net Assets': '215000',
        'SIC 2007 Description': 'Management of real estate on a fee or contract basis',
        'Shareholder Funds': '215000',
        'Shareholders Names': 'B. Sutcliffe Mrs',
        Status: 'Active',
        Telephone: '0844 600 9101',
        'Trading Address': 'Unit 2 Binbrook Technical Park, Binbrook, Market Rasen, Lincolnshire Ln8 6hf, LN8 6HF',
        'Trading Address 1': 'Unit 2 Binbrook Technical Park',
        'Trading Address 2': 'Binbrook',
        'Trading Address 3': 'Market Rasen',
        'Trading Address 4': 'Lincolnshire Ln8 6hf',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-307660',
        lat: 53.44550026662901,
        'long': -0.1972900850262472
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.197754462813066,
              53.445105259283096
            ],
            [
              -0.19689272873908,
              53.445079758686944
            ],
            [
              -0.196826706056255,
              53.44589557328946
            ],
            [
              -0.197686345988577,
              53.44592113254806
            ],
            [
              -0.197754462813066,
              53.445105259283096
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10839313',
        'Roof.Size': '3958.375466',
        'Estimated capacity': '554.1725652',
        'Title.Number': 'LL244896',
        Tenure: 'Freehold',
        'Property Address': 'Joseph Nickerson Research Centre, Rothwell, Market Rasen (LN7 6DT)',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'NICKERSON (UK) LIMITED',
        'Company Registration No. (1)': '01305690',
        'Date Proprietor Added': '10/08/2004',
        POSTCODE_2: 'LN7 6DT',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '43',
        'Capital Employed': '30349303',
        Cash: '14566045',
        Charges: '0',
        'Cost of Sales': '39080948',
        'Cost of Sales Delta': '881432',
        'Cost of Sales Growth': '2.307442848',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '28754077',
        'Current Liabilities': '5615841',
        'Debt to Capital': '50.3608188',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '1659622',
        'Financial Year End': 'June',
        'Industry Keywords': 'mustard, oilseeds, cereals, barley, agricultural, food, maize, farmers, crops, live animals, agricultural raw materials, semi-finished goods, textile raw materials, merchants, agricultural merchants, plants, plant, seeds',
        'Net Assets': '17852803',
        'SIC 2007 Description': 'Agents involved in the sale of agricultural raw materials, live animals, textile raw materials and semi-finished goods',
        'Shareholder Funds': '17852803',
        'Shareholders Names': 'Limagrain Verneuil Holding Sa',
        Status: 'Active',
        Telephone: '01472 371471',
        'Trading Address': 'Joseph Nickerson Research Centre, Rothwell, Market Rasen, Lincolnshire, LN7 6DT',
        'Trading Address 1': 'Joseph Nickerson Research Centre',
        'Trading Address 2': 'Rothwell',
        'Trading Address 3': 'Market Rasen',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '50391689',
        'Ultimate Domestic Parent Name': 'Limagrain UK Limited',
        'Ultimate Domestic Parent Id': 'company/gb/01305690',
        'Ultimate Parent Name': 'Groupe Limagrain Holding',
        'Ultimate Parent Id': 'company/fr/322791039',
        'Web Address 1': 'http://www.lgseeds.co.uk',
        'Web Address 2': 'http://www.nickerson.co.uk',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '23138236',
        lat: 53.482469942699744,
        'long': -0.24825121846434817
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.248147682814201,
              53.482914896379945
            ],
            [
              -0.248857772212146,
              53.48265291744636
            ],
            [
              -0.248365967754019,
              53.48217824761016
            ],
            [
              -0.248571459391535,
              53.48210322931845
            ],
            [
              -0.248432013527669,
              53.48196885776252
            ],
            [
              -0.247540467778578,
              53.48229694410751
            ],
            [
              -0.247634376509106,
              53.48238794809524
            ],
            [
              -0.248130190293962,
              53.48220553598235
            ],
            [
              -0.248188425081932,
              53.48226257502996
            ],
            [
              -0.24806645772288,
              53.482307533202494
            ],
            [
              -0.248119217088274,
              53.48235846894156
            ],
            [
              -0.248297808934352,
              53.48229258579407
            ],
            [
              -0.248334626087521,
              53.482328095229924
            ],
            [
              -0.24775954349729,
              53.482539641039075
            ],
            [
              -0.248147682814201,
              53.482914896379945
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10840799',
        'Roof.Size': '3832.149661',
        'Estimated capacity': '536.5009525',
        'Title.Number': 'LL135566',
        Tenure: 'Freehold',
        'Property Address': 'Land and Hangar at Bardney Airfield, Tupholme',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'OMEX AGRICULTURE LIMITED',
        'Company Registration No. (1)': '01246369',
        'Date Proprietor Added': '19/11/1996',
        POSTCODE_2: 'LN3 5TP',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '44',
        'Capital Employed': '42575971',
        Cash: '0',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '44054711',
        'Current Liabilities': '18812538',
        'Debt to Capital': '34.80347763',
        'Dividends Payable': '3600000',
        'Dormant Company': 'No',
        EBITDA: '9883283',
        'Financial Year End': 'December',
        'Industry Keywords': 'agriculture, research, sales team, marketing, blog posts, wastewater treatment, anaerobic digestion, environmental impact, marketing material, chemicals, manufacturing, nitrogen compounds, fertilisers',
        'Net Assets': '40023173',
        'SIC 2007 Description': 'Manufacture of fertilisers and nitrogen compounds',
        'Shareholder Funds': '40023173',
        'Shareholders Names': 'Omex Agricultural Holdings Ltd',
        Status: 'Active',
        Telephone: '01526 396000',
        'Trading Address': 'Bardney Airfield, Henry Lane, Bardney, Lincoln, Lincolnshire, LN3 5TP',
        'Trading Address 1': 'Bardney Airfield',
        'Trading Address 2': 'Henry Lane',
        'Trading Address 3': 'Bardney',
        'Trading Address 4': 'Lincoln, Lincolnshire',
        Turnover: '97376890',
        'Ultimate Domestic Parent Name': 'Omex Agricultural Holdings Ltd.',
        'Ultimate Domestic Parent Id': 'company/gb/01121655',
        'Ultimate Parent Name': 'Omex International Ltd',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.omex.co.uk',
        'Web Address 2': 'http://www.omexagriculture.co.uk',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '25242173',
        lat: 53.222810025112395,
        'long': -0.28545294430273005
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.285516990864225,
              53.22312133695246
            ],
            [
              -0.285746359813126,
              53.223034287377224
            ],
            [
              -0.285848543839509,
              53.22313123056763
            ],
            [
              -0.286092057622191,
              53.22303881000593
            ],
            [
              -0.285816755813035,
              53.22277566987185
            ],
            [
              -0.285957884937447,
              53.222722591293895
            ],
            [
              -0.285655746044441,
              53.222430117087654
            ],
            [
              -0.284737300852367,
              53.22277650007921
            ],
            [
              -0.285042059860635,
              53.223067036536165
            ],
            [
              -0.285341354621958,
              53.222954473577246
            ],
            [
              -0.285516990864225,
              53.22312133695246
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10847193',
        'Roof.Size': '15830.8768',
        'Estimated capacity': '2216.322752',
        'Title.Number': 'LL264736',
        Tenure: 'Freehold',
        'Property Address': 'land at Bardney, Lincoln',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'BRITISH SUGAR PLC',
        'Company Registration No. (1)': '00315158',
        'Date Proprietor Added': '',
        POSTCODE_2: 'LN3 5UF',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '84',
        'Capital Employed': '549200000',
        Cash: '200000',
        Charges: 'Satisfied',
        'Cost of Sales': '493500000',
        'Cost of Sales Delta': '-48400000',
        'Cost of Sales Growth': '-8.931537184',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '256000000',
        'Current Liabilities': '96900000',
        'Debt to Capital': '65.60903885',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '22600000',
        'Financial Year End': 'August',
        'Industry Keywords': 'factory, managing director, environmental, sustainability, corporate responsibility, executive team, food, bioethanol, topsoil, horticulture, electricity, animals, renewable energy, animal feeds, renewable, manufacturing, energy, molasses, sugar beet, sugar',
        'Net Assets': '222200000',
        'SIC 2007 Description': 'Manufacture of sugar',
        'Shareholder Funds': '222200000',
        'Shareholders Names': 'Abf Investments Plc',
        Status: 'Active',
        Telephone: '01733 563171',
        'Trading Address': 'Weston Centre, 10 Grosvenor Street, London, W1K 4QY',
        'Trading Address 1': 'Weston Centre',
        'Trading Address 2': '10 Grosvenor Street',
        'Trading Address 3': 'London',
        'Trading Address 4': '0',
        Turnover: '594300000',
        'Ultimate Domestic Parent Name': 'Wittington Investments Limited',
        'Ultimate Domestic Parent Id': 'company/gb/00366054',
        'Ultimate Parent Name': 'Wittington Investments Limited',
        'Ultimate Parent Id': 'company/gb/00366054',
        'Web Address 1': 'http://www.britishsugar.co.uk',
        'Web Address 2': 'http://www.bstopsoil.co.uk',
        'Web Address 3': 'http://www.silverspoon.co.uk',
        'Web Address 4': 'http://www.crusha.co.uk',
        'Working Capital': '159100000',
        lat: 53.203972942781455,
        'long': -0.3352609961172614
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.334146926560024,
              53.20352967801051
            ],
            [
              -0.334612600665503,
              53.20405041122411
            ],
            [
              -0.334990164692337,
              53.20392812072976
            ],
            [
              -0.334731873974694,
              53.20362712451485
            ],
            [
              -0.334904674976473,
              53.203572633203834
            ],
            [
              -0.335138418341447,
              53.203842540521435
            ],
            [
              -0.335484741161092,
              53.20372655439993
            ],
            [
              -0.335523648503437,
              53.203769620244266
            ],
            [
              -0.334702838438174,
              53.204040075418874
            ],
            [
              -0.335138651511024,
              53.204524159732834
            ],
            [
              -0.335612842955896,
              53.20437741624674
            ],
            [
              -0.335807902496872,
              53.20459482006479
            ],
            [
              -0.335756151455106,
              53.20461270640349
            ],
            [
              -0.335770977019176,
              53.204628106439394
            ],
            [
              -0.335661056520283,
              53.20471745924303
            ],
            [
              -0.33561198264361,
              53.2046623847511
            ],
            [
              -0.335510532603453,
              53.20454859360409
            ],
            [
              -0.335368312733202,
              53.20459434331697
            ],
            [
              -0.335549413002902,
              53.204797168662346
            ],
            [
              -0.335445248972061,
              53.204830684368446
            ],
            [
              -0.335596372197985,
              53.204999558449394
            ],
            [
              -0.335854572752269,
              53.20491677166447
            ],
            [
              -0.335685191757124,
              53.2047283145659
            ],
            [
              -0.335787379114847,
              53.204645326476744
            ],
            [
              -0.335837456190862,
              53.204628135938904
            ],
            [
              -0.336189086689389,
              53.20502464736636
            ],
            [
              -0.336484009771972,
              53.204929246704694
            ],
            [
              -0.336286791035422,
              53.20470561084237
            ],
            [
              -0.336751730250478,
              53.204558014458364
            ],
            [
              -0.336109194167373,
              53.20383245709784
            ],
            [
              -0.335800009135471,
              53.20393287176858
            ],
            [
              -0.336091781539869,
              53.20426265140134
            ],
            [
              -0.3359355797944,
              53.20430991462143
            ],
            [
              -0.335717120512754,
              53.204062697221076
            ],
            [
              -0.335821278979858,
              53.20402927110748
            ],
            [
              -0.335587352862668,
              53.20375621614466
            ],
            [
              -0.33581937516516,
              53.2036803456265
            ],
            [
              -0.335592825078793,
              53.20342177754398
            ],
            [
              -0.335231352508047,
              53.20353854119433
            ],
            [
              -0.335299432642758,
              53.20361698550569
            ],
            [
              -0.335195588007458,
              53.2036500559493
            ],
            [
              -0.33507684572179,
              53.203515076263685
            ],
            [
              -0.335407540056939,
              53.203412087186784
            ],
            [
              -0.335290214102318,
              53.20327919517789
            ],
            [
              -0.334630481778988,
              53.20348879088515
            ],
            [
              -0.334131205076301,
              53.20291967156365
            ],
            [
              -0.333974330839841,
              53.20290327412549
            ],
            [
              -0.33395326127869,
              53.202925364439444
            ],
            [
              -0.333938024918931,
              53.202936029207535
            ],
            [
              -0.333878089623341,
              53.202960362936196
            ],
            [
              -0.333939502511341,
              53.2030291855025
            ],
            [
              -0.333590421696279,
              53.203142970980046
            ],
            [
              -0.333939148504325,
              53.20353630226046
            ],
            [
              -0.333829985288879,
              53.20357136483483
            ],
            [
              -0.333873171093259,
              53.203619885012905
            ],
            [
              -0.334146926560024,
              53.20352967801051
            ]
          ],
          [
            [
              -0.336040636037021,
              53.20439633650642
            ],
            [
              -0.33617282809133,
              53.20435431163988
            ],
            [
              -0.336318144080054,
              53.20451851886055
            ],
            [
              -0.336185499227418,
              53.20456062746613
            ],
            [
              -0.336040636037021,
              53.20439633650642
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10847563',
        'Roof.Size': '6436.635856',
        'Estimated capacity': '901.1290198',
        'Title.Number': 'LL110224',
        Tenure: 'Freehold',
        'Property Address': 'land and buildings on the south side of Gallamore Lane, Market Rasen',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'RPC CONTAINERS LIMITED',
        'Company Registration No. (1)': '02786492',
        'Date Proprietor Added': '30/08/1994',
        POSTCODE_2: 'LN8 3HA',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '27',
        'Capital Employed': '377700000',
        Cash: '4100000',
        Charges: 'Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '423300000',
        'Current Liabilities': '173200000',
        'Debt to Capital': '37.44781267',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '96300000',
        'Financial Year End': 'March',
        'Industry Keywords': 'process engineering, automotive engineering, graphic design solutions, electronic engineering, engineering & technical services, pipe manufacturing, cad engineering, design services, engineering products, manufacturing software, markets, plastic product design, packaging, design company, design firm, plastic containers, industrial manufacturing, manufacturing company, group purchasing, design engineering, corporate governance, corporate responsibility, design development, global footprint, innovative design, product design, stock exchange, engineering company, packing goods, plastic packing goods, rigid plastic packaging, plastic products, engineering, manufacturing, plastic, design, plastics, plastic packaging',
        'Net Assets': '344600000',
        'SIC 2007 Description': 'Manufacture of plastic packing goods',
        'Shareholder Funds': '344600000',
        'Shareholders Names': 'Rigid Plastic Containers Holdings Ltd',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Sapphire House, Crown Way, Rushden, Northamptonshire, NN10 6FB',
        'Trading Address 1': 'Sapphire House',
        'Trading Address 2': 'Crown Way',
        'Trading Address 3': 'Rushden',
        'Trading Address 4': 'Northamptonshire',
        Turnover: '266800000',
        'Ultimate Domestic Parent Name': 'Berry UK Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/12042360',
        'Ultimate Parent Name': 'Berry Global, Inc',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.rpc-group.com',
        'Web Address 2': 'http://www.rpc-containers.co.uk',
        'Web Address 3': 'http://www.rpcgroup.co.uk',
        'Web Address 4': '0',
        'Working Capital': '250100000',
        lat: 53.39136212211893,
        'long': -0.33943494113630884
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.339104163222056,
              53.39074263266472
            ],
            [
              -0.339194843379313,
              53.39173545337109
            ],
            [
              -0.339084917722812,
              53.39173913816917
            ],
            [
              -0.339080406928463,
              53.39167345091985
            ],
            [
              -0.338892696801832,
              53.39167803112084
            ],
            [
              -0.338924251087403,
              53.392091994363504
            ],
            [
              -0.339111372302799,
              53.3920871362196
            ],
            [
              -0.339090068011192,
              53.39181157655666
            ],
            [
              -0.339330923967616,
              53.39180548787859
            ],
            [
              -0.339336130898989,
              53.39186489203806
            ],
            [
              -0.339206658188755,
              53.3918684848504
            ],
            [
              -0.339218748703856,
              53.39202147719785
            ],
            [
              -0.339750798041182,
              53.39200658276039
            ],
            [
              -0.339681936041004,
              53.39107106045161
            ],
            [
              -0.33996483063381,
              53.3910627683419
            ],
            [
              -0.339931389241402,
              53.39071638135006
            ],
            [
              -0.339104163222056,
              53.39074263266472
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10859496',
        'Roof.Size': '4409.18083',
        'Estimated capacity': '617.2853162',
        'Title.Number': 'LL85210',
        Tenure: 'Freehold',
        'Property Address': 'land on the north side of Faldingworth Road, Faldingworth',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'HESSLE DOCK COMPANY LIMITED',
        'Company Registration No. (1)': '03117906',
        'Date Proprietor Added': '24/10/2003',
        POSTCODE_2: 'LN8 3SQ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '24',
        'Capital Employed': '1274686',
        Cash: '201557',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '955899',
        'Current Liabilities': '1727719',
        'Debt to Capital': '60.74147225',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': 'engineering research & development, research & development, shipping',
        'Net Assets': '1178700',
        'SIC 2007 Description': 'Other research and experimental development on natural sciences and engineering',
        'Shareholder Funds': '1178700',
        'Shareholders Names': 'Jayne Briggs Mrs',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Hessle Dock Company Limited, 1 Parliament Street, Hull, East Yorkshire, HU1 2AS',
        'Trading Address 1': 'Hessle Dock Company Limited',
        'Trading Address 2': '1 Parliament Street',
        'Trading Address 3': 'Hull',
        'Trading Address 4': 'East Yorkshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-771820',
        lat: 53.35392358078874,
        'long': -0.45367642902021865
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.451903978749691,
              53.35437382470729
            ],
            [
              -0.451853732993361,
              53.35430503202996
            ],
            [
              -0.451708870113496,
              53.354342886711805
            ],
            [
              -0.451751520734168,
              53.35440151260813
            ],
            [
              -0.451300547514486,
              53.35451935793641
            ],
            [
              -0.451247442249179,
              53.35444657248513
            ],
            [
              -0.450975010953644,
              53.35451765031185
            ],
            [
              -0.451036846947173,
              53.354602415426804
            ],
            [
              -0.450856641115656,
              53.35464942921585
            ],
            [
              -0.450902438495545,
              53.35471241125515
            ],
            [
              -0.456277916063663,
              53.353311099690075
            ],
            [
              -0.456219670044279,
              53.353230698751396
            ],
            [
              -0.456043275498651,
              53.35327651108005
            ],
            [
              -0.455997618927261,
              53.35321362275234
            ],
            [
              -0.455827746841975,
              53.35325772123216
            ],
            [
              -0.455874980479179,
              53.35332269762943
            ],
            [
              -0.454839955014005,
              53.353591664084156
            ],
            [
              -0.454770961384191,
              53.3534969197693
            ],
            [
              -0.454013488801392,
              53.353693870107726
            ],
            [
              -0.454092636631908,
              53.35380277011435
            ],
            [
              -0.451903978749691,
              53.35437382470729
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10859774',
        'Roof.Size': '7166.342559',
        'Estimated capacity': '1003.287958',
        'Title.Number': 'LL145630',
        Tenure: 'Freehold',
        'Property Address': 'Crecy Farm, Highgate Lane, Normanby By Spital, Market Rasen (LN8 2HQ)',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'P D HOOK (GROUP) LIMITED',
        'Company Registration No. (1)': '05196402',
        'Date Proprietor Added': '03/02/2005',
        POSTCODE_2: 'LN8 2HQ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '16',
        'Capital Employed': '168715000',
        Cash: '39314000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '93850000',
        'Cost of Sales Delta': '5991000',
        'Cost of Sales Growth': '6.818880251',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '98485000',
        'Current Liabilities': '50986000',
        'Debt to Capital': '38.20237505',
        'Dividends Payable': '5029000',
        'Dormant Company': 'No',
        EBITDA: '29532000',
        'Financial Year End': 'October',
        'Industry Keywords': 'agricultural, supply chain, learning development, retail sector, retail, poultry, free range, head offices, agricultural services, holding companies management, chicken, chicks',
        'Net Assets': '135770000',
        'SIC 2007 Description': 'Activities of head offices',
        'Shareholder Funds': '135770000',
        'Shareholders Names': 'James William Hook, Fioana Anne Hook',
        Status: 'Active',
        Telephone: '01993 850261',
        'Trading Address': 'Cote, Bampton, Oxfordshire, OX18 2EG',
        'Trading Address 1': 'Cote',
        'Trading Address 2': 'Bampton',
        'Trading Address 3': 'Oxfordshire',
        'Trading Address 4': '0',
        Turnover: '185311000',
        'Ultimate Domestic Parent Name': 'P. D. Hook (Group) Limited',
        'Ultimate Domestic Parent Id': 'company/gb/05196402',
        'Ultimate Parent Name': 'P. D. Hook (Group) Limited',
        'Ultimate Parent Id': 'company/gb/05196402',
        'Web Address 1': 'http://www.pdhook.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '47499000',
        lat: 53.38112735266801,
        'long': -0.485581021904505
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.484695796300855,
              53.380900019034094
            ],
            [
              -0.485125683888493,
              53.381652591123405
            ],
            [
              -0.485501251456366,
              53.38157213578256
            ],
            [
              -0.485104913486818,
              53.3808780628979
            ],
            [
              -0.485215154260375,
              53.38085581937149
            ],
            [
              -0.48561078387824,
              53.38154871426459
            ],
            [
              -0.485983014802937,
              53.381469024145055
            ],
            [
              -0.485588230482201,
              53.38077757954848
            ],
            [
              -0.48569377709205,
              53.380756264879324
            ],
            [
              -0.48608785297541,
              53.381446531503386
            ],
            [
              -0.486469629404207,
              53.38136471354938
            ],
            [
              -0.486040424395248,
              53.380613503464886
            ],
            [
              -0.484695796300855,
              53.380900019034094
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10864275',
        'Roof.Size': '6794.139321',
        'Estimated capacity': '951.1795049',
        'Title.Number': 'LL132339',
        Tenure: 'Freehold',
        'Property Address': 'Grimme UK Ltd, Station Road, Swineshead, Boston (PE20 3PS)',
        District: 'BOSTON',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'GRIMME U.K. LIMITED',
        'Company Registration No. (1)': '02846291',
        'Date Proprietor Added': '16/08/1996',
        POSTCODE_2: 'PE20 3PN',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '27',
        'Capital Employed': '14587741',
        Cash: '2427924',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '29760093',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '19768351',
        'Current Liabilities': '8060701',
        'Debt to Capital': '53.96602998',
        'Dividends Payable': '1000000',
        'Dormant Company': 'No',
        EBITDA: '1722184',
        'Financial Year End': 'December',
        'Industry Keywords': 'agricultural tractors, agricultural industry, machinery sales, agricultural machine, farm machinery, agricultural equipment, farm equipment, agricultural implements, potato, handling equipment, technology, potatoes, data systems, control systems, manufacturing, machinery, forestry machinery, agricultural machinery repair, agricultural machinery services, agricultural machinery sales, agricultural machinery',
        'Net Assets': '10425977',
        'SIC 2007 Description': 'Manufacture of agricultural and forestry machinery (other than agricultural tractors)',
        'Shareholder Funds': '10425977',
        'Shareholders Names': 'Grimme International Beteiligangs GmbH',
        Status: 'Active',
        Telephone: '01205 822300',
        'Trading Address': 'Station Road, Swineshead, Boston, Lincolnshire, PE20 3PS',
        'Trading Address 1': 'Station Road',
        'Trading Address 2': 'Swineshead',
        'Trading Address 3': 'Boston',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '36760695',
        'Ultimate Domestic Parent Name': 'Grimme (U.K.) Limited',
        'Ultimate Domestic Parent Id': 'company/gb/02846291',
        'Ultimate Parent Name': 'Grimme GmbH & Co. Kg',
        'Ultimate Parent Id': 'company/de/Oldenburg (Oldenburg) HRA 203503',
        'Web Address 1': 'http://www.grimme.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '11707650',
        lat: 52.96131852315281,
        'long': -0.17819252897464175
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.177864537387744,
              52.96070792611216
            ],
            [
              -0.177471918741866,
              52.96087425333437
            ],
            [
              -0.17767821922501,
              52.961052730831675
            ],
            [
              -0.177463315149723,
              52.96114608305406
            ],
            [
              -0.178330133217456,
              52.961911694509716
            ],
            [
              -0.179036528070063,
              52.96160947156658
            ],
            [
              -0.178110779800302,
              52.96080403320369
            ],
            [
              -0.178020142777072,
              52.960842111915404
            ],
            [
              -0.177864537387744,
              52.96070792611216
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10864587',
        'Roof.Size': '4181.129108',
        'Estimated capacity': '585.3580751',
        'Title.Number': 'LL380322',
        Tenure: 'Freehold',
        'Property Address': 'land at The Meadows, Little Hale Fen, Sleaford (NG34 9BG)',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'D E NICKOLS & SONS LIMITED',
        'Company Registration No. (1)': '00548232',
        'Date Proprietor Added': '27/02/2018',
        POSTCODE_2: 'NG34 9BG',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '65',
        'Capital Employed': '2963553',
        Cash: '3477',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '687786',
        'Current Liabilities': '395656',
        'Debt to Capital': '39.02647915',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'June',
        'Industry Keywords': 'breakfast cereals, seeds, oil seeds, leguminous crops, crop farming, farming, crops, cereals',
        'Net Assets': '2048228',
        'SIC 2007 Description': 'Growing of cereals (except rice), leguminous crops and oil seeds',
        'Shareholder Funds': '2048228',
        'Shareholders Names': 'Joanne Margaret Collins, Peter Thomas Nickols, Joanne Margaret Collins & Peter Thomas Nickols & Robert John Nickols, Robert John Nickols',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': '18 Northgate, Sleaford, Lincolnshire, NG34 7BJ',
        'Trading Address 1': '18 Northgate',
        'Trading Address 2': 'Sleaford',
        'Trading Address 3': 'Lincolnshire',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 52.949471812075636,
        'long': -0.2561024387956824
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.256915402730221,
              52.94944982142963
            ],
            [
              -0.256832364224156,
              52.94942343038085
            ],
            [
              -0.255968382288452,
              52.94917709427685
            ],
            [
              -0.255834197275986,
              52.94934540275935
            ],
            [
              -0.255389773584605,
              52.94921499188335
            ],
            [
              -0.255206401935448,
              52.94944407178466
            ],
            [
              -0.255533598564268,
              52.949539588106674
            ],
            [
              -0.255631796765317,
              52.94957393431103
            ],
            [
              -0.256137532161038,
              52.94972331460754
            ],
            [
              -0.256194133739834,
              52.94965204232909
            ],
            [
              -0.256634643599868,
              52.9497911133946
            ],
            [
              -0.256915402730221,
              52.94944982142963
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10866587',
        'Roof.Size': '12378.46055',
        'Estimated capacity': '1732.984477',
        'Title.Number': 'LL248601',
        Tenure: 'Freehold',
        'Property Address': 'land on the north side of Horncastle Road, Bardney',
        District: 'WEST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'RASE DISTRIBUTION LIMITED',
        'Company Registration No. (1)': '02403313',
        'Date Proprietor Added': '08/08/2011',
        POSTCODE_2: 'LN3 5SU',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '31',
        'Capital Employed': '6118360',
        Cash: '955323',
        Charges: 'Satisfied',
        'Cost of Sales': '7127677',
        'Cost of Sales Delta': '-1982468.818',
        'Cost of Sales Growth': '-21.76110962',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '2783400',
        'Current Liabilities': '800936',
        'Debt to Capital': '11.76485296',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '2173974',
        'Financial Year End': 'December',
        'Industry Keywords': 'warehousing, logistics software, bespoke logistics, global freight forwarding, logistics management services, logistics solutions, logistics company, freight forwarding, logistics jobs, site logistics, logistics management, logistical support, freight road transport, transport, freight transport, road transport, logistics services, logistics',
        'Net Assets': '6105251',
        'SIC 2007 Description': 'Freight transport by road',
        'Shareholder Funds': '6105251',
        'Shareholders Names': 'Hw Coates Limited',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Ladywood House Ladywood Works, Leicester Road, Lutterworth, Leicestershire Le17 4hd, LE17 4HD',
        'Trading Address 1': 'Ladywood House Ladywood Works',
        'Trading Address 2': 'Leicester Road',
        'Trading Address 3': 'Lutterworth',
        'Trading Address 4': 'Leicestershire Le17 4hd',
        Turnover: '9841750',
        'Ultimate Domestic Parent Name': 'H.W. Coates (Holdings) Limited',
        'Ultimate Domestic Parent Id': 'company/gb/07107882',
        'Ultimate Parent Name': 'H.W. Coates (Holdings) Limited',
        'Ultimate Parent Id': 'company/gb/07107882',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '1982464',
        lat: 53.20459447046857,
        'long': -0.30517399240942505
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.303777559480209,
              53.204734065140435
            ],
            [
              -0.3042500811444,
              53.20501929986398
            ],
            [
              -0.304480713595761,
              53.204881619313454
            ],
            [
              -0.305120365634618,
              53.20526874748895
            ],
            [
              -0.305500619857638,
              53.205042754147605
            ],
            [
              -0.305045108272253,
              53.20476639677206
            ],
            [
              -0.305063526891556,
              53.2047549718255
            ],
            [
              -0.305471672454729,
              53.205002247257596
            ],
            [
              -0.306458481554015,
              53.20441556784309
            ],
            [
              -0.305655693366946,
              53.20393182242827
            ],
            [
              -0.305165859088719,
              53.2042258399197
            ],
            [
              -0.305021826269449,
              53.20413838646614
            ],
            [
              -0.304443792212684,
              53.20448175981469
            ],
            [
              -0.304324040892169,
              53.20440885518276
            ],
            [
              -0.303777559480209,
              53.204734065140435
            ]
          ],
          [
            [
              -0.304689796688275,
              53.20480808974569
            ],
            [
              -0.304302200824812,
              53.204575218277306
            ],
            [
              -0.304317559226864,
              53.204565457987165
            ],
            [
              -0.304705116079504,
              53.20479931774667
            ],
            [
              -0.304689796688275,
              53.20480808974569
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10868493',
        'Roof.Size': '4462.877304',
        'Estimated capacity': '624.8028226',
        'Title.Number': 'LL125321',
        Tenure: 'Freehold',
        'Property Address': 'land and buildings on the west side of Mill Lane, Billinghay',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'G W PADLEY HOLDINGS LIMITED',
        'Company Registration No. (1)': '00633999',
        'Date Proprietor Added': '15/12/1995',
        POSTCODE_2: 'LN4 4ES',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '61',
        'Capital Employed': '38089000',
        Cash: '15613000',
        Charges: 'Satisfied',
        'Cost of Sales': '3157000',
        'Cost of Sales Delta': '-5522000',
        'Cost of Sales Growth': '-63.62484157',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '18219000',
        'Current Liabilities': '1705000',
        'Debt to Capital': '5.292255114',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '1831000',
        'Financial Year End': 'July',
        'Industry Keywords': 'poultry products, poultry meat, head offices, holding companies management, vegetables, arable farming, poultry, vegetable processing',
        'Net Assets': '37688000',
        'SIC 2007 Description': 'Activities of head offices',
        'Shareholder Funds': '37688000',
        'Shareholders Names': 'Zedra Holdings (Cayman) Limited, Zedra Trust Company (Suisse) Sa',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Cumberland Court, 80 Mount Street, Nottingham, Nottinghamshire, NG1 6HH',
        'Trading Address 1': 'Cumberland Court',
        'Trading Address 2': '80 Mount Street',
        'Trading Address 3': 'Nottingham',
        'Trading Address 4': 'Nottinghamshire',
        Turnover: '5269000',
        'Ultimate Domestic Parent Name': 'G W Padley Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/00633999',
        'Ultimate Parent Name': 'Zedra Holdings (Cayman) Ltd',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.gwpadley.co.uk',
        'Web Address 2': 'http://www.chickenology.co.uk',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '16514000',
        lat: 53.07611824781272,
        'long': -0.29397885993255163
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.293214234097839,
              53.07604532549851
            ],
            [
              -0.293700575414412,
              53.07649533506073
            ],
            [
              -0.293607622659971,
              53.076531760803405
            ],
            [
              -0.293680496843009,
              53.07659915312905
            ],
            [
              -0.294761956510685,
              53.07617530687745
            ],
            [
              -0.294614050029411,
              53.07603842484357
            ],
            [
              -0.293676344629394,
              53.07640589518328
            ],
            [
              -0.293619602184599,
              53.076353388130755
            ],
            [
              -0.294559129262449,
              53.07598513523409
            ],
            [
              -0.294409202747514,
              53.075846515852874
            ],
            [
              -0.293459368378439,
              53.0762187552142
            ],
            [
              -0.293403635311862,
              53.07616716154518
            ],
            [
              -0.294355440556473,
              53.07579414176633
            ],
            [
              -0.29420666987513,
              53.07565652760197
            ],
            [
              -0.293214234097839,
              53.07604532549851
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10868652',
        'Roof.Size': '5708.212854',
        'Estimated capacity': '799.1497996',
        'Title.Number': 'LL243850',
        Tenure: 'Freehold',
        'Property Address': 'Meads Farm, Sleaford Road, Heckington, Sleaford (NG34 9QN)',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'MOY PARK LIMITED',
        'Company Registration No. (1)': 'NI004842',
        'Date Proprietor Added': '20/03/2006',
        POSTCODE_2: 'NG34 9QN',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '59',
        'Capital Employed': '375694000',
        Cash: '27286000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '1391991000',
        'Cost of Sales Delta': '9867000',
        'Cost of Sales Growth': '0.713901213',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '325908000',
        'Current Liabilities': '261735000',
        'Debt to Capital': '50.72549256',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '123488000',
        'Financial Year End': 'December',
        'Industry Keywords': 'seafood processing, food processing machinery, pork, free range, food service, food industry, food processing equipment, food & beverages, food manufacturing, business units, senior management team, lounge, consumer brands, marketing, beef products, investment, commodities, parks, agriculture, food production, meat products, food company, meat preservation, mill, kitchen, welfare, poultry products, turkey, mills, poultry meat, poultry wholesale, poultry dealer, livestock, farming, food products, food processing, farmers, food processing industry, chicken, foodservice, poultry, food',
        'Net Assets': '314090000',
        'SIC 2007 Description': 'Processing and preserving of poultry meat',
        'Shareholder Funds': '314090000',
        'Shareholders Names': 'Moy Park (Newco) Limited',
        Status: 'Active',
        Telephone: '028 3835 2233',
        'Trading Address': 'Unit 39, The Food Park, Seagoe Industrial Area, Portadown, Craigavon, County Armagh, BT63 5QE',
        'Trading Address 1': 'Unit 39, The Food Park',
        'Trading Address 2': 'Seagoe Industrial Area',
        'Trading Address 3': 'Portadown',
        'Trading Address 4': 'Craigavon, County Armagh',
        Turnover: '1580444000',
        'Ultimate Domestic Parent Name': 'Moy Park Limited',
        'Ultimate Domestic Parent Id': 'company/gb/NI004842',
        'Ultimate Parent Name': 'Jbs S.A.',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.moypark.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '64173000',
        lat: 52.98663032174952,
        'long': -0.33507841326201104
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.334920584509723,
              52.986852356056666
            ],
            [
              -0.335052277730595,
              52.98631002199796
            ],
            [
              -0.334755340261756,
              52.986283747279
            ],
            [
              -0.334623082674365,
              52.98682903990194
            ],
            [
              -0.334504263474228,
              52.9868196438522
            ],
            [
              -0.334635414320916,
              52.98627217819439
            ],
            [
              -0.33433816577309,
              52.986246257681294
            ],
            [
              -0.334197055210479,
              52.986835657748195
            ],
            [
              -0.335780492244976,
              52.98697200919314
            ],
            [
              -0.335765311320287,
              52.98703751523119
            ],
            [
              -0.335952589636497,
              52.987052633046424
            ],
            [
              -0.335996240850157,
              52.986889801726264
            ],
            [
              -0.335772240832003,
              52.98686886571353
            ],
            [
              -0.335886366388335,
              52.98638022623137
            ],
            [
              -0.335592506303717,
              52.98635525531702
            ],
            [
              -0.335465532539176,
              52.986899004540206
            ],
            [
              -0.335343769576266,
              52.98688866909495
            ],
            [
              -0.3354727326278,
              52.98634746510669
            ],
            [
              -0.33517369629035,
              52.98632152165973
            ],
            [
              -0.335044733047003,
              52.98686263544277
            ],
            [
              -0.334920584509723,
              52.986852356056666
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10875046',
        'Roof.Size': '5312.614212',
        'Estimated capacity': '743.7659897',
        'Title.Number': 'LL314802',
        Tenure: 'Freehold',
        'Property Address': 'Land at Nocton Heath, Lincoln',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'BEESWAX DYSON FARMING LIMITED',
        'Company Registration No. (1)': '06970038',
        'Date Proprietor Added': '29/01/2010',
        POSTCODE_2: 'LN4 2GR',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '11',
        'Capital Employed': '529144000',
        Cash: '4000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '6414000',
        'Cost of Sales Delta': '-1515000',
        'Cost of Sales Growth': '-19.10707529',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '29393000',
        'Current Liabilities': '15532000',
        'Debt to Capital': '3.206860592',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '6809000',
        'Financial Year End': 'December',
        'Industry Keywords': 'agriculture, holding companies, energy, farming, agricultural holding companies, holding companies management, investment, investment property',
        'Net Assets': '527209000',
        'SIC 2007 Description': 'Activities of agricultural holding companies',
        'Shareholder Funds': '527209000',
        'Shareholders Names': 'New Beeswax Dyson Farming Limited',
        Status: 'Active',
        Telephone: '01526 322058',
        'Trading Address': 'The Estate Office Cyclone Way, Nocton, Lincoln Ln4 2gr, LN4 2GR',
        'Trading Address 1': 'The Estate Office Cyclone Way',
        'Trading Address 2': 'Nocton',
        'Trading Address 3': 'Lincoln Ln4 2gr',
        'Trading Address 4': '0',
        Turnover: '21923000',
        'Ultimate Domestic Parent Name': 'Weybourne Group Limited',
        'Ultimate Domestic Parent Id': 'company/gb/08445070',
        'Ultimate Parent Name': 'Weybourne Group Limited',
        'Ultimate Parent Id': 'company/gb/08445070',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '13861000',
        lat: 53.164166947420334,
        'long': -0.39188484305359556
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.392514078850991,
              53.1644713439318
            ],
            [
              -0.392084992468536,
              53.16369664545194
            ],
            [
              -0.391255612826341,
              53.163862547367756
            ],
            [
              -0.391684685799489,
              53.16463724871432
            ],
            [
              -0.392514078850991,
              53.1644713439318
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10870487',
        'Roof.Size': '4734.300898',
        'Estimated capacity': '662.8021257',
        'Title.Number': 'LL262484',
        Tenure: 'Freehold',
        'Property Address': 'Land on the north west side of Church Lane, Anwick, Sleaford (NG34 9SS)',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'J PARKER DUTCH BULBS (WHOLESALE) LIMITED',
        'Company Registration No. (1)': '01467306',
        'Date Proprietor Added': '07/12/2005',
        POSTCODE_2: 'NG34 9SS',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '40',
        'Capital Employed': '7289588',
        Cash: '4268445',
        Charges: 'Outstanding',
        'Cost of Sales': '16824925',
        'Cost of Sales Delta': '876186',
        'Cost of Sales Growth': '5.493763488',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '6129103',
        'Current Liabilities': '3317445',
        'Debt to Capital': '32.62436348',
        'Dividends Payable': '300000',
        'Dormant Company': 'No',
        EBITDA: '2035133',
        'Financial Year End': 'June',
        'Industry Keywords': 'retail sales, pet food, seeds, pet animals, specialised stores, flowers, stores, bulbs, fertilisers, garden, retail outlets, mail order, plants',
        'Net Assets': '7146556',
        'SIC 2007 Description': 'Retail sale of flowers, plants, seeds, fertilisers, pet animals and pet food in specialised stores',
        'Shareholder Funds': '7146556',
        'Shareholders Names': 'Trustees of Discretionary Settlement of Mrs N.J. Duiker-Parker, Margaret Roberta Duiker-Parker Mrs, Paul H. Duiker-Parker, C.A. Duiker-Parker, Daniel Duiker Parker',
        Status: 'Active',
        Telephone: '0161 848 1109',
        'Trading Address': '14 Hadfield Street, Old Trafford, Manchester, Lancashire, M16 9FG',
        'Trading Address 1': '14 Hadfield Street',
        'Trading Address 2': 'Old Trafford',
        'Trading Address 3': 'Manchester',
        'Trading Address 4': 'Lancashire',
        Turnover: '23516167',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.dutchbulbs.co.uk',
        'Web Address 2': 'http://www.jparkers.co.uk',
        'Web Address 3': 'http://www.jparker.co.uk',
        'Web Address 4': '0',
        'Working Capital': '2811658',
        lat: 53.04261383927239,
        'long': -0.3394859415211259
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.339666969380244,
              53.04267583827826
            ],
            [
              -0.338905570864485,
              53.04289814326957
            ],
            [
              -0.33910816070859,
              53.043149909064454
            ],
            [
              -0.340145615732482,
              53.04284371122643
            ],
            [
              -0.339461112021513,
              53.04200059207521
            ],
            [
              -0.339178232755831,
              53.04208366754182
            ],
            [
              -0.339260869413021,
              53.04218461184735
            ],
            [
              -0.338956416989654,
              53.04227394841533
            ],
            [
              -0.339187613046127,
              53.04255757905288
            ],
            [
              -0.339497527400511,
              53.04246589079174
            ],
            [
              -0.339666969380244,
              53.04267583827826
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10872208',
        'Roof.Size': '4565.127433',
        'Estimated capacity': '639.1178406',
        'Title.Number': 'LL284588',
        Tenure: 'Freehold',
        'Property Address': 'land on the east side of East Road, Sleaford',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'MELBOURNE HOLDINGS LIMITED',
        'Company Registration No. (1)': '01437478',
        'Date Proprietor Added': '01/04/2015',
        POSTCODE_2: 'NG34 7EQ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '41',
        'Capital Employed': '66537129',
        Cash: '7103119',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '38962618',
        'Cost of Sales Delta': '-2331012',
        'Cost of Sales Growth': '-5.644967517',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '40536744',
        'Current Liabilities': '7957919',
        'Debt to Capital': '19.4131226',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '6624103',
        'Financial Year End': 'December',
        'Industry Keywords': 'property development company, commercial property development, property finance, residential property investment, commercial property finance, commercial development, residential property development, property development consultancy, property development finance, property development loans, project development, real estate development, haulage, real estate, building projects development, real estate sales, property, property development',
        'Net Assets': '60033233',
        'SIC 2007 Description': 'Development of building projects',
        'Shareholder Funds': '60033233',
        'Shareholders Names': 'Lynne Patricia Priestley, John Langstaff Priestley Mr',
        Status: 'Active',
        Telephone: '01529 461666',
        'Trading Address': 'Station Road, Heckington, Sleaford, Lincolnshire, NG34 9NF',
        'Trading Address 1': 'Station Road',
        'Trading Address 2': 'Heckington',
        'Trading Address 3': 'Sleaford',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '47635314',
        'Ultimate Domestic Parent Name': 'Melbourne Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/01437478',
        'Ultimate Parent Name': 'Melbourne Holdings Limited',
        'Ultimate Parent Id': 'company/gb/01437478',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '32578825',
        lat: 53.0062060223684,
        'long': -0.3929911798535948
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.392469381138203,
              53.00658483084861
            ],
            [
              -0.393000969757376,
              53.00667436534324
            ],
            [
              -0.393046686581544,
              53.006575102831675
            ],
            [
              -0.393226207847988,
              53.00660521821879
            ],
            [
              -0.3934572182127,
              53.006103633664246
            ],
            [
              -0.393277847818377,
              53.00607352065099
            ],
            [
              -0.39341465912515,
              53.00577671706915
            ],
            [
              -0.39288291828704,
              53.00568754190077
            ],
            [
              -0.392469381138203,
              53.00658483084861
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10872259',
        'Roof.Size': '10281.15209',
        'Estimated capacity': '1439.361293',
        'Title.Number': 'LL330693',
        Tenure: 'Freehold',
        'Property Address': 'Land on the north side of Boston Road, Sleaford',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'GREP1 LIMITED',
        'Company Registration No. (1)': '06000706',
        'Date Proprietor Added': '09/01/2012',
        POSTCODE_2: 'NG34 9GH',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '13',
        'Capital Employed': '150315699',
        Cash: '15143356',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '20259930',
        'Cost of Sales Delta': '7196383',
        'Cost of Sales Growth': '55.08751184',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '32299105',
        'Current Liabilities': '9181852',
        'Debt to Capital': '90.0013029',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '18359345',
        'Financial Year End': 'September',
        'Industry Keywords': 'electricity transmission, electricity suppliers, electricity prices, electricity market, electricity tariffs, electricity connections, electricity industry, electricity control, electricity brokers, electricity heating, power products, electrical generation, power generation, electrical systems, electrical supplies, electric generators, generators, electricity generation, electricity',
        'Net Assets': '15947677',
        'SIC 2007 Description': 'Production of electricity',
        'Shareholder Funds': '15947677',
        'Shareholders Names': 'Cep Biomass Ltd',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': '4th Floor The Peak, 5 Wilton Road, London, Sw1v 1an, SW1V 1AN',
        'Trading Address 1': '4th Floor The Peak',
        'Trading Address 2': '5 Wilton Road',
        'Trading Address 3': 'London',
        'Trading Address 4': 'Sw1v 1an',
        Turnover: '44573345',
        'Ultimate Domestic Parent Name': 'Cep Biomass Limited',
        'Ultimate Domestic Parent Id': 'company/gb/07640793',
        'Ultimate Parent Name': 'Cep Biomass Power Bv',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '23117253',
        lat: 52.99931228355102,
        'long': -0.38296437687812845
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.383119259842475,
              52.99998992754605
            ],
            [
              -0.382860459659742,
              53.00000061258683
            ],
            [
              -0.382865143933058,
              53.00004275018666
            ],
            [
              -0.383145733922105,
              53.00003119270538
            ],
            [
              -0.383151433340698,
              53.00008206453897
            ],
            [
              -0.38310740488738,
              53.00007589196175
            ],
            [
              -0.383074514954852,
              53.000074725511915
            ],
            [
              -0.383085713115723,
              53.000173859313755
            ],
            [
              -0.383808463814637,
              53.000145926499286
            ],
            [
              -0.383778019702778,
              52.99985980582208
            ],
            [
              -0.383621769876036,
              52.99986586296406
            ],
            [
              -0.383584393721246,
              52.999514019903685
            ],
            [
              -0.383186665472651,
              52.99952991919395
            ],
            [
              -0.383196465863376,
              52.999618605415044
            ],
            [
              -0.382837823456518,
              52.9996333268672
            ],
            [
              -0.38279531735924,
              52.99924347530814
            ],
            [
              -0.383085129856245,
              52.99923222323328
            ],
            [
              -0.383097147990976,
              52.999345302929214
            ],
            [
              -0.383732585860401,
              52.99932067840962
            ],
            [
              -0.383662315343563,
              52.9986605655145
            ],
            [
              -0.383026890501242,
              52.99868509987999
            ],
            [
              -0.38307873044261,
              52.999172171917735
            ],
            [
              -0.382551190867397,
              52.999192596892456
            ],
            [
              -0.382499505789568,
              52.99870552669813
            ],
            [
              -0.381862434699828,
              52.99873021225425
            ],
            [
              -0.381932528883645,
              52.9993903239672
            ],
            [
              -0.382689293076088,
              52.99936106284389
            ],
            [
              -0.382737066202622,
              52.999809332141375
            ],
            [
              -0.383097057305857,
              52.99979444952153
            ],
            [
              -0.383119259842475,
              52.99998992754605
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10873425',
        'Roof.Size': '4397.29784',
        'Estimated capacity': '615.6216976',
        'Title.Number': 'LL67814',
        Tenure: 'Freehold',
        'Property Address': 'Land at Fen Lane, Metheringham, Lincoln (LN4 3AQ)',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'ROCHMILLS PROPERTY LTD',
        'Company Registration No. (1)': '10798908',
        'Date Proprietor Added': '07/11/2017',
        POSTCODE_2: 'LN4 3AQ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '3',
        'Capital Employed': '4957392',
        Cash: '52265',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '1946513',
        'Current Liabilities': '99121',
        'Debt to Capital': '35.87658135',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': 'leased real estate, renting, real estate',
        'Net Assets': '3242409',
        'SIC 2007 Description': 'Letting and operating of own or leased real estate (other than Housing Association real estate and conference and exhibition services) n.e.c.',
        'Shareholder Funds': '3242409',
        'Shareholders Names': 'Rochmills (Holdings) Limited',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': '369 Wellingborough Road, Northampton, NN1 4EU',
        'Trading Address 1': '369 Wellingborough Road',
        'Trading Address 2': 'Northampton',
        'Trading Address 3': '0',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'Rochmills (Holdings) Limited',
        'Ultimate Domestic Parent Id': 'company/gb/09962909',
        'Ultimate Parent Name': 'Rochmills (Holdings) Limited',
        'Ultimate Parent Id': 'company/gb/09962909',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 53.141228288063864,
        'long': -0.3979323547032534
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.398417032718728,
              53.14128001951615
            ],
            [
              -0.3980840450806,
              53.14097950203261
            ],
            [
              -0.398153558404104,
              53.140951579456534
            ],
            [
              -0.398486138126402,
              53.14125101246753
            ],
            [
              -0.398827936088961,
              53.14111374772688
            ],
            [
              -0.398475388450936,
              53.140796426854955
            ],
            [
              -0.397023869591783,
              53.141377513660714
            ],
            [
              -0.397313162729085,
              53.14163474386983
            ],
            [
              -0.39785346623265,
              53.141422480382204
            ],
            [
              -0.397914736664691,
              53.14147949179277
            ],
            [
              -0.398417032718728,
              53.14128001951615
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10874597',
        'Roof.Size': '13069.5381',
        'Estimated capacity': '1829.735334',
        'Title.Number': 'LL183876',
        Tenure: 'Freehold',
        'Property Address': 'Land and buildings lying to the South of Hinaidi Lane, Nocton',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'LEDA PROPERTIES LIMITED',
        'Company Registration No. (1)': '01257376',
        'Date Proprietor Added': '03/05/2000',
        POSTCODE_2: 'LN4 2DB',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '44',
        'Capital Employed': '53644853',
        Cash: '673036',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '10040449',
        'Current Liabilities': '3713166',
        'Debt to Capital': '52.05280364',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'March',
        'Industry Keywords': 'agriculture, tyre, wholesale, leguminous crops, oil seeds, motor, crops, cereals, crop farming, seeds, motor vehicles, motor vehicle, farming',
        'Net Assets': '27501562',
        'SIC 2007 Description': 'Growing of cereals (except rice), leguminous crops and oil seeds',
        'Shareholder Funds': '27501562',
        'Shareholders Names': 'Andrew Leslie Basson, Mark Daniel Basson, Leslie William Basson',
        Status: 'Active',
        Telephone: '01235 526078',
        'Trading Address': 'Tyre Sales Abingdon, Marcham Road, Abingdon, Oxfordshire, OX14 1TZ',
        'Trading Address 1': 'Tyre Sales Abingdon',
        'Trading Address 2': 'Marcham Road',
        'Trading Address 3': 'Abingdon',
        'Trading Address 4': 'Oxfordshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'Leda Properties Limited',
        'Ultimate Domestic Parent Id': 'company/gb/01257376',
        'Ultimate Parent Name': 'Leda Properties Limited',
        'Ultimate Parent Id': 'company/gb/01257376',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '6327283',
        lat: 53.16470751133874,
        'long': -0.4093598612028553
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.409756822633844,
              53.165939001802066
            ],
            [
              -0.409527191203814,
              53.16561544644606
            ],
            [
              -0.40959044436706,
              53.16559992945541
            ],
            [
              -0.409831998144663,
              53.165940904430286
            ],
            [
              -0.409944530043365,
              53.16591157141537
            ],
            [
              -0.40970644772093,
              53.16557378947957
            ],
            [
              -0.409773667975143,
              53.165556167786455
            ],
            [
              -0.410021213940005,
              53.16590908901792
            ],
            [
              -0.409920633706238,
              53.165934805904435
            ],
            [
              -0.40991381386504,
              53.1660545499961
            ],
            [
              -0.410044634284897,
              53.16624274648043
            ],
            [
              -0.410185707069098,
              53.16629524253844
            ],
            [
              -0.410218419996019,
              53.166341078065265
            ],
            [
              -0.410453094460419,
              53.16628146066324
            ],
            [
              -0.410260451759624,
              53.16600928312493
            ],
            [
              -0.410347904145205,
              53.165986627106236
            ],
            [
              -0.410450761340206,
              53.1661300399223
            ],
            [
              -0.410578843388672,
              53.16609686837867
            ],
            [
              -0.410465881219103,
              53.165939476406166
            ],
            [
              -0.41022061601626,
              53.16600236862627
            ],
            [
              -0.410145654411315,
              53.16589753494716
            ],
            [
              -0.410282095746164,
              53.165860789449354
            ],
            [
              -0.409996237105201,
              53.165459710958686
            ],
            [
              -0.410156919021731,
              53.165418704353904
            ],
            [
              -0.410439519348539,
              53.16581892988189
            ],
            [
              -0.410556247040513,
              53.16578938258979
            ],
            [
              -0.410272134022364,
              53.165389586646974
            ],
            [
              -0.410424203226852,
              53.16535089200088
            ],
            [
              -0.410710331019688,
              53.16575287203116
            ],
            [
              -0.41082932770195,
              53.165722635557636
            ],
            [
              -0.410546787423932,
              53.165320703690014
            ],
            [
              -0.410670929708818,
              53.16528882796623
            ],
            [
              -0.410941997017797,
              53.16566930045554
            ],
            [
              -0.411079151369948,
              53.1656334625517
            ],
            [
              -0.410524768398567,
              53.16484781170639
            ],
            [
              -0.410403049438183,
              53.16487891052455
            ],
            [
              -0.410667622571312,
              53.165252824307494
            ],
            [
              -0.410518261854955,
              53.16529110591145
            ],
            [
              -0.410230185083667,
              53.164885323597794
            ],
            [
              -0.410103481941254,
              53.16491770406943
            ],
            [
              -0.410392388422876,
              53.16532529574295
            ],
            [
              -0.410240163121795,
              53.165364168062446
            ],
            [
              -0.4099518559047,
              53.16495658401492
            ],
            [
              -0.409838283225428,
              53.16498581323282
            ],
            [
              -0.410128400376953,
              53.16539297222334
            ],
            [
              -0.409979786298298,
              53.165431263122585
            ],
            [
              -0.409696701009513,
              53.165032198649136
            ],
            [
              -0.409573931000411,
              53.16506337249098
            ],
            [
              -0.409856845895329,
              53.165462974378066
            ],
            [
              -0.409773943004025,
              53.165483982695086
            ],
            [
              -0.408286192345885,
              53.163362904425306
            ],
            [
              -0.408367121591807,
              53.16334258997428
            ],
            [
              -0.40862729878148,
              53.1637092578873
            ],
            [
              -0.408727665195288,
              53.16368515743353
            ],
            [
              -0.408467450797608,
              53.163315432683504
            ],
            [
              -0.408643050643902,
              53.16327130122253
            ],
            [
              -0.408897787518523,
              53.16363951403087
            ],
            [
              -0.409000170388569,
              53.163613462510575
            ],
            [
              -0.408742206691095,
              53.16324349873558
            ],
            [
              -0.408913393722916,
              53.163201375598284
            ],
            [
              -0.409168541196592,
              53.16356662664248
            ],
            [
              -0.409267136096246,
              53.16354187278427
            ],
            [
              -0.409011871642883,
              53.16317572140271
            ],
            [
              -0.409259165666347,
              53.16311420730797
            ],
            [
              -0.409240951988836,
              53.16308897216668
            ],
            [
              -0.408993040105309,
              53.16315101736319
            ],
            [
              -0.408740308661485,
              53.162781213330916
            ],
            [
              -0.408639914460618,
              53.162806122505074
            ],
            [
              -0.408893253191212,
              53.16317566516908
            ],
            [
              -0.408721262187469,
              53.163219305816945
            ],
            [
              -0.408459990717122,
              53.16284992647991
            ],
            [
              -0.408357625978355,
              53.1628755282779
            ],
            [
              -0.408617388311674,
              53.16324524727445
            ],
            [
              -0.408445559564492,
              53.163288530099194
            ],
            [
              -0.408187821779517,
              53.16292072565694
            ],
            [
              -0.408089061574179,
              53.16294592590407
            ],
            [
              -0.408348855243559,
              53.163314746923504
            ],
            [
              -0.408260998659067,
              53.16333631726935
            ],
            [
              -0.407866007964386,
              53.16278894769685
            ],
            [
              -0.407829019052826,
              53.16279816233142
            ],
            [
              -0.408201231270647,
              53.16331430239449
            ],
            [
              -0.408117122369065,
              53.163335653023296
            ],
            [
              -0.407876437120161,
              53.16299216870606
            ],
            [
              -0.407758206870218,
              53.16302205285916
            ],
            [
              -0.40801638010995,
              53.16339013376051
            ],
            [
              -0.407842134732053,
              53.16343401271198
            ],
            [
              -0.407585384824977,
              53.16306388278288
            ],
            [
              -0.407494790633497,
              53.16308667454723
            ],
            [
              -0.408074418899843,
              53.1639156492526
            ],
            [
              -0.408185421580862,
              53.16388710656649
            ],
            [
              -0.407887080369464,
              53.16346041448868
            ],
            [
              -0.408012551472562,
              53.163428918905346
            ],
            [
              -0.40826959291538,
              53.163799411349366
            ],
            [
              -0.408411378327841,
              53.16376363841846
            ],
            [
              -0.408152697889397,
              53.16339294458184
            ],
            [
              -0.408236345053945,
              53.16337194735236
            ],
            [
              -0.408785893060251,
              53.16415727510205
            ],
            [
              -0.408698765910169,
              53.164179305016006
            ],
            [
              -0.408504512309167,
              53.16389865241134
            ],
            [
              -0.408390499592593,
              53.16392769456495
            ],
            [
              -0.408641370816393,
              53.16428318047885
            ],
            [
              -0.408833550701022,
              53.16423171873419
            ],
            [
              -0.409289625709843,
              53.16487950854672
            ],
            [
              -0.4092160933983,
              53.16489794465058
            ],
            [
              -0.409069206060565,
              53.16469137299991
            ],
            [
              -0.408956706701807,
              53.16471989652282
            ],
            [
              -0.409240547293677,
              53.165118523420574
            ],
            [
              -0.409084686772945,
              53.16515860429975
            ],
            [
              -0.408962146921333,
              53.16498535060539
            ],
            [
              -0.408852045640566,
              53.16501372626818
            ],
            [
              -0.409123623379159,
              53.1653960977125
            ],
            [
              -0.409235250558436,
              53.165366933084975
            ],
            [
              -0.409104250191442,
              53.16518376758122
            ],
            [
              -0.409259954623153,
              53.165143864389236
            ],
            [
              -0.409548548839759,
              53.16554758831366
            ],
            [
              -0.409459601752583,
              53.16557022378452
            ],
            [
              -0.409395580882886,
              53.165477042758795
            ],
            [
              -0.408916415522519,
              53.16559892865145
            ],
            [
              -0.4089803225954,
              53.16568707408296
            ],
            [
              -0.409281866792567,
              53.16561118151032
            ],
            [
              -0.409541623304268,
              53.16599384354306
            ],
            [
              -0.409756822633844,
              53.165939001802066
            ]
          ],
          [
            [
              -0.410197000489098,
              53.166273008468885
            ],
            [
              -0.410064932254752,
              53.16621973363138
            ],
            [
              -0.409962375127889,
              53.16607227903632
            ],
            [
              -0.410016880221293,
              53.16605871271012
            ],
            [
              -0.410130465845064,
              53.16622348513839
            ],
            [
              -0.410158705299644,
              53.16621631056774
            ],
            [
              -0.410197000489098,
              53.166273008468885
            ]
          ],
          [
            [
              -0.410070150027673,
              53.16589643721613
            ],
            [
              -0.409796234893678,
              53.165512238846055
            ],
            [
              -0.409877190763535,
              53.165491294419475
            ],
            [
              -0.41015008310088,
              53.16587484965742
            ],
            [
              -0.410070150027673,
              53.16589643721613
            ]
          ],
          [
            [
              -0.409723296198046,
              53.165502454957696
            ],
            [
              -0.409660937016555,
              53.16551807385315
            ],
            [
              -0.409374906985414,
              53.16511375515524
            ],
            [
              -0.409436375218818,
              53.16509794471625
            ],
            [
              -0.409723296198046,
              53.165502454957696
            ]
          ],
          [
            [
              -0.409324174026891,
              53.16492824561599
            ],
            [
              -0.409424922122898,
              53.16507190090692
            ],
            [
              -0.409351838089018,
              53.16509034308189
            ],
            [
              -0.409251239713324,
              53.16494668972791
            ],
            [
              -0.409324174026891,
              53.16492824561599
            ]
          ],
          [
            [
              -0.409440264057281,
              53.16560772306861
            ],
            [
              -0.40966447874646,
              53.165919968920406
            ],
            [
              -0.409623706307885,
              53.165930302289695
            ],
            [
              -0.409401601745097,
              53.16561763504458
            ],
            [
              -0.409440264057281,
              53.16560772306861
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10874755',
        'Roof.Size': '4584.533548',
        'Estimated capacity': '641.8346967',
        'Title.Number': 'LL330403',
        Tenure: 'Freehold',
        'Property Address': 'Nocton Nurseries, Nocton (LN4 2AB)',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'MUNKS AGRICULTURAL CONTRACTORS LIMITED',
        'Company Registration No. (1)': '06525585',
        'Date Proprietor Added': '21/12/2011',
        POSTCODE_2: 'LN4 2EH',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '12',
        'Capital Employed': '10346261',
        Cash: '540',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '2778312',
        'Current Liabilities': '2939671',
        'Debt to Capital': '41.85555819',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'September',
        'Industry Keywords': 'landscape gardening, crop farming, farming, crops, agricultural services',
        'Net Assets': '7725031',
        'SIC 2007 Description': 'Support activities for crop production',
        'Shareholder Funds': '7725031',
        'Shareholders Names': 'Damian Wesley Munks Mr, Stefan Munks Mr',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Wood Lane, South Kyme, Lincoln, Lincolnshire, LN4 4AB',
        'Trading Address 1': 'Wood Lane',
        'Trading Address 2': 'South Kyme',
        'Trading Address 3': 'Lincoln',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-161359',
        lat: 53.15419571644612,
        'long': -0.4146311258079968
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.41455486324585,
              53.15465179549034
            ],
            [
              -0.415271945632539,
              53.15447398991172
            ],
            [
              -0.414854063097824,
              53.15386979177675
            ],
            [
              -0.414582077353837,
              53.15393746125429
            ],
            [
              -0.414439073635372,
              53.15373130710247
            ],
            [
              -0.413992515622209,
              53.15384079135938
            ],
            [
              -0.41455486324585,
              53.15465179549034
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10881229',
        'Roof.Size': '4053.815211',
        'Estimated capacity': '567.5341295',
        'Title.Number': 'LL58639',
        Tenure: 'Freehold',
        'Property Address': 'Tesco Store, 65 Northgate, Sleaford (NG34 7BB)',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'TESCO STORES LIMITED',
        'Company Registration No. (1)': '00519500',
        'Date Proprietor Added': '',
        POSTCODE_2: 'NG34 7BB',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '67',
        'Capital Employed': '10128000000',
        Cash: '1481000000',
        Charges: 'Outstanding, Partially Satisfied, Satisfied',
        'Cost of Sales': '40273000000',
        'Cost of Sales Delta': '566000000',
        'Cost of Sales Growth': '1.425441358',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '8184000000',
        'Current Liabilities': '9318000000',
        'Debt to Capital': '71.08402756',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '1446000000',
        'Financial Year End': 'February',
        'Industry Keywords': 'governance, waste, retail units, retail merchandising, b2c, retail display, retail trade, retail business, retail market, retail shop, retail outlets, health products, packaging products, sustainable development, cash flow, community grants, corporate responsibility, software, books, market leader, oak, internet services, petrol, carbon footprint, electronics, carbon reduction, garden, clothing, market share, retailing, financial services, internet, corporate governance, general merchandise, tea, retailer, stalls, food waste, grocer, toys, furniture, shop, stock exchange, supermarket, grocery, tobacco, retail stores, retail sales, stores, beverages, food, retail',
        'Net Assets': '5623000000',
        'SIC 2007 Description': 'Retail sale in non-specialised stores with food, beverages or tobacco predominating',
        'Shareholder Funds': '5623000000',
        'Shareholders Names': 'Tesco Holdings Ltd',
        Status: 'Active',
        Telephone: '0345 610 6775',
        'Trading Address': 'Tesco House Shire Park, Cirrus Building, 1a Shires Park, Kestrel Way, Welwyn Garden City, Hertfordshire, AL7 1GA',
        'Trading Address 1': 'Tesco House Shire Park',
        'Trading Address 2': 'Cirrus Building',
        'Trading Address 3': '1a Shires Park, Kestrel Way',
        'Trading Address 4': 'Welwyn Garden City, Hertfordshire',
        Turnover: '42219000000',
        'Ultimate Domestic Parent Name': 'Tesco Stores Limited',
        'Ultimate Domestic Parent Id': 'company/gb/00519500',
        'Ultimate Parent Name': 'Tesco Plc',
        'Ultimate Parent Id': 'company/gb/00445790',
        'Web Address 1': 'http://www.tescoplc.com',
        'Web Address 2': 'http://www.tescobank.co.uk',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-1134000000',
        lat: 53.0012802171248,
        'long': -0.41232307125842305
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.412797134314709,
              53.001591392424274
            ],
            [
              -0.412820465191748,
              53.001560687959056
            ],
            [
              -0.412713676476966,
              53.00094955323966
            ],
            [
              -0.41182465418626,
              53.00100915409221
            ],
            [
              -0.411922140411335,
              53.001605421658105
            ],
            [
              -0.412643212291277,
              53.00155832192102
            ],
            [
              -0.412797134314709,
              53.001591392424274
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10882351',
        'Roof.Size': '15853.28947',
        'Estimated capacity': '2219.460526',
        'Title.Number': 'LL67011',
        Tenure: 'Freehold',
        'Property Address': 'land on the south side of Green Man Lane, Scopwick',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'BLANKNEY ESTATES LIMITED',
        'Company Registration No. (1)': '00362871',
        'Date Proprietor Added': '12/06/1990',
        POSTCODE_2: 'LN5 0AX',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '80',
        'Capital Employed': '20717968',
        Cash: '1132576',
        Charges: 'Outstanding',
        'Cost of Sales': '12183423',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '8856987',
        'Current Liabilities': '3124506',
        'Debt to Capital': '41.10106191',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '2237978',
        'Financial Year End': 'April',
        'Industry Keywords': 'cattle farming, agriculture, golf course, holiday, feeds, golf, construction industry, property, residential property, commercial property, crop farming, oil seeds, leguminous crops, processing plant, crops, seeds, cereals, plants, plant, arable farming, farming',
        'Net Assets': '14042964',
        'SIC 2007 Description': 'Growing of cereals (except rice), leguminous crops and oil seeds',
        'Shareholder Funds': '14042964',
        'Shareholders Names': 'H.S. Ripley, A. Lott, G.C.H. Smith Jpm Parker 87 Sett & J.P.M. Parker Jpm Parker 87 Sett & G.J. Page Jpm Parker 87 Sett, G.C.H. Smith Jpm Parker 99 Disc Sett & G.J. Page Jpm Parker 99 Disc Sett & J.P.M. Parker Jpm Parker 99 Disc Sett, S. Lott, R.W. Parker, J.P.M. Parker Raymond Caudwell 85 Sett & G.C.H. Smith Raymond Caudwell 85 Sett, G.C.H. Smith Eric Parker 82 Sett (Jpmp Sector) & J.P.M. Parker Eric Parker 82 Sett (Jpmp Sector) & J.L. Parker Eric Parker 82 Sett (Jpmp Sector), G.J. Page Eric Parker 65 Sett (New) & G.C.H. Smith Eric Parker 65 Sett (New), J.P.M. Parker Eric Parker 82 Sett (Jpmp Sector) & J.L. Parker Eric Parker 82 Sett Jpmp Sector & G.C.H. Smith Eric Parker 82 Sett (Jpmp Sector), G.C.H. Smith Jpm Parker 87 Sett & G.J. Page Jpm Parker 87 Sett & J.P.M. Parker Jpm Parker 87 Sett, G.C.H. Smith Jpm Parker 96 Sett & G.J. Page Jpm Parker 96 Sett & J.P.M. Parker Jpm Parker 96 Sett, J.P.M. Parker',
        Status: 'Active',
        Telephone: '01522 810264',
        'Trading Address': 'Temple Grange, Navenby, Lincoln, LN5 0AX',
        'Trading Address 1': 'Temple Grange',
        'Trading Address 2': 'Navenby',
        'Trading Address 3': 'Lincoln',
        'Trading Address 4': '0',
        Turnover: '14102258',
        'Ultimate Domestic Parent Name': 'Blankney Estates Limited',
        'Ultimate Domestic Parent Id': 'company/gb/00362871',
        'Ultimate Parent Name': 'Blankney Estates Limited',
        'Ultimate Parent Id': 'company/gb/00362871',
        'Web Address 1': 'http://www.blankney.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '5732481',
        lat: 53.107776163021384,
        'long': -0.4635453904404555
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.464039888114533,
              53.10792312638055
            ],
            [
              -0.463583017372282,
              53.10652820107624
            ],
            [
              -0.462797808709619,
              53.1066212681637
            ],
            [
              -0.463263558855929,
              53.108043011229164
            ],
            [
              -0.462879649599128,
              53.108088308221795
            ],
            [
              -0.463023476388207,
              53.10852932189579
            ],
            [
              -0.46320517062289,
              53.10850748479549
            ],
            [
              -0.463302639331754,
              53.10881161273967
            ],
            [
              -0.464441416044733,
              53.10867905288186
            ],
            [
              -0.46418825996284,
              53.10790562249026
            ],
            [
              -0.464039888114533,
              53.10792312638055
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10883080',
        'Roof.Size': '3980.827415',
        'Estimated capacity': '557.3158381',
        'Title.Number': 'LL194225',
        Tenure: 'Freehold',
        'Property Address': 'Land lying to the west of East Road, Sleaford',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'MELBOURNE HOLDINGS LIMITED',
        'Company Registration No. (1)': '01437478',
        'Date Proprietor Added': '23/03/2005',
        POSTCODE_2: 'NG34 8GL',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '41',
        'Capital Employed': '66537129',
        Cash: '7103119',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '38962618',
        'Cost of Sales Delta': '-2331012',
        'Cost of Sales Growth': '-5.644967517',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '40536744',
        'Current Liabilities': '7957919',
        'Debt to Capital': '19.4131226',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '6624103',
        'Financial Year End': 'December',
        'Industry Keywords': 'property development company, commercial property development, property finance, residential property investment, commercial property finance, commercial development, residential property development, property development consultancy, property development finance, property development loans, project development, real estate development, haulage, real estate, building projects development, real estate sales, property, property development',
        'Net Assets': '60033233',
        'SIC 2007 Description': 'Development of building projects',
        'Shareholder Funds': '60033233',
        'Shareholders Names': 'Lynne Patricia Priestley, John Langstaff Priestley Mr',
        Status: 'Active',
        Telephone: '01529 461666',
        'Trading Address': 'Station Road, Heckington, Sleaford, Lincolnshire, NG34 9NF',
        'Trading Address 1': 'Station Road',
        'Trading Address 2': 'Heckington',
        'Trading Address 3': 'Sleaford',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '47635314',
        'Ultimate Domestic Parent Name': 'Melbourne Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/01437478',
        'Ultimate Parent Name': 'Melbourne Holdings Limited',
        'Ultimate Parent Id': 'company/gb/01437478',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '32578825',
        lat: 53.00903935751496,
        'long': -0.39826953464388754
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.398876499509579,
              53.00888248023775
            ],
            [
              -0.398088275613441,
              53.008750858015645
            ],
            [
              -0.398040451553372,
              53.008854948655504
            ],
            [
              -0.397693225182194,
              53.00879695925771
            ],
            [
              -0.39753935273946,
              53.009132374916646
            ],
            [
              -0.398837912766377,
              53.009349177036775
            ],
            [
              -0.398928067092238,
              53.00915242851064
            ],
            [
              -0.398765116796166,
              53.00912524199399
            ],
            [
              -0.398876499509579,
              53.00888248023775
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10883101',
        'Roof.Size': '8118.30209',
        'Estimated capacity': '1136.562293',
        'Title.Number': 'LL366951',
        Tenure: 'Freehold',
        'Property Address': 'Royal Oak Bridge Warehouse, East Road, Sleaford (NG34 7EQ)',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'GEORGE H KIME & CO LIMITED',
        'Company Registration No. (1)': '00345929',
        'Date Proprietor Added': '30/06/2016',
        POSTCODE_2: 'NG34 7DG',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '81',
        'Capital Employed': '3277404',
        Cash: '77456',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '2278391',
        'Current Liabilities': '2816442',
        'Debt to Capital': '70.94368647',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'March',
        'Industry Keywords': 'road haulage services, intermodal transport, rail transport, road freight, transport services, air freight services, freight services, storage, shipping, blue chip, next day delivery, delivery, vehicles, pallet, pallet distribution, haulage, freight road transport, freight, road haulage, freight transport, road transport, transport',
        'Net Assets': '1770647',
        'SIC 2007 Description': 'Freight transport by road',
        'Shareholder Funds': '1770647',
        'Shareholders Names': 'Jennifer Mary Robson Mrs, Robert Hugh Kime Mr, Nigel Robert Kime & Andrew Clive Severn, Nigel Robert Kime Mr, Barbara Mary Kime Mrs',
        Status: 'Active',
        Telephone: '01205 870282',
        'Trading Address': 'Main Road, Wrangle, Boston, Lincolnshire, PE22 9AW',
        'Trading Address 1': 'Main Road',
        'Trading Address 2': 'Wrangle',
        'Trading Address 3': 'Boston',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.georgehkime.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-538051',
        lat: 53.006549931861855,
        'long': -0.39453525846984544
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.393712969691948,
              53.00695881311488
            ],
            [
              -0.394572036616342,
              53.00719238018246
            ],
            [
              -0.395357592592144,
              53.00614106950503
            ],
            [
              -0.394498393098779,
              53.00590750619004
            ],
            [
              -0.393712969691948,
              53.00695881311488
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10883192',
        'Roof.Size': '4841.596908',
        'Estimated capacity': '677.8235671',
        'Title.Number': 'LL85608',
        Tenure: 'Freehold',
        'Property Address': 'Unit 11, Woodbridge Road, Sleaford (NG34 7EW)',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'SLEAFORD QUALITY FOODS LIMITED',
        'Company Registration No. (1)': '00943156',
        'Date Proprietor Added': '04/01/2018',
        POSTCODE_2: 'NG34 7EG',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '51',
        'Capital Employed': '8035449',
        Cash: '101999',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '44788907',
        'Cost of Sales Delta': '-311473',
        'Cost of Sales Growth': '-0.690621675',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '26932145',
        'Current Liabilities': '24602824',
        'Debt to Capital': '75.50917599',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '256784',
        'Financial Year End': 'March',
        'Industry Keywords': 'food manufacture, food service, food processing, food & beverages, product search, fruit, dried fruit, vegetables, fruits, quality ingredients, food products, manufacturing, catering, spices, pasta, rice, foodservice, seeds, food manufacturing, herbs, food',
        'Net Assets': '7993382',
        'SIC 2007 Description': 'Manufacture of other food products n.e.c.',
        'Shareholder Funds': '7993382',
        'Shareholders Names': 'Sleaford Food Group Ltd',
        Status: 'Active',
        Telephone: '01529 305000',
        'Trading Address': 'Woodbridge Road, East Rd Indust Estate, Sleaford, Lincolnshire, NG34 7JX',
        'Trading Address 1': 'Woodbridge Road',
        'Trading Address 2': 'East Rd Indust Estate',
        'Trading Address 3': 'Sleaford',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '55583698',
        'Ultimate Domestic Parent Name': 'Jain International Foods Limited',
        'Ultimate Domestic Parent Id': 'company/gb/07045183',
        'Ultimate Parent Name': 'Jain Irrigation Systems Limited',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.sleafordqf.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '2329321',
        lat: 53.005678075355476,
        'long': -0.4004372457555177
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.400360663018571,
              53.00623616496106
            ],
            [
              -0.400391432786105,
              53.00631497269847
            ],
            [
              -0.400680749775006,
              53.00627391352629
            ],
            [
              -0.400654883956016,
              53.00620757818421
            ],
            [
              -0.400608334383916,
              53.006039556111375
            ],
            [
              -0.400411761843547,
              53.006059387386074
            ],
            [
              -0.400386111793196,
              53.00596707343956
            ],
            [
              -0.400645515465492,
              53.00594089517778
            ],
            [
              -0.400624212875982,
              53.00586401285727
            ],
            [
              -0.400959013403316,
              53.00583021744162
            ],
            [
              -0.400871031854127,
              53.00551294176541
            ],
            [
              -0.40075075604181,
              53.005525079159455
            ],
            [
              -0.400655255506588,
              53.00518145102624
            ],
            [
              -0.399976387944001,
              53.005249993289375
            ],
            [
              -0.400252802498213,
              53.00624703045132
            ],
            [
              -0.400360663018571,
              53.00623616496106
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11103021',
        'Roof.Size': '3832.400898',
        'Estimated capacity': '536.5361257',
        'Title.Number': 'LL266396',
        Tenure: 'Freehold',
        'Property Address': '37 Skirbeck Road, Boston (PE21 6DA)',
        District: 'BOSTON',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'PORT OF BOSTON LIMITED',
        'Company Registration No. (1)': '02198182',
        'Date Proprietor Added': '09/03/2006',
        POSTCODE_2: 'PE21 6DB',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '32',
        'Capital Employed': '22537000',
        Cash: '56000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '3518000',
        'Cost of Sales Delta': '624000',
        'Cost of Sales Growth': '21.56185211',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '7810000',
        'Current Liabilities': '2010000',
        'Debt to Capital': '36.16735243',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '926000',
        'Financial Year End': 'September',
        'Industry Keywords': 'agency services, stock control, brokering, port operations, transport, transport services, supply chain solutions, supply chain, cargo handling',
        'Net Assets': '15669000',
        'SIC 2007 Description': 'Cargo handling for water transport activities of division 50',
        'Shareholder Funds': '15669000',
        'Shareholders Names': 'Victoria Group Holdings Ltd',
        Status: 'Active',
        Telephone: '01205 365571',
        'Trading Address': 'Dock Office, The Dock, Boston, Lincolnshire, PE21 6BN',
        'Trading Address 1': 'Dock Office',
        'Trading Address 2': 'The Dock',
        'Trading Address 3': 'Boston',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '6400000',
        'Ultimate Domestic Parent Name': 'Victoria Ports Limited',
        'Ultimate Domestic Parent Id': 'company/gb/11100367',
        'Ultimate Parent Name': 'Victoria Ports Limited',
        'Ultimate Parent Id': 'company/gb/11100367',
        'Web Address 1': 'http://www.portofboston.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '5800000',
        lat: 52.96966586974221,
        'long': -0.015289422449548111
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.015070995051978,
              52.96929233212319
            ],
            [
              -0.014716792927717,
              52.9698591229549
            ],
            [
              -0.015510322620103,
              52.97003987181691
            ],
            [
              -0.015859856931237,
              52.9694707531005
            ],
            [
              -0.015070995051978,
              52.96929233212319
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10884342',
        'Roof.Size': '6277.830417',
        'Estimated capacity': '878.8962584',
        'Title.Number': 'LL273086',
        Tenure: 'Freehold',
        'Property Address': 'Land on the east side of Mere Road, Branston',
        District: 'NORTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'BRANSTON HOLDINGS LIMITED',
        'Company Registration No. (1)': '05866320',
        'Date Proprietor Added': '04/09/2006',
        POSTCODE_2: 'LN4 1NJ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '14',
        'Capital Employed': '48836000',
        Cash: '1655000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '134717000',
        'Cost of Sales Delta': '18263000',
        'Cost of Sales Growth': '15.68258712',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '28042000',
        'Current Liabilities': '15819000',
        'Debt to Capital': '33.45139587',
        'Dividends Payable': '1700000',
        'Dormant Company': 'No',
        EBITDA: '10317000',
        'Financial Year End': 'July',
        'Industry Keywords': 'sweet potatoes, vegetables, baked potatoes, jacket potatoes, account management, water recycling, recycling plant, factory, solar pv, carbon trust standard, prepared foods, carbon, head offices, supermarket, labelling, marketing, holding companies management, potato, potatoes, seed potatoes',
        'Net Assets': '43027000',
        'SIC 2007 Description': 'Activities of head offices',
        'Shareholder Funds': '43027000',
        'Shareholders Names': 'Thomas James Truscott, John Robert Ward, Nelstrop Farms Ltd, James Dean, Malcolm John Mair, Ian James Mair, Roger Mowbray Warwick, Helen Jane Mair, Trustees of Lockwood 1995 Pension Fund, Christopher Edward Newsum Howard, Judith Lockwood, Walronds Park Ltd, David Nelstrop, Marigold Jean Mair, John William Lockwood, Graeme Beattie, C.J. Nelstrop, Robert Nelstrop & Sarah Nelstrop, William Nelstrop, F.G. Battle & Sons Ltd, Robert Nelstrop Farms Ltd, John Mair',
        Status: 'Active',
        Telephone: '01522 794411',
        'Trading Address': 'Mere Road, Branston, Lincoln, Lincolnshire, LN4 1NJ',
        'Trading Address 1': 'Mere Road',
        'Trading Address 2': 'Branston',
        'Trading Address 3': 'Lincoln',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '152455000',
        'Ultimate Domestic Parent Name': 'Branston Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/05866320',
        'Ultimate Parent Name': 'Branston Holdings Limited',
        'Ultimate Parent Id': 'company/gb/05866320',
        'Web Address 1': 'http://www.branston.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '12223000',
        lat: 53.17957214185094,
        'long': -0.4718419711544652
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.47243908039922,
              53.1799478431691
            ],
            [
              -0.472634441799249,
              53.17936430374294
            ],
            [
              -0.471244871254686,
              53.179196440454206
            ],
            [
              -0.471049491845573,
              53.179779977486675
            ],
            [
              -0.47243908039922,
              53.1799478431691
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10888600',
        'Roof.Size': '4117.064414',
        'Estimated capacity': '576.389018',
        'Title.Number': 'LL316969',
        Tenure: 'Freehold',
        'Property Address': 'land on the west side of West Road, Billingborough, Sleaford',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'CROMA LIMITED',
        'Company Registration No. (1)': '08301048',
        'Date Proprietor Added': '26/06/2019',
        POSTCODE_2: 'NG34 0JE',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '7',
        'Capital Employed': '1712428',
        Cash: '0',
        Charges: '0',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '14012',
        'Current Liabilities': '2317715',
        'Debt to Capital': '82.23651121',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'March',
        'Industry Keywords': 'site locations, business management services, personal data, photographic processing, social media, registered office service, bespoke tailoring, medical doctors, skin care, cosmetic medicine, renting, real estate, leased real estate',
        'Net Assets': '715894',
        'SIC 2007 Description': 'Letting and operating of own or leased real estate (other than Housing Association real estate and conference and exhibition services) n.e.c.',
        'Shareholder Funds': '715894',
        'Shareholders Names': 'Pradeep Kaur Lalli, Bimaljit Singh, Navjeet Singh Lalli, Resham Kaur, Ranjeet Singh Lalli',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': '511 Uppingham Road, Leicester, Leicestershire, LE5 6QB',
        'Trading Address 1': '511 Uppingham Road',
        'Trading Address 2': 'Leicester',
        'Trading Address 3': 'Leicestershire',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.cromapharma.fr',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-2303703',
        lat: 52.89276865080382,
        'long': -0.34428525336303084
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.343712989513077,
              52.892573447819466
            ],
            [
              -0.343846960406106,
              52.89289618135443
            ],
            [
              -0.343673369569739,
              52.89292252990542
            ],
            [
              -0.343705096111832,
              52.89299885084117
            ],
            [
              -0.343878687235987,
              52.89297250224644
            ],
            [
              -0.343931919482822,
              52.89310081745845
            ],
            [
              -0.344886609061158,
              52.89295553475759
            ],
            [
              -0.34466034565643,
              52.89242527772238
            ],
            [
              -0.343712989513077,
              52.892573447819466
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10897120',
        'Roof.Size': '5941.736187',
        'Estimated capacity': '831.8430662',
        'Title.Number': 'LL148372',
        Tenure: 'Freehold',
        'Property Address': 'land on the south side of Spalding Road, Bourne',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'LINCOLNSHIRE HERBS LTD',
        'Company Registration No. (1)': '03396683',
        'Date Proprietor Added': '10/10/2016',
        POSTCODE_2: 'PE10 0AU',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '23',
        'Capital Employed': '8190646',
        Cash: '418847',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '16848922',
        'Cost of Sales Delta': '2203717',
        'Cost of Sales Growth': '15.04736192',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '3806558',
        'Current Liabilities': '4905790',
        'Debt to Capital': '61.54654595',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '336306',
        'Financial Year End': 'December',
        'Industry Keywords': 'retail, food service, agriculture, steps, sustainability, networking, flats, retailer, environment, career opportunities, herbs supplier, fresh produce, cooking, food, food products, food production, cereals, leguminous crops, oil seeds, seeds, crops, crop farming, herb growers, farming, herbs',
        'Net Assets': '5036032',
        'SIC 2007 Description': 'Growing of other non-perennial crops',
        'Shareholder Funds': '5036032',
        'Shareholders Names': 'Spisa Holding Ab',
        Status: 'Active',
        Telephone: '01778 424224',
        'Trading Address': 'Spalding Road, Bourne, Lincolnshire, PE10 0AT',
        'Trading Address 1': 'Spalding Road',
        'Trading Address 2': 'Bourne',
        'Trading Address 3': 'Lincolnshire',
        'Trading Address 4': '0',
        Turnover: '17178432',
        'Ultimate Domestic Parent Name': 'Lincolnshire Herbs Ltd',
        'Ultimate Domestic Parent Id': 'company/gb/03396683',
        'Ultimate Parent Name': 'Gavia Food Holding Ab',
        'Ultimate Parent Id': 'company/se/556688-8649',
        'Web Address 1': 'http://www.lincolnshire-herbs.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-1099232',
        lat: 52.77071159704197,
        'long': -0.350627763578112
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.351074583677993,
              52.76984484354614
            ],
            [
              -0.351220983523245,
              52.770493655508346
            ],
            [
              -0.351111211949491,
              52.770503547797105
            ],
            [
              -0.351173477813525,
              52.77077799436985
            ],
            [
              -0.349122417891589,
              52.770948070609016
            ],
            [
              -0.349070862680879,
              52.770719983167545
            ],
            [
              -0.348878624229963,
              52.77073501910595
            ],
            [
              -0.348957487183168,
              52.77108368980358
            ],
            [
              -0.349550357455634,
              52.77103484957247
            ],
            [
              -0.349565197596341,
              52.77110104636696
            ],
            [
              -0.349799401952023,
              52.771081738182986
            ],
            [
              -0.349784561461257,
              52.77101554141478
            ],
            [
              -0.350089099433925,
              52.7709904681035
            ],
            [
              -0.350111038033403,
              52.7710885000427
            ],
            [
              -0.351340147506848,
              52.7709845750202
            ],
            [
              -0.351310213409747,
              52.77083914213806
            ],
            [
              -0.351598316116301,
              52.77081743275976
            ],
            [
              -0.351391001958938,
              52.76990920781977
            ],
            [
              -0.351256873242632,
              52.76992038004187
            ],
            [
              -0.35123681543295,
              52.76983136495676
            ],
            [
              -0.351074583677993,
              52.76984484354614
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10912064',
        'Roof.Size': '5434.664069',
        'Estimated capacity': '760.8529697',
        'Title.Number': 'LL352853',
        Tenure: 'Freehold',
        'Property Address': 'Land at Bradston Works, Baston Outgang Road, Baston, Peterborough (PE6 9PT)',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'PMK RECYCLING LIMITED',
        'Company Registration No. (1)': '05486583',
        'Date Proprietor Added': '20/10/2014',
        POSTCODE_2: 'PE6 9PT',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '15',
        'Capital Employed': '3136112',
        Cash: '949315',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '1540809',
        'Current Liabilities': '861379',
        'Debt to Capital': '35.03377494',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'September',
        'Industry Keywords': 'recycling, scrap, non-metal waste, non-metal scrap, sorted materials recovery, materials recovery, waste',
        'Net Assets': '2597019',
        'SIC 2007 Description': 'Recovery of sorted materials',
        'Shareholder Funds': '2597019',
        'Shareholders Names': 'Damian Pumpr, Ahmet Rushti, Phillips Naomi, Susan Pumpr',
        Status: 'Active',
        Telephone: '01778 561144',
        'Trading Address': 'Star Lane House, Star Lane, Stamford, Lincolnshire, PE9 1PH',
        'Trading Address 1': 'Star Lane House',
        'Trading Address 2': 'Star Lane',
        'Trading Address 3': 'Stamford',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 52.71676445511988,
        'long': -0.33684277479539476
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.33656925618641,
              52.71693596923815
            ],
            [
              -0.336485103214565,
              52.71697641511303
            ],
            [
              -0.336906058009209,
              52.71729851962215
            ],
            [
              -0.337435745235469,
              52.71704297282515
            ],
            [
              -0.337271304400126,
              52.71691713614087
            ],
            [
              -0.337186088938586,
              52.71695828685827
            ],
            [
              -0.33712163463494,
              52.71690901352139
            ],
            [
              -0.337468717134089,
              52.716744317883496
            ],
            [
              -0.336830768496387,
              52.71625743088653
            ],
            [
              -0.336127442672184,
              52.71659801703545
            ],
            [
              -0.33656925618641,
              52.71693596923815
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10912292',
        'Roof.Size': '4839.497151',
        'Estimated capacity': '677.5296011',
        'Title.Number': 'LL318521',
        Tenure: 'Freehold',
        'Property Address': 'Land on the south and west side of Tunnel Bank, Bourne',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'T M TRUSTEES LIMITED',
        'Company Registration No. (1)': '03094287',
        'Date Proprietor Added': '14/05/2015',
        POSTCODE_2: 'PE10 0DJ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '25',
        'Capital Employed': '0',
        Cash: '0',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Not Rated',
        'Current Assets': '0',
        'Current Liabilities': '0',
        'Debt to Capital': '0',
        'Dividends Payable': '0',
        'Dormant Company': 'Yes',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': '0',
        'Net Assets': '0',
        'SIC 2007 Description': 'Non-trading company',
        'Shareholder Funds': '0',
        'Shareholders Names': 'Graham Macdonald Muir Mr, Joseph Brian Talbot Mr',
        Status: 'Active',
        Telephone: '0115 841 5000',
        'Trading Address': '55 Maid Marian Way, Nottingham, NG1 6GE',
        'Trading Address 1': '55 Maid Marian Way',
        'Trading Address 2': 'Nottingham',
        'Trading Address 3': '0',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 52.7576682025253,
        'long': -0.3628342247183551
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.363383095170329,
              52.75791964192685
            ],
            [
              -0.363231653804772,
              52.75729477962147
            ],
            [
              -0.362271575631355,
              52.75738042376057
            ],
            [
              -0.362423148242986,
              52.75800537907907
            ],
            [
              -0.362837996298999,
              52.75796831057906
            ],
            [
              -0.36287020674249,
              52.75810127527573
            ],
            [
              -0.363100084319963,
              52.758080712739854
            ],
            [
              -0.363067869819581,
              52.75794783795834
            ],
            [
              -0.363383095170329,
              52.75791964192685
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10912345',
        'Roof.Size': '4058.385521',
        'Estimated capacity': '568.1739729',
        'Title.Number': 'LL128781',
        Tenure: 'Freehold',
        'Property Address': 'land on the west side of Cherry Holt Road, Bourne',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'BRANCH BROS (MARKET DEEPING) LIMITED',
        'Company Registration No. (1)': '00921887',
        'Date Proprietor Added': '18/04/1996',
        POSTCODE_2: 'PE10 9JR',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '52',
        'Capital Employed': '1346002',
        Cash: '953662',
        Charges: 'Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '1304607',
        'Current Liabilities': '400855',
        'Debt to Capital': '28.7780282',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': 'family business, garden, diy, temporary structures, basic materials, building products, building supplies, public buildings, garden products, fence panels, garden buildings, power tools, merchant, car care, landscaping products, key cutting, cutting tools, loft insulation, key cutting service, kitchen worktops, retail, merchants, landscape, glass, timber, hardware, retail hardware, builders merchants, paints, painting, building materials, building',
        'Net Assets': '1244146',
        'SIC 2007 Description': 'Agents involved in the sale of timber and building materials',
        'Shareholder Funds': '1244146',
        'Shareholders Names': 'Linda Branch Mrs, Ronald Branch, Ashley Branch',
        Status: 'Active',
        Telephone: '01778 342255',
        'Trading Address': '106 Bridge Street, Deeping St. James, Peterborough, Cambridgeshire, PE6 8EH',
        'Trading Address 1': '106 Bridge Street',
        'Trading Address 2': 'Deeping St. James',
        'Trading Address 3': 'Peterborough',
        'Trading Address 4': 'Cambridgeshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.branchbros.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '903752',
        lat: 52.7634086205938,
        'long': -0.36313372967841373
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.362818313313793,
              52.76378849362619
            ],
            [
              -0.363633643604533,
              52.76376836641221
            ],
            [
              -0.363611403597518,
              52.763436489877336
            ],
            [
              -0.363341111439278,
              52.76344319002287
            ],
            [
              -0.363308024240614,
              52.76295005390967
            ],
            [
              -0.362762995089288,
              52.76296357063244
            ],
            [
              -0.362818313313793,
              52.76378849362619
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10913920',
        'Roof.Size': '13947.59232',
        'Estimated capacity': '1952.662925',
        'Title.Number': 'LL78137',
        Tenure: 'Freehold',
        'Property Address': 'Tarmac Precast Concrete, Barholm Road, Tallington, Stamford (PE9 4RL)',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'TARMAC BUILDING PRODUCTS LIMITED',
        'Company Registration No. (1)': '04026569',
        'Date Proprietor Added': '01/09/2011',
        POSTCODE_2: 'PE9 4RL',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '20',
        'Capital Employed': '88720000',
        Cash: '27626000',
        Charges: 'Outstanding',
        'Cost of Sales': '154591000',
        'Cost of Sales Delta': '6634000',
        'Cost of Sales Growth': '4.483735139',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '98674000',
        'Current Liabilities': '69903000',
        'Debt to Capital': '54.14536353',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '17608000',
        'Financial Year End': 'December',
        'Industry Keywords': 'construction contractors, construction services, general construction, commercial construction, construction engineering, infrastructure, environment, sustainable building, temporary structures, civil construction, construction work, public buildings, construction projects, building site, basic materials, construction company, cement plants, building construction, building projects, construction industry, construction software, building industry, bitumen, construction site, building supplies, asset management, sports, delivery, houses, vehicles, road planing, hospitals, technical expertise, car park, health safety, technical support, hydraulic lime, decorative aggregates, lime mortar, plants, carbon footprint, sports pitches, screed, mortars, soil, silo, built environment, asphalt surfacing, manufacturing, building products, non-metallic mineral products, maintenance, sustainable construction, mineral products, concrete, highway maintenance, cement, lime, asphalt, tarmac, construction, building, building materials',
        'Net Assets': '72736000',
        'SIC 2007 Description': 'Manufacture of concrete products for construction purposes',
        'Shareholder Funds': '72736000',
        'Shareholders Names': 'Tarmac Holdings Limited',
        Status: 'Active',
        Telephone: '0333 003 4555',
        'Trading Address': 'Interchange 10 Railway Drive, Wolverhampton, Wv1 1lh, WV1 1LH',
        'Trading Address 1': 'Interchange 10 Railway Drive',
        'Trading Address 2': 'Wolverhampton',
        'Trading Address 3': 'Wv1 1lh',
        'Trading Address 4': '0',
        Turnover: '239641000',
        'Ultimate Domestic Parent Name': 'Crh (UK) Limited',
        'Ultimate Domestic Parent Id': 'company/gb/01380120',
        'Ultimate Parent Name': 'Donegal Lime',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.tarmac.com',
        'Web Address 2': 'http://www.charcon.co.uk',
        'Web Address 3': 'http://www.charcontunnels.co.uk',
        'Web Address 4': 'http://www.silomate.co.uk',
        'Working Capital': '28771000',
        lat: 52.66669170761588,
        'long': -0.3844024806001789
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.384583517428283,
              52.6671868696817
            ],
            [
              -0.38446635543725,
              52.66708763460373
            ],
            [
              -0.385716367659029,
              52.666543383358764
            ],
            [
              -0.385540904845347,
              52.666392917875726
            ],
            [
              -0.385626710646154,
              52.66635587661235
            ],
            [
              -0.385545719576286,
              52.6662863547857
            ],
            [
              -0.38545976603803,
              52.666323393977805
            ],
            [
              -0.385241590061619,
              52.66613620387581
            ],
            [
              -0.385421311738944,
              52.66605872586841
            ],
            [
              -0.385223489698387,
              52.66588907498534
            ],
            [
              -0.383048529852874,
              52.6668447925849
            ],
            [
              -0.383128858493625,
              52.666912149344476
            ],
            [
              -0.383012295956077,
              52.66696504219911
            ],
            [
              -0.38307688644031,
              52.66701806899918
            ],
            [
              -0.38319326806634,
              52.666966072674605
            ],
            [
              -0.383719170049182,
              52.6674062339009
            ],
            [
              -0.384034649027705,
              52.66727288955007
            ],
            [
              -0.384146190380501,
              52.66737213832948
            ],
            [
              -0.384583517428283,
              52.6671868696817
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '10913923',
        'Roof.Size': '5259.499572',
        'Estimated capacity': '736.3299401',
        'Title.Number': 'LL78137',
        Tenure: 'Freehold',
        'Property Address': 'Tarmac Precast Concrete, Barholm Road, Tallington, Stamford (PE9 4RL)',
        District: 'SOUTH KESTEVEN',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'TARMAC BUILDING PRODUCTS LIMITED',
        'Company Registration No. (1)': '04026569',
        'Date Proprietor Added': '01/09/2011',
        POSTCODE_2: 'PE9 4RJ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '20',
        'Capital Employed': '88720000',
        Cash: '27626000',
        Charges: 'Outstanding',
        'Cost of Sales': '154591000',
        'Cost of Sales Delta': '6634000',
        'Cost of Sales Growth': '4.483735139',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '98674000',
        'Current Liabilities': '69903000',
        'Debt to Capital': '54.14536353',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '17608000',
        'Financial Year End': 'December',
        'Industry Keywords': 'construction contractors, construction services, general construction, commercial construction, construction engineering, infrastructure, environment, sustainable building, temporary structures, civil construction, construction work, public buildings, construction projects, building site, basic materials, construction company, cement plants, building construction, building projects, construction industry, construction software, building industry, bitumen, construction site, building supplies, asset management, sports, delivery, houses, vehicles, road planing, hospitals, technical expertise, car park, health safety, technical support, hydraulic lime, decorative aggregates, lime mortar, plants, carbon footprint, sports pitches, screed, mortars, soil, silo, built environment, asphalt surfacing, manufacturing, building products, non-metallic mineral products, maintenance, sustainable construction, mineral products, concrete, highway maintenance, cement, lime, asphalt, tarmac, construction, building, building materials',
        'Net Assets': '72736000',
        'SIC 2007 Description': 'Manufacture of concrete products for construction purposes',
        'Shareholder Funds': '72736000',
        'Shareholders Names': 'Tarmac Holdings Limited',
        Status: 'Active',
        Telephone: '0333 003 4555',
        'Trading Address': 'Interchange 10 Railway Drive, Wolverhampton, Wv1 1lh, WV1 1LH',
        'Trading Address 1': 'Interchange 10 Railway Drive',
        'Trading Address 2': 'Wolverhampton',
        'Trading Address 3': 'Wv1 1lh',
        'Trading Address 4': '0',
        Turnover: '239641000',
        'Ultimate Domestic Parent Name': 'Crh (UK) Limited',
        'Ultimate Domestic Parent Id': 'company/gb/01380120',
        'Ultimate Parent Name': 'Donegal Lime',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.tarmac.com',
        'Web Address 2': 'http://www.charcon.co.uk',
        'Web Address 3': 'http://www.charcontunnels.co.uk',
        'Web Address 4': 'http://www.silomate.co.uk',
        'Working Capital': '28771000',
        lat: 52.66624064811155,
        'long': -0.3830380402526346
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.384546464928563,
              52.66570938646308
            ],
            [
              -0.384349973451984,
              52.66554388790388
            ],
            [
              -0.381515180163483,
              52.666783225089624
            ],
            [
              -0.381701525449511,
              52.666943015623055
            ],
            [
              -0.384546464928563,
              52.66570938646308
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11019698',
        'Roof.Size': '4376.354884',
        'Estimated capacity': '612.6896838',
        'Title.Number': 'LL58845',
        Tenure: 'Freehold',
        'Property Address': 'Land west of roadway at Port Sutton Bridge, Spalding',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'PORT SUTTON BRIDGE LIMITED',
        'Company Registration No. (1)': '01577650',
        'Date Proprietor Added': '01/09/1998',
        POSTCODE_2: 'PE12 9QR',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '39',
        'Capital Employed': '2930986',
        Cash: '34046',
        Charges: 'Outstanding',
        'Cost of Sales': '2321971',
        'Cost of Sales Delta': '-891204',
        'Cost of Sales Growth': '-27.73593097',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '711694',
        'Current Liabilities': '416818',
        'Debt to Capital': '12.45048993',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '-431359',
        'Financial Year End': 'December',
        'Industry Keywords': 'architecture, engineering, technical consulting, consulting',
        'Net Assets': '2930986',
        'SIC 2007 Description': 'Other professional, scientific and technical activities (not including environmental consultancy or quantity surveying) n.e.c.',
        'Shareholder Funds': '2930986',
        'Shareholders Names': 'C.Ro Ports Killingholme Ltd',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': '2nd Floor, 130 Shaftesbury Avenue, London, W1D 5EU',
        'Trading Address 1': '2nd Floor',
        'Trading Address 2': '130 Shaftesbury Avenue',
        'Trading Address 3': 'London',
        'Trading Address 4': '0',
        Turnover: '2693028',
        'Ultimate Domestic Parent Name': 'Simon Group Limited',
        'Ultimate Domestic Parent Id': 'company/gb/00052665',
        'Ultimate Parent Name': 'Cldn Lignes Sa',
        'Ultimate Parent Id': 'company/lu/B73465',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '294876',
        lat: 52.77589415969279,
        'long': 0.19874154021408255
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.19916682072897,
              52.77555508202585
            ],
            [
              0.199383996777004,
              52.776066075505064
            ],
            [
              0.198318125158547,
              52.77623419114066
            ],
            [
              0.198098787272242,
              52.77572125779498
            ],
            [
              0.19916682072897,
              52.77555508202585
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11022508',
        'Roof.Size': '6046.158548',
        'Estimated capacity': '846.4621967',
        'Title.Number': 'LL247642',
        Tenure: 'Freehold',
        'Property Address': 'The Peele School, Little London, Long Sutton',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'THE LINCOLNSHIRE EDUCATIONAL TRUST LIMITED',
        'Company Registration No. (1)': '07647805',
        'Date Proprietor Added': '16/07/2019',
        POSTCODE_2: 'PE12 9LF',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '9',
        'Capital Employed': '17791261',
        Cash: '3260739',
        Charges: '0',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '3821497',
        'Current Liabilities': '987995',
        'Debt to Capital': '36.52431704',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'August',
        'Industry Keywords': 'education research, higher education, urban regeneration, newspapers, rankings, engineering, housing, guides, engineering company, pool, retailer, students, farmers, cathedral, alumni, agricultural, research, learning, professional development, food production, professional development courses, food retailers, food products, agricultural products, farming, food, food processing, development courses, colleges, university, courses, technology, adult education, charity, charities, school, curriculum, education',
        'Net Assets': '11920261',
        'SIC 2007 Description': 'Other education n.e.c.',
        'Shareholder Funds': '11920261',
        'Shareholders Names': '0',
        Status: 'Active',
        Telephone: '01406 493000',
        'Trading Address': 'Brayford Pool, Campus Way, Lincoln, Lincolnshire, LN6 7TS',
        'Trading Address 1': 'Brayford Pool',
        'Trading Address 2': 'Campus Way',
        'Trading Address 3': 'Lincoln',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '21429483',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://let.lincoln.ac.uk',
        'Web Address 2': 'http://www.lincoln.ac.uk',
        'Web Address 3': 'http://www.universityacademyholbeach.org',
        'Web Address 4': '0',
        'Working Capital': '2833502',
        lat: 52.793747399094315,
        'long': 0.13030990643013718
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.129933390280385,
              52.793235377224406
            ],
            [
              0.130072213446047,
              52.79325949853519
            ],
            [
              0.130107932664264,
              52.79318306184489
            ],
            [
              0.129797067584269,
              52.79312885198101
            ],
            [
              0.129840367253286,
              52.79304059079564
            ],
            [
              0.130377389748098,
              52.79313353738367
            ],
            [
              0.13034490496494,
              52.79320937660948
            ],
            [
              0.130153204247963,
              52.79317766349079
            ],
            [
              0.130113910713343,
              52.793265942772756
            ],
            [
              0.130260919860183,
              52.79329063617384
            ],
            [
              0.130123718495295,
              52.793585042209195
            ],
            [
              0.130444072792892,
              52.79364213782307
            ],
            [
              0.13058257294238,
              52.793353102766346
            ],
            [
              0.130684253869738,
              52.79336988828526
            ],
            [
              0.130713870867896,
              52.79330821656949
            ],
            [
              0.130845968854689,
              52.793331288916214
            ],
            [
              0.130526786665395,
              52.794008300319355
            ],
            [
              0.13114082892929,
              52.7941138865317
            ],
            [
              0.131236134908591,
              52.79391832534341
            ],
            [
              0.131489625597154,
              52.79396385189149
            ],
            [
              0.13137396452836,
              52.794204195244255
            ],
            [
              0.131226450007365,
              52.794178343428385
            ],
            [
              0.131190595844598,
              52.79425199562248
            ],
            [
              0.129695834318406,
              52.793992560361716
            ],
            [
              0.129728265775349,
              52.79392166742149
            ],
            [
              0.129264635272239,
              52.7938404377807
            ],
            [
              0.129323821170608,
              52.793716017019854
            ],
            [
              0.129763301259136,
              52.793791745822624
            ],
            [
              0.129753987786414,
              52.793613258998924
            ],
            [
              0.129933390280385,
              52.793235377224406
            ]
          ],
          [
            [
              0.130316167456563,
              52.79372005020008
            ],
            [
              0.130094326066064,
              52.79368168528552
            ],
            [
              0.130004340659743,
              52.79387391318932
            ],
            [
              0.130224696607802,
              52.7939122150393
            ],
            [
              0.130316167456563,
              52.79372005020008
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11027951',
        'Roof.Size': '11672.40421',
        'Estimated capacity': '1634.136589',
        'Title.Number': 'LL233563',
        Tenure: 'Freehold',
        'Property Address': 'Manor Farm, Washway Road, Holbeach',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'O A TAYLOR & SONS LIMITED',
        'Company Registration No. (1)': '00345754',
        'Date Proprietor Added': '13/10/2003',
        POSTCODE_2: 'PE12 7PP',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '81',
        'Capital Employed': '859610',
        Cash: '46863',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '46863',
        'Current Liabilities': '221800',
        'Debt to Capital': '23.0777411',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'November',
        'Industry Keywords': 'stockists, sales team, flowers, guides, recruitment, video, press office, family business, seeds, wholesalers, oil seeds, leguminous crops, crop farming, crops, bulbs, farming, cereals',
        'Net Assets': '831845',
        'SIC 2007 Description': 'Growing of cereals (except rice), leguminous crops and oil seeds',
        'Shareholder Funds': '831845',
        'Shareholders Names': 'Brian Denis Taylor, Roger David Taylor, John Derek Taylor of Holbeach',
        Status: 'Active',
        Telephone: '01406 422266',
        'Trading Address': 'Washway House Farm, Washway Road, Holbeach, Spalding, Lincolnshire, PE12 7PP',
        'Trading Address 1': 'Washway House Farm',
        'Trading Address 2': 'Washway Road',
        'Trading Address 3': 'Holbeach',
        'Trading Address 4': 'Spalding, Lincolnshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.taylors-bulbs.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-174937',
        lat: 52.81811559118095,
        'long': 0.02886198979438523
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.028814241080988,
              52.818828191156676
            ],
            [
              0.027764764782225,
              52.818010419372065
            ],
            [
              0.029092284173161,
              52.817386682413456
            ],
            [
              0.029562574449553,
              52.81775293367023
            ],
            [
              0.029303933824974,
              52.81787459893604
            ],
            [
              0.029890917986545,
              52.818321300422866
            ],
            [
              0.028814241080988,
              52.818828191156676
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11030872',
        'Roof.Size': '44682.22964',
        'Estimated capacity': '6255.51215',
        'Title.Number': 'LL123082',
        Tenure: 'Freehold',
        'Property Address': 'land lying to the South of Fishtoft Road, Boston',
        District: 'BOSTON',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'INTERPAN UK LIMITED',
        'Company Registration No. (1)': '03071064',
        'Date Proprietor Added': '06/10/1995',
        POSTCODE_2: 'PE21 0BJ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '25',
        'Capital Employed': '38511000',
        Cash: '0',
        Charges: 'Satisfied',
        'Cost of Sales': '126156000',
        'Cost of Sales Delta': '-4041000',
        'Cost of Sales Growth': '-3.103758151',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '46897000',
        'Current Liabilities': '25754000',
        'Debt to Capital': '40.76558002',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '-244000',
        'Financial Year End': 'December',
        'Industry Keywords': 'commercial construction, construction engineering, timber supplies, timber construction, structural timber, timber buildings, timber structures, timber treatment, timber orangeries, lumbering, sheet materials, sawn timber, plywood, lumber, veneer, glulam, building construction, building projects, building site, construction site, construction work, construction software, construction company, construction projects, civil construction, building industry, supply chain, financial information, email newsletter, social media, business development, open source, birch plywood, paper, pulp, construction industry, saw milling, wood impregnation, wood planing, merchants, wood products, timber merchants, timber, wood, construction',
        'Net Assets': '38067000',
        'SIC 2007 Description': 'Sawmilling and planing of wood',
        'Shareholder Funds': '38067000',
        'Shareholders Names': 'Metsa Wood UK Holdings Ltd',
        Status: 'Active',
        Telephone: '01205 362461',
        'Trading Address': 'Old Golf Course, Fishtoft Road, Boston, Lincolnshire, PE21 0BJ',
        'Trading Address 1': 'Old Golf Course',
        'Trading Address 2': 'Fishtoft Road',
        'Trading Address 3': 'Boston',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '149257000',
        'Ultimate Domestic Parent Name': 'Metsa Wood UK Holdings Ltd',
        'Ultimate Domestic Parent Id': 'company/gb/03085887',
        'Ultimate Parent Name': 'Metsaliitto Osuuskunta',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.metsawood.com',
        'Web Address 2': 'http://www.metsawood.co.uk',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 52.962294921643675,
        'long': 0.0002943573237900499
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.000226656683088,
              52.96091283737176
            ],
            [
              0.002289623266498,
              52.96137438523041
            ],
            [
              0.001179964939219,
              52.96328751126232
            ],
            [
              0.000331195255254,
              52.963106342288754
            ],
            [
              0.000061087666402,
              52.96356761690491
            ],
            [
              -0.00179233455596,
              52.963166790466204
            ],
            [
              -0.001520382651081,
              52.962706388219615
            ],
            [
              -0.001788921741798,
              52.962649946662175
            ],
            [
              -0.001735303705631,
              52.962556620541214
            ],
            [
              -0.001445413939325,
              52.96261755791287
            ],
            [
              -0.000961530627852,
              52.961787218245384
            ],
            [
              0.000882270134465,
              52.96217749446344
            ],
            [
              0.000934998569258,
              52.962090835196925
            ],
            [
              -0.000312648034518,
              52.96183213394464
            ],
            [
              0.000226656683088,
              52.96091283737176
            ]
          ],
          [
            [
              0.000277669508656,
              52.96308224900658
            ],
            [
              0.000058437715051,
              52.96303604026443
            ],
            [
              0.000028242236638,
              52.96308833497929
            ],
            [
              0.000247470118186,
              52.96313445393717
            ],
            [
              0.000277669508656,
              52.96308224900658
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11030880',
        'Roof.Size': '4553.169172',
        'Estimated capacity': '637.4436841',
        'Title.Number': 'LL224166',
        Tenure: 'Freehold',
        'Property Address': 'Havenside, Fishtoft Road, Boston (PE21 0AH)',
        District: 'BOSTON',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'CITICLIENT (CPF) NOMINEES LIMITED',
        'Company Registration No. (1)': '03999261',
        'Date Proprietor Added': '',
        POSTCODE_2: 'PE21 0AH',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '20',
        'Capital Employed': '2',
        Cash: '0',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Not Rated',
        'Current Assets': '2',
        'Current Liabilities': '0',
        'Debt to Capital': '0',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': '0',
        'Net Assets': '2',
        'SIC 2007 Description': 'Non-trading company',
        'Shareholder Funds': '2',
        'Shareholders Names': 'Citibank Europe Plc',
        Status: 'Active',
        Telephone: '020 7986 6000',
        'Trading Address': '33 Canada Square, Canary Wharf, London, E14 5LB',
        'Trading Address 1': '33 Canada Square',
        'Trading Address 2': 'Canary Wharf',
        'Trading Address 3': 'London',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'Citibank UK Limited',
        'Ultimate Domestic Parent Id': 'company/gb/11283101',
        'Ultimate Parent Name': 'Citibank Overseas Investments Corp',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '2',
        lat: 52.96191506681329,
        'long': 0.0030092074016168932
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.002800863386648,
              52.96177556776846
            ],
            [
              0.002621994181612,
              52.9617552960962
            ],
            [
              0.002696155813785,
              52.96151714073672
            ],
            [
              0.002879537313633,
              52.96153841513052
            ],
            [
              0.002934658003885,
              52.96135866059888
            ],
            [
              0.003170505764646,
              52.96138435479267
            ],
            [
              0.00314318402397,
              52.96147616110487
            ],
            [
              0.003450201693089,
              52.96151144398084
            ],
            [
              0.003161001434197,
              52.96246061497938
            ],
            [
              0.002604432416223,
              52.96240301412932
            ],
            [
              0.002800863386648,
              52.96177556776846
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11030888',
        'Roof.Size': '6031.158522',
        'Estimated capacity': '844.3621931',
        'Title.Number': 'LL224166',
        Tenure: 'Freehold',
        'Property Address': 'Havenside, Fishtoft Road, Boston (PE21 0AH)',
        District: 'BOSTON',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'CITICLIENT (CPF) NOMINEES LIMITED',
        'Company Registration No. (1)': '03999261',
        'Date Proprietor Added': '',
        POSTCODE_2: 'PE21 0AH',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '20',
        'Capital Employed': '2',
        Cash: '0',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Not Rated',
        'Current Assets': '2',
        'Current Liabilities': '0',
        'Debt to Capital': '0',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': '0',
        'Net Assets': '2',
        'SIC 2007 Description': 'Non-trading company',
        'Shareholder Funds': '2',
        'Shareholders Names': 'Citibank Europe Plc',
        Status: 'Active',
        Telephone: '020 7986 6000',
        'Trading Address': '33 Canada Square, Canary Wharf, London, E14 5LB',
        'Trading Address 1': '33 Canada Square',
        'Trading Address 2': 'Canary Wharf',
        'Trading Address 3': 'London',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'Citibank UK Limited',
        'Ultimate Domestic Parent Id': 'company/gb/11283101',
        'Ultimate Parent Name': 'Citibank Overseas Investments Corp',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '2',
        lat: 52.96198656255351,
        'long': 0.003960345291806006
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.003762293886215,
              52.96140530627092
            ],
            [
              0.004483371603654,
              52.9614852111015
            ],
            [
              0.004157680777057,
              52.96256887208978
            ],
            [
              0.003437535617773,
              52.96248697148766
            ],
            [
              0.003762293886215,
              52.96140530627092
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11030892',
        'Roof.Size': '9287.385651',
        'Estimated capacity': '1300.233991',
        'Title.Number': 'LL224166',
        Tenure: 'Freehold',
        'Property Address': 'Havenside, Fishtoft Road, Boston (PE21 0AH)',
        District: 'BOSTON',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'CITICLIENT (CPF) NOMINEES LIMITED',
        'Company Registration No. (1)': '03999261',
        'Date Proprietor Added': '',
        POSTCODE_2: 'PE21 0AH',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '20',
        'Capital Employed': '2',
        Cash: '0',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Not Rated',
        'Current Assets': '2',
        'Current Liabilities': '0',
        'Debt to Capital': '0',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': '0',
        'Net Assets': '2',
        'SIC 2007 Description': 'Non-trading company',
        'Shareholder Funds': '2',
        'Shareholders Names': 'Citibank Europe Plc',
        Status: 'Active',
        Telephone: '020 7986 6000',
        'Trading Address': '33 Canada Square, Canary Wharf, London, E14 5LB',
        'Trading Address 1': '33 Canada Square',
        'Trading Address 2': 'Canary Wharf',
        'Trading Address 3': 'London',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'Citibank UK Limited',
        'Ultimate Domestic Parent Id': 'company/gb/11283101',
        'Ultimate Parent Name': 'Citibank Overseas Investments Corp',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '2',
        lat: 52.96193669871401,
        'long': 0.004841917725932231
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.005211802144712,
              52.9623995968717
            ],
            [
              0.005075958551706,
              52.9623857055678
            ],
            [
              0.004999427698635,
              52.96265285258124
            ],
            [
              0.004203890269074,
              52.96256674419214
            ],
            [
              0.004523518110862,
              52.96145504435442
            ],
            [
              0.004363727418949,
              52.96143840942336
            ],
            [
              0.004416729813179,
              52.96125806053187
            ],
            [
              0.005519412005485,
              52.96136982264825
            ],
            [
              0.005211802144712,
              52.9623995968717
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11030894',
        'Roof.Size': '6366.761717',
        'Estimated capacity': '891.3466404',
        'Title.Number': 'LL224166',
        Tenure: 'Freehold',
        'Property Address': 'Havenside, Fishtoft Road, Boston (PE21 0AH)',
        District: 'BOSTON',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'CITICLIENT (CPF) NOMINEES LIMITED',
        'Company Registration No. (1)': '03999261',
        'Date Proprietor Added': '',
        POSTCODE_2: 'PE21 0AH',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '20',
        'Capital Employed': '2',
        Cash: '0',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Not Rated',
        'Current Assets': '2',
        'Current Liabilities': '0',
        'Debt to Capital': '0',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': '0',
        'Net Assets': '2',
        'SIC 2007 Description': 'Non-trading company',
        'Shareholder Funds': '2',
        'Shareholders Names': 'Citibank Europe Plc',
        Status: 'Active',
        Telephone: '020 7986 6000',
        'Trading Address': '33 Canada Square, Canary Wharf, London, E14 5LB',
        'Trading Address 1': '33 Canada Square',
        'Trading Address 2': 'Canary Wharf',
        'Trading Address 3': 'London',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'Citibank UK Limited',
        'Ultimate Domestic Parent Id': 'company/gb/11283101',
        'Ultimate Parent Name': 'Citibank Overseas Investments Corp',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '2',
        lat: 52.96083372526563,
        'long': 0.004932884813978301
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.00453346277706,
              52.960372932248845
            ],
            [
              0.005571277203262,
              52.960502330827765
            ],
            [
              0.005336380314887,
              52.961285341591264
            ],
            [
              0.004298772061086,
              52.96117688576441
            ],
            [
              0.00453346277706,
              52.960372932248845
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11030896',
        'Roof.Size': '4359.644425',
        'Estimated capacity': '610.3502195',
        'Title.Number': 'LL224166',
        Tenure: 'Freehold',
        'Property Address': 'Havenside, Fishtoft Road, Boston (PE21 0AH)',
        District: 'BOSTON',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'CITICLIENT (CPF) NOMINEES LIMITED',
        'Company Registration No. (1)': '03999261',
        'Date Proprietor Added': '',
        POSTCODE_2: 'PE21 0QP',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '20',
        'Capital Employed': '2',
        Cash: '0',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Not Rated',
        'Current Assets': '2',
        'Current Liabilities': '0',
        'Debt to Capital': '0',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': '0',
        'Net Assets': '2',
        'SIC 2007 Description': 'Non-trading company',
        'Shareholder Funds': '2',
        'Shareholders Names': 'Citibank Europe Plc',
        Status: 'Active',
        Telephone: '020 7986 6000',
        'Trading Address': '33 Canada Square, Canary Wharf, London, E14 5LB',
        'Trading Address 1': '33 Canada Square',
        'Trading Address 2': 'Canary Wharf',
        'Trading Address 3': 'London',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'Citibank UK Limited',
        'Ultimate Domestic Parent Id': 'company/gb/11283101',
        'Ultimate Parent Name': 'Citibank Overseas Investments Corp',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '2',
        lat: 52.962001830711735,
        'long': 0.005712690562502148
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.005860838296671,
              52.96249572351686
            ],
            [
              0.005242492809523,
              52.96242892824334
            ],
            [
              0.005511546667368,
              52.96157305597286
            ],
            [
              0.006009397434602,
              52.96162965636455
            ],
            [
              0.006096260452831,
              52.96134785911172
            ],
            [
              0.006220351644251,
              52.961362037537235
            ],
            [
              0.005860838296671,
              52.96249572351686
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11031512',
        'Roof.Size': '12688.78793',
        'Estimated capacity': '1776.43031',
        'Title.Number': 'LL263954',
        Tenure: 'Freehold',
        'Property Address': 'land and buildings on the west side of Mill Lane, Butterwick',
        District: 'BOSTON',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'ANNYALLA CHICKS FARMS LIMITED',
        'Company Registration No. (1)': '09400063',
        'Date Proprietor Added': '11/07/2017',
        POSTCODE_2: 'PE22 0JF',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '5',
        'Capital Employed': '5235421',
        Cash: '0',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '336741',
        'Current Liabilities': '4866733',
        'Debt to Capital': '93.75024376',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': 'poultry breeder',
        'Net Assets': '631360',
        'SIC 2007 Description': 'Other personal service activities n.e.c.',
        'Shareholder Funds': '631360',
        'Shareholders Names': 'Gary Mawer, Treasa Mawer, John Mawer Jnr, Susan Mawer',
        Status: 'Active',
        Telephone: '01978 664721',
        'Trading Address': 'Abenbury Way, Wrexham Industrial Estate, Wrexham, Clwyd, LL13 9UZ',
        'Trading Address 1': 'Abenbury Way',
        'Trading Address 2': 'Wrexham Industrial Estate',
        'Trading Address 3': 'Wrexham',
        'Trading Address 4': 'Clwyd',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-4529992',
        lat: 52.98860966593963,
        'long': 0.05822682487150421
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.059317225941947,
              52.98861998005322
            ],
            [
              0.05948876172936,
              52.98865854561183
            ],
            [
              0.05908741933103,
              52.989329646974475
            ],
            [
              0.058404156990417,
              52.9891765897181
            ],
            [
              0.058553804336826,
              52.98892405475614
            ],
            [
              0.057861214199091,
              52.98877205504216
            ],
            [
              0.057715240533553,
              52.98901418612869
            ],
            [
              0.057371766108582,
              52.988941102470726
            ],
            [
              0.057521598666761,
              52.988695488528926
            ],
            [
              0.057265742097093,
              52.98864138693612
            ],
            [
              0.057347259678472,
              52.98850700256346
            ],
            [
              0.056910560593289,
              52.98841278440017
            ],
            [
              0.05720217961808,
              52.98792781041827
            ],
            [
              0.058154130743423,
              52.988139068071625
            ],
            [
              0.058051482785693,
              52.9883021398118
            ],
            [
              0.058466078069667,
              52.98839520813401
            ],
            [
              0.058511399276778,
              52.98832060903479
            ],
            [
              0.059381649384469,
              52.9885147507682
            ],
            [
              0.059317225941947,
              52.98861998005322
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11033752',
        'Roof.Size': '5835.841531',
        'Estimated capacity': '817.0178143',
        'Title.Number': 'LL205175',
        Tenure: 'Freehold',
        'Property Address': 'Majors Farm and 1 and 2 Tinsley Cottage, Whaplode Marsh and 1 and 2 The Chase, Red Cow Drove, Moulton Marsh, (PE12 6LW)',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'P.J.DUFFY & SON (FARMS) LIMITED',
        'Company Registration No. (1)': '02053621',
        'Date Proprietor Added': '24/10/2001',
        POSTCODE_2: 'PE12 8HF',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '34',
        'Capital Employed': '16469422',
        Cash: '2785754',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '4283995',
        'Current Liabilities': '1308944',
        'Debt to Capital': '15.46116218',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'October',
        'Industry Keywords': 'breakfast cereals, seeds, oil seeds, leguminous crops, crop farming, farming, crops, cereals',
        'Net Assets': '15029624',
        'SIC 2007 Description': 'Growing of cereals (except rice), leguminous crops and oil seeds',
        'Shareholder Funds': '15029624',
        'Shareholders Names': 'Karen Marina Duffy Mrs, Philip John Duffy Mr',
        Status: 'Active',
        Telephone: '01205 260310',
        'Trading Address': 'Lynway Nurseries, Main Road, Algarkirk, Boston, Lincolnshire, PE20 2BE',
        'Trading Address 1': 'Lynway Nurseries',
        'Trading Address 2': 'Main Road',
        'Trading Address 3': 'Algarkirk',
        'Trading Address 4': 'Boston, Lincolnshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '2975051',
        lat: 52.860089587044016,
        'long': -0.0023259838753316615
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.001712313072135,
              52.86040813660936
            ],
            [
              -0.00326639644238,
              52.86032819240796
            ],
            [
              -0.003236152619596,
              52.86011334181684
            ],
            [
              -0.002609430001241,
              52.86014559576691
            ],
            [
              -0.002600142661102,
              52.860079356739384
            ],
            [
              -0.003065904143729,
              52.860055364354224
            ],
            [
              -0.003036893144453,
              52.859891332753094
            ],
            [
              -0.002326605414646,
              52.85992865294809
            ],
            [
              -0.00227317217476,
              52.85965281349802
            ],
            [
              -0.002186800643811,
              52.85966071056371
            ],
            [
              -0.001747633043092,
              52.85968469755121
            ],
            [
              -0.001783710650043,
              52.85992113492431
            ],
            [
              -0.001645028080731,
              52.859929499754166
            ],
            [
              -0.001712313072135,
              52.86040813660936
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11033949',
        'Roof.Size': '3784.881261',
        'Estimated capacity': '529.8833765',
        'Title.Number': 'LL107194',
        Tenure: 'Freehold',
        'Property Address': 'land on the East side of Washway Road, Moulton Marsh',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'SANSBURY BAILEY HOLDINGS LIMITED',
        'Company Registration No. (1)': '04191305',
        'Date Proprietor Added': '13/06/2017',
        POSTCODE_2: 'PE12 6LQ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '19',
        'Capital Employed': '12960077',
        Cash: '416520',
        Charges: 'Outstanding, Partially Satisfied, Satisfied',
        'Cost of Sales': '37612753',
        'Cost of Sales Delta': '466327',
        'Cost of Sales Growth': '1.255375147',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '9044996',
        'Current Liabilities': '11816079',
        'Debt to Capital': '80.19116444',
        'Dividends Payable': '71989',
        'Dormant Company': 'No',
        EBITDA: '2324796',
        'Financial Year End': 'June',
        'Industry Keywords': 'sweet potatoes, tomato, preserved vegetables, sweet peppers, potatoes, prepared vegetables, mushrooms, supply chain, farming, factory, marketing business, marketing, vegetables',
        'Net Assets': '4907868',
        'SIC 2007 Description': 'Other personal service activities n.e.c.',
        'Shareholder Funds': '4907868',
        'Shareholders Names': 'Colin Bailey, Marcus Batty, Sarah Bailey, Sansbury Bailey Farming Limited',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Washway Road, Fosdyke, Spalding, Lincs, PE12 6LQ',
        'Trading Address 1': 'Washway Road',
        'Trading Address 2': 'Fosdyke',
        'Trading Address 3': 'Spalding',
        'Trading Address 4': 'Lincs',
        Turnover: '46667826',
        'Ultimate Domestic Parent Name': 'Sansbury Bailey Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/04191305',
        'Ultimate Parent Name': 'Sansbury Bailey Holdings Limited',
        'Ultimate Parent Id': 'company/gb/04191305',
        'Web Address 1': 'http://www.emmettuk.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-2771083',
        lat: 52.8617590007056,
        'long': -0.03478517141787523
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.034528400173568,
              52.86212250664114
            ],
            [
              -0.035418531401737,
              52.861846476732495
            ],
            [
              -0.035040774138165,
              52.86139571988086
            ],
            [
              -0.034152768688819,
              52.861670883044454
            ],
            [
              -0.034528400173568,
              52.86212250664114
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11047021',
        'Roof.Size': '12629.19668',
        'Estimated capacity': '1768.087535',
        'Title.Number': 'LL130020',
        Tenure: 'Freehold',
        'Property Address': 'Sutton Bridge Ltd, East Bank, Wingland, Sutton Bridge, Spalding (PE12 9YB)',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'PRODUCE WORLD INVESTMENTS LTD',
        'Company Registration No. (1)': '03107225',
        'Date Proprietor Added': '07/04/2004',
        POSTCODE_2: 'PE12 9YA',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '25',
        'Capital Employed': '15212000',
        Cash: '1006000',
        Charges: 'Outstanding, Partially Satisfied, Satisfied',
        'Cost of Sales': '83635000',
        'Cost of Sales Delta': '-14583000',
        'Cost of Sales Growth': '-14.84758395',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '17393000',
        'Current Liabilities': '23367000',
        'Debt to Capital': '79.32294772',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '7116000',
        'Financial Year End': 'December',
        'Industry Keywords': 'organic vegetables, health, collaborative working, supply chain, career opportunities, food service, food, vegetables, head offices, washing, marketing, holding companies management',
        'Net Assets': '7977000',
        'SIC 2007 Description': 'Activities of head offices',
        'Shareholder Funds': '7977000',
        'Shareholders Names': 'Russell Burgess Limited',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Stanley Farm, Great Drove, Yaxley, Peterborough, Cambridgeshire, PE7 3TW',
        'Trading Address 1': 'Stanley Farm',
        'Trading Address 2': 'Great Drove',
        'Trading Address 3': 'Yaxley',
        'Trading Address 4': 'Peterborough, Cambridgeshire',
        Turnover: '103348000',
        'Ultimate Domestic Parent Name': 'Russell Burgess Limited',
        'Ultimate Domestic Parent Id': 'company/gb/00557434',
        'Ultimate Parent Name': 'Russell Burgess Limited',
        'Ultimate Parent Id': 'company/gb/00557434',
        'Web Address 1': 'http://www.frommyfarm.co.uk',
        'Web Address 2': 'http://www.suttonbridge.co.uk',
        'Web Address 3': 'http://www.produceworld.com',
        'Web Address 4': 'http://www.marshallsveg.co.uk',
        'Working Capital': '-5974000',
        lat: 52.76063984731947,
        'long': 0.19259553320845435
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.193602049199688,
              52.76101727112952
            ],
            [
              0.193473308773303,
              52.76105678637057
            ],
            [
              0.193577846397241,
              52.76118154005996
            ],
            [
              0.192470812009803,
              52.76151481810795
            ],
            [
              0.191978512497403,
              52.76092914485423
            ],
            [
              0.192557850283477,
              52.76075434446282
            ],
            [
              0.192144758305586,
              52.76025532206703
            ],
            [
              0.191757803034689,
              52.760373966127986
            ],
            [
              0.191438430172544,
              52.7599848810009
            ],
            [
              0.191876953178234,
              52.75984838124763
            ],
            [
              0.191971325251871,
              52.759959747359815
            ],
            [
              0.192677206535844,
              52.759743315320755
            ],
            [
              0.192907463788196,
              52.76002228337413
            ],
            [
              0.192697955661187,
              52.760088466863245
            ],
            [
              0.193229222522182,
              52.76072107037706
            ],
            [
              0.193330117361017,
              52.76069079193931
            ],
            [
              0.193602049199688,
              52.76101727112952
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11049489',
        'Roof.Size': '44086.5751',
        'Estimated capacity': '6172.120514',
        'Title.Number': 'LL197012',
        Tenure: 'Freehold',
        'Property Address': 'Land on the south side of Bridge Road, Long Sutton',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'PRINCES LIMITED',
        'Company Registration No. (1)': '02328824',
        'Date Proprietor Added': '01/02/2012',
        POSTCODE_2: 'PE12 9EF',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '31',
        'Capital Employed': '518185000',
        Cash: '8546000',
        Charges: '0',
        'Cost of Sales': '1259967000',
        'Cost of Sales Delta': '-106498000',
        'Cost of Sales Growth': '-7.793686629',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '524867000',
        'Current Liabilities': '536790000',
        'Debt to Capital': '72.42948885',
        'Dividends Payable': '5658000',
        'Dormant Company': 'No',
        EBITDA: '46853000',
        'Financial Year End': 'March',
        'Industry Keywords': 'energy drinks, drinks manufacturer, heritage, food products, cuisine, grocery store, sourcing, sustainability, supply chain, brand products, investment, convenience stores, supermarkets, tuna, supermarket, wholesale suppliers, food manufacturing, manufacturer, foodservice, cooking sauces, food & beverages, cooking, wholesale, vegetables, importation, meat, sauces, fruit, tobacco, fruits, manufacturing, pasta, soup, soft drinks, beverages, edible oils, fish, fruit juices, fruit juice producers, fruit juice merchants, grocery, drinks, food & drink, drink, food',
        'Net Assets': '290862000',
        'SIC 2007 Description': 'Non-specialised wholesale of food, beverages and tobacco',
        'Shareholder Funds': '290862000',
        'Shareholders Names': 'Mitsubishi Corp',
        Status: 'Active',
        Telephone: '01406 367000',
        'Trading Address': 'Royal Liver Building, Pier Head, Liverpool, Merseyside, L3 1NX',
        'Trading Address 1': 'Royal Liver Building',
        'Trading Address 2': 'Pier Head',
        'Trading Address 3': 'Liverpool',
        'Trading Address 4': 'Merseyside',
        Turnover: '1519221000',
        'Ultimate Domestic Parent Name': 'Princes Limited',
        'Ultimate Domestic Parent Id': 'company/gb/02328824',
        'Ultimate Parent Name': 'Princes Limited',
        'Ultimate Parent Id': 'company/gb/02328824',
        'Web Address 1': 'http://www.princesgroup.com',
        'Web Address 2': 'http://www.edibleoilsltd.com',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 52.77802841749174,
        'long': 0.1475251763163341
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.149073840530802,
              52.77872844363961
            ],
            [
              0.149098134994399,
              52.77885136264831
            ],
            [
              0.14819152185898,
              52.77891642756656
            ],
            [
              0.148178306594525,
              52.77884680533798
            ],
            [
              0.148107275342958,
              52.77885267721917
            ],
            [
              0.147994352516305,
              52.77885094585916
            ],
            [
              0.147995270572441,
              52.77887259800619
            ],
            [
              0.147810823269402,
              52.778875758142206
            ],
            [
              0.146296039113396,
              52.77899623569472
            ],
            [
              0.146248190286628,
              52.77879524928904
            ],
            [
              0.145819321378978,
              52.778830251759906
            ],
            [
              0.145782227247114,
              52.77826915333885
            ],
            [
              0.14626853211621,
              52.77822331131357
            ],
            [
              0.146257518722341,
              52.778113997900334
            ],
            [
              0.145908787897874,
              52.778135232760626
            ],
            [
              0.145835170536233,
              52.77723861444371
            ],
            [
              0.146025121058029,
              52.777232930141714
            ],
            [
              0.146010753731901,
              52.777067482434234
            ],
            [
              0.148573508124919,
              52.77698458437047
            ],
            [
              0.14861505584495,
              52.77759325494625
            ],
            [
              0.149216478111058,
              52.77759395779459
            ],
            [
              0.149215493340037,
              52.77798922856873
            ],
            [
              0.149401433785214,
              52.777989365902414
            ],
            [
              0.149401006608571,
              52.778107068384735
            ],
            [
              0.149542613672863,
              52.77810719963751
            ],
            [
              0.149540923857697,
              52.77880701493658
            ],
            [
              0.149213218020159,
              52.77880665925755
            ],
            [
              0.149213519341174,
              52.778728520324805
            ],
            [
              0.149073840530802,
              52.77872844363961
            ]
          ],
          [
            [
              0.148204968770078,
              52.778271245812086
            ],
            [
              0.148205203875956,
              52.77821279880108
            ],
            [
              0.148018369158013,
              52.77821258586613
            ],
            [
              0.14800857190346,
              52.77810396985419
            ],
            [
              0.1481862522545,
              52.778098953875435
            ],
            [
              0.148184537363905,
              52.777955755227865
            ],
            [
              0.14802573093824,
              52.777952607081886
            ],
            [
              0.14803147072819,
              52.77784335005655
            ],
            [
              0.147915112334939,
              52.77784105147264
            ],
            [
              0.147961877657922,
              52.778269803811234
            ],
            [
              0.148204968770078,
              52.778271245812086
            ]
          ],
          [
            [
              0.148153187694015,
              52.777617265081766
            ],
            [
              0.148540387875592,
              52.77761555695355
            ],
            [
              0.14854367742517,
              52.77746561416015
            ],
            [
              0.147938182348495,
              52.77748754955957
            ],
            [
              0.147962723593463,
              52.77777212597706
            ],
            [
              0.148154989067852,
              52.77777727570127
            ],
            [
              0.148153187694015,
              52.777617265081766
            ]
          ],
          [
            [
              0.147868163361059,
              52.77823958207075
            ],
            [
              0.147840736396472,
              52.778052971995805
            ],
            [
              0.14760310789185,
              52.778060061898344
            ],
            [
              0.147607729727695,
              52.77812381568087
            ],
            [
              0.147635446724451,
              52.778238041454905
            ],
            [
              0.147868163361059,
              52.77823958207075
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11049498',
        'Roof.Size': '4530.680628',
        'Estimated capacity': '634.2952879',
        'Title.Number': 'LL197012',
        Tenure: 'Freehold',
        'Property Address': 'Land on the south side of Bridge Road, Long Sutton',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'PRINCES LIMITED',
        'Company Registration No. (1)': '02328824',
        'Date Proprietor Added': '01/02/2012',
        POSTCODE_2: 'PE12 9EG',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '31',
        'Capital Employed': '518185000',
        Cash: '8546000',
        Charges: '0',
        'Cost of Sales': '1259967000',
        'Cost of Sales Delta': '-106498000',
        'Cost of Sales Growth': '-7.793686629',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '524867000',
        'Current Liabilities': '536790000',
        'Debt to Capital': '72.42948885',
        'Dividends Payable': '5658000',
        'Dormant Company': 'No',
        EBITDA: '46853000',
        'Financial Year End': 'March',
        'Industry Keywords': 'energy drinks, drinks manufacturer, heritage, food products, cuisine, grocery store, sourcing, sustainability, supply chain, brand products, investment, convenience stores, supermarkets, tuna, supermarket, wholesale suppliers, food manufacturing, manufacturer, foodservice, cooking sauces, food & beverages, cooking, wholesale, vegetables, importation, meat, sauces, fruit, tobacco, fruits, manufacturing, pasta, soup, soft drinks, beverages, edible oils, fish, fruit juices, fruit juice producers, fruit juice merchants, grocery, drinks, food & drink, drink, food',
        'Net Assets': '290862000',
        'SIC 2007 Description': 'Non-specialised wholesale of food, beverages and tobacco',
        'Shareholder Funds': '290862000',
        'Shareholders Names': 'Mitsubishi Corp',
        Status: 'Active',
        Telephone: '01406 367000',
        'Trading Address': 'Royal Liver Building, Pier Head, Liverpool, Merseyside, L3 1NX',
        'Trading Address 1': 'Royal Liver Building',
        'Trading Address 2': 'Pier Head',
        'Trading Address 3': 'Liverpool',
        'Trading Address 4': 'Merseyside',
        Turnover: '1519221000',
        'Ultimate Domestic Parent Name': 'Princes Limited',
        'Ultimate Domestic Parent Id': 'company/gb/02328824',
        'Ultimate Parent Name': 'Princes Limited',
        'Ultimate Parent Id': 'company/gb/02328824',
        'Web Address 1': 'http://www.princesgroup.com',
        'Web Address 2': 'http://www.edibleoilsltd.com',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 52.778038797786465,
        'long': 0.15017135880749433
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.149696180938918,
              52.77759488554839
            ],
            [
              0.149947426752884,
              52.77759338888635
            ],
            [
              0.149947573132578,
              52.7776685526345
            ],
            [
              0.150538184098133,
              52.77766647764847
            ],
            [
              0.15053028014528,
              52.77857374277614
            ],
            [
              0.150080697042686,
              52.778573441213524
            ],
            [
              0.150085196517513,
              52.778120293307396
            ],
            [
              0.149693176293171,
              52.778120565299304
            ],
            [
              0.149696180938918,
              52.77759488554839
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11049623',
        'Roof.Size': '5388.210218',
        'Estimated capacity': '754.3494305',
        'Title.Number': 'LL194747',
        Tenure: 'Freehold',
        'Property Address': 'Land and buildings on the South side of Winters Lane, Long Sutton',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'ADM AGRICULTURE LIMITED',
        'Company Registration No. (1)': '00904957',
        'Date Proprietor Added': '15/04/2019',
        POSTCODE_2: 'PE12 9BE',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '53',
        'Capital Employed': '61136007',
        Cash: '4345149',
        Charges: '0',
        'Cost of Sales': '464410497',
        'Cost of Sales Delta': '97514014',
        'Cost of Sales Growth': '26.5780727',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '140331116',
        'Current Liabilities': '90918817',
        'Debt to Capital': '60.26185858',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '15021826',
        'Financial Year End': 'December',
        'Industry Keywords': 'animal feeds, tobacco, importation, unmanufactured tobacco, seeds, biomass, wholesale, animals, feeds, grain, energy, energy industry, commodities',
        'Net Assets': '60423761',
        'SIC 2007 Description': 'Wholesale of grain, unmanufactured tobacco, seeds and animal feeds',
        'Shareholder Funds': '60423761',
        'Shareholders Names': 'Archer Daniels Midland (UK) Limited',
        Status: 'Active',
        Telephone: '01923 279000',
        'Trading Address': '5 Hercules Way, Leavesden, Watford, Hertfordshire, WD25 7GS',
        'Trading Address 1': '5 Hercules Way',
        'Trading Address 2': 'Leavesden',
        'Trading Address 3': 'Watford',
        'Trading Address 4': 'Hertfordshire',
        Turnover: '482854094',
        'Ultimate Domestic Parent Name': 'Archer Daniels Midland (UK) Limited',
        'Ultimate Domestic Parent Id': 'company/gb/01090901',
        'Ultimate Parent Name': 'Archer Daniels Midland Europe B.V.',
        'Ultimate Parent Id': 'company/nl/24149451',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '49412299',
        lat: 52.76582808514444,
        'long': 0.13145388369014943
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.132463793866309,
              52.765604481569575
            ],
            [
              0.132453081547014,
              52.76567039973599
            ],
            [
              0.132335935364095,
              52.76566342485591
            ],
            [
              0.132308757237305,
              52.76591413814165
            ],
            [
              0.131608778526728,
              52.76588617048725
            ],
            [
              0.131634359016782,
              52.76565122067649
            ],
            [
              0.131500829843247,
              52.765642651273794
            ],
            [
              0.131401016798461,
              52.76630008208728
            ],
            [
              0.130799610326485,
              52.76626611228602
            ],
            [
              0.130889743336799,
              52.76568042577253
            ],
            [
              0.131116721501596,
              52.76569334084554
            ],
            [
              0.131164807700717,
              52.765372840247565
            ],
            [
              0.131538606518046,
              52.76539336613145
            ],
            [
              0.131513171619384,
              52.765561508819815
            ],
            [
              0.132463793866309,
              52.765604481569575
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11049631',
        'Roof.Size': '4500.073415',
        'Estimated capacity': '630.0102781',
        'Title.Number': 'LL318729',
        Tenure: 'Freehold',
        'Property Address': 'Land on the south of Winters Lane, Long Sutton',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'ADM AGRICULTURE LIMITED',
        'Company Registration No. (1)': '00904957',
        'Date Proprietor Added': '15/04/2019',
        POSTCODE_2: 'PE12 9BE',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '53',
        'Capital Employed': '61136007',
        Cash: '4345149',
        Charges: '0',
        'Cost of Sales': '464410497',
        'Cost of Sales Delta': '97514014',
        'Cost of Sales Growth': '26.5780727',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '140331116',
        'Current Liabilities': '90918817',
        'Debt to Capital': '60.26185858',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '15021826',
        'Financial Year End': 'December',
        'Industry Keywords': 'animal feeds, tobacco, importation, unmanufactured tobacco, seeds, biomass, wholesale, animals, feeds, grain, energy, energy industry, commodities',
        'Net Assets': '60423761',
        'SIC 2007 Description': 'Wholesale of grain, unmanufactured tobacco, seeds and animal feeds',
        'Shareholder Funds': '60423761',
        'Shareholders Names': 'Archer Daniels Midland (UK) Limited',
        Status: 'Active',
        Telephone: '01923 279000',
        'Trading Address': '5 Hercules Way, Leavesden, Watford, Hertfordshire, WD25 7GS',
        'Trading Address 1': '5 Hercules Way',
        'Trading Address 2': 'Leavesden',
        'Trading Address 3': 'Watford',
        'Trading Address 4': 'Hertfordshire',
        Turnover: '482854094',
        'Ultimate Domestic Parent Name': 'Archer Daniels Midland (UK) Limited',
        'Ultimate Domestic Parent Id': 'company/gb/01090901',
        'Ultimate Parent Name': 'Archer Daniels Midland Europe B.V.',
        'Ultimate Parent Id': 'company/nl/24149451',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '49412299',
        lat: 52.76558157303043,
        'long': 0.13305734258486437
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.133375550513979,
              52.76491914041244
            ],
            [
              0.133179993048316,
              52.76626755947348
            ],
            [
              0.132739185766144,
              52.76624401782763
            ],
            [
              0.132934613084332,
              52.764895691844224
            ],
            [
              0.133375550513979,
              52.76491914041244
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11062485',
        'Roof.Size': '7745.170527',
        'Estimated capacity': '1084.323874',
        'Title.Number': 'LL146172',
        Tenure: 'Freehold',
        'Property Address': 'land lying to the West of Rangells Gate, Fulney',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'DAVID BOWMAN LIMITED',
        'Company Registration No. (1)': '03284743',
        'Date Proprietor Added': '30/09/1997',
        POSTCODE_2: 'PE12 6EW',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '23',
        'Capital Employed': '590271',
        Cash: '1434',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '320726',
        'Current Liabilities': '99992',
        'Debt to Capital': '15.58521897',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': 'prepared vegetables, mushrooms, preserved vegetables, sweet potatoes, tomato, potatoes, warehousing services, logistics, sweet peppers, warehouse, writing skills, books, editing, training, writing, nursery products, nurseries, vegetable producer, fruits, fruit producer, storage, warehousing, vegetables',
        'Net Assets': '582684',
        'SIC 2007 Description': 'Operation of warehousing and storage facilities for land transport activities of division 49',
        'Shareholder Funds': '582684',
        'Shareholders Names': 'Kathleen Janet Bowman Mrs, David Edward Bowman Mr',
        Status: 'Active',
        Telephone: '01775 769710',
        'Trading Address': '1-4 London Road, Spalding, Lincolnshire, PE11 2TA',
        'Trading Address 1': '1-4 London Road',
        'Trading Address 2': 'Spalding',
        'Trading Address 3': 'Lincolnshire',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'David Bowman Limited',
        'Ultimate Domestic Parent Id': 'company/gb/03284743',
        'Ultimate Parent Name': 'David Bowman Limited',
        'Ultimate Parent Id': 'company/gb/03284743',
        'Web Address 1': 'http://www.hostileediting.net',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '220734',
        lat: 52.7821942356964,
        'long': -0.12535224207221732
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.125032685763551,
              52.7819089000681
            ],
            [
              -0.124537171939497,
              52.782004641566665
            ],
            [
              -0.124898071210102,
              52.78269130831468
            ],
            [
              -0.1261563583222,
              52.78245045673272
            ],
            [
              -0.12575962486145,
              52.78167251078561
            ],
            [
              -0.124984050505595,
              52.781819212545564
            ],
            [
              -0.125032685763551,
              52.7819089000681
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11065956',
        'Roof.Size': '4013.022583',
        'Estimated capacity': '561.8231616',
        'Title.Number': 'LL56017',
        Tenure: 'Freehold',
        'Property Address': 'Land and Buildings on the north and south sides of Horseshoe Road, Spalding',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'BROADGATE BUILDERS (SPALDING) LIMITED',
        'Company Registration No. (1)': '01242542',
        'Date Proprietor Added': '05/06/1990',
        POSTCODE_2: 'PE11 1JJ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '44',
        'Capital Employed': '23387689',
        Cash: '2382356',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '6758226',
        'Cost of Sales Delta': '-3300851',
        'Cost of Sales Growth': '-32.81465089',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '14913497',
        'Current Liabilities': '5372495',
        'Debt to Capital': '40.72802872',
        'Dividends Payable': '839807',
        'Dormant Company': 'No',
        EBITDA: '2056846',
        'Financial Year End': 'April',
        'Industry Keywords': 'craftsmen, quality homes, houses, appliances, care homes, fibre broadband, customer care, photovoltaic solar panels, circus, bedroom, home builders, builders, building, land, construction, civil engineering works, domestic buildings',
        'Net Assets': '17046728',
        'SIC 2007 Description': 'Construction of domestic buildings',
        'Shareholder Funds': '17046728',
        'Shareholders Names': 'The David Deas-Bissett-Clarke Will Trust Trustees of, The David Deas-Bissett-Clarke 1998 Discretionary Settlement Trustees of',
        Status: 'Active',
        Telephone: '01406 380532',
        'Trading Address': 'Broadgate House, 519 Broadgate, Weston Hills, Spalding, Lincolnshire, PE12 6DB',
        'Trading Address 1': 'Broadgate House',
        'Trading Address 2': '519 Broadgate',
        'Trading Address 3': 'Weston Hills',
        'Trading Address 4': 'Spalding, Lincolnshire',
        Turnover: '10100221',
        'Ultimate Domestic Parent Name': 'Broadgate Builders (Spalding) Limited',
        'Ultimate Domestic Parent Id': 'company/gb/01242542',
        'Ultimate Parent Name': 'Broadgate Builders (Spalding) Limited',
        'Ultimate Parent Id': 'company/gb/01242542',
        'Web Address 1': 'http://www.broadgatehomes.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '9541002',
        lat: 52.77824403265509,
        'long': -0.16979286333020305
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.16929223862002,
              52.77794113699305
            ],
            [
              -0.169171862810476,
              52.77837883610904
            ],
            [
              -0.169822507984935,
              52.778444707888774
            ],
            [
              -0.169790146900199,
              52.77856225691039
            ],
            [
              -0.170213430164987,
              52.77860510883308
            ],
            [
              -0.170366163068321,
              52.778049679696984
            ],
            [
              -0.16929223862002,
              52.77794113699305
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11070623',
        'Roof.Size': '16024.99733',
        'Estimated capacity': '2243.499626',
        'Title.Number': 'LL138210',
        Tenure: 'Freehold',
        'Property Address': 'Funcoast World, Skegness (PE25 1NJ)',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'BUTLINS SKYLINE LIMITED',
        'Company Registration No. (1)': '04011665',
        'Date Proprietor Added': '22/02/2001',
        POSTCODE_2: 'PE25 1NJ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '20',
        'Capital Employed': '89136000',
        Cash: '5411000',
        Charges: 'Outstanding, Partially Satisfied, Satisfied',
        'Cost of Sales': '58292000',
        'Cost of Sales Delta': '-1350000',
        'Cost of Sales Growth': '-2.263505583',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '16025000',
        'Current Liabilities': '82771000',
        'Debt to Capital': '70.16933575',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': 'university accommodation, accomodation, accommodation advice, hotel accommodation, serviced accommodation, rental accommodation, care accommodation, festival accommodation, resort, ski resorts, visa, conference, conference venue, school, family holidays, parks, school holidays, restaurant, post office, skylines, entertainment, holiday park, restaurants, camp, family fun, camps, package holidays, recreational vehicle parks, trailer parks, camping sites, camping, holiday accommodation, accommodation, resorts, leisure, holiday',
        'Net Assets': '51281000',
        'SIC 2007 Description': 'Camping grounds, recreational vehicle parks and trailer parks',
        'Shareholder Funds': '51281000',
        'Shareholders Names': 'Durlacher Nominees Ltd',
        Status: 'Active',
        Telephone: '01243 820202',
        'Trading Address': 'No1 Park Lane, Hemel Hempstead, Herts, HP2 4YL',
        'Trading Address 1': 'No1 Park Lane',
        'Trading Address 2': 'Hemel Hempstead',
        'Trading Address 3': 'Herts',
        'Trading Address 4': '0',
        Turnover: '230136000',
        'Ultimate Domestic Parent Name': 'Bourne Leisure Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/04011667',
        'Ultimate Parent Name': 'Bourne Leisure Holdings Limited',
        'Ultimate Parent Id': 'company/gb/04011667',
        'Web Address 1': 'http://www.butlinsshoreline.net',
        'Web Address 2': 'http://www.butlins.com',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-66746000',
        lat: 53.18051109483258,
        'long': 0.35084614630189154
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.349998268824312,
              53.18111226639326
            ],
            [
              0.349916659989775,
              53.180736523882985
            ],
            [
              0.350293381833571,
              53.18070670185877
            ],
            [
              0.35027327713469,
              53.18061548018926
            ],
            [
              0.349947070690816,
              53.18064124839557
            ],
            [
              0.349902890176173,
              53.18043541675743
            ],
            [
              0.349794092182649,
              53.18044376739742
            ],
            [
              0.349687077382453,
              53.179950923539295
            ],
            [
              0.350398051742672,
              53.17989490506729
            ],
            [
              0.350442106281923,
              53.18009840137493
            ],
            [
              0.350644779546963,
              53.180082532815206
            ],
            [
              0.350594887536813,
              53.17991187910288
            ],
            [
              0.35229670324071,
              53.17970969360739
            ],
            [
              0.352354446591344,
              53.17987875301286
            ],
            [
              0.351212106699681,
              53.18001334085843
            ],
            [
              0.351170126508744,
              53.18007216177323
            ],
            [
              0.35116344046108,
              53.18014691923255
            ],
            [
              0.351228188990277,
              53.180293633018856
            ],
            [
              0.351368192785525,
              53.1806122077036
            ],
            [
              0.351366304270589,
              53.18076644095306
            ],
            [
              0.351831709391844,
              53.18072991790374
            ],
            [
              0.351895695549828,
              53.18073683605399
            ],
            [
              0.351917168715332,
              53.180743604783174
            ],
            [
              0.351940642359124,
              53.180754110227355
            ],
            [
              0.351964366683606,
              53.18076919613577
            ],
            [
              0.351973036646001,
              53.18077720673054
            ],
            [
              0.351984158464688,
              53.18079173234395
            ],
            [
              0.351989914958018,
              53.18080393634793
            ],
            [
              0.352055828356928,
              53.181108598899506
            ],
            [
              0.351477294139367,
              53.18115374030074
            ],
            [
              0.35149691876021,
              53.18124434189265
            ],
            [
              0.350992016592606,
              53.18128398099217
            ],
            [
              0.350930255042383,
              53.181000364848785
            ],
            [
              0.350879884700269,
              53.18100432625082
            ],
            [
              0.350564236047727,
              53.181067200421666
            ],
            [
              0.349998268824312,
              53.18111226639326
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11070632',
        'Roof.Size': '6465.193653',
        'Estimated capacity': '905.1271114',
        'Title.Number': 'LL138210',
        Tenure: 'Freehold',
        'Property Address': 'Funcoast World, Skegness (PE25 1NJ)',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'BUTLINS SKYLINE LIMITED',
        'Company Registration No. (1)': '04011665',
        'Date Proprietor Added': '22/02/2001',
        POSTCODE_2: 'PE25 1NJ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '20',
        'Capital Employed': '89136000',
        Cash: '5411000',
        Charges: 'Outstanding, Partially Satisfied, Satisfied',
        'Cost of Sales': '58292000',
        'Cost of Sales Delta': '-1350000',
        'Cost of Sales Growth': '-2.263505583',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '16025000',
        'Current Liabilities': '82771000',
        'Debt to Capital': '70.16933575',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': 'university accommodation, accomodation, accommodation advice, hotel accommodation, serviced accommodation, rental accommodation, care accommodation, festival accommodation, resort, ski resorts, visa, conference, conference venue, school, family holidays, parks, school holidays, restaurant, post office, skylines, entertainment, holiday park, restaurants, camp, family fun, camps, package holidays, recreational vehicle parks, trailer parks, camping sites, camping, holiday accommodation, accommodation, resorts, leisure, holiday',
        'Net Assets': '51281000',
        'SIC 2007 Description': 'Camping grounds, recreational vehicle parks and trailer parks',
        'Shareholder Funds': '51281000',
        'Shareholders Names': 'Durlacher Nominees Ltd',
        Status: 'Active',
        Telephone: '01243 820202',
        'Trading Address': 'No1 Park Lane, Hemel Hempstead, Herts, HP2 4YL',
        'Trading Address 1': 'No1 Park Lane',
        'Trading Address 2': 'Hemel Hempstead',
        'Trading Address 3': 'Herts',
        'Trading Address 4': '0',
        Turnover: '230136000',
        'Ultimate Domestic Parent Name': 'Bourne Leisure Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/04011667',
        'Ultimate Parent Name': 'Bourne Leisure Holdings Limited',
        'Ultimate Parent Id': 'company/gb/04011667',
        'Web Address 1': 'http://www.butlinsshoreline.net',
        'Web Address 2': 'http://www.butlins.com',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-66746000',
        lat: 53.18155176440418,
        'long': 0.3502500046472033
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.350805155853427,
              53.181197759645634
            ],
            [
              0.350735859557432,
              53.18124426245203
            ],
            [
              0.350773873044777,
              53.18126338221956
            ],
            [
              0.350819236107912,
              53.1816576419056
            ],
            [
              0.350674732610634,
              53.181667237615265
            ],
            [
              0.350714246761599,
              53.18199561871419
            ],
            [
              0.350461285033938,
              53.1820061861623
            ],
            [
              0.350456142727561,
              53.18196142249282
            ],
            [
              0.349827228418808,
              53.18198597166712
            ],
            [
              0.349791446364537,
              53.18200492946534
            ],
            [
              0.349776755838432,
              53.18200998456835
            ],
            [
              0.349760771030855,
              53.18201326700466
            ],
            [
              0.349744080154079,
              53.182014585347375
            ],
            [
              0.349727281242264,
              53.182013927796405
            ],
            [
              0.349710972329656,
              53.18201128255203
            ],
            [
              0.349695761271439,
              53.18200681744127
            ],
            [
              0.349682400519503,
              53.18200060752837
            ],
            [
              0.349679152820954,
              53.181998693584504
            ],
            [
              0.349671208732603,
              53.18199300616717
            ],
            [
              0.349662793763269,
              53.18198418118532
            ],
            [
              0.349657329670809,
              53.18197457869983
            ],
            [
              0.349655135111108,
              53.1819645520644
            ],
            [
              0.349656229723855,
              53.18195446053223
            ],
            [
              0.349660633148655,
              53.181944663356255
            ],
            [
              0.349668066009862,
              53.18193552568886
            ],
            [
              0.349678403350743,
              53.18192749954557
            ],
            [
              0.349702698387427,
              53.181914702498716
            ],
            [
              0.34968694815528,
              53.181782665542435
            ],
            [
              0.349831450114408,
              53.18177576837224
            ],
            [
              0.349829198234497,
              53.18175648212613
            ],
            [
              0.34979046337968,
              53.18172964401978
            ],
            [
              0.349760725728229,
              53.18170038062106
            ],
            [
              0.349738217927643,
              53.181666479055025
            ],
            [
              0.34972665361297,
              53.181632901019526
            ],
            [
              0.349723651603596,
              53.18158620697974
            ],
            [
              0.349732853447841,
              53.181546464955474
            ],
            [
              0.349758223243852,
              53.18149858173318
            ],
            [
              0.3498033010409,
              53.18145255738643
            ],
            [
              0.349765329441208,
              53.18108107862738
            ],
            [
              0.34990554250101,
              53.18107516507387
            ],
            [
              0.349913340291444,
              53.181138397857595
            ],
            [
              0.350533551221281,
              53.181113660263975
            ],
            [
              0.350584802963714,
              53.18107929197789
            ],
            [
              0.350805155853427,
              53.181197759645634
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11070658',
        'Roof.Size': '4953.151587',
        'Estimated capacity': '693.4412222',
        'Title.Number': 'LL138210',
        Tenure: 'Freehold',
        'Property Address': 'Funcoast World, Skegness (PE25 1NJ)',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'BUTLINS SKYLINE LIMITED',
        'Company Registration No. (1)': '04011665',
        'Date Proprietor Added': '22/02/2001',
        POSTCODE_2: 'PE25 1NJ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '20',
        'Capital Employed': '89136000',
        Cash: '5411000',
        Charges: 'Outstanding, Partially Satisfied, Satisfied',
        'Cost of Sales': '58292000',
        'Cost of Sales Delta': '-1350000',
        'Cost of Sales Growth': '-2.263505583',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '16025000',
        'Current Liabilities': '82771000',
        'Debt to Capital': '70.16933575',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': 'university accommodation, accomodation, accommodation advice, hotel accommodation, serviced accommodation, rental accommodation, care accommodation, festival accommodation, resort, ski resorts, visa, conference, conference venue, school, family holidays, parks, school holidays, restaurant, post office, skylines, entertainment, holiday park, restaurants, camp, family fun, camps, package holidays, recreational vehicle parks, trailer parks, camping sites, camping, holiday accommodation, accommodation, resorts, leisure, holiday',
        'Net Assets': '51281000',
        'SIC 2007 Description': 'Camping grounds, recreational vehicle parks and trailer parks',
        'Shareholder Funds': '51281000',
        'Shareholders Names': 'Durlacher Nominees Ltd',
        Status: 'Active',
        Telephone: '01243 820202',
        'Trading Address': 'No1 Park Lane, Hemel Hempstead, Herts, HP2 4YL',
        'Trading Address 1': 'No1 Park Lane',
        'Trading Address 2': 'Hemel Hempstead',
        'Trading Address 3': 'Herts',
        'Trading Address 4': '0',
        Turnover: '230136000',
        'Ultimate Domestic Parent Name': 'Bourne Leisure Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/04011667',
        'Ultimate Parent Name': 'Bourne Leisure Holdings Limited',
        'Ultimate Parent Id': 'company/gb/04011667',
        'Web Address 1': 'http://www.butlinsshoreline.net',
        'Web Address 2': 'http://www.butlins.com',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-66746000',
        lat: 53.178284256248745,
        'long': 0.3499087225605068
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.350126397362403,
              53.17873134422127
            ],
            [
              0.349545265365819,
              53.178747576695834
            ],
            [
              0.349474788663607,
              53.177890864462725
            ],
            [
              0.35031967681523,
              53.17786780842932
            ],
            [
              0.350375543525278,
              53.1786075657844
            ],
            [
              0.350198464323939,
              53.17861240934358
            ],
            [
              0.35017967278904,
              53.17836453817791
            ],
            [
              0.349980927561516,
              53.17836998883135
            ],
            [
              0.349985305543641,
              53.17842816423659
            ],
            [
              0.350103208371189,
              53.17842493839331
            ],
            [
              0.350126397362403,
              53.17873134422127
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11070672',
        'Roof.Size': '3887.172404',
        'Estimated capacity': '544.2041366',
        'Title.Number': 'LL138210',
        Tenure: 'Freehold',
        'Property Address': 'Funcoast World, Skegness (PE25 1NJ)',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'BUTLINS SKYLINE LIMITED',
        'Company Registration No. (1)': '04011665',
        'Date Proprietor Added': '22/02/2001',
        POSTCODE_2: 'PE25 1NJ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '20',
        'Capital Employed': '89136000',
        Cash: '5411000',
        Charges: 'Outstanding, Partially Satisfied, Satisfied',
        'Cost of Sales': '58292000',
        'Cost of Sales Delta': '-1350000',
        'Cost of Sales Growth': '-2.263505583',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '16025000',
        'Current Liabilities': '82771000',
        'Debt to Capital': '70.16933575',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': 'university accommodation, accomodation, accommodation advice, hotel accommodation, serviced accommodation, rental accommodation, care accommodation, festival accommodation, resort, ski resorts, visa, conference, conference venue, school, family holidays, parks, school holidays, restaurant, post office, skylines, entertainment, holiday park, restaurants, camp, family fun, camps, package holidays, recreational vehicle parks, trailer parks, camping sites, camping, holiday accommodation, accommodation, resorts, leisure, holiday',
        'Net Assets': '51281000',
        'SIC 2007 Description': 'Camping grounds, recreational vehicle parks and trailer parks',
        'Shareholder Funds': '51281000',
        'Shareholders Names': 'Durlacher Nominees Ltd',
        Status: 'Active',
        Telephone: '01243 820202',
        'Trading Address': 'No1 Park Lane, Hemel Hempstead, Herts, HP2 4YL',
        'Trading Address 1': 'No1 Park Lane',
        'Trading Address 2': 'Hemel Hempstead',
        'Trading Address 3': 'Herts',
        'Trading Address 4': '0',
        Turnover: '230136000',
        'Ultimate Domestic Parent Name': 'Bourne Leisure Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/04011667',
        'Ultimate Parent Name': 'Bourne Leisure Holdings Limited',
        'Ultimate Parent Id': 'company/gb/04011667',
        'Web Address 1': 'http://www.butlinsshoreline.net',
        'Web Address 2': 'http://www.butlins.com',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-66746000',
        lat: 53.17941563355939,
        'long': 0.350031395817187
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.350251039600492,
              53.17913051312605
            ],
            [
              0.350390317415385,
              53.179789053624745
            ],
            [
              0.349712511533227,
              53.17984127075757
            ],
            [
              0.349576404494915,
              53.17919948019987
            ],
            [
              0.35017955735968,
              53.17915341241043
            ],
            [
              0.350152092209564,
              53.17883450357121
            ],
            [
              0.350418073839513,
              53.178827006312396
            ],
            [
              0.35043963717417,
              53.17912553207336
            ],
            [
              0.350251039600492,
              53.17913051312605
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11072737',
        'Roof.Size': '6586.243045',
        'Estimated capacity': '922.0740263',
        'Title.Number': 'LL357368',
        Tenure: 'Freehold',
        'Property Address': 'Hallgarth Farm, Thimbleby, Horncastle (LN9 5RE)',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'BEESWAX DYSON FARMING LIMITED',
        'Company Registration No. (1)': '06970038',
        'Date Proprietor Added': '23/04/2015',
        POSTCODE_2: 'LN9 5RZ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '11',
        'Capital Employed': '529144000',
        Cash: '4000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '6414000',
        'Cost of Sales Delta': '-1515000',
        'Cost of Sales Growth': '-19.10707529',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '29393000',
        'Current Liabilities': '15532000',
        'Debt to Capital': '3.206860592',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '6809000',
        'Financial Year End': 'December',
        'Industry Keywords': 'agriculture, holding companies, energy, farming, agricultural holding companies, holding companies management, investment, investment property',
        'Net Assets': '527209000',
        'SIC 2007 Description': 'Activities of agricultural holding companies',
        'Shareholder Funds': '527209000',
        'Shareholders Names': 'New Beeswax Dyson Farming Limited',
        Status: 'Active',
        Telephone: '01526 322058',
        'Trading Address': 'The Estate Office Cyclone Way, Nocton, Lincoln Ln4 2gr, LN4 2GR',
        'Trading Address 1': 'The Estate Office Cyclone Way',
        'Trading Address 2': 'Nocton',
        'Trading Address 3': 'Lincoln Ln4 2gr',
        'Trading Address 4': '0',
        Turnover: '21923000',
        'Ultimate Domestic Parent Name': 'Weybourne Group Limited',
        'Ultimate Domestic Parent Id': 'company/gb/08445070',
        'Ultimate Parent Name': 'Weybourne Group Limited',
        'Ultimate Parent Id': 'company/gb/08445070',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '13861000',
        lat: 53.206805827132236,
        'long': -0.18189066370849502
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.181351266406077,
              53.2074795427008
            ],
            [
              -0.182898416146954,
              53.20636832420368
            ],
            [
              -0.182430281420084,
              53.20613238522692
            ],
            [
              -0.180883480924455,
              53.207242344420656
            ],
            [
              -0.181351266406077,
              53.2074795427008
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11072985',
        'Roof.Size': '5614.301343',
        'Estimated capacity': '786.002188',
        'Title.Number': 'LL206599',
        Tenure: 'Freehold',
        'Property Address': 'Mapleton Farm, Moor Lane, Horsington (LN10 5EJ)',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'ANNAKIN FARMS LIMITED',
        'Company Registration No. (1)': '03383182',
        'Date Proprietor Added': '25/11/2004',
        POSTCODE_2: 'LN10 5EJ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '23',
        'Capital Employed': '4683717',
        Cash: '36920',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '780124',
        'Current Liabilities': '584792',
        'Debt to Capital': '71.03525874',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'March',
        'Industry Keywords': 'poultry products, poultry meat, agriculture, poultry farming, farming, poultry',
        'Net Assets': '1526010',
        'SIC 2007 Description': 'Raising of poultry',
        'Shareholder Funds': '1526010',
        'Shareholders Names': 'Michael William Annakin, Marlene Ann Annakin, Robert James Annakin, Daniel William Annakin',
        Status: 'Active',
        Telephone: '01526 398307',
        'Trading Address': 'Top Farm, Stainfield, Market Rasen, Lincolnshire, LN8 5JL',
        'Trading Address 1': 'Top Farm',
        'Trading Address 2': 'Stainfield',
        'Trading Address 3': 'Market Rasen',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 53.1934633309827,
        'long': -0.2113651913656029
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.211711496876784,
              53.19307449470664
            ],
            [
              -0.211768580506374,
              53.19309486008171
            ],
            [
              -0.210974272146304,
              53.193897527924946
            ],
            [
              -0.211302910932216,
              53.19401483884328
            ],
            [
              -0.212139095089345,
              53.19316982460265
            ],
            [
              -0.21142411961847,
              53.192915460945095
            ],
            [
              -0.210587791947725,
              53.19376010849799
            ],
            [
              -0.210916428610947,
              53.19387742046747
            ],
            [
              -0.211711496876784,
              53.19307449470664
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11072986',
        'Roof.Size': '5616.866946',
        'Estimated capacity': '786.3613724',
        'Title.Number': 'LL206599',
        Tenure: 'Freehold',
        'Property Address': 'Mapleton Farm, Moor Lane, Horsington (LN10 5EJ)',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'ANNAKIN FARMS LIMITED',
        'Company Registration No. (1)': '03383182',
        'Date Proprietor Added': '25/11/2004',
        POSTCODE_2: 'LN10 5EJ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '23',
        'Capital Employed': '4683717',
        Cash: '36920',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '780124',
        'Current Liabilities': '584792',
        'Debt to Capital': '71.03525874',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'March',
        'Industry Keywords': 'poultry products, poultry meat, agriculture, poultry farming, farming, poultry',
        'Net Assets': '1526010',
        'SIC 2007 Description': 'Raising of poultry',
        'Shareholder Funds': '1526010',
        'Shareholders Names': 'Michael William Annakin, Marlene Ann Annakin, Robert James Annakin, Daniel William Annakin',
        Status: 'Active',
        Telephone: '01526 398307',
        'Trading Address': 'Top Farm, Stainfield, Market Rasen, Lincolnshire, LN8 5JL',
        'Trading Address 1': 'Top Farm',
        'Trading Address 2': 'Stainfield',
        'Trading Address 3': 'Market Rasen',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 53.19320468028985,
        'long': -0.21057493098766258
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.210918236953915,
              53.1928165255179
            ],
            [
              -0.210978711997844,
              53.19283811092096
            ],
            [
              -0.210184397604811,
              53.19364068360091
            ],
            [
              -0.210513032234571,
              53.193757996668644
            ],
            [
              -0.211349222941441,
              53.19291307787171
            ],
            [
              -0.210633056024681,
              53.19265518536614
            ],
            [
              -0.209796718117344,
              53.19349982731169
            ],
            [
              -0.2101253468525,
              53.193617231278516
            ],
            [
              -0.210918236953915,
              53.1928165255179
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11075464',
        'Roof.Size': '5048.368388',
        'Estimated capacity': '706.7715743',
        'Title.Number': 'LL88113',
        Tenure: 'Freehold',
        'Property Address': 'Wayside, Youngers Lane, Burgh Le Marsh, Skegness (PE24 5JQ)',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'L J FAIRBURN & SON LIMITED',
        'Company Registration No. (1)': '01182857',
        'Date Proprietor Added': '28/03/2003',
        POSTCODE_2: 'PE24 5JQ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '46',
        'Capital Employed': '31951256',
        Cash: '10208',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '86666012',
        'Cost of Sales Delta': '7685602',
        'Cost of Sales Growth': '9.731023174',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '16141825',
        'Current Liabilities': '13836463',
        'Debt to Capital': '67.19940996',
        'Dividends Payable': '15000',
        'Dormant Company': 'No',
        EBITDA: '3545039',
        'Financial Year End': 'September',
        'Industry Keywords': 'poultry meat, poultry products, dinner, managing director, family business, chicken, charity, charities, free range eggs, free range, charity challenge, recipes, farming, poultry farming, poultry, poultry farmers, egg',
        'Net Assets': '15018642',
        'SIC 2007 Description': 'Raising of poultry',
        'Shareholder Funds': '15018642',
        'Shareholders Names': 'Caroline Mary Fairburn-Wright, Sarah Hall, Daniel Fairburn, S.H. Fairburn',
        Status: 'Active',
        Telephone: '01507 462264',
        'Trading Address': 'Ivy House Farm Office, Farlesthorpe Road Bilsby, Alford, Lincolnshire, LN13 9PL',
        'Trading Address 1': 'Ivy House Farm Office',
        'Trading Address 2': 'Farlesthorpe Road Bilsby',
        'Trading Address 3': 'Alford',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '92743650',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.ljfairburnpoultry.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '2305362',
        lat: 53.16734017431492,
        'long': 0.27437192425550844
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.275201931825541,
              53.16715821009336
            ],
            [
              0.275125419863634,
              53.16761802805007
            ],
            [
              0.273533829613871,
              53.167522464920836
            ],
            [
              0.273567990983754,
              53.1673168202259
            ],
            [
              0.275069972334412,
              53.16740708205933
            ],
            [
              0.275076671308675,
              53.16736685465725
            ],
            [
              0.273574691354322,
              53.167276592892875
            ],
            [
              0.27361020427547,
              53.16706256083506
            ],
            [
              0.275201931825541,
              53.16715821009336
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11080708',
        'Roof.Size': '3746.007469',
        'Estimated capacity': '524.4410457',
        'Title.Number': 'LL244088',
        Tenure: 'Freehold',
        'Property Address': 'The Old Aerodrome, South East side of Sleaford Road, East Kirkby (PE23 4BU)',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'A E LENTON (ESTATES) LIMITED',
        'Company Registration No. (1)': '00531491',
        'Date Proprietor Added': '22/07/2004',
        POSTCODE_2: 'PE23 4BU',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '66',
        'Capital Employed': '1997933',
        Cash: '36107',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '1520694',
        'Current Liabilities': '4013661',
        'Debt to Capital': '67.31667175',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'April',
        'Industry Keywords': 'agriculture, seeds, crop farming, leguminous crops, crops, cereals, oil seeds, arable farming, farming',
        'Net Assets': '1964789',
        'SIC 2007 Description': 'Growing of cereals (except rice), leguminous crops and oil seeds',
        'Shareholder Funds': '1964789',
        'Shareholders Names': 'Heather Burnett, Marlene Shooter, A.E. Lenton (Holdings) Ltd, A.E. Lenton (Holdings) Ltd & A.E. Lenton Ltd',
        Status: 'Active',
        Telephone: '01754 820203',
        'Trading Address': 'Hagnaby Priory, Hagnaby, Spilsby, Lincolnshire, PE23 4BP',
        'Trading Address 1': 'Hagnaby Priory',
        'Trading Address 2': 'Hagnaby',
        'Trading Address 3': 'Spilsby',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'A.E. Lenton (Holdings) Limited',
        'Ultimate Domestic Parent Id': 'company/gb/00691934',
        'Ultimate Parent Name': 'A.E. Lenton (Holdings) Limited',
        'Ultimate Parent Id': 'company/gb/00691934',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 53.14124349771592,
        'long': 0.002149238196394202
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.002008592848344,
              53.14097921746298
            ],
            [
              0.001987594144127,
              53.14099386543277
            ],
            [
              0.002773646799528,
              53.14150254290176
            ],
            [
              0.002367352836367,
              53.141729643635884
            ],
            [
              0.001575021683217,
              53.141220979152216
            ],
            [
              0.001795235233797,
              53.141098242777524
            ],
            [
              0.001706282524367,
              53.14104058096919
            ],
            [
              0.001919353841189,
              53.14092183034138
            ],
            [
              0.001819085771812,
              53.14085554820549
            ],
            [
              0.002220022284502,
              53.140632315376
            ],
            [
              0.002409956800269,
              53.14075552728698
            ],
            [
              0.002008592848344,
              53.14097921746298
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11083594',
        'Roof.Size': '5543.988426',
        'Estimated capacity': '776.1583796',
        'Title.Number': 'LL77050',
        Tenure: 'Freehold',
        'Property Address': 'land and buildings on the south side of Wainfleet Road, Skegness',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'SAFEWAY LIMITED',
        'Company Registration No. (1)': '01299733',
        'Date Proprietor Added': '',
        POSTCODE_2: 'PE25 3QT',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '43',
        'Capital Employed': '1654000000',
        Cash: '0',
        Charges: '0',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '1597000000',
        'Current Liabilities': '1154000000',
        'Debt to Capital': '41.0968661',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'February',
        'Industry Keywords': 'retail display, retail business, retail outlets, grocer, retail units, retail trade, retail stores, retail market, retail shop, retail merchandising, b2c, capital management, ready meals, private equity investors, investors, ingredient, private equity, photographic, lottery, pharmacy, union, floristry, photographic processing, fuel, dry cleaning, bakery, grocery, meat, liquor, retail sales, dairy, delicatessens, snack food, beverages, tobacco, food, stores, retail, supermarket, grocery store',
        'Net Assets': '1654000000',
        'SIC 2007 Description': 'Retail sale in non-specialised stores with food, beverages or tobacco predominating',
        'Shareholder Funds': '1654000000',
        'Shareholders Names': 'Neerock Ltd, Wm Morrison Supermarkets Holdings Limited',
        Status: 'Active',
        Telephone: '01259 216792',
        'Trading Address': 'Hilmore House, Gain Lane, Bradford, West Yorkshire, BD3 7DL',
        'Trading Address 1': 'Hilmore House',
        'Trading Address 2': 'Gain Lane',
        'Trading Address 3': 'Bradford',
        'Trading Address 4': 'West Yorkshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'Wm Morrison Supermarkets P L C',
        'Ultimate Domestic Parent Id': 'company/gb/00358949',
        'Ultimate Parent Name': 'Wm Morrison Supermarkets P L C',
        'Ultimate Parent Id': 'company/gb/00358949',
        'Web Address 1': 'http://www.safeway.co.uk',
        'Web Address 2': 'http://www.morrisons.co.uk',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '443000000',
        lat: 53.14279253251927,
        'long': 0.33203778133474826
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.331342396620642,
              53.142368072481524
            ],
            [
              0.331514148407624,
              53.14234214120491
            ],
            [
              0.331579255980597,
              53.14249784939703
            ],
            [
              0.332097634005555,
              53.14241972289631
            ],
            [
              0.332605780931474,
              53.14264946773831
            ],
            [
              0.332758417335782,
              53.143040553682496
            ],
            [
              0.331691948660501,
              53.14320341602769
            ],
            [
              0.331342396620642,
              53.142368072481524
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11085462',
        'Roof.Size': '3626.344997',
        'Estimated capacity': '507.6882996',
        'Title.Number': 'LL283320',
        Tenure: 'Freehold',
        'Property Address': 'St. Hughs School, Cromwell Avenue, Woodhall Spa (LN10 6TQ)',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'ST HUGHS SCHOOL (WOODHALL SPA) LIMITED',
        'Company Registration No. (1)': '00796794',
        'Date Proprietor Added': '29/05/2007',
        POSTCODE_2: 'LN10 6TH',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '56',
        'Capital Employed': '1486603',
        Cash: '115796',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '599899',
        'Current Liabilities': '805035',
        'Debt to Capital': '38.36330171',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'August',
        'Industry Keywords': 'junior school, secondary school, primary school, day school, specialist school, minibus driver, dining room, minibus, photo gallery, curriculum, school curriculum, art, arts, virtual tour, photo, education, spa, primary education, charity, charities, registered charity, preparatory school, school',
        'Net Assets': '1412490',
        'SIC 2007 Description': 'Primary education',
        'Shareholder Funds': '1412490',
        'Shareholders Names': '0',
        Status: 'Active',
        Telephone: '01526 352169',
        'Trading Address': 'Cromwell Avenue, Woodhall Spa, Lincolnshire, LN10 6TQ',
        'Trading Address 1': 'Cromwell Avenue',
        'Trading Address 2': 'Woodhall Spa',
        'Trading Address 3': 'Lincolnshire',
        'Trading Address 4': '0',
        Turnover: '2094336',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.st-hughs.lincs.sch.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-205136',
        lat: 53.14903585925772,
        'long': -0.21278485201141434
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.212395133165317,
              53.14906960795831
            ],
            [
              -0.212863195933277,
              53.149570103451246
            ],
            [
              -0.213553377455883,
              53.14932397028451
            ],
            [
              -0.213356219367585,
              53.149120172160636
            ],
            [
              -0.213452882938946,
              53.14908988716734
            ],
            [
              -0.212899263144702,
              53.14843860359101
            ],
            [
              -0.212650940326389,
              53.148527205556135
            ],
            [
              -0.212774478972431,
              53.14865995653785
            ],
            [
              -0.212879663779597,
              53.14862611393787
            ],
            [
              -0.213428925836231,
              53.14923112276845
            ],
            [
              -0.212972147399814,
              53.14939157569986
            ],
            [
              -0.212773115921111,
              53.149182534188604
            ],
            [
              -0.212935979700783,
              53.14912816061671
            ],
            [
              -0.212956481054368,
              53.14908459629655
            ],
            [
              -0.212833544671684,
              53.149056140438084
            ],
            [
              -0.212748451966245,
              53.148962624266204
            ],
            [
              -0.212456364648951,
              53.14906072786096
            ],
            [
              -0.212246647336529,
              53.14883543263205
            ],
            [
              -0.212614586832869,
              53.14871122792991
            ],
            [
              -0.212536411357828,
              53.148627614698775
            ],
            [
              -0.211968173453785,
              53.14881821581642
            ],
            [
              -0.212250792539042,
              53.14911724655675
            ],
            [
              -0.212395133165317,
              53.14906960795831
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11085843',
        'Roof.Size': '5815.718951',
        'Estimated capacity': '814.2006531',
        'Title.Number': 'LL327826',
        Tenure: 'Freehold',
        'Property Address': 'Mill Farm And Lodge Farm, Lodge Road, Tattershall, Lincoln (LN4 4JS)',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'BREEDON AGGREGATES ENGLAND LIMITED',
        'Company Registration No. (1)': '00613713',
        'Date Proprietor Added': '01/09/2011',
        POSTCODE_2: 'LN4 4JS',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '61',
        'Capital Employed': '14981052',
        Cash: '1201',
        Charges: 'Outstanding, Partially Satisfied, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Not Rated',
        'Current Assets': '3270248',
        'Current Liabilities': '9987293',
        'Debt to Capital': '42.228058',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '650896',
        'Financial Year End': 'December',
        'Industry Keywords': 'manufacturing, ready-mixed concrete, concrete, gravel, mortars',
        'Net Assets': '14424698',
        'SIC 2007 Description': '0',
        'Shareholder Funds': '14424698',
        'Shareholders Names': 'Frank Egerton Gilman, Hanson Quarry Products Ventures Ltd, F.H. Gilman & Company',
        Status: 'Dissolved',
        Telephone: '0',
        'Trading Address': 'Central Square 8th Floor, 29 Wellington Street, Leeds, West Yorkshire Ls1 4, LS1 4DL',
        'Trading Address 1': 'Central Square 8th Floor',
        'Trading Address 2': '29 Wellington Street',
        'Trading Address 3': 'Leeds',
        'Trading Address 4': 'West Yorkshire Ls1 4',
        Turnover: '11976154',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 53.126819068575884,
        'long': -0.21891978808204676
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.219313431765093,
              53.12731537861101
            ],
            [
              -0.219455610668017,
              53.12716620032625
            ],
            [
              -0.218839927460212,
              53.12695399232743
            ],
            [
              -0.218886647396235,
              53.126904885679785
            ],
            [
              -0.219502330143477,
              53.12711709343468
            ],
            [
              -0.219644511440299,
              53.12696782506221
            ],
            [
              -0.219028826377299,
              53.12675570789601
            ],
            [
              -0.219075392795044,
              53.12670668878086
            ],
            [
              -0.219687429381705,
              53.12692027948284
            ],
            [
              -0.21982874741881,
              53.12677378494166
            ],
            [
              -0.218555179048071,
              53.126329319215984
            ],
            [
              -0.218034024501629,
              53.1268745060569
            ],
            [
              -0.219313431765093,
              53.12731537861101
            ]
          ],
          [
            [
              -0.218786284877016,
              53.12693556900577
            ],
            [
              -0.218306476238148,
              53.12677016213088
            ],
            [
              -0.218353196573249,
              53.1267210556946
            ],
            [
              -0.21883300485324,
              53.12688646237939
            ],
            [
              -0.218786284877016,
              53.12693556900577
            ]
          ],
          [
            [
              -0.218496702506642,
              53.12657234790168
            ],
            [
              -0.218545132348591,
              53.12652164873919
            ],
            [
              -0.219021907213356,
              53.12668808810829
            ],
            [
              -0.218975187671275,
              53.12673719481401
            ],
            [
              -0.218496702506642,
              53.12657234790168
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11087594',
        'Roof.Size': '5745.509407',
        'Estimated capacity': '804.371317',
        'Title.Number': 'LL294113',
        Tenure: 'Freehold',
        'Property Address': 'Land on the west side of Croft Bank, Croft',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'FCFM GROUP PROPERTIES III LIMITED',
        'Company Registration No. (1)': '10238290',
        'Date Proprietor Added': '03/03/2020',
        POSTCODE_2: 'PE24 4AW',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '4',
        'Capital Employed': '1441088',
        Cash: '118172',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '1303654',
        'Cost of Sales Delta': '897148',
        'Cost of Sales Growth': '220.6973575',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '5218837',
        'Current Liabilities': '16637749',
        'Debt to Capital': '92.07575686',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '454857',
        'Financial Year End': 'March',
        'Industry Keywords': 'financial services, financial services holding companies, holding companies, investment, investment property',
        'Net Assets': '1432611',
        'SIC 2007 Description': 'Activities of financial services holding companies',
        'Shareholder Funds': '1432611',
        'Shareholders Names': 'Fcfm Group Limited',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': '39 Sloane Street, Knightsbridge, London, SW1X 9LP',
        'Trading Address 1': '39 Sloane Street',
        'Trading Address 2': 'Knightsbridge',
        'Trading Address 3': 'London',
        'Trading Address 4': '0',
        Turnover: '1975063',
        'Ultimate Domestic Parent Name': 'Faro Capital Limited',
        'Ultimate Domestic Parent Id': 'company/gb/09610476',
        'Ultimate Parent Name': 'Incap Netherlands (Holdings) B.V.',
        'Ultimate Parent Id': 'company/nl/33272788',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-11418912',
        lat: 53.1141493481308,
        'long': 0.24946288539803443
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.250101410885539,
              53.11460015749424
            ],
            [
              0.249070369929053,
              53.11448640103497
            ],
            [
              0.249112457999861,
              53.114359463013976
            ],
            [
              0.248946051437277,
              53.11433770284904
            ],
            [
              0.249003354571054,
              53.1141644438253
            ],
            [
              0.248722314529353,
              53.11413082374027
            ],
            [
              0.248828535736272,
              53.1138097288092
            ],
            [
              0.249095293501258,
              53.113841730445706
            ],
            [
              0.249011872979764,
              53.11409289492661
            ],
            [
              0.249129162267188,
              53.114106951960686
            ],
            [
              0.24927985666977,
              53.11364457789134
            ],
            [
              0.249756800451209,
              53.11370119650443
            ],
            [
              0.249727384829684,
              53.11379319026615
            ],
            [
              0.249888083290612,
              53.113811550776546
            ],
            [
              0.249709467956225,
              53.114385850994964
            ],
            [
              0.249841112948865,
              53.1144000855093
            ],
            [
              0.249863495061603,
              53.11429644658539
            ],
            [
              0.250302969874047,
              53.11433075463446
            ],
            [
              0.250282009594463,
              53.11442726393497
            ],
            [
              0.25015671019643,
              53.11441722557366
            ],
            [
              0.250101410885539,
              53.11460015749424
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11090812',
        'Roof.Size': '4430.657291',
        'Estimated capacity': '620.2920207',
        'Title.Number': 'LL277188',
        Tenure: 'Freehold',
        'Property Address': 'land on the west side of Main Road, Carrington, Boston',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'BEESWAX DYSON FARMING LIMITED',
        'Company Registration No. (1)': '06970038',
        'Date Proprietor Added': '25/04/2013',
        POSTCODE_2: 'PE22 7JD',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '11',
        'Capital Employed': '529144000',
        Cash: '4000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '6414000',
        'Cost of Sales Delta': '-1515000',
        'Cost of Sales Growth': '-19.10707529',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '29393000',
        'Current Liabilities': '15532000',
        'Debt to Capital': '3.206860592',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '6809000',
        'Financial Year End': 'December',
        'Industry Keywords': 'agriculture, holding companies, energy, farming, agricultural holding companies, holding companies management, investment, investment property',
        'Net Assets': '527209000',
        'SIC 2007 Description': 'Activities of agricultural holding companies',
        'Shareholder Funds': '527209000',
        'Shareholders Names': 'New Beeswax Dyson Farming Limited',
        Status: 'Active',
        Telephone: '01526 322058',
        'Trading Address': 'The Estate Office Cyclone Way, Nocton, Lincoln Ln4 2gr, LN4 2GR',
        'Trading Address 1': 'The Estate Office Cyclone Way',
        'Trading Address 2': 'Nocton',
        'Trading Address 3': 'Lincoln Ln4 2gr',
        'Trading Address 4': '0',
        Turnover: '21923000',
        'Ultimate Domestic Parent Name': 'Weybourne Group Limited',
        'Ultimate Domestic Parent Id': 'company/gb/08445070',
        'Ultimate Parent Name': 'Weybourne Group Limited',
        'Ultimate Parent Id': 'company/gb/08445070',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '13861000',
        lat: 53.07120386033822,
        'long': -0.051607622131534664
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.05190578856812,
              53.07075921705589
            ],
            [
              -0.051220824170575,
              53.07078468077501
            ],
            [
              -0.051309448540193,
              53.07164850093751
            ],
            [
              -0.051994426623689,
              53.071623036838105
            ],
            [
              -0.05190578856812,
              53.07075921705589
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11093092',
        'Roof.Size': '5654.27337',
        'Estimated capacity': '791.5982718',
        'Title.Number': 'LL112533',
        Tenure: 'Freehold',
        'Property Address': 'Poplar Farm, Midville Road, Stickney, Boston (PE22 8DU)',
        District: 'EAST LINDSEY',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'A.R. CRAVEN FARMS LIMITED',
        'Company Registration No. (1)': '03115021',
        'Date Proprietor Added': '29/12/1995',
        POSTCODE_2: 'PE22 8DN',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '25',
        'Capital Employed': '11726604',
        Cash: '466274',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '1945943',
        'Current Liabilities': '1132555',
        'Debt to Capital': '52.27524599',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': 'cattle farming, agriculture, animals, crop farming, animal farming, cereals, mixed farming, livestock farming, livestock, arable farming, crops, farming',
        'Net Assets': '6137002',
        'SIC 2007 Description': 'Mixed farming',
        'Shareholder Funds': '6137002',
        'Shareholders Names': 'Judith Mary Craven, Andrew Robert Craven',
        Status: 'Active',
        Telephone: '01205 480900',
        'Trading Address': 'The Poplars, Main Road, Stickford, Boston, Lincolnshire, PE22 8EL',
        'Trading Address 1': 'The Poplars',
        'Trading Address 2': 'Main Road',
        'Trading Address 3': 'Stickford',
        'Trading Address 4': 'Boston, Lincolnshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '813388',
        lat: 53.09979386154144,
        'long': 0.02201031415242554
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.022137947000438,
              53.100584363996944
            ],
            [
              0.021654105713901,
              53.10055868983113
            ],
            [
              0.021882605712961,
              53.099003395127795
            ],
            [
              0.022366583095521,
              53.099029155834714
            ],
            [
              0.022137947000438,
              53.100584363996944
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11097365',
        'Roof.Size': '8533.83056',
        'Estimated capacity': '1194.736278',
        'Title.Number': 'LL346524',
        Tenure: 'Freehold',
        'Property Address': 'Land lying to the west of Ings Road, Benington, Boston',
        District: 'BOSTON',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'STAPLES BROTHERS LIMITED',
        'Company Registration No. (1)': '00568996',
        'Date Proprietor Added': '10/01/2014',
        POSTCODE_2: 'PE22 0SE',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '64',
        'Capital Employed': '38488043',
        Cash: '6578',
        Charges: 'Outstanding',
        'Cost of Sales': '28454228',
        'Cost of Sales Delta': '2101651',
        'Cost of Sales Growth': '7.975125165',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '17511176',
        'Current Liabilities': '15318921',
        'Debt to Capital': '42.8524029',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '6819221',
        'Financial Year End': 'April',
        'Industry Keywords': 'sweet potatoes, prepared vegetables, tomato, preserved vegetables, potatoes, fruit, pome fruits, mushrooms, sweet peppers, staples, home products, production system, nursery products, horticultural specialties, tubers, melons, farming, vegetable suppliers, fruit producer, vegetable producer, fruits, vegetables',
        'Net Assets': '30749387',
        'SIC 2007 Description': 'Growing of vegetables and melons, roots and tubers',
        'Shareholder Funds': '30749387',
        'Shareholders Names': 'George Read, Trustees of Janet Read (Vernon Read) Settlement, Trustees of Janet Read (George Read) Settlement, Trustees of Janet Read No 2 Discretionary Settlement, Trustees of Staples Childrens Settlement, Vernon Read',
        Status: 'Active',
        Telephone: '01205 872900',
        'Trading Address': 'Station Road, Sibsey, Boston, Lincolnshire, PE22 0SE',
        'Trading Address 1': 'Station Road',
        'Trading Address 2': 'Sibsey',
        'Trading Address 3': 'Boston',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '35216832',
        'Ultimate Domestic Parent Name': 'Staples Brothers Limited',
        'Ultimate Domestic Parent Id': 'company/gb/00568996',
        'Ultimate Parent Name': 'Staples Brothers Limited',
        'Ultimate Parent Id': 'company/gb/00568996',
        'Web Address 1': 'http://www.staplesvegetables.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '2192255',
        lat: 53.03351998347003,
        'long': 0.03616180494264625
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              0.03563795231676,
              53.03395207655571
            ],
            [
              0.0356463778882,
              53.034042288662
            ],
            [
              0.035536927663402,
              53.03404596019561
            ],
            [
              0.035451030615209,
              53.033121570208806
            ],
            [
              0.036805040809307,
              53.033075821434316
            ],
            [
              0.03688268755132,
              53.03390999562313
            ],
            [
              0.03563795231676,
              53.03395207655571
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11102035',
        'Roof.Size': '3740.500337',
        'Estimated capacity': '523.6700472',
        'Title.Number': 'LL54745',
        Tenure: 'Freehold',
        'Property Address': 'Del Monte Fresh Produce Uk, Slippery Gowt Lane, Wyberton, Boston (PE21 7AA)',
        District: 'BOSTON',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'GENPROP LIMITED',
        'Company Registration No. (1)': '11706840',
        'Date Proprietor Added': '18/06/2019',
        POSTCODE_2: 'PE21 7AA',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '1',
        'Capital Employed': '0',
        Cash: '0',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Moderate',
        'Current Assets': '0',
        'Current Liabilities': '0',
        'Debt to Capital': '0',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': '0',
        'Industry Keywords': 'leased real estate, renting, real estate',
        'Net Assets': '0',
        'SIC 2007 Description': 'Letting and operating of own or leased real estate (other than Housing Association real estate and conference and exhibition services) n.e.c.',
        'Shareholder Funds': '0',
        'Shareholders Names': 'Lewis Mitchell Bourne',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'The Maltings, Church Lane, Swineshead, Boston, PE20 3HY',
        'Trading Address 1': 'The Maltings',
        'Trading Address 2': 'Church Lane',
        'Trading Address 3': 'Swineshead',
        'Trading Address 4': 'Boston',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 52.9556064639077,
        'long': -0.010675543574778942
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.011128147952382,
              52.95533982536715
            ],
            [
              -0.010197705119625,
              52.95532955385365
            ],
            [
              -0.010184560571066,
              52.955771497091874
            ],
            [
              -0.010418625050903,
              52.955774067525375
            ],
            [
              -0.010414851305108,
              52.95590167247831
            ],
            [
              -0.011110794900412,
              52.955909366323276
            ],
            [
              -0.011128147952382,
              52.95533982536715
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11108210',
        'Roof.Size': '8580.895559',
        'Estimated capacity': '1201.325378',
        'Title.Number': 'LL240331',
        Tenure: 'Freehold',
        'Property Address': 'Land on the north side of Sleaford Road, Boston',
        District: 'BOSTON',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'ASDA STORES LIMITED',
        'Company Registration No. (1)': '00464777',
        'Date Proprietor Added': '',
        POSTCODE_2: 'PE21 8EQ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '71',
        'Capital Employed': '6869000000',
        Cash: '192100000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '5726900000',
        'Current Liabilities': '4294000000',
        'Debt to Capital': '49.1928693',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '707300000',
        'Financial Year End': 'December',
        'Industry Keywords': 'retail merchandising, retail outlets, retail display, b2c, retail trade, retail market, retail shop, retail units, retail business, mobile phone, carpets, networking, security, retail stores, acquisition, land, investment, planning applications, head offices, marketing, promotion, property, property development, retailer, market share, local councils, financial, grocery, general merchandise, clothing, retail sales, food, financial services, stores, retail, supermarkets, supermarket',
        'Net Assets': '5671600000',
        'SIC 2007 Description': 'Other retail sale in non-specialised stores',
        'Shareholder Funds': '5671600000',
        'Shareholders Names': 'Asda Group Ltd',
        Status: 'Active',
        Telephone: '0113 243 5435',
        'Trading Address': 'Asda House, Great Wilson Street, Leeds, West Yorkshire, LS11 5AD',
        'Trading Address 1': 'Asda House',
        'Trading Address 2': 'Great Wilson Street',
        'Trading Address 3': 'Leeds',
        'Trading Address 4': 'West Yorkshire',
        Turnover: '22842800000',
        'Ultimate Domestic Parent Name': 'Broadstreet Great Wilson Europe Limited',
        'Ultimate Domestic Parent Id': 'company/gb/04521152',
        'Ultimate Parent Name': 'Walmart Inc',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.asda.com',
        'Web Address 2': 'http://www.asda-clothing.co.uk',
        'Web Address 3': 'http://www.asda-entertainment.co.uk',
        'Web Address 4': 'http://www.asdageorge.co.uk',
        'Working Capital': '1432900000',
        lat: 52.97988077647071,
        'long': -0.031859129588818
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.031092047514298,
              52.98027819914417
            ],
            [
              -0.032099291294619,
              52.98042623245121
            ],
            [
              -0.032194466886312,
              52.98019045499228
            ],
            [
              -0.032414501365974,
              52.98022277761592
            ],
            [
              -0.032657780578855,
              52.979622183289344
            ],
            [
              -0.032397520076982,
              52.9795835311158
            ],
            [
              -0.032428191920845,
              52.97950842747372
            ],
            [
              -0.031980914673367,
              52.97944195423861
            ],
            [
              -0.032024827988868,
              52.97933443387181
            ],
            [
              -0.031695910151059,
              52.97928574265212
            ],
            [
              -0.031652153086825,
              52.97939308567805
            ],
            [
              -0.031462175562708,
              52.97936485564922
            ],
            [
              -0.031092047514298,
              52.98027819914417
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11109062',
        'Roof.Size': '6672.402853',
        'Estimated capacity': '934.1363994',
        'Title.Number': 'LL106128',
        Tenure: 'Freehold',
        'Property Address': 'land on the east side of London Road, Boston',
        District: 'BOSTON',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'SGBD PROPERTY HOLDINGS LIMITED',
        'Company Registration No. (1)': '00213753',
        'Date Proprietor Added': '06/04/2011',
        POSTCODE_2: 'PE21 7FG',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '94',
        'Capital Employed': '49849000',
        Cash: '0',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '26511000',
        'Current Liabilities': '136302000',
        'Debt to Capital': '75.92492117',
        'Dividends Payable': '10680000',
        'Dormant Company': 'No',
        EBITDA: '26774000',
        'Financial Year End': 'December',
        'Industry Keywords': 'glass, ironing, iron, tools, housing, construction, masonry, thermal performance, cavity, walls, wiring accessories, industry news, careers site, building materials, housing developments, new housing developments, access equipment, stone walling, power tools, tool hire, business support, commercial property, builders, building, merchants',
        'Net Assets': '44816000',
        'SIC 2007 Description': 'Other business support service activities n.e.c.',
        'Shareholder Funds': '44816000',
        'Shareholders Names': 'Saint-Gobain Building Distribution Limited',
        Status: 'Active',
        Telephone: '024 7643 8400',
        'Trading Address': 'Saint-Gobain House, Binley Business Park, Coventry, West Midlands, CV3 2TT',
        'Trading Address 1': 'Saint-Gobain House',
        'Trading Address 2': 'Binley Business Park',
        'Trading Address 3': 'Coventry',
        'Trading Address 4': 'West Midlands',
        Turnover: '26511000',
        'Ultimate Domestic Parent Name': 'Saint-Gobain Limited',
        'Ultimate Domestic Parent Id': 'company/gb/03291592',
        'Ultimate Parent Name': 'Compagnie De Saint-Gobain',
        'Ultimate Parent Id': 'company/fr/542039532',
        'Web Address 1': 'http://www.jewson.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-109791000',
        lat: 52.96036992938865,
        'long': -0.03244389388115356
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.032026871021194,
              52.96033611827238
            ],
            [
              -0.031987329862858,
              52.960118338656606
            ],
            [
              -0.031760349500695,
              52.96013301052135
            ],
            [
              -0.031797403282199,
              52.96034652340765
            ],
            [
              -0.031006063916522,
              52.960382144389705
            ],
            [
              -0.031025951235627,
              52.96053756306297
            ],
            [
              -0.032199730818999,
              52.96048373051966
            ],
            [
              -0.032224834032976,
              52.960737503400416
            ],
            [
              -0.033067504455254,
              52.9607028137512
            ],
            [
              -0.033022782479765,
              52.960320239636175
            ],
            [
              -0.033554957395417,
              52.96030630022006
            ],
            [
              -0.033551083563083,
              52.96024402066034
            ],
            [
              -0.033431397601454,
              52.96024680523117
            ],
            [
              -0.033410373935189,
              52.959985008537195
            ],
            [
              -0.03331868351083,
              52.95998771681816
            ],
            [
              -0.033324933194566,
              52.96006325199874
            ],
            [
              -0.032365188611907,
              52.96009267851465
            ],
            [
              -0.032114248845035,
              52.9601103708157
            ],
            [
              -0.032154432537831,
              52.96033040869333
            ],
            [
              -0.032026871021194,
              52.96033611827238
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11109096',
        'Roof.Size': '6585.083023',
        'Estimated capacity': '921.9116232',
        'Title.Number': 'LL106128',
        Tenure: 'Freehold',
        'Property Address': 'land on the east side of London Road, Boston',
        District: 'BOSTON',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'SGBD PROPERTY HOLDINGS LIMITED',
        'Company Registration No. (1)': '00213753',
        'Date Proprietor Added': '06/04/2011',
        POSTCODE_2: 'PE21 7HE',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '94',
        'Capital Employed': '49849000',
        Cash: '0',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '26511000',
        'Current Liabilities': '136302000',
        'Debt to Capital': '75.92492117',
        'Dividends Payable': '10680000',
        'Dormant Company': 'No',
        EBITDA: '26774000',
        'Financial Year End': 'December',
        'Industry Keywords': 'glass, ironing, iron, tools, housing, construction, masonry, thermal performance, cavity, walls, wiring accessories, industry news, careers site, building materials, housing developments, new housing developments, access equipment, stone walling, power tools, tool hire, business support, commercial property, builders, building, merchants',
        'Net Assets': '44816000',
        'SIC 2007 Description': 'Other business support service activities n.e.c.',
        'Shareholder Funds': '44816000',
        'Shareholders Names': 'Saint-Gobain Building Distribution Limited',
        Status: 'Active',
        Telephone: '024 7643 8400',
        'Trading Address': 'Saint-Gobain House, Binley Business Park, Coventry, West Midlands, CV3 2TT',
        'Trading Address 1': 'Saint-Gobain House',
        'Trading Address 2': 'Binley Business Park',
        'Trading Address 3': 'Coventry',
        'Trading Address 4': 'West Midlands',
        Turnover: '26511000',
        'Ultimate Domestic Parent Name': 'Saint-Gobain Limited',
        'Ultimate Domestic Parent Id': 'company/gb/03291592',
        'Ultimate Parent Name': 'Compagnie De Saint-Gobain',
        'Ultimate Parent Id': 'company/fr/542039532',
        'Web Address 1': 'http://www.jewson.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-109791000',
        lat: 52.95958895704632,
        'long': -0.0343762130710031
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.033060339733242,
              52.95942395154407
            ],
            [
              -0.033073111960184,
              52.95953618675105
            ],
            [
              -0.033210353744109,
              52.95953036655237
            ],
            [
              -0.033228887087241,
              52.95969610169092
            ],
            [
              -0.033704710432704,
              52.959677003428155
            ],
            [
              -0.033722287885504,
              52.95983103478049
            ],
            [
              -0.035635254877931,
              52.95975478226729
            ],
            [
              -0.035602482349077,
              52.95947597927686
            ],
            [
              -0.034964770329255,
              52.95950274766464
            ],
            [
              -0.034948311471166,
              52.95934693688078
            ],
            [
              -0.033060339733242,
              52.95942395154407
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11111220',
        'Roof.Size': '4074.606566',
        'Estimated capacity': '570.4449192',
        'Title.Number': 'LL361757',
        Tenure: 'Freehold',
        'Property Address': 'land lying to the south of Wash Road, Kirton, Boston',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'MARTIN DUCKWORTH LIMITED',
        'Company Registration No. (1)': '02383784',
        'Date Proprietor Added': '11/11/2015',
        POSTCODE_2: 'PE20 1LA',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '31',
        'Capital Employed': '8997502',
        Cash: '1017145',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '103479273',
        'Cost of Sales Delta': '6535394',
        'Cost of Sales Growth': '6.741419951',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '20556932',
        'Current Liabilities': '20649269',
        'Debt to Capital': '84.89319798',
        'Dividends Payable': '204200',
        'Dormant Company': 'No',
        EBITDA: '1561942',
        'Financial Year End': 'December',
        'Industry Keywords': 'engineering, motor, automotive, consultant, used cars, rover, used car, new motor vehicles, cars, new vehicles, land, light motor vehicles, new cars, motor vehicles, vehicles',
        'Net Assets': '4478679',
        'SIC 2007 Description': 'Sale of new cars and light motor vehicles',
        'Shareholder Funds': '4478679',
        'Shareholders Names': 'Sally Jean Penelope Duckworth Mrs, Ben James Duckworth, Martin James Duckworth Mr',
        Status: 'Active',
        Telephone: '01673 842101',
        'Trading Address': 'Racecourse Garage, Willingham Road, Market Rasen, Lincolnshire, LN8 3RE',
        'Trading Address 1': 'Racecourse Garage',
        'Trading Address 2': 'Willingham Road',
        'Trading Address 3': 'Market Rasen',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '111265912',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.duckworth.co.uk',
        'Web Address 2': 'http://www.duckworthparts.co.uk',
        'Web Address 3': 'http://www.duckworthlandrover.co.uk',
        'Web Address 4': '0',
        'Working Capital': '-92337',
        lat: 52.924517245641574,
        'long': -0.054245823157299
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.05358863092536,
              52.92417840430315
            ],
            [
              -0.053362700088094,
              52.92445260474714
            ],
            [
              -0.05475262570997,
              52.9248709621391
            ],
            [
              -0.055066973356676,
              52.92448932940608
            ],
            [
              -0.054554246277805,
              52.924335015263146
            ],
            [
              -0.054465826090274,
              52.924442354635985
            ],
            [
              -0.05358863092536,
              52.92417840430315
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11113948',
        'Roof.Size': '13346.00741',
        'Estimated capacity': '1868.441037',
        'Title.Number': 'LL302134',
        Tenure: 'Freehold',
        'Property Address': 'Land lying to the south of Holbeach Road, Spalding',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'PORK FARMS CASPIAN LIMITED',
        'Company Registration No. (1)': '09104836',
        'Date Proprietor Added': '08/09/2014',
        POSTCODE_2: 'PE12 6EP',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '6',
        'Capital Employed': '5231000',
        Cash: '1967000',
        Charges: 'Outstanding, Partially Satisfied, Satisfied',
        'Cost of Sales': '91384000',
        'Cost of Sales Delta': '4634000',
        'Cost of Sales Growth': '5.341786744',
        Country: 'GB',
        'Credit Risk': 'Moderate',
        'Current Assets': '21501000',
        'Current Liabilities': '36912000',
        'Debt to Capital': '158.2113281',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '-1338000',
        'Financial Year End': 'March',
        'Industry Keywords': 'retail, millers, investors, bakery, factory, sausage rolls, pork pies, pork, financial information, news media, food, food products, markets, retailer, manufacturing, foodservice',
        'Net Assets': '-24532000',
        'SIC 2007 Description': 'Manufacture of other food products n.e.c.',
        'Shareholder Funds': '-24532000',
        'Shareholders Names': 'Addo Food Group Limited',
        Status: 'Active',
        Telephone: '0115 986 6462',
        'Trading Address': 'Dunsil Drive, Queens Drive Industrial Estate, Nottingham, Nottinghamshire, NG2 1LU',
        'Trading Address 1': 'Dunsil Drive',
        'Trading Address 2': 'Queens Drive Industrial Estate',
        'Trading Address 3': 'Nottingham',
        'Trading Address 4': 'Nottinghamshire',
        Turnover: '113354000',
        'Ultimate Domestic Parent Name': 'Addo Food Group (Holdings) Ltd',
        'Ultimate Domestic Parent Id': 'company/gb/10672448',
        'Ultimate Parent Name': 'Addo Food Group (Holdings) Ltd',
        'Ultimate Parent Id': 'company/gb/10672448',
        'Web Address 1': 'http://www.addofoodgroup.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-15411000',
        lat: 52.79567575314047,
        'long': -0.12607443046989256
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.126846323585239,
              52.7962767525026
            ],
            [
              -0.126777697768339,
              52.79552997520643
            ],
            [
              -0.126891512121399,
              52.79552538856358
            ],
            [
              -0.126878747513765,
              52.79540821721951
            ],
            [
              -0.126752155275242,
              52.79541332137497
            ],
            [
              -0.126730601113125,
              52.79518659364366
            ],
            [
              -0.126395677097839,
              52.795197129123544
            ],
            [
              -0.126374584905671,
              52.79492518505439
            ],
            [
              -0.125476908570662,
              52.794956769375695
            ],
            [
              -0.125507307240428,
              52.79522256713185
            ],
            [
              -0.12529736099599,
              52.795231028045215
            ],
            [
              -0.125311112179784,
              52.79537689572227
            ],
            [
              -0.125420101945607,
              52.795374122325505
            ],
            [
              -0.125431215019404,
              52.795515812550654
            ],
            [
              -0.125578832406749,
              52.795511581399175
            ],
            [
              -0.125592419915081,
              52.79566472896448
            ],
            [
              -0.125261353611572,
              52.79567514239329
            ],
            [
              -0.125275362592011,
              52.79582191321431
            ],
            [
              -0.125605704332374,
              52.795811128662365
            ],
            [
              -0.125614088933752,
              52.795909350360304
            ],
            [
              -0.125457412956505,
              52.7959137980817
            ],
            [
              -0.125464605754639,
              52.795998334999496
            ],
            [
              -0.125290065008384,
              52.79600429841549
            ],
            [
              -0.125322821420492,
              52.79633252933756
            ],
            [
              -0.126846323585239,
              52.7962767525026
            ]
          ],
          [
            [
              -0.126279894764843,
              52.795213012570855
            ],
            [
              -0.126179182797237,
              52.79521645692969
            ],
            [
              -0.126176443565436,
              52.795187013868805
            ],
            [
              -0.126277155465112,
              52.795183569511664
            ],
            [
              -0.126279894764843,
              52.795213012570855
            ]
          ],
          [
            [
              -0.125930166958266,
              52.79524012568887
            ],
            [
              -0.125700260691154,
              52.79524710325024
            ],
            [
              -0.125696646002944,
              52.7952035308451
            ],
            [
              -0.12592655590278,
              52.79519646344196
            ],
            [
              -0.125930166958266,
              52.79524012568887
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11114025',
        'Roof.Size': '12415.63673',
        'Estimated capacity': '1738.189142',
        'Title.Number': 'LL326382',
        Tenure: 'Freehold',
        'Property Address': 'Land on the east side of Marsh Road, Spalding',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'T F BOWMAN & SON LIMITED',
        'Company Registration No. (1)': '10058114',
        'Date Proprietor Added': '23/05/2017',
        POSTCODE_2: 'PE12 6AT',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '4',
        'Capital Employed': '23675395',
        Cash: '268060',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '328323',
        'Current Liabilities': '1148592',
        'Debt to Capital': '93.41038166',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'January',
        'Industry Keywords': '0',
        'Net Assets': '1635806',
        'SIC 2007 Description': 'Other personal service activities n.e.c.',
        'Shareholder Funds': '1635806',
        'Shareholders Names': 'Peter Andrew Bowman Mr, Rita Bowman Mrs',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': '1-4 London Road, Spalding, Lincolnshire, PE11 2TA',
        'Trading Address 1': '1-4 London Road',
        'Trading Address 2': 'Spalding',
        'Trading Address 3': 'Lincolnshire',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'T F Bowman & Son Limited',
        'Ultimate Domestic Parent Id': 'company/gb/10058114',
        'Ultimate Parent Name': 'T F Bowman & Son Limited',
        'Ultimate Parent Id': 'company/gb/10058114',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-820269',
        lat: 52.80287282087228,
        'long': -0.12678243117886348
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.126108632183088,
              52.803172164876834
            ],
            [
              -0.125861616670551,
              52.8032276027195
            ],
            [
              -0.12603648087985,
              52.80351150529029
            ],
            [
              -0.127852615436644,
              52.80310394159362
            ],
            [
              -0.127539647801812,
              52.802591655217405
            ],
            [
              -0.127888816908919,
              52.80251328089212
            ],
            [
              -0.127829211113863,
              52.80241586969284
            ],
            [
              -0.127502960193424,
              52.80248912113993
            ],
            [
              -0.127360474079608,
              52.80225599002024
            ],
            [
              -0.125767267985976,
              52.802613482490166
            ],
            [
              -0.125909332808458,
              52.802845889646385
            ],
            [
              -0.12568838485893,
              52.80289544529388
            ],
            [
              -0.125748821879181,
              52.80299412942462
            ],
            [
              -0.125969622015903,
              52.80294457132795
            ],
            [
              -0.126108632183088,
              52.803172164876834
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11114482',
        'Roof.Size': '9598.297535',
        'Estimated capacity': '1343.761655',
        'Title.Number': 'LL280596',
        Tenure: 'Freehold',
        'Property Address': 'land at Wardentree Lane, Pinchbeck, Spalding',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'WORLDWIDE FRUIT LIMITED',
        'Company Registration No. (1)': '03831143',
        'Date Proprietor Added': '',
        POSTCODE_2: 'PE11 3BB',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '21',
        'Capital Employed': '7785000',
        Cash: '2304000',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '148197000',
        'Cost of Sales Delta': '4484000',
        'Cost of Sales Growth': '3.120107436',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '19860000',
        'Current Liabilities': '18747000',
        'Debt to Capital': '75.35052013',
        'Dividends Payable': '2700000',
        'Dormant Company': 'No',
        EBITDA: '3864000',
        'Financial Year End': 'December',
        'Industry Keywords': 'sweet potatoes, mushrooms, prepared vegetables, tomato, pome fruits, potatoes, preserved vegetables, sweet peppers, marketing, production system, coaching culture, pear, sustainability, news media, wholesale, sourcing, vegetable wholesale, fruit wholesale, fruit, vegetables, fruits',
        'Net Assets': '6540000',
        'SIC 2007 Description': 'Wholesale of fruit and vegetables',
        'Shareholder Funds': '6540000',
        'Shareholders Names': 'Fruition Po Ltd, Enzafruit New Zealand International Limited',
        Status: 'Active',
        Telephone: '01775 717000',
        'Trading Address': 'Worldwide Fruit, Apple Way, Pinchbeck, Spalding, Lincolnshire, PE11 3BB',
        'Trading Address 1': 'Worldwide Fruit',
        'Trading Address 2': 'Apple Way',
        'Trading Address 3': 'Pinchbeck',
        'Trading Address 4': 'Spalding, Lincolnshire',
        Turnover: '160984000',
        'Ultimate Domestic Parent Name': 'Worldwide Fruit Limited',
        'Ultimate Domestic Parent Id': 'company/gb/03831143',
        'Ultimate Parent Name': 'Worldwide Fruit Limited',
        'Ultimate Parent Id': 'company/gb/03831143',
        'Web Address 1': 'http://www.worldwidefruit.co.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '1113000',
        lat: 52.80697443692571,
        'long': -0.13699748561321273
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.136835526655989,
              52.807269131751454
            ],
            [
              -0.136527033269214,
              52.807412365107375
            ],
            [
              -0.136731676226963,
              52.80757435489788
            ],
            [
              -0.136961005863088,
              52.80746781907241
            ],
            [
              -0.136875899717937,
              52.80740058081284
            ],
            [
              -0.137087816466888,
              52.80730222264335
            ],
            [
              -0.136974158220983,
              52.80721223919507
            ],
            [
              -0.137056371801656,
              52.8071736110438
            ],
            [
              -0.137231931617422,
              52.80731140785455
            ],
            [
              -0.137550372420988,
              52.80768169963534
            ],
            [
              -0.137786043639939,
              52.80760726876172
            ],
            [
              -0.137869295356442,
              52.80770405676156
            ],
            [
              -0.138032328176869,
              52.807652490779816
            ],
            [
              -0.137949220618535,
              52.80755579506482
            ],
            [
              -0.138134058549039,
              52.80749737851392
            ],
            [
              -0.137815465789433,
              52.80712708592938
            ],
            [
              -0.137609789737459,
              52.80719056937886
            ],
            [
              -0.137362138983356,
              52.80699688800861
            ],
            [
              -0.137523077617636,
              52.80692137451304
            ],
            [
              -0.136627392214505,
              52.806220780659025
            ],
            [
              -0.135919890204011,
              52.8065529350602
            ],
            [
              -0.136835526655989,
              52.807269131751454
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11115772',
        'Roof.Size': '6389.353673',
        'Estimated capacity': '894.5095142',
        'Title.Number': 'LL139091',
        Tenure: 'Freehold',
        'Property Address': 'land and buildings on the South side of Wardentree Lane, Pinchbeck',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'WM MORRISON SUPERMARKETS PLC',
        'Company Registration No. (1)': '00358949',
        'Date Proprietor Added': '10/03/2000',
        POSTCODE_2: 'PE11 3TY',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '80',
        'Capital Employed': '7524000000',
        Cash: '305000000',
        Charges: 'Satisfied',
        'Cost of Sales': '16907000000',
        'Cost of Sales Delta': '-176000000',
        'Cost of Sales Growth': '-1.030264005',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '1322000000',
        'Current Liabilities': '3396000000',
        'Debt to Capital': '58.41575092',
        'Dividends Payable': '302000000',
        'Dormant Company': 'No',
        EBITDA: '1031000000',
        'Financial Year End': 'February',
        'Industry Keywords': 'food products, retail market, retail shop, retail outlets, retail business, b2c, retail units, retail merchandising, retail display, retail trade, retail stores, doors, manufacturing, charity, retailing, charities, accessibility, corporate responsibility, store finder, financial, butter, egg, information privacy, financial statements, grocery, corporate governance, corporate news, retailer, shop, fresh food, market share, supermarkets, stalls, cd, dvd, dvds, clothing, drink, drinks, books, magazine, retail sales, beverages, tobacco, stores, food, supermarket, retail',
        'Net Assets': '4541000000',
        'SIC 2007 Description': 'Retail sale in non-specialised stores with food, beverages or tobacco predominating',
        'Shareholder Funds': '4541000000',
        'Shareholders Names': 'Undisclosed',
        Status: 'Active',
        Telephone: '0845 611 5000',
        'Trading Address': 'Hilmore House, 71 Gain Lane, Bradford, West Yorkshire, BD3 7DL',
        'Trading Address 1': 'Hilmore House',
        'Trading Address 2': '71 Gain Lane',
        'Trading Address 3': 'Bradford',
        'Trading Address 4': 'West Yorkshire',
        Turnover: '17536000000',
        'Ultimate Domestic Parent Name': 'Wm Morrison Supermarkets P L C',
        'Ultimate Domestic Parent Id': 'company/gb/00358949',
        'Ultimate Parent Name': 'Wm Morrison Supermarkets P L C',
        'Ultimate Parent Id': 'company/gb/00358949',
        'Web Address 1': 'http://www.morrisons-corporate.com',
        'Web Address 2': 'http://www.morrisons.net',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 52.8076830204003,
        'long': -0.14221438337544548
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.141583823245301,
              52.80774529498584
            ],
            [
              -0.141390833747943,
              52.80779315997119
            ],
            [
              -0.141595578981348,
              52.80809584769441
            ],
            [
              -0.142468453397572,
              52.807879441611064
            ],
            [
              -0.142872439074143,
              52.807984302983016
            ],
            [
              -0.143031958782522,
              52.80775393958078
            ],
            [
              -0.142719277165381,
              52.807292676923375
            ],
            [
              -0.142536023945882,
              52.80733844845744
            ],
            [
              -0.142493300873153,
              52.80727565379614
            ],
            [
              -0.141442578661494,
              52.80753656590024
            ],
            [
              -0.141583823245301,
              52.80774529498584
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11116119',
        'Roof.Size': '6013.673758',
        'Estimated capacity': '841.9143261',
        'Title.Number': 'LL170368',
        Tenure: 'Freehold',
        'Property Address': 'Land on the South East side of Enterprise Way, Pinchbeck, Spalding (PE11 3YR)',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'TOTAL WORLDFRESH LIMITED',
        'Company Registration No. (1)': '02208873',
        'Date Proprietor Added': '18/01/2012',
        POSTCODE_2: 'PE11 3SW',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '32',
        'Capital Employed': '9235000',
        Cash: '6942000',
        Charges: 'Satisfied',
        'Cost of Sales': '135334000',
        'Cost of Sales Delta': '-18881000',
        'Cost of Sales Growth': '-12.2432967',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '25433000',
        'Current Liabilities': '28728000',
        'Debt to Capital': '80.43621421',
        'Dividends Payable': '1100000',
        'Dormant Company': 'No',
        EBITDA: '4161000',
        'Financial Year End': 'December',
        'Industry Keywords': 'preserved vegetables, sweet peppers, mushrooms, potatoes, tomato, prepared vegetables, sweet potatoes, pome fruits, service provision, quality assurance, supply chain, category management, consumer health, social media, cold storage, stock exchange, infrastructure, wholesale, importation, fruit, fresh fruit, fresh produce, vegetables, fruits',
        'Net Assets': '7427000',
        'SIC 2007 Description': 'Wholesale of fruit and vegetables',
        'Shareholder Funds': '7427000',
        'Shareholders Names': 'Saybest Ltd',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Enterprise Way, Pinchbeck, Spalding, Lincolnshire, PE11 3YR',
        'Trading Address 1': 'Enterprise Way',
        'Trading Address 2': 'Pinchbeck',
        'Trading Address 3': 'Spalding',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '149962000',
        'Ultimate Domestic Parent Name': 'Tph (UK) Limited',
        'Ultimate Domestic Parent Id': 'company/gb/05989477',
        'Ultimate Parent Name': 'Total Produce Public Limited Company',
        'Ultimate Parent Id': 'company/ie/427687',
        'Web Address 1': 'http://www.totalproduce.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-3295000',
        lat: 52.80366381791218,
        'long': -0.1459264814131842
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.145089701957933,
              52.80328437668456
            ],
            [
              -0.145184895887313,
              52.803421444883895
            ],
            [
              -0.145301575170864,
              52.80339180110628
            ],
            [
              -0.145344352173204,
              52.80345333693247
            ],
            [
              -0.14557847475026,
              52.80339352144633
            ],
            [
              -0.145762212262867,
              52.803660180634
            ],
            [
              -0.145368697151815,
              52.80375976178515
            ],
            [
              -0.145632077640013,
              52.80414202794301
            ],
            [
              -0.145958260674452,
              52.80405946525363
            ],
            [
              -0.146029927952593,
              52.804163798524364
            ],
            [
              -0.146368955942009,
              52.804079187715566
            ],
            [
              -0.146286514352938,
              52.80395958192143
            ],
            [
              -0.1466146412359,
              52.803876598271714
            ],
            [
              -0.146116941361004,
              52.80315406093527
            ],
            [
              -0.145491435243492,
              52.803312323521624
            ],
            [
              -0.145414408467965,
              52.80320152271308
            ],
            [
              -0.145089701957933,
              52.80328437668456
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11116129',
        'Roof.Size': '10046.87346',
        'Estimated capacity': '1406.562284',
        'Title.Number': 'LL83151',
        Tenure: 'Freehold',
        'Property Address': 'land lying to the South West of Cresswell Close, Pinchbeck',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'WM MORRISON SUPERMARKETS PLC',
        'Company Registration No. (1)': '00358949',
        'Date Proprietor Added': '05/04/2007',
        POSTCODE_2: 'PE11 3SW',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '80',
        'Capital Employed': '7524000000',
        Cash: '305000000',
        Charges: 'Satisfied',
        'Cost of Sales': '16907000000',
        'Cost of Sales Delta': '-176000000',
        'Cost of Sales Growth': '-1.030264005',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '1322000000',
        'Current Liabilities': '3396000000',
        'Debt to Capital': '58.41575092',
        'Dividends Payable': '302000000',
        'Dormant Company': 'No',
        EBITDA: '1031000000',
        'Financial Year End': 'February',
        'Industry Keywords': 'food products, retail market, retail shop, retail outlets, retail business, b2c, retail units, retail merchandising, retail display, retail trade, retail stores, doors, manufacturing, charity, retailing, charities, accessibility, corporate responsibility, store finder, financial, butter, egg, information privacy, financial statements, grocery, corporate governance, corporate news, retailer, shop, fresh food, market share, supermarkets, stalls, cd, dvd, dvds, clothing, drink, drinks, books, magazine, retail sales, beverages, tobacco, stores, food, supermarket, retail',
        'Net Assets': '4541000000',
        'SIC 2007 Description': 'Retail sale in non-specialised stores with food, beverages or tobacco predominating',
        'Shareholder Funds': '4541000000',
        'Shareholders Names': 'Undisclosed',
        Status: 'Active',
        Telephone: '0845 611 5000',
        'Trading Address': 'Hilmore House, 71 Gain Lane, Bradford, West Yorkshire, BD3 7DL',
        'Trading Address 1': 'Hilmore House',
        'Trading Address 2': '71 Gain Lane',
        'Trading Address 3': 'Bradford',
        'Trading Address 4': 'West Yorkshire',
        Turnover: '17536000000',
        'Ultimate Domestic Parent Name': 'Wm Morrison Supermarkets P L C',
        'Ultimate Domestic Parent Id': 'company/gb/00358949',
        'Ultimate Parent Name': 'Wm Morrison Supermarkets P L C',
        'Ultimate Parent Id': 'company/gb/00358949',
        'Web Address 1': 'http://www.morrisons-corporate.com',
        'Web Address 2': 'http://www.morrisons.net',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 52.80454219882237,
        'long': -0.14367885986883358
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.143078883615463,
              52.805099892206755
            ],
            [
              -0.144808400443388,
              52.804654661275634
            ],
            [
              -0.144636524505958,
              52.804405628113216
            ],
            [
              -0.144486473859587,
              52.80444544840036
            ],
            [
              -0.144140012795426,
              52.80395543010399
            ],
            [
              -0.14326105968108,
              52.80418425004231
            ],
            [
              -0.143365184126697,
              52.80433098961827
            ],
            [
              -0.14303523812911,
              52.80441465512623
            ],
            [
              -0.142972301421083,
              52.80432493170985
            ],
            [
              -0.142596695090671,
              52.804421727151
            ],
            [
              -0.143078883615463,
              52.805099892206755
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11116416',
        'Roof.Size': '6197.671482',
        'Estimated capacity': '867.6740075',
        'Title.Number': 'LL156366',
        Tenure: 'Freehold',
        'Property Address': 'Empire World Trade Ltd, Enterprise Way, Pinchbeck, Spalding (PE11 3YR)',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'GREENYARD FRESH UK LTD',
        'Company Registration No. (1)': '02411719',
        'Date Proprietor Added': '14/06/2016',
        POSTCODE_2: 'PE11 3XH',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '31',
        'Capital Employed': '17942566',
        Cash: '7318',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '142094994',
        'Cost of Sales Delta': '-8320535',
        'Cost of Sales Growth': '-5.531699456',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '15475129',
        'Current Liabilities': '23994480',
        'Debt to Capital': '57.21547483',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '-2742197',
        'Financial Year End': 'March',
        'Industry Keywords': 'company information, business development, financial data, due diligence, corporate social responsibility, annual awards, fresh produce, flowers, fruit, dried fruit, wholesale, vegetables, fruits',
        'Net Assets': '17942566',
        'SIC 2007 Description': 'Wholesale of fruit and vegetables',
        'Shareholder Funds': '17942566',
        'Shareholders Names': 'Greenyard Fresh Nl Bv',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'C/O Duncan & Toplis Limited, Enterprise Way, Spalding, Lincolnshire Pe11 3yr, PE11 3YR',
        'Trading Address 1': 'C/O Duncan & Toplis Limited',
        'Trading Address 2': 'Enterprise Way',
        'Trading Address 3': 'Spalding',
        'Trading Address 4': 'Lincolnshire Pe11 3yr',
        Turnover: '154261214',
        'Ultimate Domestic Parent Name': 'Greenyard Fresh UK Limited',
        'Ultimate Domestic Parent Id': 'company/gb/02411719',
        'Ultimate Parent Name': 'Greenyard Fresh Holding Nl B.V.',
        'Ultimate Parent Id': 'company/nl/24395727',
        'Web Address 1': 'http://www.greenyardfresh.co.uk',
        'Web Address 2': 'http://www.national-dahlia-collection.co.uk',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-8519351',
        lat: 52.80523527606344,
        'long': -0.15084719025173354
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.151479887202895,
              52.805552027057274
            ],
            [
              -0.151148036016175,
              52.804719081797394
            ],
            [
              -0.150278194586845,
              52.80484560134652
            ],
            [
              -0.150585349882783,
              52.805620353928255
            ],
            [
              -0.150177747990772,
              52.805681161325836
            ],
            [
              -0.150228391073497,
              52.80580575295218
            ],
            [
              -0.150681264303922,
              52.80573746935676
            ],
            [
              -0.150657778224574,
              52.80567560683408
            ],
            [
              -0.151479887202895,
              52.805552027057274
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11116419',
        'Roof.Size': '4479.639746',
        'Estimated capacity': '627.1495644',
        'Title.Number': 'LL173631',
        Tenure: 'Freehold',
        'Property Address': 'Land on the East side of Moorcroft Lane, South Holland Enterprise Park, Spalding',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'PARAGON LABELS LIMITED',
        'Company Registration No. (1)': '02925612',
        'Date Proprietor Added': '16/09/1999',
        POSTCODE_2: 'PE11 3YQ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '26',
        'Capital Employed': '60769000',
        Cash: '25737000',
        Charges: 'Outstanding, Partially Satisfied, Satisfied',
        'Cost of Sales': '131816000',
        'Cost of Sales Delta': '-6556000',
        'Cost of Sales Growth': '-4.737952765',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '106952000',
        'Current Liabilities': '78917000',
        'Debt to Capital': '76.93970763',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '14316000',
        'Financial Year End': 'December',
        'Industry Keywords': 'decorative coatings, resistant coatings, specialist coatings, powder coating, film coatings, manufacturer, litigation, flexible packaging, protein, packaging company, sustainability, food products, metal coating, industrial coatings, protective coatings, investor relations, manufacturing company, medical supplies, personal care, supply chain, pet food, food service, convenience foods, capital partners, packaging solutions, investment, private investment, medical packaging, coat, technology, film, medical, electronics, paper, printing, packaging products, packaging, consumer markets, markets, manufacturing, food, coating, coatings',
        'Net Assets': '32212000',
        'SIC 2007 Description': 'Printing (other than printing of newspapers and printing on labels and tags) n.e.c.',
        'Shareholder Funds': '32212000',
        'Shareholders Names': 'Covers Flexibles Holdings UK Limited',
        Status: 'Active',
        Telephone: '01775 717300',
        'Trading Address': 'Holland Place Wardentree Park, Pinchbeck, Spalding, Lincolnshire Pe11 3z, PE11 3ZN',
        'Trading Address 1': 'Holland Place Wardentree Park',
        'Trading Address 2': 'Pinchbeck',
        'Trading Address 3': 'Spalding',
        'Trading Address 4': 'Lincolnshire Pe11 3z',
        Turnover: '154598000',
        'Ultimate Domestic Parent Name': 'Coveris Flexibles Holdings UK Limited',
        'Ultimate Domestic Parent Id': 'company/gb/08339980',
        'Ultimate Parent Name': 'Coveris Holdings S.A',
        'Ultimate Parent Id': 'company/lu/B178003',
        'Web Address 1': 'http://www.coveris.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '28035000',
        lat: 52.80365404754925,
        'long': -0.15038863945413697
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.149634305087137,
              52.80377339900122
            ],
            [
              -0.150323780513599,
              52.80370511991483
            ],
            [
              -0.150411410618004,
              52.80402781416243
            ],
            [
              -0.151020753908991,
              52.80396709287122
            ],
            [
              -0.150852804373128,
              52.803348521602274
            ],
            [
              -0.149554458712958,
              52.80347716832135
            ],
            [
              -0.149634305087137,
              52.80377339900122
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11116438',
        'Roof.Size': '6071.292672',
        'Estimated capacity': '849.9809741',
        'Title.Number': 'LL252000',
        Tenure: 'Freehold',
        'Property Address': 'Albert Warehouse, Pinchbeck Road, Spalding (PE11 1QG)',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'ELSOMS (SPALDING) LIMITED',
        'Company Registration No. (1)': '00384216',
        'Date Proprietor Added': '11/02/2005',
        POSTCODE_2: 'PE11 1QG',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '76',
        'Capital Employed': '23124168',
        Cash: '6367011',
        Charges: 'Satisfied',
        'Cost of Sales': '17122473',
        'Cost of Sales Delta': '-665478',
        'Cost of Sales Growth': '-3.741172887',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '21044101',
        'Current Liabilities': '4126005',
        'Debt to Capital': '19.18481765',
        'Dividends Payable': '455340',
        'Dormant Company': 'No',
        EBITDA: '2583457',
        'Financial Year End': 'June',
        'Industry Keywords': 'energy, treatment specialists, vegetable seeds, crop farming, technology, research, research institutions, agricultural, property, property development, live animals, semi-finished goods, textile raw materials, agricultural raw materials, vegetables, plant breeding, crops, plants, plant, seeds',
        'Net Assets': '22022277',
        'SIC 2007 Description': 'Agents involved in the sale of agricultural raw materials, live animals, textile raw materials and semi-finished goods',
        'Shareholder Funds': '22022277',
        'Shareholders Names': 'Chris Bettinson, Katie Baxter, Susan Elizabeth Kennedy, Panida Tinkran Wadsworth, Elsoms Developments Limited, Richard Plummer, Paul Hurst, Melanie Ebbage, Peter Noel Tavernor, Anthony Eric White, Rachel Petronella Keeling, Robin Laurie Wood, Graham Steward Brickles, Alison Marie Steward, Margaret Helen Desoyza, John Malcolm Keeton, Richard Alan Edmond, Adrian Hayler, Christina Tyrrell, Mark Andrew Nightingale, Bejo Zaden Bv, Andrew James Donald Vincent, Andrew Peter Davis, Rachel Mary Cook, Ian Chapman, Elsoms Sip Trustees Limited, James Anthony Wilson, Graham Collison, David James Sharples Coop, Catherine Alice Kelley, Thomas Humphrey Sinclair, Michael Andrew Todd, Anthony Stuart Guthrie, Christopher Goddard, Ral Keeling Trustjgk4a, Christopher Hodson, Mark Wilkinson, Nicholas Waterman, Miroslav Bukan, Ral Keeling Trustagk4a, George Goodwin, Sarah Jane Woolsey, Anthony Gordon Keeling, Janice Plumb, John Michael Bloomer, Milika Buurman, Ral Keeling Trustjgk2a, Andrew Piers Symons Keeling, Ral Keeling Trustagk3a, Sally Minns, Ral Keeling Trustagk1, Wendy Stacey, Robert Harvey Miles, John Draper, Gillian Mary Yates, Christopher Pratt, Charlotte Hayes, Ral Keeling Trustjgk3, Brendan White, Keely Watson, Roger Anthony Luke Keeling, Richard Tudor, Susan Hentley, Ral Keeling Trustagk2, Petronella Gerarda Keeling, Stephen Smith, Richard Moss, Matthew Jason Bean, Rachel Brundenall',
        Status: 'Active',
        Telephone: '01775 715000',
        'Trading Address': 'Albert Warehouse, Pinchbeck Road, Spalding, PE11 1QG',
        'Trading Address 1': 'Albert Warehouse',
        'Trading Address 2': 'Pinchbeck Road',
        'Trading Address 3': 'Spalding',
        'Trading Address 4': '0',
        Turnover: '25921112',
        'Ultimate Domestic Parent Name': 'Elsoms (Spalding) Limited',
        'Ultimate Domestic Parent Id': 'company/gb/00384216',
        'Ultimate Parent Name': 'Elsoms (Spalding) Limited',
        'Ultimate Parent Id': 'company/gb/00384216',
        'Web Address 1': 'http://www.elsoms.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '16918096',
        lat: 52.79702548119158,
        'long': -0.15065808715953372
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.15010441483892,
              52.79705251970286
            ],
            [
              -0.149969444106732,
              52.797020835851995
            ],
            [
              -0.149864571798618,
              52.79718516953742
            ],
            [
              -0.150024658138252,
              52.797222729434964
            ],
            [
              -0.149976510202363,
              52.79729822012525
            ],
            [
              -0.150861989381351,
              52.79750586297704
            ],
            [
              -0.151379006645044,
              52.79669594270042
            ],
            [
              -0.150692548371797,
              52.79653492077199
            ],
            [
              -0.150537792537466,
              52.79677732625694
            ],
            [
              -0.150313969836675,
              52.79672474794271
            ],
            [
              -0.15010441483892,
              52.79705251970286
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11116458',
        'Roof.Size': '3705.127505',
        'Estimated capacity': '518.7178507',
        'Title.Number': 'LL128839',
        Tenure: 'Freehold',
        'Property Address': 'land on the South side of Vernatts Drain, Spalding',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'FOWLER WELCH LIMITED',
        'Company Registration No. (1)': '01001101',
        'Date Proprietor Added': '04/08/2003',
        POSTCODE_2: 'PE11 1QP',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '49',
        'Capital Employed': '57234000',
        Cash: '7394000',
        Charges: 'Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '40207000',
        'Current Liabilities': '22702000',
        'Debt to Capital': '29.77757206',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '7149000',
        'Financial Year End': 'March',
        'Industry Keywords': 'white papers, media news, waste management, food, supply chain services, supermarket, raw materials, high street retailers, key account management, supermarkets, contract packing, transport solutions, vendor managed inventory, transport, logistics solutions, consumer goods, food manufacturing, supply chain solutions, logistics & supply chain, storage, supply chain, warehousing, manufacturer, retailer, logistics provider, logistics',
        'Net Assets': '56133000',
        'SIC 2007 Description': 'Operation of warehousing and storage facilities for land transport activities of division 49',
        'Shareholder Funds': '56133000',
        'Shareholders Names': 'Dart Group Plc',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'West Marsh Road, Spalding, Lincolnshire Pe11 2bb, PE11 2BB',
        'Trading Address 1': 'West Marsh Road',
        'Trading Address 2': 'Spalding',
        'Trading Address 3': 'Lincolnshire Pe11 2bb',
        'Trading Address 4': '0',
        Turnover: '178747000',
        'Ultimate Domestic Parent Name': 'Dart Group Plc',
        'Ultimate Domestic Parent Id': 'company/gb/01295221',
        'Ultimate Parent Name': 'Dart Group Plc',
        'Ultimate Parent Id': 'company/gb/01295221',
        'Web Address 1': 'http://www.fowlerwelch.co.uk',
        'Web Address 2': 'http://www.fowler-welch.co.uk',
        'Web Address 3': 'http://www.fowlerwelch.net',
        'Web Address 4': 'http://www.fowler-welch.net',
        'Working Capital': '17505000',
        lat: 52.79968464045456,
        'long': -0.1469999860779141
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.146507807041988,
              52.799949128278634
            ],
            [
              -0.147577186328319,
              52.799875920380074
            ],
            [
              -0.14749212697906,
              52.799420111067874
            ],
            [
              -0.146422754951262,
              52.79949340815225
            ],
            [
              -0.146507807041988,
              52.799949128278634
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11116480',
        'Roof.Size': '6003.694389',
        'Estimated capacity': '840.5172145',
        'Title.Number': 'LL219978',
        Tenure: 'Freehold',
        'Property Address': 'land on the east side of Spalding Road, Pinchbeck',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'NHS PROPERTY SERVICES LIMITED',
        'Company Registration No. (1)': '07888110',
        'Date Proprietor Added': '23/05/2013',
        POSTCODE_2: 'PE11 3DT',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '8',
        'Capital Employed': '4328769000',
        Cash: '112849000',
        Charges: '0',
        'Cost of Sales': '661172000',
        'Cost of Sales Delta': '42077000',
        'Cost of Sales Growth': '6.79653365',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '703795000',
        'Current Liabilities': '293620000',
        'Debt to Capital': '20.94462409',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '103898000',
        'Financial Year End': 'March',
        'Industry Keywords': 'residential property services, property landlords, property information, commercial property experts, property advice, property finding, property repossession, commercial property sales, commercial property letting, residential property investment, health care, delivery, pct, social, clinical services, primary care, national health service, gp practices, national health, strategic health authorities, health services, pcts, social care, gps, health authorities, primary care trusts, health, hospital & health care, property services, commercial real estate, facilities management, real estate management, property management, real estate, facilities management services, property',
        'Net Assets': '3654247000',
        'SIC 2007 Description': 'Management of real estate on a fee or contract basis',
        'Shareholder Funds': '3654247000',
        'Shareholders Names': 'Secretary of State For Health & Social Care',
        Status: 'Active',
        Telephone: '020 3049 4300',
        'Trading Address': '85 Gresham Street, London, EC2V 7NQ',
        'Trading Address 1': '85 Gresham Street',
        'Trading Address 2': 'London',
        'Trading Address 3': '0',
        'Trading Address 4': '0',
        Turnover: '797743000',
        'Ultimate Domestic Parent Name': 'Nhs Property Services Limited',
        'Ultimate Domestic Parent Id': 'company/gb/07888110',
        'Ultimate Parent Name': 'Secretary of State For Health',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://property.nhs.uk',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '0',
        lat: 52.80169637477393,
        'long': -0.15021912908095383
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.14937101108354,
              52.80156108157837
            ],
            [
              -0.149799072241654,
              52.80154860664007
            ],
            [
              -0.149809503086697,
              52.80168030357051
            ],
            [
              -0.149695442865458,
              52.801683380037986
            ],
            [
              -0.14970609973884,
              52.80182721797803
            ],
            [
              -0.149934224734114,
              52.80182097507193
            ],
            [
              -0.149938239180726,
              52.801873183857055
            ],
            [
              -0.149565607907809,
              52.80212091188853
            ],
            [
              -0.149905928751396,
              52.80230585312463
            ],
            [
              -0.150181744179343,
              52.802123236048644
            ],
            [
              -0.150029849336785,
              52.80203905246141
            ],
            [
              -0.150127999153087,
              52.80197396136493
            ],
            [
              -0.150189783697464,
              52.80201070762352
            ],
            [
              -0.150228236052677,
              52.802048438739455
            ],
            [
              -0.150246631038283,
              52.80210087168388
            ],
            [
              -0.150318987172606,
              52.802108742635305
            ],
            [
              -0.150359533652588,
              52.802121602049255
            ],
            [
              -0.150408946662973,
              52.80208982585749
            ],
            [
              -0.150361193462943,
              52.80204403798798
            ],
            [
              -0.150369667260519,
              52.802008656720815
            ],
            [
              -0.150392138986895,
              52.80198949713107
            ],
            [
              -0.15042774809159,
              52.801968384538185
            ],
            [
              -0.150494846969151,
              52.8020057529145
            ],
            [
              -0.151162245372529,
              52.80156544858662
            ],
            [
              -0.150676622305724,
              52.80129831894491
            ],
            [
              -0.150529758276748,
              52.8013962765248
            ],
            [
              -0.150255333908016,
              52.801395954855934
            ],
            [
              -0.150020715966826,
              52.801394455021054
            ],
            [
              -0.150007895004068,
              52.801224690081135
            ],
            [
              -0.149780658802627,
              52.80123103687479
            ],
            [
              -0.149794025101998,
              52.80140890199978
            ],
            [
              -0.149360351011667,
              52.80142083983463
            ],
            [
              -0.14937101108354,
              52.80156108157837
            ]
          ],
          [
            [
              -0.150181571217631,
              52.801938563908905
            ],
            [
              -0.150293915678428,
              52.801864073886044
            ],
            [
              -0.150398412144015,
              52.8019220745874
            ],
            [
              -0.150286067707461,
              52.80199656470879
            ],
            [
              -0.150181571217631,
              52.801938563908905
            ]
          ],
          [
            [
              -0.149834702978547,
              52.802152529651806
            ],
            [
              -0.149919874597225,
              52.80209613718288
            ],
            [
              -0.149991040305182,
              52.80213554721147
            ],
            [
              -0.149905868699072,
              52.80219193973127
            ],
            [
              -0.149834702978547,
              52.802152529651806
            ]
          ],
          [
            [
              -0.150469516909592,
              52.80184406454392
            ],
            [
              -0.150377628344273,
              52.801793093380205
            ],
            [
              -0.150644432683608,
              52.801616448478256
            ],
            [
              -0.15073632119864,
              52.80166741943625
            ],
            [
              -0.150469516909592,
              52.80184406454392
            ]
          ],
          [
            [
              -0.150265485472538,
              52.80166709350271
            ],
            [
              -0.150044925762871,
              52.801673365079246
            ],
            [
              -0.15003465389483,
              52.801541400930894
            ],
            [
              -0.150425420783797,
              52.80153032025164
            ],
            [
              -0.150427735775947,
              52.80155966609279
            ],
            [
              -0.150265485472538,
              52.80166709350271
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11117053',
        'Roof.Size': '4382.535255',
        'Estimated capacity': '613.5549357',
        'Title.Number': 'LL142673',
        Tenure: 'Freehold',
        'Property Address': 'land on the north and south sides of Herdgate Lane, Pinchbeck',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'L & D FLOWERS LIMITED',
        'Company Registration No. (1)': '05729536',
        'Date Proprietor Added': '27/07/2015',
        POSTCODE_2: 'PE11 3UP',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '14',
        'Capital Employed': '1325047',
        Cash: '7',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Moderate',
        'Current Assets': '2189095',
        'Current Liabilities': '2394801',
        'Debt to Capital': '87.08412279',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'April',
        'Industry Keywords': 'cereals, crop farming, non-perennial crops, farming, crops',
        'Net Assets': '480451',
        'SIC 2007 Description': 'Growing of cereals (except rice), leguminous crops and oil seeds',
        'Shareholder Funds': '480451',
        'Shareholders Names': 'Simon James Dobney, Judith Elsie Lacey, James Alexander Lacey, Robert Alexander Lacey',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Bank House, Broad Street, Spalding, Lincolnshire, PE11 1TB',
        'Trading Address 1': 'Bank House',
        'Trading Address 2': 'Broad Street',
        'Trading Address 3': 'Spalding',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '-205706',
        lat: 52.82538272081947,
        'long': -0.14480836573196051
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.144396609514069,
              52.825319579652636
            ],
            [
              -0.144214070401192,
              52.82534684446972
            ],
            [
              -0.144366314078633,
              52.82572054068653
            ],
            [
              -0.145466889429551,
              52.82556062586532
            ],
            [
              -0.145306487867952,
              52.825151739909224
            ],
            [
              -0.145018557349614,
              52.825193182616395
            ],
            [
              -0.144952569815063,
              52.8250244747469
            ],
            [
              -0.144316824351202,
              52.825115951601155
            ],
            [
              -0.144396609514069,
              52.825319579652636
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11117242',
        'Roof.Size': '4855.112842',
        'Estimated capacity': '679.7157979',
        'Title.Number': 'LL163416',
        Tenure: 'Freehold',
        'Property Address': 'Land on the north side of Wardentree Lane, Pinchbeck',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'GUTTRIDGE SERVICES LIMITED',
        'Company Registration No. (1)': '00731549',
        'Date Proprietor Added': '11/01/1999',
        POSTCODE_2: 'PE11 3UU',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '58',
        'Capital Employed': '4297328',
        Cash: '21292',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '7988033',
        'Cost of Sales Delta': '878230',
        'Cost of Sales Growth': '12.35238163',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '2958786',
        'Current Liabilities': '2036817',
        'Debt to Capital': '40.97738527',
        'Dividends Payable': '38698',
        'Dormant Company': 'No',
        EBITDA: '432668',
        'Financial Year End': 'December',
        'Industry Keywords': 'used machinery, manufacturing industry, forestry machinery, welding fabrication, commercial manufacturing, fabrication company, pipe manufacturing, manufacturing software, metal sheet, sheet metal engineering, industrial manufacturing, manufacturing company, personal data, research, energy, energy consumption, university, grain, technology, high throughput, pharmaceuticals, cement, handling solutions, bulk materials handling, bulk materials, screw conveyors, chain conveyors, industrial sectors, bucket elevators, belt conveyors, feeds, sheets, design, food, sheet, chemical, lifting equipment, chemicals, material handling, handling equipment, material handling equipment, sheet metal fabrication, pharmaceutical industry, sheet metal, fabrication work, machinery, manufacturing',
        'Net Assets': '3738578',
        'SIC 2007 Description': 'Manufacture of lifting and handling equipment',
        'Shareholder Funds': '3738578',
        'Shareholders Names': 'Mitchells Holdings (Asia) Pte Limited',
        Status: 'Active',
        Telephone: '01775 765300',
        'Trading Address': 'Wardentree Park, Pinchbeck, Spalding, Lincolnshire, PE11 3UU',
        'Trading Address 1': 'Wardentree Park',
        'Trading Address 2': 'Pinchbeck',
        'Trading Address 3': 'Spalding',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '11527362',
        'Ultimate Domestic Parent Name': 'Guttridge Limited',
        'Ultimate Domestic Parent Id': 'company/gb/00731549',
        'Ultimate Parent Name': 'Mitchells Holdings (Asia) Pte Limited',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.guttridge.com',
        'Web Address 2': 'http://www.guttridge.co.uk',
        'Web Address 3': 'http://www.laserfab.co.uk',
        'Web Address 4': '0',
        'Working Capital': '921969',
        lat: 52.80933390898051,
        'long': -0.14255294282687628
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.143219482571911,
              52.8092305433752
            ],
            [
              -0.142884706054647,
              52.809148164325194
            ],
            [
              -0.142932871081357,
              52.80907636294618
            ],
            [
              -0.142474898760003,
              52.80896382386933
            ],
            [
              -0.142427037316792,
              52.809035450005425
            ],
            [
              -0.142090062751223,
              52.808952494839424
            ],
            [
              -0.141814197035964,
              52.809364086375794
            ],
            [
              -0.142264089503137,
              52.80947479325483
            ],
            [
              -0.142304340451592,
              52.80941464607933
            ],
            [
              -0.142631628674579,
              52.80949520131627
            ],
            [
              -0.142440619924705,
              52.80977487987654
            ],
            [
              -0.142791145981766,
              52.80986380013734
            ],
            [
              -0.143219482571911,
              52.8092305433752
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11117257',
        'Roof.Size': '4689.620681',
        'Estimated capacity': '656.5468953',
        'Title.Number': 'LL209104',
        Tenure: 'Freehold',
        'Property Address': 'Land lying to the North of Wardentree Lane, Pinchbeck',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'LINGARDEN BULBS LIMITED',
        'Company Registration No. (1)': '05984372',
        'Date Proprietor Added': '21/03/2007',
        POSTCODE_2: 'PE11 3ZN',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '13',
        'Capital Employed': '2267250',
        Cash: '966918',
        Charges: 'Outstanding',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '1196291',
        'Current Liabilities': '505766',
        'Debt to Capital': '36.26643337',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'December',
        'Industry Keywords': 'plants, wholesale, flowers',
        'Net Assets': '1767342',
        'SIC 2007 Description': 'Wholesale of flowers and plants',
        'Shareholder Funds': '1767342',
        'Shareholders Names': 'Caroline Sarah Gibbard Mrs, Stuart Carr Mr, Jan De Kroon Mr',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': '1-4 London Road, Spalding, Lincolnshire, PE11 2TA',
        'Trading Address 1': '1-4 London Road',
        'Trading Address 2': 'Spalding',
        'Trading Address 3': 'Lincolnshire',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'Lingarden Bulbs Limited',
        'Ultimate Domestic Parent Id': 'company/gb/05984372',
        'Ultimate Parent Name': 'Lingarden Bulbs Limited',
        'Ultimate Parent Id': 'company/gb/05984372',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '690525',
        lat: 52.811102484152805,
        'long': -0.14129686539822933
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.141098322265867,
              52.810692132874344
            ],
            [
              -0.14083310547905,
              52.81110056239602
            ],
            [
              -0.140716924817619,
              52.811072758990754
            ],
            [
              -0.140585278523119,
              52.81127523528992
            ],
            [
              -0.141548837597048,
              52.811505117415344
            ],
            [
              -0.141945545878924,
              52.81089411638837
            ],
            [
              -0.141098322265867,
              52.810692132874344
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11117297',
        'Roof.Size': '3692.086573',
        'Estimated capacity': '516.8921202',
        'Title.Number': 'LL390150',
        Tenure: 'Freehold',
        'Property Address': 'Wool Hall Farm, Cross Gate, Wykeham, Spalding (PE12 6HW)',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'LINCOLNSHIRE FIELD PRODUCTS LIMITED',
        'Company Registration No. (1)': '01870434',
        'Date Proprietor Added': '09/04/2019',
        POSTCODE_2: 'PE12 6HW',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '35',
        'Capital Employed': '13550661',
        Cash: '291',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '41632192',
        'Cost of Sales Delta': '-4786370',
        'Cost of Sales Growth': '-10.31132761',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '22650327',
        'Current Liabilities': '21553349',
        'Debt to Capital': '63.97738321',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '496939',
        'Financial Year End': 'February',
        'Industry Keywords': 'fresh produce, nursery products, vegetables, horticultural specialties, arable farming, melons, tubers, farming',
        'Net Assets': '12645383',
        'SIC 2007 Description': 'Growing of vegetables and melons, roots and tubers',
        'Shareholder Funds': '12645383',
        'Shareholders Names': 'Keepstem Ltd',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Wool Hall Farm, Cross Gate, Wykeham, Spalding, Lincolnshire, PE12 6HW',
        'Trading Address 1': 'Wool Hall Farm',
        'Trading Address 2': 'Cross Gate',
        'Trading Address 3': 'Wykeham',
        'Trading Address 4': 'Spalding, Lincolnshire',
        Turnover: '53610996',
        'Ultimate Domestic Parent Name': 'Keepstem Limited',
        'Ultimate Domestic Parent Id': 'company/gb/03466000',
        'Ultimate Parent Name': 'Keepstem Limited',
        'Ultimate Parent Id': 'company/gb/03466000',
        'Web Address 1': 'http://www.lincsfp.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '1096978',
        lat: 52.80696094542116,
        'long': -0.10668164125783099
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.106600554607563,
              52.80719073781153
            ],
            [
              -0.107336359936301,
              52.8071859329771
            ],
            [
              -0.107329098059398,
              52.80677925431043
            ],
            [
              -0.105886552514874,
              52.806789502990526
            ],
            [
              -0.105892452421596,
              52.80706336632413
            ],
            [
              -0.106597631621692,
              52.80705654879852
            ],
            [
              -0.106600554607563,
              52.80719073781153
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11117300',
        'Roof.Size': '7379.409213',
        'Estimated capacity': '1033.11729',
        'Title.Number': 'LL390150',
        Tenure: 'Freehold',
        'Property Address': 'Wool Hall Farm, Cross Gate, Wykeham, Spalding (PE12 6HW)',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'LINCOLNSHIRE FIELD PRODUCTS LIMITED',
        'Company Registration No. (1)': '01870434',
        'Date Proprietor Added': '09/04/2019',
        POSTCODE_2: 'PE12 6HW',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '35',
        'Capital Employed': '13550661',
        Cash: '291',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '41632192',
        'Cost of Sales Delta': '-4786370',
        'Cost of Sales Growth': '-10.31132761',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '22650327',
        'Current Liabilities': '21553349',
        'Debt to Capital': '63.97738321',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '496939',
        'Financial Year End': 'February',
        'Industry Keywords': 'fresh produce, nursery products, vegetables, horticultural specialties, arable farming, melons, tubers, farming',
        'Net Assets': '12645383',
        'SIC 2007 Description': 'Growing of vegetables and melons, roots and tubers',
        'Shareholder Funds': '12645383',
        'Shareholders Names': 'Keepstem Ltd',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Wool Hall Farm, Cross Gate, Wykeham, Spalding, Lincolnshire, PE12 6HW',
        'Trading Address 1': 'Wool Hall Farm',
        'Trading Address 2': 'Cross Gate',
        'Trading Address 3': 'Wykeham',
        'Trading Address 4': 'Spalding, Lincolnshire',
        Turnover: '53610996',
        'Ultimate Domestic Parent Name': 'Keepstem Limited',
        'Ultimate Domestic Parent Id': 'company/gb/03466000',
        'Ultimate Parent Name': 'Keepstem Limited',
        'Ultimate Parent Id': 'company/gb/03466000',
        'Web Address 1': 'http://www.lincsfp.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '1096978',
        lat: 52.80782912482125,
        'long': -0.10695969010354398
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.107069690154172,
              52.80743656782783
            ],
            [
              -0.106258726775989,
              52.8074329790076
            ],
            [
              -0.106264532191864,
              52.808166539102274
            ],
            [
              -0.107736956097659,
              52.8081662921891
            ],
            [
              -0.107738258404037,
              52.80757939528622
            ],
            [
              -0.107069571155562,
              52.807579339429516
            ],
            [
              -0.107069690154172,
              52.80743656782783
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11118206',
        'Roof.Size': '3935.964309',
        'Estimated capacity': '551.0350033',
        'Title.Number': 'LL362252',
        Tenure: 'Freehold',
        'Property Address': 'Wragg Marsh Farm, Marsh Road, Spalding Marsh, Spalding (PE12 6HQ)',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'GEORGE HAY & SONS LIMITED',
        'Company Registration No. (1)': '00636201',
        'Date Proprietor Added': '01/12/2015',
        POSTCODE_2: 'PE12 6HQ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '61',
        'Capital Employed': '6661989',
        Cash: '188576',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '1148339',
        'Current Liabilities': '824834',
        'Debt to Capital': '15.53832647',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'May',
        'Industry Keywords': 'seeds, leguminous crops, crop farming, farming, cereals, farmers, crops, oil seeds',
        'Net Assets': '6323496',
        'SIC 2007 Description': 'Growing of cereals (except rice), leguminous crops and oil seeds',
        'Shareholder Funds': '6323496',
        'Shareholders Names': 'Reamcourt Ltd',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Wykeham Abbey, The Chase, Wykeham, Spalding, PE12 6HE',
        'Trading Address 1': 'Wykeham Abbey',
        'Trading Address 2': 'The Chase',
        'Trading Address 3': 'Wykeham',
        'Trading Address 4': 'Spalding',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'Reamcourt Limited',
        'Ultimate Domestic Parent Id': 'company/gb/03499986',
        'Ultimate Parent Name': 'Reamcourt Limited',
        'Ultimate Parent Id': 'company/gb/03499986',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '323505',
        lat: 52.85447271824634,
        'long': -0.07614969561765755
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.076315078476811,
              52.85488157206087
            ],
            [
              -0.076829343446583,
              52.85456111212534
            ],
            [
              -0.075982978263847,
              52.854065314166306
            ],
            [
              -0.075469665874637,
              52.8543810208017
            ],
            [
              -0.076315078476811,
              52.85488157206087
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11118526',
        'Roof.Size': '9152.35555',
        'Estimated capacity': '1281.329777',
        'Title.Number': 'LL292157',
        Tenure: 'Freehold',
        'Property Address': 'Downtown Furnishing Store, Grantham Road, Boston (PE21 7NL)',
        District: 'BOSTON',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'OLDRID & CO., LIMITED',
        'Company Registration No. (1)': '00284283',
        'Date Proprietor Added': '16/01/2008',
        POSTCODE_2: 'PE21 7JD',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '86',
        'Capital Employed': '39188974',
        Cash: '388679',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '22109348',
        'Cost of Sales Delta': '-3032023',
        'Cost of Sales Growth': '-12.05989522',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '11811635',
        'Current Liabilities': '5807511',
        'Debt to Capital': '26.54267106',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '165061',
        'Financial Year End': 'January',
        'Industry Keywords': 'italian restaurant, greek restaurant, seafood restaurant, led lighting products, lighting fixtures, rosette restaurant, thai restaurant, led lighting, vegetarian restaurant, decorative lighting, lifestyle, creative, free delivery, retailer, curtains, delivery, duvet, cold drinks, charity work, email newsletter, family business, electrical appliances, office chairs, family run business, kitchen utensils, family run, retail sales, computer, travel, retail distribution, furniture, food, bathroom, garden, garden centre, shop, home accessories, bedding, restaurants, office furniture, tableware, home office, stores, bathroom lighting, lighting, restaurant',
        'Net Assets': '33053216',
        'SIC 2007 Description': 'Other retail sale in non-specialised stores',
        'Shareholder Funds': '33053216',
        'Shareholders Names': 'A.L. Hodgson, Joseph Scrase, R.J. Isaac, R.D. Armstrong, M.E. Medlock Mrs, W. Lawton, J. Holmes, Jan Alsnas, D.M. Milne Mrs, J.R. Crookall, C.E. Berman Mrs, P.J. Russell Mrs, E.A. Crookall, S.J. Isaac, H.A. Andrieu, R.E. Baumber Mrs, G.M. Smith Mr, R.E. Stephenson Mrs, A.K. Boyd, N.J. Ennion Mrs, T. Crookall Mr, J.F. Ball, P. Maltby Mrs, P.A. Clark, Emma Nevitt, R.S. Isaac, C.R. Tupper Mr, C. Read Mr, J. Isaac, R.A. Almond, K.L. Isaac, J.E. Sherwood Jones, R. Crookall, John Rogers, S.M. Hayes, Andrew John Isaac, Richard Broadhead, M. Johnson, C. Smith Mrs, C.M. Phoenix Mrs, R.B. Isaac, S.K. Isaac Mrs, Victoria Scrase, A.M. Isaac, R.D. Goulder, S.J. Boyd-Noel, W.P. Medlock Mr, R D Isaac Trustee For Lji, M.J. Armstrong, Laura Cook, S.J. Isaac Miss, J. Brooks, Theresa Scrase, Elizabeth Ann Freeston, V. Hall, R.D. Isaac (Aw Isaac Trust), P.J. Melvin Mr, J. Johnston, J.M. Isaac, C.M. Baines, K.M. Buckley, E.J. Harwood Mr, V.J. Russell Miss, S.C. Medlock, C. Thomas, S.E. Coote, T.C.W. Russell Mr, R. Isaac, F. Isaac Mrs, N.D. Isaac Mr, Bjorn Alsnas, S.J. Holland, M.J. Stephenson Mr, L.P. Goulder, J.M. Havell, June Parker, R. Mitchell Mrs, James Isaac Mr, S.J. Medlock, P.E. Isaac, E. Isaac Mrs, C.G. Crookall, D.E. Hannay, Christine Medlock, R.M. Macintyre, S.M. Isaac, J.C. Young Mrs, Jopt, I. Nodder Mrs, I.M. Booth, S.E. Hubbard, H. Patel, A.B. Isaac, J.A. Nickols Mrs, S.D. Smith Mrs, R.T. Cook, S.D. Goulder, M.E. Durant, S.E. Brookes, K.L. Craven',
        Status: 'Active',
        Telephone: '01205 361251',
        'Trading Address': '11 Strait Bargate, Boston, Lincolnshire, PE21 6UF',
        'Trading Address 1': '11 Strait Bargate',
        'Trading Address 2': 'Boston',
        'Trading Address 3': 'Lincolnshire',
        'Trading Address 4': '0',
        Turnover: '35983132',
        'Ultimate Domestic Parent Name': 'Oldrid & Co.,Limited',
        'Ultimate Domestic Parent Id': 'company/gb/00284283',
        'Ultimate Parent Name': 'Oldrid & Co.,Limited',
        'Ultimate Parent Id': 'company/gb/00284283',
        'Web Address 1': 'http://www.oldrids.co.uk',
        'Web Address 2': 'http://www.oldridsandcoltd.co.uk',
        'Web Address 3': 'http://www.downtowndirect.co.uk',
        'Web Address 4': '0',
        'Working Capital': '6004124',
        lat: 52.97084957066047,
        'long': -0.05973415772568921
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.059373510162795,
              52.971487273636896
            ],
            [
              -0.060356413209869,
              52.97143139298467
            ],
            [
              -0.060196833670886,
              52.97049196605894
            ],
            [
              -0.060182523915889,
              52.97042906788618
            ],
            [
              -0.060155369260226,
              52.97025061040218
            ],
            [
              -0.058919098217919,
              52.97032141171492
            ],
            [
              -0.058953140459439,
              52.97052884189579
            ],
            [
              -0.059209178757897,
              52.97051477207427
            ],
            [
              -0.059373510162795,
              52.971487273636896
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11119995',
        'Roof.Size': '7430.994677',
        'Estimated capacity': '1040.339255',
        'Title.Number': 'LL308742',
        Tenure: 'Freehold',
        'Property Address': 'Land on the north of Burtoft Lane, Wigtoft, Boston',
        District: 'BOSTON',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'G W PADLEY HOLDINGS LIMITED',
        'Company Registration No. (1)': '00633999',
        'Date Proprietor Added': '03/07/2009',
        POSTCODE_2: 'PE20 2NZ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '61',
        'Capital Employed': '38089000',
        Cash: '15613000',
        Charges: 'Satisfied',
        'Cost of Sales': '3157000',
        'Cost of Sales Delta': '-5522000',
        'Cost of Sales Growth': '-63.62484157',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '18219000',
        'Current Liabilities': '1705000',
        'Debt to Capital': '5.292255114',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '1831000',
        'Financial Year End': 'July',
        'Industry Keywords': 'poultry products, poultry meat, head offices, holding companies management, vegetables, arable farming, poultry, vegetable processing',
        'Net Assets': '37688000',
        'SIC 2007 Description': 'Activities of head offices',
        'Shareholder Funds': '37688000',
        'Shareholders Names': 'Zedra Holdings (Cayman) Limited, Zedra Trust Company (Suisse) Sa',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': 'Cumberland Court, 80 Mount Street, Nottingham, Nottinghamshire, NG1 6HH',
        'Trading Address 1': 'Cumberland Court',
        'Trading Address 2': '80 Mount Street',
        'Trading Address 3': 'Nottingham',
        'Trading Address 4': 'Nottinghamshire',
        Turnover: '5269000',
        'Ultimate Domestic Parent Name': 'G W Padley Holdings Limited',
        'Ultimate Domestic Parent Id': 'company/gb/00633999',
        'Ultimate Parent Name': 'Zedra Holdings (Cayman) Ltd',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.gwpadley.co.uk',
        'Web Address 2': 'http://www.chickenology.co.uk',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '16514000',
        lat: 52.906105915666224,
        'long': -0.11711620925881305
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.117540475142674,
              52.90656108019369
            ],
            [
              -0.117803239982396,
              52.906609028551145
            ],
            [
              -0.118142092880486,
              52.90593075035998
            ],
            [
              -0.116570118112556,
              52.90564366834917
            ],
            [
              -0.116603492137905,
              52.90557703754508
            ],
            [
              -0.116473812596242,
              52.905553313850994
            ],
            [
              -0.11610042000825,
              52.90630053722518
            ],
            [
              -0.116364507332868,
              52.90634877948419
            ],
            [
              -0.116691652518478,
              52.905694054862266
            ],
            [
              -0.116788139238995,
              52.905711677874145
            ],
            [
              -0.116462785883611,
              52.906362834913786
            ],
            [
              -0.116725548279275,
              52.90641078563549
            ],
            [
              -0.117050898088503,
              52.90575962784849
            ],
            [
              -0.117146204098666,
              52.9057770520377
            ],
            [
              -0.116819870440066,
              52.90643035222765
            ],
            [
              -0.11708425659494,
              52.906478597611496
            ],
            [
              -0.117410731378406,
              52.90582538886702
            ],
            [
              -0.117510022625705,
              52.905843505268756
            ],
            [
              -0.11718386197551,
              52.9064963596296
            ],
            [
              -0.117442199094324,
              52.906543519357896
            ],
            [
              -0.117768356249055,
              52.90589066426047
            ],
            [
              -0.117866466786087,
              52.90590858183955
            ],
            [
              -0.117540475142674,
              52.90656108019369
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11120263',
        'Roof.Size': '4054.9494',
        'Estimated capacity': '567.692916',
        'Title.Number': 'LL113562',
        Tenure: 'Freehold',
        'Property Address': 'land and buildings on the north side of A17, Hoffleet Stow, Bicker',
        District: 'BOSTON',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'HANDLESPARK LIMITED',
        'Company Registration No. (1)': '03642105',
        'Date Proprietor Added': '10/01/2011',
        POSTCODE_2: 'PE20 3AF',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '22',
        'Capital Employed': '1323792',
        Cash: '434',
        Charges: 'Outstanding, Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Low',
        'Current Assets': '202711',
        'Current Liabilities': '23819',
        'Debt to Capital': '47.70731316',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'September',
        'Industry Keywords': 'food serving, food, nuts, catering, fruit, fruits',
        'Net Assets': '704702',
        'SIC 2007 Description': 'Other food service activities',
        'Shareholder Funds': '704702',
        'Shareholders Names': 'Jonathan Mark Brunton & Francesca Ida Gair Brunton',
        Status: 'Active',
        Telephone: '0',
        'Trading Address': '4-6 Dudley Road, Tunbridge Wells, Kent, TN1 1LF',
        'Trading Address 1': '4-6 Dudley Road',
        'Trading Address 2': 'Tunbridge Wells',
        'Trading Address 3': 'Kent',
        'Trading Address 4': '0',
        Turnover: '0',
        'Ultimate Domestic Parent Name': 'Handlespark Limited',
        'Ultimate Domestic Parent Id': 'company/gb/03642105',
        'Ultimate Parent Name': 'Handlespark Limited',
        'Ultimate Parent Id': 'company/gb/03642105',
        'Web Address 1': '0',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '178892',
        lat: 52.91522786488303,
        'long': -0.14482207492042004
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.144291718906834,
              52.914871973889106
            ],
            [
              -0.144048070721614,
              52.91522734106941
            ],
            [
              -0.144429963824148,
              52.91532258331395
            ],
            [
              -0.144540599402835,
              52.91535442982889
            ],
            [
              -0.145594100214181,
              52.91561866016542
            ],
            [
              -0.145673292965278,
              52.91550571607032
            ],
            [
              -0.145300778661055,
              52.91541017475707
            ],
            [
              -0.145471379844854,
              52.91516991286692
            ],
            [
              -0.144291718906834,
              52.914871973889106
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11127937',
        'Roof.Size': '3937.488546',
        'Estimated capacity': '551.2483964',
        'Title.Number': 'LL62025',
        Tenure: 'Freehold',
        'Property Address': 'Land on the North side of Northgate, Pinchbeck, Spalding',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'D P PACKAGING (SPALDING) LIMITED',
        'Company Registration No. (1)': '02241100',
        'Date Proprietor Added': '02/08/2019',
        POSTCODE_2: 'PE11 3SQ',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '32',
        'Capital Employed': '3658018',
        Cash: '888853',
        Charges: 'Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '3014242',
        'Current Liabilities': '770197',
        'Debt to Capital': '19.53676594',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'June',
        'Industry Keywords': 'prepared vegetables, preserved vegetables, sweet potatoes, tomato, potatoes, pome fruits, mushrooms, sweet peppers, food serving, association, catering, packagers, packaging, cold storage, food, soil, packers, soil association, storage, vegetables, fruit, fruits',
        'Net Assets': '3563085',
        'SIC 2007 Description': 'Other food service activities',
        'Shareholder Funds': '3563085',
        'Shareholders Names': 'Mark Jonathan Parkinson, David John Parkinson',
        Status: 'Active',
        Telephone: '01775 766069',
        'Trading Address': 'Northgate, Pinchbeck, Spalding, Lincolnshire, PE11 3SQ',
        'Trading Address 1': 'Northgate',
        'Trading Address 2': 'Pinchbeck',
        'Trading Address 3': 'Spalding',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.dppackaging.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '2244045',
        lat: 52.81792325348325,
        'long': -0.18089725049528727
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.180760665366976,
              52.81772405253673
            ],
            [
              -0.180738260620721,
              52.81782701177624
            ],
            [
              -0.180496674918934,
              52.817809013099684
            ],
            [
              -0.180537237399851,
              52.81761597613982
            ],
            [
              -0.180283323002562,
              52.81759473118614
            ],
            [
              -0.180184084303895,
              52.8180662081732
            ],
            [
              -0.181548772278498,
              52.81816948045515
            ],
            [
              -0.181627594492301,
              52.81778858517641
            ],
            [
              -0.180760665366976,
              52.81772405253673
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        fid: '11127993',
        'Roof.Size': '7199.910196',
        'Estimated capacity': '1007.987427',
        'Title.Number': 'LL62025',
        Tenure: 'Freehold',
        'Property Address': 'Land on the North side of Northgate, Pinchbeck, Spalding',
        District: 'SOUTH HOLLAND',
        County: 'LINCOLNSHIRE',
        Region: 'EAST MIDLANDS',
        Freeholder: 'D P PACKAGING (SPALDING) LIMITED',
        'Company Registration No. (1)': '02241100',
        'Date Proprietor Added': '02/08/2019',
        POSTCODE_2: 'PE11 3SL',
        'Proprietorship Category': 'Limited Company or Public Limited Company',
        Age: '32',
        'Capital Employed': '3658018',
        Cash: '888853',
        Charges: 'Satisfied',
        'Cost of Sales': '0',
        'Cost of Sales Delta': '0',
        'Cost of Sales Growth': '0',
        Country: 'GB',
        'Credit Risk': 'Very Low',
        'Current Assets': '3014242',
        'Current Liabilities': '770197',
        'Debt to Capital': '19.53676594',
        'Dividends Payable': '0',
        'Dormant Company': 'No',
        EBITDA: '0',
        'Financial Year End': 'June',
        'Industry Keywords': 'prepared vegetables, preserved vegetables, sweet potatoes, tomato, potatoes, pome fruits, mushrooms, sweet peppers, food serving, association, catering, packagers, packaging, cold storage, food, soil, packers, soil association, storage, vegetables, fruit, fruits',
        'Net Assets': '3563085',
        'SIC 2007 Description': 'Other food service activities',
        'Shareholder Funds': '3563085',
        'Shareholders Names': 'Mark Jonathan Parkinson, David John Parkinson',
        Status: 'Active',
        Telephone: '01775 766069',
        'Trading Address': 'Northgate, Pinchbeck, Spalding, Lincolnshire, PE11 3SQ',
        'Trading Address 1': 'Northgate',
        'Trading Address 2': 'Pinchbeck',
        'Trading Address 3': 'Spalding',
        'Trading Address 4': 'Lincolnshire',
        Turnover: '0',
        'Ultimate Domestic Parent Name': '0',
        'Ultimate Domestic Parent Id': '0',
        'Ultimate Parent Name': '0',
        'Ultimate Parent Id': '0',
        'Web Address 1': 'http://www.dppackaging.com',
        'Web Address 2': '0',
        'Web Address 3': '0',
        'Web Address 4': '0',
        'Working Capital': '2244045',
        lat: 52.81792766047891,
        'long': -0.18209235690573683
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -0.181846821760178,
              52.81760628678123
            ],
            [
              -0.18178922899999,
              52.818555896824215
            ],
            [
              -0.182680719280598,
              52.818576384076415
            ],
            [
              -0.18292928299944,
              52.818523279111965
            ],
            [
              -0.182932055441767,
              52.81846748958626
            ],
            [
              -0.182526637139532,
              52.818463529416626
            ],
            [
              -0.182550766770672,
              52.818009690952906
            ],
            [
              -0.182661677176714,
              52.818011928794036
            ],
            [
              -0.182666358856446,
              52.817924611297805
            ],
            [
              -0.18229098379897,
              52.817915626030285
            ],
            [
              -0.182309729237872,
              52.81767622219903
            ],
            [
              -0.182174395278795,
              52.817654729548856
            ],
            [
              -0.182216642675436,
              52.81754560070835
            ],
            [
              -0.182403124715475,
              52.817571832548964
            ],
            [
              -0.182479794623794,
              52.81737035729888
            ],
            [
              -0.181473811002837,
              52.81722080631563
            ],
            [
              -0.181452748747174,
              52.81728099071697
            ],
            [
              -0.181340978570104,
              52.81752688076046
            ],
            [
              -0.181846821760178,
              52.81760628678123
            ]
          ]
        ]
      }
    }
  ]
}

export default lincolnshire;