import React, { PureComponent } from 'react';
import { ListGroup, Card, Accordion } from 'react-bootstrap';
import classes from './drawer.module.css';

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

class Drawer extends PureComponent {
  //console.log('DATA IN DRAWER');
  //console.log(this.props.data2);
  render() {
    this.props.lincolnshire.features.sort((a, b) => {
      if (a.properties['Freeholder'] === b.properties['Freeholder']) {
        return b.properties['Roof.Size'] - b.properties['Roof.Size'];
      }
      return a.properties['Freeholder'] > b.properties['Freeholder'] ? 1 : -1;
    });

    // this.props.data3.features.sort((a, b) =>
    //   a.properties['Proprietor.Name..1.'] > b.properties['Proprietor.Name..1.']
    //     ? 1
    //     : -1
    // );
    // const leicester = this.props.data.features.filter(
    //   (o) => o.properties.Project === 'Leeds'
    // );
    //console.log(leeds);
    // const didcot = this.props.data.features.filter(
    //   (o) => o.properties.Project === 'Didcot'
    // );
    //console.log(didcot);
    // const drawerData =
    //   this.props.data.features.map(property =>
    //     <ListGroup.Item onClick={this.this.props.click(property.longitude,property.latitude)}>
    //       <p><strong>Freeholder: </strong>MUIEEE</p>
    //     </ListGroup.Item>)
    return (
      <div>
        <Accordion>
          <Card className="bg-dark">
            <Accordion.Toggle as={Card.Header} eventKey="0">
              {this.props.lincolnshire.name}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <ListGroup className={classes.Item}>
                  {this.props.lincolnshire
                    ? this.props.lincolnshire.features.map((property) => (
                        <ListGroup.Item
                          action
                          onClick={() =>
                            this.props.click(
                              property.properties['lat'],
                              parseFloat(property.properties['long'], 10),
                              this.props.lincolnshire.name
                            )
                          }
                          className="bg-dark"
                        >
                          <p
                            style={{
                              fontSize: '11px',
                              marginBottom: '5px',
                              color: 'white',
                            }}
                          >
                            <strong> Freeholder: </strong>
                            {property.properties['Freeholder']}{' '}
                          </p>
                          {/* {property.properties.Leaseholder !== 'n/a' ? (
                        <p
                          style={{
                            fontSize: '11px',
                            marginBottom: '5px',
                            color: 'white',
                          }}
                        >
                          <strong> Leaseholder: </strong>
                          {property.properties.Leaseholder}
                        </p>
                      ) : null} */}
                          <p
                            style={{
                              fontSize: '11px',
                              marginBottom: '5px',
                              color: 'white',
                            }}
                          >
                            <strong> Capacity (kWp): </strong>
                            {numberWithCommas(
                              parseInt(
                                (parseInt(
                                  property.properties['Roof.Size'],
                                  10
                                ) *
                                  140) /
                                  1000,
                                10
                              )
                            )}
                          </p>
                        </ListGroup.Item>
                      ))
                    : null}
                </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>

      /* <ListGroup className={classes.Item}>
      {this.props.data.features ? this.props.data.features.map(property => <ListGroup.Item action onClick={() => this.props.click(property.properties["Latitude"], property.properties["Longitude"])} className="bg-dark"> 
        <p style={{fontSize: '11px', marginBottom: '5px', color: 'white'}}><strong> Freeholder: </strong>{property.properties.Freeholder} </p>
        {property.properties.Leaseholder !== 'n/a' ? <p style={{fontSize: '11px', marginBottom: '5px',  color: 'white'}}><strong> Leaseholder: </strong>{property.properties.Leaseholder}</p> : null }
        <p style={{fontSize: '11px', marginBottom: '5px',  color: 'white'}}><strong> Average Load (kWh): </strong>{property.properties['Average Load (kWh)']}</p>
        </ListGroup.Item>) : null}
    </ListGroup> */
    );
  }
}

export default Drawer;
