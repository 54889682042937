function doctorData (data) {
  if(data === "undefined" || data === "") return null;
  else return data;
}

//!!for fuck sake, data is killing me

const helpers = {
    propertyData: function (property) {

    if(property.length > 0) {

      const data = property[0].properties

      const myData = {
        fid: data.fid,
        titleNumber: doctorData(data["title Number"]) || doctorData(data["Title Number"]),
        region: doctorData(data.Region),
        proprietorshipCategory: doctorData(data["Proprietorship Category (1)"]),
        proprietorName: doctorData(data["Proprietor Name (1)"]),
        propertyAddress: doctorData(data["Property Address"]),
        pricePaid: doctorData(data["Price Paid"]),
        postcode: doctorData(data.Postcode),
        multipleAddressIndicator: doctorData(data["Multiple Address Indicator"]),
        district: doctorData(data.District),
        county: doctorData(data.County),
        dateProprietorAdded: doctorData(data["Date Proprietor Added"]),
        companyRegistrationNumber: doctorData(data["Company Registration No. (1)"])
      }

      return myData;

    } else 
    return null;
  },
  roofData: function (roof) {
    if(roof.length > 0) {
      return roof[0].properties["Roof Size"].toFixed(3)
    } else 
    return null;
  },
  leasesData: function (leases) {
    if(leases.length > 0) {
      return leases.map(lease => lease.properties).map(lease => {
        const myData = {
          lid: lease.fid,
          region: doctorData(lease.Region) || null,
          district: doctorData(lease.District) || null,
          county: doctorData(lease.County) || null,
          address: doctorData(lease['Property Address']) || null,
          proprietor: doctorData(lease['Proprietor Name (1)']) || null,
          companyNo: doctorData(lease['Company Registration No. (1)']) || null,
          datePropr: doctorData(lease["Date Proprietor Added"]) || null
        }
        return myData;
      });
    } else 
    return null;
  },
  // getId: function (property) {
  //   if (property.fid) {
  //     console.log("AVEM FID: " + property.fid)
  //     return property.fid;
  //   } else {
  //     console.log("AVEM LID: " + property.leaseholds[0].lid);
  //     return property.leaseholds[0].lid;
  //   }
  // }
}

export default helpers;